import { createContext, useContext, useReducer } from 'react';

import { IntegrationService, OrganizationType } from '@/classes/Organization';
import User from '@/classes/User';

import { DEFAULT_UPDATES_LIMIT } from '@/lib/config';
import contentMatches from '@/lib/helpers/contentMatches';
import getErrorMessage from '@/lib/helpers/getErrorMessage';

import type { PeopleOrdersBy } from '@/components/layout/FilterAside/FilterAside';
import type { TasksFiltersBy } from '@/components/layout/FilterAside/TasksFilter';

type ContentFilter =
  | 'all'
  | 'following' // all but users page
  | 'ungrouped' // people and users pages only
  | 'admins' // users page only
  | 'invitations'; // users page ( and organization admins ) only
// export const PEOPLE_ORDERS_BY = ['First name', 'Last name', 'Joined'] as const;

// For sorting / filtering of organizations for super admins
export type OrganizationsStatusFilter =
  | 'active' // stripe statuses
  | 'trialing'
  | 'canceled'
  | 'open' // issue statuses
  | 'review'
  | 'scheduled' // setup meeting status
  | 'standby'; // import status

export class AppState {
  uid: string | null = null;
  recentlyLoggedOut = false;

  // Selected organization/location/group
  organizationId: string | null = null;
  organizationDocPath: string | null = null;
  locationId: string | null = null;
  locationDocPath: string | null = null;
  groupId: string | null = null; // ( supercedes contentFilter )
  groupName: string | null = null;
  // These typesense keys are generated whenever groups/permissions change
  // ( and on boot ) to be able to search people and updates
  typesensePeopleKey: string | null = null;
  typesenseUpdatesKey: string | null = null;

  // Additional aside filter settings
  contentFilter: ContentFilter = 'all';
  showArchivedPeople = false;
  showArchivedUsers = false;
  showRestrictedUpdates = true;
  // List of followed people IDs in current/active location
  followedPeopleIds: string[] = [];

  // General states
  isDropdownOpen = false;
  isMobileAsideOpen = false;
  isManageGroupsModalOpen = false;
  isAboutNotebirdModalOpen = false;
  isScheduleACallModalOpen = false;
  visualTheme: User['preferences']['visualTheme'] = 'primary';
  isDarkModeEnabled = false;
  // These couple states are interesting from the standpoint they
  // uniquely cache and manage state from particular url params for the filter aside
  // see `FilterAsideCache` in `FilterAside` component
  filterAsidePage: 'people' | 'activity' | 'tasks' | 'users' | 'admin' = 'people';
  isFilterAsidePageActive = false;
  // A state used for temporarily disabling all baseQueries
  // ( used, right now, only for when integrating/importing
  //   a BUNCH of people to prevent hanging a user's system )
  disableQueries = false;

  // Permissions
  isOrganizationAdmin = false;
  isLocationAdmin = false;
  hasRestrictedAccess = false;

  // Contextual helper statuses
  isOnboarding = false;
  isSingleUser = true;
  isSingleLocation = true;
  hasSingleGroup = false;
  isActive = true;
  isTrialing = false;
  daysRemaining = 15;
  willEnd = false;
  isCanceled = false;
  hasCardError = false;

  // People ( page ) sort
  orderPeopleBy: PeopleOrdersBy = 'Last name';
  orderPeopleDirection: 'primary' | 'invserse' = 'primary';
  // And preservation of people scroll/search
  peopleScrollCacheId = '';
  peopleScrollCacheIndex = 0;
  peopleSearchCache = '';

  // Activity ( page ) filters
  filterUpdatesBy: string | null = null;
  filterMilestonesBy: string | null = null;
  updatesLimit = DEFAULT_UPDATES_LIMIT; // How many show in the feed, paginated in 20s, max 200

  // Task ( page ) filter
  filterTasksBy: TasksFiltersBy = 'My';

  // Just tack on a couple things for super admins
  isSuperAdmin = false;
  superSearchKey: string | null = null;
  organizationsTypeFilter: OrganizationType | IntegrationService | null = null; // Or even an integration type
  organizationsStatusFilter: OrganizationsStatusFilter | null = null;
  showAdminCharts = false;
  kidcardMode = false;
}

function init(): AppState {
  try {
    const session = JSON.parse(sessionStorage.getItem('app-state') || '{}');
    return typeof session === 'object' ? { ...new AppState(), ...session } : { ...new AppState() };
  } catch (error) {
    console.error('Could not set session state. ', getErrorMessage(error));
    return { ...new AppState() };
  }
}

function reducer(state: AppState, action: Partial<AppState>): AppState {
  if (!contentMatches(state, action)) {
    const nextState = { ...state, ...action };
    sessionStorage.setItem('app-state', JSON.stringify(nextState));
    return nextState;
  }
  return state;
}

// Context ( with hook shortcut )
const appStateContext = createContext({ ...new AppState() });
export default function useAppState() {
  return useContext(appStateContext);
}
// Set app state dispatcher
const setAppStateContext = createContext<React.Dispatch<Partial<AppState>>>(() => null);
export const useSetAppState = () => useContext(setAppStateContext);

// Context definition w/ provider
export const AppStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [appState, setAppState] = useReducer(reducer, { ...new AppState() }, init);

  return (
    <appStateContext.Provider value={appState}>
      <setAppStateContext.Provider value={setAppState}>{children}</setAppStateContext.Provider>
    </appStateContext.Provider>
  );
};
