import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
// Libs
import { MAX_GROUP_ASSIGNMENTS } from '@/lib/config';
// Classes
import Doc from '@/classes/Doc';
import Group from '@/classes/Group';
// Context data
import useAppState from '@/contexts/appState';
import { UsersListItem } from '@/contexts/usersList';
import useLocation from '@/contexts/location';
import useGroups from '@/contexts/groups';
// Layout components
import SheetHeader from '@/components/layout/SheetHeader';
// Form elements
import ReactSelect from '@/components/formElements/ReactSelect';
import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import Spacer from '@/components/common/Spacer';
import Padding from '@/components/common/Padding';
import Divider from '@/components/common/Divider';
import Accordion from '@/components/common/Accordion';
import Expanded from '@/components/common/Expanded';
import { Toggle } from '@/components/formElements/FormElements';
import firebase, { db } from '@/lib/firebase';
import useUser from '@/contexts/user';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';
import useLocations from '@/contexts/locations';
import DescriptionBlock from '@/components/common/DescriptionBlock';
import useDebouncedState from '@/hooks/useDebouncedState';
import { createPortal } from 'react-dom';
import {
  DeleteCannotBeUndone,
  DeleteExtra,
  DeleteWarning,
  FormModal,
} from '@/components/common/Modals';
import Loader from '@/components/common/Loader';

// Styles
const FlexRow = styled.div`
  display: flex;
  align-items: flex-start;
`;
const RoleDescription = styled.div<{ danger?: boolean }>`
  margin: 0 32px 8px;
  font-size: 20px;
  line-height: 1.4;
  color: ${({ theme }) => theme.textSecondary};
  strong {
    color: ${({ danger, theme }) => (danger ? theme.danger500 : theme.textPrimary)};
  }
  label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
  }
  small {
    display: block;
    line-height: 1.3;
    font-size: 16px;
    color: ${({ theme }) => theme.textTertiary};
  }
`;
// const LearnMore = styled.div`
//   margin: 0 32px 12px;
//   font-size: 14px;
// `;
const GroupRow = styled.div<{ danger?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 32px 0 52px;
  font-size: 18px;
  font-weight: bold;
  color: ${({ danger, theme }) => (danger ? theme.danger500 : theme.textTertiary)};

  button {
    opacity: 0;
    transition: opacity 150ms ease;
  }

  &:hover {
    color: ${({ danger, theme }) => (danger ? theme.danger500 : theme.textPrimary)};
    button {
      opacity: 1;
    }
  }
`;
const PermissionsButton = styled(SecondaryButton)`
  margin: 4px 24px;
`;

const ToggleLabel = styled.label`
  margin: 0 32px 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  strong {
    display: block;
    font-size: 18px;
    color: ${({ theme }) => theme.textPrimary};
  }
  color: ${({ theme }) => theme.textTertiary};
  cursor: pointer;
`;

// Props
type Props = {
  user: UsersListItem;
  selectedGroupId?: string;
  setSelectedGroupId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setGroupToRemove: React.Dispatch<React.SetStateAction<Doc<Group> | undefined>>;
  assignMemberToGroup: ({
    groupId,
    remove,
  }: {
    groupId?: string | undefined;
    remove?: boolean | undefined;
  }) => Promise<void>;
  setShowArchiveModal: React.Dispatch<React.SetStateAction<boolean>>;
  convertToGroupMember(): void;
  isConvertingToGroupMember: boolean;
  convertToAdmin(): void;
};

// Component
const PermissionsAccordion = ({
  user,
  selectedGroupId,
  setSelectedGroupId,
  setGroupToRemove,
  assignMemberToGroup,
  setShowArchiveModal,
  convertToGroupMember,
  isConvertingToGroupMember,
  convertToAdmin,
}: Props) => {
  // App state
  const {
    isOrganizationAdmin,
    hasRestrictedAccess: currentUserHasRestrictedAccess,
    organizationDocPath,
  } = useAppState();
  // Context data
  const [currentUser] = useUser();
  const [locations] = useLocations();
  const [currentLocation] = useLocation();
  const [groups] = useGroups();

  // Master accordion state
  const [isExpanded, setIsExpanded] = useState(false);

  // If group member, these are groups the user is NOT in
  const userIsGroupMember =
    user.isLocationUser && !user.isLocationAdmin && !user.isOrganizationAdmin;
  const otherGroups = useMemo(
    () => (userIsGroupMember ? groups?.filter(({ id }) => !user.groups.includes(id)) || [] : []),
    [userIsGroupMember, groups, user.groups]
  );
  const otherGroupsOptions = otherGroups.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  // Transformations (and corresponding form/modal states)
  // Add or remove user/member from group
  const [showGroupSelector, setShowGroupSelector] = useState(false);

  // Sync 'can manage settings' status (location admin) to firestore ( debounced )
  const [showConfirmOrganizationAdminModal, setShowConfirmOrganizationAdminModal] = useState(false);
  const dbSetCanManageSettings = useCallback(
    (canManageSettingsEnabled: boolean, forceUpdate?: boolean) => {
      // Typestopper
      if (!currentUser || !organizationDocPath || !currentLocation || !locations) return;

      // If isn't already location admin of all locations, warn before upgrading
      // (because user will get access to all locations, not just the currently active one)
      if (
        canManageSettingsEnabled &&
        !forceUpdate &&
        locations.length > 1 &&
        !locations.every(({ admins }) => admins.includes(user.id))
      ) {
        setShowConfirmOrganizationAdminModal(true);
        return;
      }

      const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;
      const meta = generateUpdatedByDottedMeta(currentUser);
      const batch = db.batch();
      batch.update(db.doc(organizationDocPath), {
        ...meta,
        admins: canManageSettingsEnabled ? arrayUnion(user.id) : arrayRemove(user.id),
      });

      // If removing as organization admin, make sure is admin at all locations
      if (!canManageSettingsEnabled) {
        // Make sure is admin (and user) at all locations
        for (const locationToUpdate of locations.filter(
          ({ admins }) => !admins.includes(user.id)
        )) {
          batch.update(db.doc(locationToUpdate.docPath), {
            ...meta,
            admins: arrayUnion(user.id),
            users: arrayUnion(user.id),
          });
        }
      }
      batch.commit();
    },
    [currentLocation, currentUser, locations, organizationDocPath, user.id]
  );
  const [canManageSettings, setCanManageSettings] = useDebouncedState({
    defaultValue: !user.isLocationAdmin,
    debouncedCallback: dbSetCanManageSettings,
    wait: 1000,
    shouldSyncDefault: true,
  });
  // Sync 'has restricted access' status to firestore ( debounced )
  const dbSetHasRestrictedAccess = useCallback(
    (hasRestrictedAccessEnabled: boolean) => {
      if (!currentUser || !organizationDocPath) return;

      db.doc(organizationDocPath).update({
        ...generateUpdatedByDottedMeta(currentUser),
        restrictedAccessUsers: hasRestrictedAccessEnabled
          ? firebase.firestore.FieldValue.arrayUnion(user.id)
          : firebase.firestore.FieldValue.arrayRemove(user.id),
      });
    },
    [currentUser, organizationDocPath, user.id]
  );
  const [hasRestrictedAccess, setHasRestrictedAccess] = useDebouncedState({
    defaultValue: user.hasRestrictedAccess,
    debouncedCallback: dbSetHasRestrictedAccess,
    wait: 1000,
    shouldSyncDefault: true,
  });

  // Only show indication of multiple location if has any and isn't admin of all
  const showLocationDistinction =
    locations && locations.length > 1 && !locations.every(({ admins }) => admins.includes(user.id));

  return (
    <>
      <SheetHeader
        expandable
        active={isExpanded}
        sticky
        leadingIcon='manage_accounts'
        mainTitle='User Role'
        onClick={() => setIsExpanded(prev => !prev)}
      />
      <Accordion show={isExpanded}>
        <DescriptionBlock>
          User roles determine what a user can see and do in Notebird.{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://guide.notebird.app/articles/3401591'
          >
            Learn more
          </a>
        </DescriptionBlock>
        <Spacer height='24px' />
        {/* Group member */}
        {userIsGroupMember && (
          <>
            {user.groups.length ? (
              <RoleDescription>
                {user.profile.name.first} can only access unrestricted activity
                {showLocationDistinction && ` at ${currentLocation?.name}`}
                <strong> within these groups:</strong>
              </RoleDescription>
            ) : (
              // No groups
              <>
                <RoleDescription danger>
                  {user.profile.name.first} has
                  <strong> no accesss to any groups</strong>
                  {showLocationDistinction && ` within ${currentLocation?.name}`}.
                </RoleDescription>
                {/* <LearnMore>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://guide.notebird.app/articles/3604014'
                  >
                    Learn more about user roles
                  </a>
                </LearnMore> */}
              </>
            )}
            {/* Groups list */}
            {user.groups.map(groupId => {
              const group = groups?.find(({ id }) => id === groupId);
              return (
                !!group && (
                  <GroupRow key={groupId}>
                    <Expanded>{group.name}</Expanded>
                    <div>
                      <SecondaryButton danger onClick={() => setGroupToRemove(group)}>
                        Remove
                      </SecondaryButton>
                    </div>
                  </GroupRow>
                )
              );
            })}
            {/* Add to group button */}
            {!!otherGroups.length && !showGroupSelector && (
              <Padding padding='16px 32px 0'>
                <SecondaryButton
                  leadingIcon='add_circle'
                  onClick={() => setShowGroupSelector(true)}
                  disabled={user.groups.length >= MAX_GROUP_ASSIGNMENTS}
                >
                  <strong>Add user to group</strong>
                </SecondaryButton>
              </Padding>
            )}
            {/* Add to group selector and confirm */}
            {!!otherGroups.length && showGroupSelector && (
              <Padding padding='12px 32px 0'>
                <FlexRow>
                  <Expanded>
                    <ReactSelect
                      placeholder='Search groups to add'
                      autoFocus
                      maxMenuHeight={192}
                      openMenuOnFocus
                      options={otherGroupsOptions}
                      onChange={selection => {
                        !!selection && setSelectedGroupId(selection.value);
                      }}
                      relative
                      // Disable this for mobile compatibility
                      blurInputOnSelect={false}
                      // This would preserve selection even when blur/close
                      // defaultValue={
                      //   otherGroupsOptions.length === 1
                      //     ? {
                      //         value: otherGroupsOptions[0].value,
                      //         label: otherGroupsOptions[0].label
                      //       }
                      //     : {}
                      // }
                      // value={
                      //   otherGroupsOptions.find(({ value }) => value === groupToAdd) ||
                      //   {}
                      // }
                      onBlur={() => {
                        setShowGroupSelector(false);
                      }}
                    />
                  </Expanded>
                  <PrimaryButton
                    disabled={!selectedGroupId}
                    // is mouseDown instead of click to preceed select's onBlur
                    onMouseDown={event => {
                      event.preventDefault();
                      assignMemberToGroup({
                        groupId: selectedGroupId,
                      });
                      setShowGroupSelector(false);
                      setSelectedGroupId(undefined);
                    }}
                  >
                    Add to group
                  </PrimaryButton>
                </FlexRow>
              </Padding>
            )}
            <Spacer height='32px' />
            {/* Role conversion options only available to org admins ( not location admins ) */}
            {isOrganizationAdmin && (
              <>
                <Divider />
                <PermissionsButton dull leadingIcon='sync_alt' onClick={convertToAdmin}>
                  Convert {user.profile.name.first} from a <strong>Group Member</strong> to an{' '}
                  <strong>Admin</strong>
                </PermissionsButton>
              </>
            )}
          </>
        )}

        {/* Is Admin ( organization or location ) */}
        {(user.isLocationAdmin || user.isOrganizationAdmin) && (
          <>
            <RoleDescription>
              <strong>{user.profile.name.first} is a Notebird admin</strong>
              {/* If more than one location ( and not location admin of all ),
                  need to specify just for this location for location admins */}
              {user.isLocationAdmin && showLocationDistinction && (
                <> for the {currentLocation?.name} location</>
              )}
              .
              <small>
                Admins have access to all people profiles, tasks, milestones, and unrestricted
                updates within Notebird.
              </small>
            </RoleDescription>
            <Spacer height='18px' />

            {/* If current user has restricted access, let them toggle on/off */}
            {currentUserHasRestrictedAccess && (
              <>
                <ToggleLabel htmlFor='restrictedAccess'>
                  <Toggle
                    name='restrictedAccess'
                    id='restrictedAccess'
                    checked={hasRestrictedAccess}
                    onChange={() => {
                      setHasRestrictedAccess(prev => !prev);
                    }}
                  />
                  <div>
                    <strong>Access to Restricted Updates</strong>
                    Allow user to view and create restricted updates.
                  </div>
                </ToggleLabel>
                <Spacer height='12px' />
              </>
            )}

            {/* If current user is organization admin, show toggle to move user between org and location admin  */}
            <ToggleLabel htmlFor='canManageSettings'>
              <Toggle
                name='canManageSettings'
                id='canManageSettings'
                checked={canManageSettings}
                onChange={() => {
                  setCanManageSettings(prev => !prev);
                }}
              />
              <div>
                <strong>Access to Account Settings</strong>
                Allow user to manage organization preferences, billing, users, and groups.
              </div>
            </ToggleLabel>

            {/* Convert to group member */}
            <Spacer height='32px' />
            <Divider />
            <Loader show={isConvertingToGroupMember} />
            <PermissionsButton
              dull
              leadingIcon='sync_alt'
              onClick={convertToGroupMember}
              disabled={isConvertingToGroupMember}
            >
              Convert {user.profile.name.first} from an <strong>Admin</strong> to a{' '}
              <strong>Group Member</strong>
            </PermissionsButton>
          </>
        )}

        {/* Archive for all users */}
        {/* (if current user is organization admin) */}
        {isOrganizationAdmin && (
          <>
            <Divider />
            <Spacer height='4px' />
            {/* Archive/unarchive user */}
            <PermissionsButton dull onClick={() => setShowArchiveModal(true)}>
              <strong>Archive</strong> user
            </PermissionsButton>
          </>
        )}
        <Spacer height='32px' />

        {/* Confirm upgrade to org admin modal */}
        {createPortal(
          <FormModal
            position='absolute'
            formTitle='Grant Admin Access'
            show={showConfirmOrganizationAdminModal}
            danger
            submitBtn={
              <span>
                <strong>Grant Full Admin Access</strong>
              </span>
            }
            handleCancel={() => {
              setCanManageSettings(false);
              setShowConfirmOrganizationAdminModal(false);
            }}
            handleSubmit={event => {
              event && event.preventDefault();
              dbSetCanManageSettings(true, true);
              setShowConfirmOrganizationAdminModal(false);
            }}
          >
            <Padding padding='32px 32px 48px'>
              <DeleteWarning>
                Are you sure you want to give {user.profile.name.first}{' '}
                <strong>full admin access</strong>?
              </DeleteWarning>
              <DeleteCannotBeUndone>
                Giving {user.profile.name.first} access to account settings will provide access to{' '}
                <em>all activity in every location</em>, not only {currentLocation?.name}.
              </DeleteCannotBeUndone>
              <DeleteExtra>
                <a href='mailto:support@notebird.app' className='intercom-launcher'>
                  <strong>Contact Notebird support</strong>
                </a>{' '}
                or visit our <a href='https://boo'>help center</a> if you have any questions about
                why you are receiving this message.
              </DeleteExtra>
            </Padding>
          </FormModal>,
          document.getElementById('user-detail-sheet-modal-root') as HTMLElement
        )}
      </Accordion>
    </>
  );
};
export default PermissionsAccordion;
