import { useState } from 'react';
import styled from 'styled-components';

import { db, fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import cardinalTheme from '@/styles/cardinalTheme';
import darkTheme from '@/styles/darkTheme';
import orioleTheme from '@/styles/orioleTheme';
import parakeetTheme from '@/styles/parakeetTheme';
import primaryTheme from '@/styles/primaryTheme';

import { usePostHog } from 'posthog-js/react';

import useUser from '@/contexts/user';

import Accordion from '@/components/common/Accordion';
import FlexRow from '@/components/common/FlexRow';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Themes
const THEMES = [
  { id: 'primary', name: 'Notebird', theme: primaryTheme },
  { id: 'cardinal', name: 'Cardinal', theme: cardinalTheme },
  { id: 'oriole', name: 'Oriole', theme: orioleTheme },
  { id: 'parakeet', name: 'Parakeet', theme: parakeetTheme },
] as const;

// Styles
interface ButtonProps {
  color: string;
  before?: string;
  after?: string;
}
const ThemeButton = styled.button.attrs<ButtonProps>(({ before = '', after = '' }) => ({
  children: (
    <>
      {before}
      <div className='colorBlock' />
      {after}
    </>
  ),
}))`
  appearance: none;
  -webkit-appearance: none;
  outline: none;

  flex: 1;
  border-radius: 6px;
  border: 2px solid transparent;

  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.textFaded};

  /* Color block */
  .colorBlock {
    content: '';
    display: block;
    width: 100%;
    height: 64px;
    margin: 6px 0;
    border-radius: 6px;
    background-color: ${({ color }) => color};

    opacity: 0.5;
    transform: scale(0.9);
    transition: transform 50ms ${({ theme }) => theme.easeStandard};
  }

  &:hover:not(.active),
  &:focus:not(.active) {
    color: ${({ theme }) => theme.textSecondary};
    .colorBlock {
      opacity: 1;
      transform: scale(0.95);
    }
  }
  &:focus:not(.active) {
    border: 2px solid ${({ theme }) => theme.hoverFade};
  }

  &.active {
    box-shadow: ${({ theme }) => theme.shadow300};
    color: ${({ theme }) => theme.textSecondary};
    font-weight: bold;
    .colorBlock {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

// Component
const ThemeAccordion = ({ show }: { show: boolean }) => {
  const [user] = useUser();
  const posthog = usePostHog();

  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(user?.preferences.isDarkModeEnabled);
  const [visualTheme, setVisualTheme] = useState(user?.preferences.visualTheme);

  return (
    <Accordion show={show}>
      <Padding padding='24px 32px' mobilePadding='24px 16px' data-intercom-target='Theme accordion'>
        {/* Dark/light mode row */}
        <FlexRow align='start'>
          {/* Light mode */}
          <ThemeButton
            color={primaryTheme.offLight}
            className={!isDarkModeEnabled ? 'active' : ''}
            tabIndex={!isDarkModeEnabled ? -1 : 0}
            onClick={() => {
              // Clear local storage holder to prevent possible log on de-dark-moding
              localStorage.removeItem('darkModeIsEnabled');
              setIsDarkModeEnabled(false);
              user && db.doc(user.docPath).update({ 'preferences.isDarkModeEnabled': false });
              posthog?.capture('prefs_toggled_dark_mode', { is_enabled: false });
              logEvent(fbAnalytics, 'toggle_dark_mode', { enabled: false });
            }}
            before='Light mode'
          ></ThemeButton>
          {/* Dark mode */}
          <ThemeButton
            color={darkTheme.backgroundColor}
            className={isDarkModeEnabled ? 'active' : ''}
            tabIndex={isDarkModeEnabled ? -1 : 0}
            onClick={() => {
              setIsDarkModeEnabled(true);
              user && db.doc(user.docPath).update({ 'preferences.isDarkModeEnabled': true });
              posthog?.capture('prefs_toggled_dark_mode', { is_enabled: false });
              logEvent(fbAnalytics, 'toggle_dark_mode', { enabled: true });
            }}
            before='Dark mode'
          ></ThemeButton>
        </FlexRow>
        <Spacer height='16px' />

        {/* Themes ( colors ) */}
        <FlexRow align='start'>
          {THEMES.map(({ id, name, theme }) => {
            const isActive = visualTheme === id;
            return (
              <ThemeButton
                key={id}
                color={theme.primary500}
                className={isActive ? 'active' : ''}
                tabIndex={isActive ? -1 : 0}
                onClick={() => {
                  setVisualTheme(id);
                  user && db.doc(user.docPath).update({ 'preferences.visualTheme': id });
                  posthog?.capture('prefs_changed_visual_theme', { theme: name });
                  logEvent(fbAnalytics, 'change_visual_theme', { theme: name });
                }}
                after={name}
              ></ThemeButton>
            );
          })}
        </FlexRow>
        <Spacer height='12px' />
      </Padding>
    </Accordion>
  );
};

// Export
export default ThemeAccordion;
