import { lazy, useEffect, useRef, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { db, fbAnalytics, fbAuth, fbFunctions, fbPerf } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';
import { FirebaseError } from 'firebase/app';
import {
  type MultiFactorError,
  browserLocalPersistence,
  browserSessionPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { trace } from 'firebase/performance';

import { type FormikHelpers, useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { usePostHog } from 'posthog-js/react';
import { object, string } from 'yup';

import useAppState from '@/contexts/appState';

import useMultiFactor from '@/hooks/useMultiFactor';
import usePageView from '@/hooks/usePageView';

import {
  Input,
  InputError,
  LabeledCheckbox,
  PasswordInput,
} from '@/components/formElements/FormElements';

import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import { FormLabel } from '@/components/common/Label';
import Loader from '@/components/common/Loader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

import NotebirdLogo from '@/components/svg/NotebirdLogo';

// Lazy load illustration
const LoginIllustration = lazy(() => import('../../svg/LoginIllustration'));

// Styles
const StyledLoginIllustration = styled(LoginIllustration)`
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(50vw - 256px);
  max-height: 384px;

  @media screen and (max-width: 850px) {
    display: none;
  }
`;
const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100vw;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: contain;
`;
const LogoWrapper = styled.div`
  width: 256px;
  margin: 9vh auto 48px;
`;
const LogInPanel = styled.div`
  position: relative;
  background-color: ${props => props.theme.sheetBackgroundColor};
  border-radius: 6px;
  margin: 0 auto 48px;
  width: 384px;
  max-width: 100%;
  max-width: 100vw;
  box-shadow: ${props => props.theme.shadow400};
`;
const Note = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 32px;
  color: ${props => props.theme.textSecondary};
`;
const Error = styled(Note)`
  color: ${props => props.theme.danger400};
`;
const ErrorBlock = styled.div`
  font-weight: bold;
  margin-top: 4px;
  color: ${props => props.theme.textTertiary};
`;
const Extras = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Footer = styled.div`
  position: relative;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.textTertiary};
  line-height: 1.4;
`;

// Form Schema
const schema = object().shape({
  password: string().required('Password is required').max(250, '250 characters max'),
  email: string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email address is required')
    .max(250, '250 characters max'),
  verificationCode: string().length(6, 'Verification code must be 6 digits'),
});

// Login Page
export default function LogInPage() {
  // Register page view
  usePageView({ title: 'Login | Notebird' });
  const posthog = usePostHog();

  // If user visits https://.../login/$email/, their email will populate
  // in the email field then redirect back to the root
  const { email: emailParam = '' } = useParams();

  // App state
  const { recentlyLoggedOut } = useAppState();

  const initialValues = {
    email: emailParam,
    password: '',
    rememberUser: true,
    verificationCode: '',
  };

  // Form State
  const [genericError, setGenericError] = useState('');
  // Refs to target focus
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  // Simple (email not found) error state tracker
  const [emailNotFound, setEmailNotFound] = useState('');

  // Invitation already exists for this email address
  const [existingInvitationEmail, setExistingInvitationEmail] = useState('');

  // Multi-factor SMS stuff
  const {
    RecaptchaRoot,
    hasSentVerification,
    resetVerification,
    sendSmsVerificationCode,
    finalizeSignIn,
    resolver,
  } = useMultiFactor();

  // Form helper (Formik)
  const { values, errors, submitCount, handleSubmit, isSubmitting, getFieldProps, setFieldValue } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: schema,
    });

  // Submit
  async function onSubmit(
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) {
    // Reset potential errors
    setGenericError('');
    setEmailNotFound('');
    setExistingInvitationEmail('');

    // Performance trace
    const loginTrace = trace(fbPerf, 'login');
    loginTrace.start();

    try {
      // If we've sent code, need to try verifying that
      if (hasSentVerification) {
        await finalizeSignIn(values.verificationCode);
        return;
      }

      // Try signing in with firebase auth
      await signInWithEmailAndPassword(fbAuth, values.email.trim(), values.password);
      // If user specified to be remembered or not, set persistence accordingly
      const persistence_type = values.rememberUser
        ? browserLocalPersistence
        : browserSessionPersistence;
      setPersistence(fbAuth, persistence_type);
      // If user specified to be remembered, we can enable db persistence too
      // ( but need to catch because it throw's a weird error when enabling after...
      // ...logging out and logging back in because db has already been established )
      values.rememberUser &&
        db.enablePersistence({ synchronizeTabs: true }).catch(error => console.error(error));

      // Log log_in analytics event
      const analyticsProps = {
        method: 'Email',
        persistence_type: persistence_type.type,
        password_length: values.password.length,
      };
      posthog?.capture('user_login', analyticsProps);
      logEvent(fbAnalytics, 'login', analyticsProps);

      // Trace persistence type
      loginTrace.putAttribute('persistence_type', persistence_type.type);
    } catch (error) {
      if (error instanceof FirebaseError) {
        loginTrace.putAttribute('error', error.code);
        // Deal with all the various error responses from firebase
        switch (error.code) {
          case 'auth/invalid-email':
            actions.setFieldError('email', 'Please enter a valid email address');
            break;
          case 'auth/user-not-found': {
            const doesInvitationExist = await httpsCallable(
              fbFunctions,
              'doesInvitationExist'
            )({
              email: values.email.toLowerCase().trim(),
            });
            if (doesInvitationExist.data) {
              setExistingInvitationEmail(values.email.trim());
              posthog?.capture('user_invitation_already_exists', { page: 'Login' });
              logEvent(fbAnalytics, 'invitation_already_exists', { page: 'login' });
              break;
            }
            setEmailNotFound(values.email);
            posthog?.capture('user_email_not_found', { attempts: submitCount });
            logEvent(fbAnalytics, 'email_not_found');
            break;
          }
          case 'auth/user-disabled':
            actions.setFieldError(
              'email',
              'This account has been disabled. Please contact support'
            );
            break;
          case 'auth/wrong-password':
            actions.setFieldError('password', 'Incorrect password. Try again');
            posthog?.capture('user_wrong_password', { attempts: submitCount });
            logEvent(fbAnalytics, 'wrong_password');
            break;
          case 'auth/too-many-requests':
            setGenericError('Too many incorrect attempts. Try again in a moment.');
            break;
          case 'auth/multi-factor-auth-required': {
            await sendSmsVerificationCode(error as MultiFactorError);
            return;
          }
          case 'auth/invalid-verification-code': {
            actions.setFieldError('verificationCode', 'Invalid verification code');
            posthog?.capture('user_invalid_code', { attempts: submitCount });
            return;
          }
          default:
            setGenericError(
              `Unknown login error. Please contact support.\n\nError code: ${error.code}`
            );
        }
      } else {
        setGenericError(
          `Unknown login error. Please contact support.\n\nError: ${JSON.stringify(error)}`
        );
      }
      console.error(error);
    }

    loginTrace.stop();
  }

  // Only allow submissions when isn't already submitting,
  // email and password aren't empty,
  // emailNotFound/existingInvitationEmail doesn't match,
  // and if has submitted, no errors are present
  // (used to determine disabled state of submit button below)
  const submitDisabled =
    isSubmitting ||
    !values.email.trim() ||
    !values.password ||
    values.email.trim() === emailNotFound ||
    values.email.trim() === existingInvitationEmail ||
    (!!submitCount && !isEmpty(errors)) ||
    (hasSentVerification && values.verificationCode.length !== 6);

  // Listen for when user submits focus any error fields
  // once state has flushed and triggered re-render (defer)
  useEffect(() => {
    if (submitCount && !isSubmitting) {
      if (errors.email) {
        emailRef.current && emailRef.current.focus();
      } else if (errors.password) {
        passwordRef.current && passwordRef.current.focus();
      }
    }
  }, [submitCount, isSubmitting, errors.email, errors.password]);

  return (
    <>
      {/* Clear email from URL (but it will remain in state to populate email field) */}
      {!!emailParam && <Navigate to='/login' replace />}

      {/* Pretty picture */}
      <ErrorSuspendPlaceholder>
        <StyledLoginIllustration tint className='fade-in' />
      </ErrorSuspendPlaceholder>

      <PageWrapper>
        {/* Logo */}
        <LogoWrapper>
          <NotebirdLogo width='256' tint />
        </LogoWrapper>

        {/* Main Panel */}
        <LogInPanel>
          <RecaptchaRoot />

          {/* Loader */}
          <Loader show={isSubmitting} />

          <Padding padding='32px 24px'>
            {/* Top error */}
            {!!genericError && <Error>{genericError}</Error>}

            {/* Form */}
            <form onSubmit={handleSubmit} noValidate>
              {/* Not verifing ( just asking for regular email + password ) */}
              {!hasSentVerification && (
                <>
                  {/* Top message */}
                  {!genericError && (
                    <Note>
                      {recentlyLoggedOut
                        ? 'You have been logged out successfully.'
                        : 'Welcome back! Please log in to continue.'}
                    </Note>
                  )}

                  {/* Email Address */}
                  <FormLabel htmlFor='email'>Email address</FormLabel>
                  <Input
                    disabled={isSubmitting}
                    ref={emailRef}
                    {...getFieldProps({ name: 'email' })}
                    type='email'
                    autoComplete='email'
                    autoFocus={!values.email}
                    error={!!submitCount && !!errors.email}
                    maxLength={300}
                  />
                  {!!submitCount && !!errors.email && <InputError>{errors.email}</InputError>}
                  {!!values.email && values.email === emailNotFound && (
                    <ErrorBlock>
                      <InputError>No user found with this email address</InputError>
                      You could try <Link to={'/signup/' + values.email.trim()}>signing up</Link>
                    </ErrorBlock>
                  )}
                  {!!values.email && values.email.trim() === existingInvitationEmail && (
                    <ErrorBlock>
                      <InputError>An invite has already been sent to this address!</InputError>
                      Please check your email inbox for an invitation link to get started with
                      Notebird.
                    </ErrorBlock>
                  )}
                  <Spacer height='24px' />

                  {/* Password */}
                  <FormLabel htmlFor='password'>Password</FormLabel>
                  <PasswordInput
                    disabled={isSubmitting}
                    forwardedRef={passwordRef}
                    {...getFieldProps({ name: 'password' })}
                    autoComplete='current-password'
                    autoFocus={!!values.email}
                    error={!!submitCount && !!errors.password}
                    maxLength={300}
                  />
                  {!!submitCount && !!errors.password && <InputError>{errors.password}</InputError>}
                  <Spacer height='32px' />

                  {/* Submit Button */}
                  <PrimaryButton block type='submit' disabled={submitDisabled}>
                    Log in
                  </PrimaryButton>

                  {/* Remember and Forgot block */}
                  <Extras>
                    <LabeledCheckbox
                      // name="rememberUser"
                      label='Stay signed in'
                      {...getFieldProps({ name: 'rememberUser' })}
                      checked={values.rememberUser}
                      disabled={isSubmitting}
                    />
                    <Link to={`/forgot/${values.email.trim()}`}>Forgot password?</Link>
                  </Extras>
                </>
              )}

              {/* Verification code */}
              {hasSentVerification && (
                <>
                  <Note>
                    <div>
                      Check your phone ending in <b>{resolver?.hints[0].displayName || '????'}</b>{' '}
                      for a text code. Please enter it below.
                    </div>
                  </Note>
                  <FormLabel htmlFor='email'>Verification Code</FormLabel>
                  <Input
                    disabled={isSubmitting}
                    {...getFieldProps({ name: 'verificationCode' })}
                    autoComplete='false'
                    autoFocus
                    error={submitCount > 1 && !!errors.verificationCode}
                  />
                  {submitCount > 1 && !!errors.verificationCode && (
                    <InputError>{errors.verificationCode}</InputError>
                  )}

                  <Spacer height='32px' />

                  {/* Submit and Back Buttons */}
                  <PrimaryButton block type='submit' disabled={submitDisabled}>
                    Verify and log in
                  </PrimaryButton>
                  <Spacer height='16px' />
                  <SecondaryButton
                    dull
                    leadingIcon='arrow_back'
                    onClick={() => {
                      resetVerification();
                      setFieldValue('verificationCode', '');
                    }}
                  >
                    Back
                  </SecondaryButton>
                </>
              )}
            </form>
          </Padding>
        </LogInPanel>

        {/* Sign up notice footer */}
        <Footer>
          Need a Notebird account?
          <br />
          <Link to={'/signup/' + values.email.trim()}>Create an account</Link>
        </Footer>

        <Spacer height='64px' />
      </PageWrapper>
    </>
  );
}
