// Core
import { animated, useSpring } from '@react-spring/web';
import useMeasurements from '../../hooks/useMeasurements';

// TODO: Could look closer into a solution like:
// https://spectrum.chat/react-spring/general/animating-height-auto-of-an-element-which-leaves-and-enters-the-dom~0419323a-d234-4d24-b061-d20a10cca183
// https://codesandbox.io/s/j2mmrzwym3
// https://github.com/rehooks/component-size#readme
// https://github.com/que-etc/resize-observer-polyfill

interface Props {
  children: React.ReactNode;
  show: boolean;
  hideShadow?: boolean;
}
const Accordion = ({ show, hideShadow = false, children }: Props) => {
  const [ref, { height }] = useMeasurements(show);

  const spring = useSpring({
    to: show
      ? [
          { height, opacity: 1, shadowOpacity: 0, overflow: 'hidden' },
          { height, opacity: 1, shadowOpacity: 0, overflow: 'visible' },
        ]
      : { height: 0, opacity: 0, shadowOpacity: 1, overflow: 'hidden' },
    from: { height: 0, opacity: 0, shadowOpacity: 1, overflow: 'hidden' },
    config: {
      tension: 1250,
      friction: 75,
    },
  });

  return (
    <animated.div
      style={{
        ...spring,
        boxShadow: hideShadow
          ? 'none'
          : spring.shadowOpacity.to(
              shadowOpacity => `0px 2px 4px rgba(62, 76, 89, ${shadowOpacity})`
            ),
      }}
    >
      <div ref={ref}>{children}</div>
    </animated.div>
  );
};

export default Accordion;
