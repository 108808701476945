import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import Notebirb from '@/classes/Notebirb';
import Photo, { PHOTO_SIZES } from '@/classes/Photo';
import { UserProfile } from '@/classes/User';

import { db, fbStorage } from '@/lib/firebase';
import { deleteObject, ref as storageRef, uploadBytes } from 'firebase/storage';

import useUser from '@/contexts/user';

import PhotoUploader from '@/components/formElements/PhotoUploader';

import PrimaryButton from '@/components/common/Buttons';
import { FormModal } from '@/components/common/Modals';
import NotebirbAvatar from '@/components/common/NotebirbAvatar';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Types / defaults
interface PhotoFile extends File {
  preview: string;
}
const defaultPhotoCrop = {
  cropX: '0',
  cropY: '0',
  cropWidth: '0',
  cropHeight: '0',
};

// Styles
const TabBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TabButton = styled.button`
  position: relative;
  min-width: 100px;
  margin: 4px 0;
  padding: 12px 24px;

  font-weight: bold;
  color: ${({ theme }) => theme.textTertiary};

  border-radius: 6px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: ${({ theme }) => theme.hoverFade};
    border-radius: 2px;
  }

  &.active {
    color: ${({ theme }) => theme.linkColor};
    &:after {
      background-color: ${({ theme }) => theme.linkColor};
    }
    /* border-bottom: 3px solid ${({ theme }) => theme.linkColor}; */
  }
  /* States */
  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.linkHover};
    background-color: ${({ theme }) => theme.hoverFade};
    &:not(.active) {
      color: ${({ theme }) => theme.textSecondary};
      &:after {
        background-color: ${({ theme }) => theme.offLight};
      }
    }
  }
  &:focus:not(:disabled) {
    outline: none;
    text-decoration: none;
  }
  &:active:not(:disabled) {
    outline: none;
  }
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;
const NotebirbSelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;

  background-color: ${props => props.theme.hoverFade};
  border: 2px solid ${props => props.theme.hoverFade};
  border-radius: 6px;
`;

// Component
interface Props {
  show: boolean;
  hide(): void;
}
const EditAvatarModal = ({ show, hide }: Props) => {
  const [user] = useUser();
  const profile = user ? user.profile : { ...new UserProfile() };

  // Initialize active tab—icon on photo tab
  const [selectedTab, setSelectedTab] = useState<'photo' | 'icon'>('photo');
  // Keep tab sync when data changes on server
  // and recalculate everytime when show changes
  useEffect(() => {
    setSelectedTab(!!profile.photo && profile.preferNotebirb ? 'icon' : 'photo');
  }, [profile.photo, profile.preferNotebirb, show]);

  // State when submitting form
  const [isSubmitting, setIsSubmitting] = useState(false);

  // For picture upload—drag and drop
  ///////////////////////////////////
  const [isDeletingPhoto, setIsDeletingPhoto] = useState(false);
  // Dont bother, it doesn't work to try and save in session storage
  // like other new person value fields... :/
  const [photoFile, setPhotoFile] = useState<PhotoFile>();
  // Settings to attach to photos metadata for correct cropping server side
  const [photoCrop, setPhtoCrop] = useState(defaultPhotoCrop);

  // Show photo dropzone if doesn't have photo selected AND
  // 1. Doesn't already have an photo uploaded
  // or
  // 2. Has uploaded an photo before but wants to delete it
  // or
  // 3. Photo is pending
  const showDropzone =
    !photoFile &&
    (!profile.photo || // 1
      (!!profile.photo && isDeletingPhoto) || // 2
      profile.photo === 'pending'); // 3

  // For notebirb randomization
  /////////////////////////////
  const [newNotebirb, setNewNotebirb] = useState<Notebirb>();

  // Photo tab submit enabled if adding or removing photo
  const photoSubmitEnabled = selectedTab === 'photo' && (!!photoFile || isDeletingPhoto);
  // Icon tab submit enabled if notebirb has changed
  const notebirbSubmitEnabled = selectedTab === 'icon' && !!newNotebirb;
  // Enable submit if we're toggling 'prefer notebirb' in the right direction (and photo is present)
  const preferNotebirbSubmitEnabled =
    !!profile.photo &&
    (selectedTab === 'photo' ? !!profile.preferNotebirb : !profile.preferNotebirb);
  // Invert it all to determine if form should be disabled
  const submitDisabled = !(
    photoSubmitEnabled ||
    notebirbSubmitEnabled ||
    preferNotebirbSubmitEnabled
  );

  // How to clear the form and close it
  const clearAndClose = useCallback(() => {
    setNewNotebirb(undefined);
    setPhotoFile(undefined);
    setPhtoCrop(defaultPhotoCrop);
    setIsDeletingPhoto(false);
    hide();
  }, [hide]);

  // Submit change to avatar
  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsSubmitting(true);

      if (user) {
        // Update photo if is on photo tab, and is uploading and/or deleting
        if (selectedTab === 'photo') {
          // Never prefer notebirb if updating photo
          db.doc(user.docPath).update({
            'profile.preferNotebirb': false,
          });

          if (!!photoFile || isDeletingPhoto) {
            // If uploading photo, set photo to pending
            // otherwise, set to null
            const photo: Photo = photoFile ? 'pending' : null;

            const photoExt = photoFile ? photoFile.name.split('.').pop() : null;

            // If has photo currently and is deleting...
            if (profile.photo instanceof Object && isDeletingPhoto) {
              const photo = profile.photo;
              // ...and if isn't replacing or extentions differ...
              if (!photoFile || photo.ext !== photoExt) {
                // ...remove previous photo + thumbs!
                try {
                  // Original
                  const photoRef = storageRef(fbStorage, `${user.docPath}/${user.id}.${photo.ext}`);
                  deleteObject(photoRef);
                  // Each thumb
                  PHOTO_SIZES.forEach(size => {
                    const thumbRef = storageRef(
                      fbStorage,
                      `${user.docPath}/${user.id}_thumb@${size}.${photo.ext}`
                    );
                    deleteObject(thumbRef);
                  });
                } catch (error) {
                  console.error('Error deleting photo', error);
                }
              }
            }

            // Upload photo (but don't await completion before closing out)
            if (photoFile) {
              const photoRef = storageRef(fbStorage, `${user.docPath}/${user.id}.${photoExt}`);
              uploadBytes(photoRef, photoFile, {
                customMetadata: {
                  ...photoCrop,
                  userId: user.id,
                },
              });
            }

            // Set (or unset) photo props for user
            await db.doc(user.docPath).update({ 'profile.photo': photo });
          }
        }

        // Update notebirb if on the icon tab and notebirb has changed (and prefer)
        if (selectedTab === 'icon') {
          await db.doc(user.docPath).update({
            'profile.notebirb': newNotebirb || profile.notebirb,
            'profile.preferNotebirb': true,
          });
        }
      }
      // Clear and close form
      clearAndClose();

      // Finish submitting state
      setIsSubmitting(false);
    },
    [
      clearAndClose,
      user,
      profile.notebirb,
      profile.photo,
      isDeletingPhoto,
      newNotebirb,
      photoCrop,
      photoFile,
      selectedTab,
    ]
  );

  return createPortal(
    <FormModal
      formTitle='Edit profile image'
      show={show}
      handleSubmit={onSubmit}
      handleCancel={clearAndClose}
      submitBtn={
        selectedTab === 'icon'
          ? 'Save icon'
          : isDeletingPhoto && !photoFile
            ? 'Remove image'
            : 'Save image'
      }
      isSubmitting={isSubmitting}
      disabled={submitDisabled}
    >
      <TabBar>
        <TabButton
          className={selectedTab === 'photo' ? 'active' : ''}
          onClick={e => {
            e.preventDefault();
            setSelectedTab('photo');
          }}
        >
          Photo
        </TabButton>
        <TabButton
          className={selectedTab === 'icon' ? 'active' : ''}
          onClick={e => {
            e.preventDefault();
            setSelectedTab('icon');
          }}
        >
          Icon
        </TabButton>
      </TabBar>

      <Padding padding='48px 32px 64px'>
        {/* Avatar upload tab */}
        {selectedTab === 'photo' && (
          <PhotoUploader
            currentPhoto={profile.photo}
            photoFile={photoFile}
            setPhotoFile={setPhotoFile}
            setPhotoCrop={setPhtoCrop}
            setDeletePhoto={() => {
              setIsDeletingPhoto(true);
            }}
            showDropzone={showDropzone}
            disabled={isSubmitting}
            isUser
          />
        )}
        {/* Notebirb randomizer tab */}
        {selectedTab === 'icon' && (
          <NotebirbSelectionWrapper>
            <NotebirbAvatar notebirb={newNotebirb ? newNotebirb : profile.notebirb} size={64} />
            <Spacer width='24px' />
            <PrimaryButton
              small
              leadingIcon='autorenew'
              onClick={e => {
                e.preventDefault();
                setNewNotebirb({ ...new Notebirb() });
              }}
            >
              Randomize
            </PrimaryButton>
          </NotebirbSelectionWrapper>
        )}
      </Padding>
    </FormModal>,
    document.getElementById('modal-portal')!
  );
};

// Export
export default EditAvatarModal;
