import styled, { keyframes } from 'styled-components';

const wiggle = keyframes`
    0% { transform: scale(1) rotate(0deg); }
    60% { transform: scale(1) rotate(0deg); }
    65% { transform: scale(0.95); }
    70% { transform: scale(1.1) rotate(5deg); }
    75% { transform: scale(1.1) rotate(-5deg); }
    80% { transform: scale(1.1) rotate(5deg); }
    85% { transform: scale(1.1) rotate(-5deg); }
    90% { transform: scale(1.1) rotate(5deg); }
    95% { transform: scale(1.1) rotate(-5deg); }
  100% { transform: scale(1) rotate(0deg); }
`;
const Wiggler = styled.div<{ disabled?: boolean }>`
  display: inline-block;
  animation: ${({ disabled }) => (disabled ? 'none' : wiggle)};
  animation-duration: 3.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
`;

export default Wiggler;
