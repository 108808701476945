import { lazy, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useLocations from '@/contexts/locations';

import usePageView from '@/hooks/usePageView';

import AnimatedFloatingSheet from '@/components/layout/AnimatedFloatingSheet';
import MobilePageHeader from '@/components/layout/MobilePageHeader';
import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';

import Label from '@/components/formElements/styled/FormLabel';
import Select from '@/components/formElements/styled/FormSelect';

import { PrimaryButtonLink, SecondaryButtonLink } from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Icon from '@/components/common/Icon';
import Loader from '@/components/common/Loader';
import Margin from '@/components/common/Margin';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Lazy load reports and illustration
const NoReportsIllustration = lazy(() => import('../../svg/NoReportsIllustration'));
const PeopleReport = lazy(() => import('./PeopleReport'));
const UpdatesReport = lazy(() => import('./UpdatesReport'));
const MilestonesReport = lazy(() => import('./MilestonesReport'));
const TasksReport = lazy(() => import('./TasksReport'));

// Styles
const FlexWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  div.header {
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.textSecondary};
    margin-bottom: 8px;
  }
  div.copy {
    color: ${props => props.theme.textFaded};
    line-height: 1.25;
  }
`;
const TypeSelectRow = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
`;
const TypeSelectLink = styled(NavLink)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  margin-right: 16px;
  &:last-child {
    margin-right: 0;
  }
  padding: 16px 4px 24px;

  cursor: pointer;
  border-radius: 6px;
  border: 2px solid ${({ theme }) => theme.hoverFade};
  background-color: ${({ theme }) => theme.hoverFade};

  text-align: center;
  font-size: 18px;
  line-height: 1.25;
  font-weight: normal;
  color: ${({ theme }) => theme.textSecondary};

  i {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.avatarText};
  }

  /* Hover */
  &:hover {
    border-color: ${({ theme }) => theme.lightAccent};
    color: ${({ theme }) => theme.textPrimary};
    i {
      color: ${({ theme }) => theme.textFaded};
    }
  }
  /* Focus */
  &:focus {
    box-shadow:
      0 0 0 2px ${({ theme }) => theme.sheetBackgroundColor},
      0 0 0 4px ${({ theme }) => theme.lightAccent};
    text-decoration: none;
    color: ${({ theme }) => theme.textPrimary};
    i {
      color: ${({ theme }) => theme.textFaded};
    }
  }

  /* Mobile */
  @media (max-width: 767px) {
    margin-right: 8px;
    font-size: 16px;
  }

  /* Active state */
  &.active {
    border: 2px solid ${({ theme }) => theme.primary500};
    font-weight: bold;
    color: ${({ theme }) => theme.textPrimary};
    &:hover {
      border-color: ${({ theme }) => theme.primary500};
      i {
        color: ${({ theme }) => theme.primary500};
      }
    }
    i {
      color: ${({ theme }) => theme.primary500};
    }
  }
`;
const SelectWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.textTertiary};
  i {
    color: ${({ theme }) => theme.textFaded};
  }
`;

// Config
const REPORTS_CONFIG = [
  { type: 'People', icon: 'people', route: 'people', Report: PeopleReport },
  { type: 'Updates', icon: 'library_books', route: 'updates', Report: UpdatesReport },
  { type: 'Milestones', icon: 'flag', route: 'milestones', Report: MilestonesReport },
  { type: 'Tasks', icon: 'check', route: 'tasks', Report: TasksReport },
] as const;
export type ReportConfig = (typeof REPORTS_CONFIG)[number];

// Route params
// Component
export default function ReportsPage() {
  // App context data
  const { locationId } = useAppState();
  const setAppState = useSetAppState();
  const [locations] = useLocations();

  // Router data
  const { type: routeType, locationId: routeLocationId } = useParams();
  const navigate = useNavigate();

  // Register page view
  const activeReport =
    !!routeLocationId && REPORTS_CONFIG.find(({ route }) => route === routeType)?.type;
  usePageView({
    title: activeReport ? `${activeReport} Report | Notebird` : 'Reports | Notebird',
  });

  // If routeLocation isn't the currently active location, set it to it.
  useEffect(() => {
    if (routeLocationId && routeLocationId !== locationId) {
      // Ensure valid location
      if (locations?.find(location => location.id === routeLocationId)) {
        setAppState({ locationId: routeLocationId });
      }
      // Otherwise, unset routeLocation
      else {
        navigate(`/reports/${routeType}`, { replace: true });
      }
    }
  }, [navigate, locationId, locations, routeLocationId, routeType, setAppState]);

  const disableButton = !routeType;

  if (!locations) return null;
  const LOCATION_OPTIONS = locations.map(({ name, id }) => ({ label: name, value: id }));

  return (
    <>
      <SheetsWrapper>
        {/* Header for mobile */}
        <MobilePageHeader hideGroupsAsideToggle>Reports</MobilePageHeader>

        {/* Left - illustration/copy */}
        <Sheet position='left' className='hidden-mobile'>
          <FlexWrapperCentered>
            <Margin margin='-64px 0 0' />
            <ErrorSuspendPlaceholder>
              <NoReportsIllustration width='232px' height='232px' className='fade-in' />
            </ErrorSuspendPlaceholder>
            <Spacer height='48px' />
            <div className='header'>View and export your data</div>
            <div className='copy'>Run reports and gain additional insight</div>
          </FlexWrapperCentered>
        </Sheet>

        {/* Right - report form */}
        <Sheet position='right'>
          <SheetHeader
            leadingIcon='bar_chart'
            mainTitle='Reports'
            primary
            className='hidden-mobile'
          />

          <Scrollable endBuffer='64px'>
            <Padding padding='32px 24px 0' mobilePadding='24px 16px 0'>
              {/* Report location slector ( if has multiple ) */}
              {locations && locations.length > 1 && (
                <>
                  <Label label='Location' htmlFor='report-location' />
                  <Select
                    placeholder='Select location'
                    value={LOCATION_OPTIONS.find(({ value }) => locationId === value)}
                    inputId='report-location'
                    options={LOCATION_OPTIONS}
                    onChange={selection =>
                      selection?.value && setAppState({ locationId: selection.value })
                    }
                  />
                  <Spacer height='24px' />
                </>
              )}

              {/* Select report type */}
              <TypeSelectRow>
                {REPORTS_CONFIG.map(({ type, icon, route }) => (
                  <TypeSelectLink key={type} to={`/reports/${route}`} replace>
                    <Icon icon={icon} iconSize='32px' />
                    {type}
                  </TypeSelectLink>
                ))}
              </TypeSelectRow>
              <Spacer height='24px' />

              {/* Type isn't yet selected notice */}
              {!routeType && (
                <SelectWarning>
                  <Icon icon='arrow_upward' iconSize='24px' /> Select a report type to continue
                </SelectWarning>
              )}
              <Spacer height='24px' />

              {/* Run report button/link */}
              {!disableButton && (
                <PrimaryButtonLink block to={`/reports/${routeType}/${locationId}`}>
                  <strong>Run Report</strong>
                </PrimaryButtonLink>
              )}
            </Padding>
          </Scrollable>
        </Sheet>

        {/* Include reports as full floating sheets */}
        {REPORTS_CONFIG.map(config => (
          <AnimatedFloatingSheet key={config.type} position='full' forwardedKey={config.type}>
            {!!routeLocationId && config.route === routeType && (
              <ErrorSuspendPlaceholder
                fallback={
                  <>
                    <SheetHeader
                      leadingIcon={config.icon}
                      mainTitle={`${config.type} Report`}
                      primary
                      trailing={
                        <SecondaryButtonLink to={`/reports/${config.route}`}>
                          <Icon icon='close' />
                        </SecondaryButtonLink>
                      }
                    />
                    <Loader show />
                  </>
                }
              >
                <config.Report locationId={routeLocationId} config={config} />
              </ErrorSuspendPlaceholder>
            )}
          </AnimatedFloatingSheet>
        ))}
      </SheetsWrapper>
    </>
  );
}
