import { lazy, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import { DEFAULT_UPDATES_LIMIT } from '@/lib/config';

import { usePostHog } from 'posthog-js/react';
import CSSTransition from 'react-transition-group/CSSTransition';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useCounts from '@/contexts/counts';
import { useFollowedGroups } from '@/contexts/groups';
import useLocation from '@/contexts/location';
import useLocations from '@/contexts/locations';
import useUser from '@/contexts/user';
import { useUserCounts } from '@/contexts/usersList';

import Scrollable from '@/components/layout/Scrollable';

import ActivityFilters from './ActivityFilters';
import AsideDivider from './AsideDivider';
import FilterButton from './FilterButton';
import ManageGroupsSheet from './ManageGroupsSheet';
import TasksFilter from './TasksFilter';

import { Toggle } from '@/components/formElements/FormElements';
import { ReactSelectSecondary } from '@/components/formElements/ReactSelect';

import { SecondaryButton } from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Expanded from '@/components/common/Expanded';
import Margin from '@/components/common/Margin';
import Modal from '@/components/common/Modal';
import Padding from '@/components/common/Padding';
import PageBlocker from '@/components/common/PageBlocker';
import Spacer from '@/components/common/Spacer';

import NotebirdLogo from '@/components/svg/NotebirdLogo';

const AdminAside = lazy(() => import('./AdminAside'));

const PEOPLE_ORDERS_BY = ['First name', 'Last name', 'Joined'] as const;
export type PeopleOrdersBy = (typeof PEOPLE_ORDERS_BY)[number];

// Styles
export const Aside = styled.aside<{ isMobileOpen: boolean }>`
  flex-shrink: none;
  /* To bump right up next to sheets wrapper */
  margin-right: -12px;

  /* Sliding aside animations for desktop */
  @media (min-width: 1024px) {
    /* Aside animations */
    &.enter {
      opacity: 0;
      transform: translateX(158px);
    }
    &.enter-active,
    &.enter-done {
      transition:
        opacity 250ms,
        transform 300ms ${({ theme }) => theme.easeDecelerate};
      opacity: 1;
      transform: translate(0);
    }
    &.exit-active {
      transition:
        opacity 250ms 50ms,
        transform 300ms ${({ theme }) => theme.easeAccelerate};
      position: absolute;
      left: 0;
      opacity: 0;
      transform: translateX(158px);
    }

    /* Child sheet-wrapper animations */
    & ~ .sheets-wrapper {
      transform-origin: center right;
    }
    &.enter ~ .sheets-wrapper {
      /* Ratio of old size to new ( under 1224px ) */
      transform: scaleX(1.2925);

      /* Really crazy stuff to fake ratios in the weird widths between 1224-1468  */
      /* https://docs.google.com/spreadsheets/d/1ju8jW7hVzWAMjeYqfyTZfsWPnRgPpAyrnURcouSqPTg/edit#gid=0 */
      @media (min-width: 1224px) {
        transform: scaleX(1.216) translate(calc((100vw - 1224px) / -2));
      }
      @media (min-width: 1285px) {
        transform: scaleX(1.1145) translate(calc((100vw - 1224px) / -2));
      }
      @media (min-width: 1347px) {
        transform: scaleX(1.082) translate(calc((100vw - 1224px) / -2));
      }
      @media (min-width: 1407px) {
        transform: scaleX(1.026) translate(calc((100vw - 1224px) / -2));
      }

      /* Safe to not scale above this value ( and static translate ) */
      @media (min-width: 1453px) {
        transform: scaleX(1) translate(-122px);
      }
    }
    &.enter-active ~ .sheets-wrapper {
      transition: transform 300ms ${({ theme }) => theme.easeStandard};
      transform: scaleX(1) translate(0);
    }
    &.exit ~ .sheets-wrapper {
      /* Inverse ratio of old size to new ( under 1224px ) */
      transform: scaleX(0.774);

      /* Really crazy stuff to fake ratios in the weird widths between 1224-1468  */
      /* ( like above ) */
      @media (min-width: 1224px) {
        transform: scaleX(0.823) translate(calc((100vw - 1224px) / 2));
      }
      @media (min-width: 1285px) {
        transform: scaleX(0.873) translate(calc((100vw - 1224px) / 2));
      }
      @media (min-width: 1347px) {
        transform: scaleX(0.924) translate(calc((100vw - 1224px) / 2));
      }
      @media (min-width: 1407px) {
        transform: scaleX(0.975) translate(calc((100vw - 1224px) / 2));
      }

      /* Safe to not scale above this value ( and static translate ) */
      @media (min-width: 1453px) {
        transform: scaleX(1) translate(122px);
      }
    }
    &.exit-active ~ .sheets-wrapper {
      transition: transform 300ms ${({ theme }) => theme.easeStandard};
      transform: scaleX(1) translate(0);
    }
  }

  display: flex;
  flex-direction: column;
  position: relative;
  width: 256px;
  height: 100%;
  overflow: hidden;

  @media (max-width: 1023px) {
    z-index: 220;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.backgroundColor};
    box-shadow: ${({ theme }) => theme.shadow400};
    ${({ isMobileOpen }) =>
      isMobileOpen
        ? css`
            opacity: 1;
            transform: translate(0);
            transition:
              opacity 250ms,
              transform 300ms ${({ theme }) => theme.easeDecelerate};
          `
        : css`
            pointer-events: none;
            opacity: 0;
            transform: translate(-256px);
            transition:
              opacity 200ms 50ms,
              transform 250ms ${({ theme }) => theme.easeStandard};
          `};
  }

  /* Don't show when printing */
  @media print {
    display: none;
  }
`;
const StyledManageGroupsButton = styled(SecondaryButton)`
  margin: 0 12px;
  margin: 0 12px env(safe-area-inset-bottom);
  & {
    color: ${({ theme }) => theme.textSecondary};
  }
  i {
    margin-right: 11px !important;
  }
  &:hover {
    background-color: ${({ theme }) => theme.hoverAsideFade};
  }
`;
const ArchivedToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;

  font-size: 14px;
  color: ${({ theme }) => theme.textSecondary};

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.textPrimary};
  }
`;
const ArchivedToggleCount = styled.div`
  margin: 0 4px;
  color: ${props => props.theme.textFaded};
  font-size: 13px;
`;

// Component
const FilterAside = () => {
  // App state
  const {
    // Main filter aside states
    filterAsidePage,
    isFilterAsidePageActive,
    isMobileAsideOpen,
    // Others
    isManageGroupsModalOpen,
    organizationId,
    isOrganizationAdmin,
    locationId,
    isLocationAdmin,
    isSingleUser,
    hasSingleGroup,
    contentFilter,
    groupId,
    showArchivedPeople,
    showArchivedUsers,
    orderPeopleBy,
    orderPeopleDirection,
    showRestrictedUpdates,
    hasRestrictedAccess,
    kidcardMode,
  } = useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [user] = useUser();
  const [locations] = useLocations();
  const [location] = useLocation();
  const [counts] = useCounts();
  const userCounts = useUserCounts();
  const { followedGroups, unfollowedGroups } = useFollowedGroups();
  const posthog = usePostHog();

  // For people sort direction dropdown
  const peopleOrderByDirectionOptions = useMemo(
    () =>
      (['First name', 'Last name'].includes(orderPeopleBy)
        ? [
            { label: 'A - Z', value: 'primary' },
            { label: 'Z - A', value: 'inverse' },
          ]
        : [
            { label: 'Most recent', value: 'primary' },
            { label: 'Oldest', value: 'inverse' },
          ]) as { label: React.ReactNode; value: typeof orderPeopleDirection }[],
    [orderPeopleBy]
  );

  // Methods for counting ( archive filtered ) people/users in specific groups
  const getPeopleGroupCount = useCallback(
    (groupId: string) => {
      const total = counts?.groups?.[groupId]?.people ?? 0;
      const archived = counts?.groups?.[groupId]?.peopleArchived ?? 0;
      return showArchivedPeople ? total : total - archived;
    },
    [counts, showArchivedPeople]
  );
  const getUsersGroupCount = useCallback(
    (groupId: string) => userCounts.groups[groupId] || 0,
    [userCounts]
  );
  // Tally up currently followed people at this location
  const followedPeopleCount = useMemo(() => {
    if (organizationId && locationId) {
      const total = user?.following[organizationId]?.[locationId]?.people?.length ?? 0;
      const archived = user?.following[organizationId]?.[locationId]?.peopleArchived?.length ?? 0;
      return showArchivedPeople ? total : total - archived;
    }
    return 0;
  }, [organizationId, locationId, user, showArchivedPeople]);

  // We need to hide groups on tasks page if only has one group
  const hideGroups = hasSingleGroup && filterAsidePage === 'tasks';

  return (
    <>
      {/* Page blocker for when on mobile expanded */}
      <PageBlocker
        show={isFilterAsidePageActive && isMobileAsideOpen}
        onClick={() => {
          setAppState({ isMobileAsideOpen: false });
        }}
        className='shown-mobile'
      />

      {/* Animated FilterAside */}
      <CSSTransition in={isFilterAsidePageActive} timeout={300} mountOnEnter unmountOnExit appear>
        {filterAsidePage === 'admin' ? (
          <ErrorSuspendPlaceholder>
            <AdminAside />
          </ErrorSuspendPlaceholder>
        ) : (
          <Aside isMobileOpen={isMobileAsideOpen} data-intercom-target='Filter aside'>
            <Spacer height='12px' />

            {/* Location selector */}
            {location && locations && locations?.length > 1 ? (
              <>
                <Padding padding='16px 4px 0 12px' mobilePadding='0 12px 0 12px'>
                  <ReactSelectSecondary
                    block
                    isSearchable={false}
                    options={[
                      {
                        label: 'Switch locations',
                        options: locations.map(({ id, name }) => ({
                          label: name,
                          value: id,
                        })),
                      },
                    ]}
                    value={{ label: location.name, value: location.id }}
                    onChange={selection => {
                      // Change location while reseting all filters to default
                      if (selection?.value) {
                        setAppState({
                          locationId: selection.value,
                          isMobileAsideOpen: false,
                          contentFilter: 'all',
                          groupId: null,
                          showArchivedPeople: false,
                          showArchivedUsers: false,
                          filterUpdatesBy: null,
                          filterMilestonesBy: null,
                          filterTasksBy: 'My',
                          updatesLimit: DEFAULT_UPDATES_LIMIT,
                        });
                      }
                    }}
                  />
                </Padding>
                <AsideDivider />
              </>
            ) : (
              <>
                <Margin margin='4px 12px 24px 0' className='shown-mobile'>
                  <NotebirdLogo height='24' width='100%' tint prefix='filter-aside-' />
                </Margin>
                <Spacer height='64px' className='hidden-mobile' />
              </>
            )}

            {/* Main area */}
            <Scrollable endBuffer='64px'>
              {/* 'All' button */}
              <FilterButton
                active={!groupId && contentFilter === 'all'}
                onClick={() => {
                  setAppState({
                    contentFilter: 'all',
                    groupId: null,
                    isMobileAsideOpen: false,
                  });
                }}
                // Only count on people and users pages
                count={
                  // All people counts ( archived considered )
                  filterAsidePage === 'people'
                    ? showArchivedPeople
                      ? counts?.people
                      : (counts?.people ?? 0) - (counts?.peopleArchived ?? 0)
                    : // All users counts ( archived ( and location? ) considered )
                      filterAsidePage === 'users'
                      ? userCounts.all
                      : undefined
                }
              >
                {/* 'All _____' title for different pages */}
                {/* {pageName === 'people' && 'All people'}
                {pageName === 'activity' && 'All activity'}
                {pageName === 'tasks' && 'All tasks'}
                {pageName === 'users' && 'All users'} */}
                {/* Switched it up, just All people vs All users */}
                {filterAsidePage === 'users'
                  ? 'All users'
                  : kidcardMode
                    ? 'All students'
                    : 'All people'}
              </FilterButton>

              {/* If is users page and has multiple users */}
              {/* show 'admins' button */}
              {filterAsidePage === 'users' && !isSingleUser && (
                <FilterButton
                  active={!groupId && contentFilter === 'admins'}
                  onClick={() => {
                    setAppState({
                      contentFilter: 'admins',
                      groupId: null,
                      isMobileAsideOpen: false,
                    });
                  }}
                  count={userCounts.admins}
                >
                  Admins
                </FilterButton>
              )}

              {/* On user's page, show invitations if has any ( org admins only ) */}
              {filterAsidePage === 'users' && !!userCounts.invitations && (
                <>
                  <AsideDivider />
                  <FilterButton
                    active={!groupId && contentFilter === 'invitations'}
                    onClick={() => {
                      setAppState({
                        contentFilter: 'invitations',
                        groupId: null,
                        isMobileAsideOpen: false,
                      });
                    }}
                    count={userCounts.invitations}
                  >
                    Pending invitations
                  </FilterButton>
                </>
              )}

              {/* 'Following' button ( if is following any people AND not on users page ) */}
              {!!followedPeopleCount && filterAsidePage !== 'users' && (
                <FilterButton
                  active={!groupId && contentFilter === 'following'}
                  onClick={() => {
                    setAppState({
                      contentFilter: 'following',
                      groupId: null,
                      isMobileAsideOpen: false,
                    });
                  }}
                  // Only count on people page
                  count={filterAsidePage === 'people' ? followedPeopleCount : undefined}
                >
                  Followed people
                </FilterButton>
              )}
              {!hideGroups && <AsideDivider />}

              {/* Followed groups list */}
              {!hideGroups &&
                followedGroups.map(group => (
                  <FilterButton
                    key={group.id}
                    active={groupId === group.id}
                    onClick={() => {
                      setAppState({ groupId: group.id, isMobileAsideOpen: false });
                    }}
                    // Only count on people and users pages
                    count={
                      filterAsidePage === 'people'
                        ? getPeopleGroupCount(group.id)
                        : filterAsidePage === 'users'
                          ? getUsersGroupCount(group.id)
                          : undefined
                    }
                  >
                    {group.name}
                  </FilterButton>
                ))}
              {!hideGroups && !!followedGroups.length && !!unfollowedGroups.length && (
                <AsideDivider />
              )}

              {/* Unfollowed groups list */}
              {!hideGroups &&
                unfollowedGroups.map(group => (
                  <FilterButton
                    key={group.id}
                    active={groupId === group.id}
                    onClick={() => {
                      setAppState({ groupId: group.id, isMobileAsideOpen: false });
                    }}
                    // Only count on people and users pages
                    count={
                      filterAsidePage === 'people'
                        ? getPeopleGroupCount(group.id)
                        : filterAsidePage === 'users'
                          ? getUsersGroupCount(group.id)
                          : undefined
                    }
                  >
                    {group.name}
                  </FilterButton>
                ))}

              {/* 'Ungrouped' button ( if isAdmin, has any, and on people/users page only )*/}
              {isLocationAdmin &&
                ((!!getPeopleGroupCount('ungrouped') && filterAsidePage === 'people') ||
                  (!!getUsersGroupCount('ungrouped') && filterAsidePage === 'users')) && (
                  <>
                    <Spacer height='12px' />
                    <FilterButton
                      // key={group.id}
                      active={!groupId && contentFilter === 'ungrouped'}
                      onClick={() => {
                        setAppState({
                          contentFilter: 'ungrouped',
                          groupId: null,
                          isMobileAsideOpen: false,
                        });
                      }}
                      // Only count on people and users pages
                      count={
                        filterAsidePage === 'people'
                          ? getPeopleGroupCount('ungrouped')
                          : filterAsidePage === 'users'
                            ? getUsersGroupCount('ungrouped')
                            : undefined
                      }
                    >
                      Ungrouped
                    </FilterButton>
                  </>
                )}

              {/* Divider if not on users page */}
              {filterAsidePage !== 'users' && <AsideDivider />}

              {/* Archived people toggle ( people+activity pages, if has any archived people ) */}
              {['people', 'activity'].includes(filterAsidePage) && !!counts?.peopleArchived && (
                <>
                  <Spacer height='8px' />
                  <ArchivedToggleWrapper
                    onClick={event => {
                      event.preventDefault();
                      setAppState({ showArchivedPeople: !showArchivedPeople });
                      posthog?.capture(
                        showArchivedPeople ? 'people_hide_archived' : 'people_show_archived',
                        { archived_people_count: counts.peopleArchived }
                      );
                    }}
                  >
                    <Toggle
                      small
                      checked={showArchivedPeople}
                      onChange={event => {
                        event.preventDefault();
                        setAppState({ showArchivedPeople: !showArchivedPeople });
                        posthog?.capture(
                          showArchivedPeople ? 'people_hide_archived' : 'people_show_archived',
                          { archived_people_count: counts.peopleArchived }
                        );
                      }}
                    />
                    <Spacer width='8px' />
                    Show archived
                    <Expanded />
                    {/* Only show count on people page */}
                    {filterAsidePage === 'people' && (
                      <ArchivedToggleCount>{counts.peopleArchived}</ArchivedToggleCount>
                    )}
                  </ArchivedToggleWrapper>
                </>
              )}

              {/* Restricted updates toggle */}
              {filterAsidePage === 'activity' && hasRestrictedAccess && (
                <>
                  <Spacer height='8px' />
                  <ArchivedToggleWrapper
                    onClick={event => {
                      event.preventDefault();
                      setAppState({ showRestrictedUpdates: !showRestrictedUpdates });
                      posthog?.capture(
                        showRestrictedUpdates
                          ? 'activity_hide_restricted_updates'
                          : 'activity_show_restricted_updates'
                      );
                    }}
                  >
                    <Toggle
                      small
                      checked={showRestrictedUpdates}
                      onChange={event => {
                        event.preventDefault();
                        setAppState({ showRestrictedUpdates: !showRestrictedUpdates });
                        posthog?.capture(
                          showRestrictedUpdates
                            ? 'activity_hide_restricted_updates'
                            : 'activity_show_restricted_updates'
                        );
                      }}
                    />
                    <Spacer width='8px' />
                    Show restricted updates
                  </ArchivedToggleWrapper>
                </>
              )}

              {/* Archived users toggle ( users page, if has any archived users, and is org admin ) */}
              {/* No one but organization admins need to see archived users */}
              {filterAsidePage === 'users' && !!userCounts.archived && isOrganizationAdmin && (
                <>
                  <AsideDivider />
                  <Spacer height='8px' />
                  <ArchivedToggleWrapper
                    onClick={event => {
                      event.preventDefault();
                      setAppState({ showArchivedUsers: !showArchivedUsers });
                    }}
                  >
                    <Toggle
                      small
                      checked={showArchivedUsers}
                      onChange={event => {
                        event.preventDefault();
                        setAppState({ showArchivedUsers: !showArchivedUsers });
                      }}
                    />
                    <Spacer width='8px' />
                    Show archived
                    <Expanded />
                    <ArchivedToggleCount>{userCounts.archived}</ArchivedToggleCount>
                  </ArchivedToggleWrapper>
                </>
              )}

              {/* People sort ( people page ) */}
              {filterAsidePage === 'people' && (
                <Padding padding='16px 12px 0'>
                  {/* Order By */}
                  <ReactSelectSecondary
                    small
                    isSearchable={false}
                    options={PEOPLE_ORDERS_BY.map(orderBy => ({
                      value: orderBy,
                      label: orderBy,
                    }))}
                    value={{
                      label: (
                        <>
                          Sort by <strong>{orderPeopleBy.toLowerCase()}</strong>
                        </>
                      ) as React.ReactNode,
                      value: orderPeopleBy,
                    }}
                    onChange={selection => {
                      if (selection?.value) {
                        setAppState({
                          orderPeopleBy: selection.value,
                          orderPeopleDirection: 'primary',
                        });
                        const analyticsProps = { filter: `${selection.value} - primary` };
                        posthog?.capture('people_ordered_by', analyticsProps);
                        logEvent(fbAnalytics, 'order_people_by', analyticsProps);
                      }
                    }}
                  />
                  {/* Order Direction */}
                  <ReactSelectSecondary
                    small
                    isSearchable={false}
                    options={peopleOrderByDirectionOptions}
                    value={peopleOrderByDirectionOptions.find(
                      ({ value }) => value === orderPeopleDirection
                    )}
                    onChange={selection => {
                      if (selection?.value) {
                        setAppState({ orderPeopleDirection: selection.value });
                        const analyticsProps = {
                          filter: `${orderPeopleBy} - ${selection.value}`,
                        };
                        posthog?.capture('people_ordered_by', analyticsProps);
                        logEvent(fbAnalytics, 'order_people_by', analyticsProps);
                      }
                    }}
                  />
                </Padding>
              )}

              {/* Activity filters ( activity page ) */}
              {filterAsidePage === 'activity' && (
                <Padding padding='16px 12px 0'>
                  <ActivityFilters />
                </Padding>
              )}

              {/* Tasks filter ( tasks page) */}
              {/* ( but single users don't need it ) */}
              {filterAsidePage === 'tasks' && !isSingleUser && (
                <Padding padding='16px 12px 0'>
                  <TasksFilter />
                </Padding>
              )}
            </Scrollable>

            {/* Manage groups button ( at bottom ) */}
            {/* ( only show if admin or member has more than 1 group ) */}
            {(isOrganizationAdmin || !hasSingleGroup) && (
              <div>
                <AsideDivider />
                <StyledManageGroupsButton
                  dull
                  leadingIcon='clear_all'
                  // leadingIcon="settings"
                  onClick={e => {
                    e.preventDefault();
                    setAppState({ isManageGroupsModalOpen: true });
                  }}
                  data-intercom-target='Manage groups button'
                >
                  Manage groups
                </StyledManageGroupsButton>
                <Spacer height='16px' />
              </div>
            )}
          </Aside>
        )}
      </CSSTransition>

      {/* Manage (add, edit, delete, follow) groups modal */}
      <Modal
        heading='Manage groups'
        handleClose={() => {
          setAppState({ isManageGroupsModalOpen: false });
        }}
      >
        {isManageGroupsModalOpen && <ManageGroupsSheet />}
      </Modal>
    </>
  );
};
export default FilterAside;
