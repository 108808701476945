import { useState, Fragment, useMemo, SetStateAction, Dispatch, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
// Libs
import { DateTime } from 'luxon';
import { HIDE_ANNIVERSARY_YEAR_PAST, HIDE_BIRTHDAY_YEAR_PAST } from '@/lib/config';
import { getFriendlyDate, pluralize, getNumberOrdinal } from '@/lib/helpers';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import merge from 'lodash/merge';
import orderBy from 'lodash/orderBy';
import intersection from 'lodash/intersection';
// Classes
import Doc, { WithId } from '@/classes/Doc';
import Person from '@/classes/Person';
import Update from '@/classes/Update';
import Milestone from '@/classes/Milestone';
// Context data
import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';
// Page/section components
import MilestoneItem from './MilestoneItem';
// Layout components
import SheetHeader from '@/components/layout/SheetHeader';
// Common components
import UpdateItem from '@/components/common/UpdateItem';
import Divider from '@/components/common/Divider';
import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import Spacer from '@/components/common/Spacer';
import LottieAnimation from '@/components/common/LazyLottieAnimation';
// import Wiggler from '@/components/common/Wiggler';
import StickyHeader from '@/components/common/StickyHeader';
import TransitionList, { TransitionListWrapper } from '@/components/common/TransitionList';
import FlexRow from '@/components/common/FlexRow';
import Padding from '@/components/common/Padding';
import Icon from '@/components/common/Icon';

// Types
export interface RelativeMilestone extends Doc<Milestone> {
  dateTime: DateTime;
  annualizedDateTime: DateTime;
  yearsFromOriginal: number;
  typeLabel: string;
  timeLabel: string;
}

// Styles
const GetStartedHeader = styled.div`
  margin-bottom: 4px;
  font-size: 18px;
  color: ${props => props.theme.textSecondary};
  strong {
    font-size: 26px;
    color: ${props => props.theme.textPrimary};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.primary500};
  }
`;
const GetStartedCopy = styled.div`
  margin-bottom: 24px;
  line-height: 1.25;
  color: ${props => props.theme.textFaded};
`;
const arrowWaveUp = keyframes`
    0% { transform: translateY(16px); opacity: 0.2; }
  100% { transform: translateY(0); opacity: 0.5; }
`;
const GetStartedArrow = styled(Icon)`
  position: absolute;
  bottom: -48px;
  right: 58px;
  color: ${({ theme }) => theme.linkColor};
  animation: ${arrowWaveUp} 800ms ${({ theme }) => theme.easeStandard} infinite alternate;
`;

// Props
interface ActivitySectionProps {
  person: Doc<Person>;
  updates: Doc<Update>[];
  milestones: Doc<Milestone>[];
  setShowNewUpdateForm: Dispatch<SetStateAction<boolean>>;
  setEditUpdateDoc: Dispatch<SetStateAction<WithId<Update> | undefined>>;
  setDeleteUpdateDoc: Dispatch<SetStateAction<WithId<Update> | undefined>>;
}
// Component
const ActivitySection = ({
  person,
  updates,
  milestones,
  setShowNewUpdateForm,
  setDeleteUpdateDoc,
  setEditUpdateDoc,
}: ActivitySectionProps) => {
  // App state
  const { uid, isActive, isLocationAdmin, isSingleUser } = useAppState();
  // Context data
  const [userGroups] = useGroups();

  // State for expanded upcoming milestones section
  const [showUpcomingMilestones, setShowUpcomingMilestones] = useState(false);

  // Updates
  //////////
  // Group updates by day
  const groupedUpdates = useMemo(() => {
    const groups = groupBy(
      orderBy(updates, ({ meta: { createdAt } }) => createdAt?.toMillis(), 'desc'),
      update =>
        update.meta.createdAt
          ? DateTime.fromJSDate(update.meta.createdAt.toDate()).startOf('day').toMillis()
          : DateTime.local().startOf('day').toMillis()
    );
    // Rename into own 'updates' object to better merge with milestones later
    const renamedGroups: {
      [key: string]: { updates: Doc<Update>[] };
    } = {};
    for (const key in groups) {
      renamedGroups[key] = { updates: groups[key] };
    }
    return renamedGroups;
  }, [updates]);

  // Milestones
  /////////////
  // Duplicate each milestone that should show annually (and purge irrelevant)
  const annualizedMilestones = useMemo(() => {
    const now = DateTime.local().startOf('day');
    const futureCutoff = now.plus({ days: 60 });
    const pastCutoff = now.minus({ days: 30 });
    // Get and format/label all original milestones
    const originalMilestones: RelativeMilestone[] =
      milestones.map(milestone => {
        const dateTime = milestone.date ? DateTime.fromISO(milestone.date).startOf('day') : now;
        const annualizedDateTime = dateTime.set({ year: now.year });
        return {
          ...milestone,
          dateTime,
          annualizedDateTime,
          yearsFromOriginal: 0,
          typeLabel: '',
          timeLabel: '',
        };
      }) || [];

    // Loop over orginal milestones and add to annualized list as necessary
    const annualizedMilestones: RelativeMilestone[] = [];
    originalMilestones.forEach(originalMilestone => {
      // Disregard anything too far in the future
      if (originalMilestone.dateTime > futureCutoff) {
        return;
      }
      // Add original if not default that's too far in the past
      if (
        !(
          ['birthday', 'anniversary', 'joinDate'].includes(originalMilestone.type) &&
          originalMilestone.dateTime < pastCutoff
        )
      ) {
        annualizedMilestones.push(originalMilestone);
      }
      // Loop over annual milestones
      if (originalMilestone.showAnnually) {
        // Only show annualized milestones AFTER milestone was created (minus 30 days)
        // (in case of birthday, anniversary, joinDate, creation time is always set to PERSON's creation time)
        const originCutoff = originalMilestone.meta.createdAt
          ? DateTime.fromJSDate(originalMilestone.meta.createdAt.toDate()).minus({ days: 30 })
          : pastCutoff;
        // Add one year until reaches future cutoff
        let yearsFromOriginal = 1;
        let dateTime = originalMilestone.dateTime.plus({ years: yearsFromOriginal });
        while (dateTime < futureCutoff) {
          if (dateTime > originCutoff) {
            annualizedMilestones.push({
              ...originalMilestone,
              dateTime,
              yearsFromOriginal,
            });
          }
          yearsFromOriginal++;
          dateTime = originalMilestone.dateTime.plus({ years: yearsFromOriginal });
        }
      }
    });

    // Label milestone types and times appropriately
    const labeledMilestones = annualizedMilestones.map(annualizedMilestone => {
      const { type, dateTime, yearsFromOriginal } = annualizedMilestone;

      let typeLabel = type;
      const isNear = pastCutoff < dateTime && dateTime < futureCutoff;
      let timeLabel = pluralize({
        count: yearsFromOriginal,
        root: '{x} year',
        singular: isNear ? ' ago' : ' since',
        plural: isNear ? 's ago' : 's since',
      });
      switch (type) {
        case 'birthday':
          if (yearsFromOriginal === 0) {
            typeLabel = dateTime > now ? 'Will be born' : 'Was born';
          } else if (yearsFromOriginal > HIDE_BIRTHDAY_YEAR_PAST) {
            typeLabel = 'Birthday';
          } else {
            typeLabel = getNumberOrdinal(yearsFromOriginal) + ' Birthday';
          }
          timeLabel = '';
          break;
        case 'anniversary':
          if (yearsFromOriginal === 0) {
            typeLabel = 'Marriage';
          } else if (yearsFromOriginal > HIDE_ANNIVERSARY_YEAR_PAST) {
            typeLabel = 'Anniversary';
          } else {
            typeLabel = getNumberOrdinal(yearsFromOriginal) + ' Anniversary';
          }
          timeLabel = '';
          break;
        case 'joinDate':
          typeLabel = dateTime > now && yearsFromOriginal < 1 ? 'Will join' : 'Joined';
          timeLabel = yearsFromOriginal < 1 ? '' : timeLabel;
          break;
      }

      return { ...annualizedMilestone, typeLabel, timeLabel };
    });

    return labeledMilestones;
  }, [milestones]);
  // Group milestones by day
  const groupedMilestones = useMemo(() => {
    const groups = groupBy(annualizedMilestones, milestone =>
      milestone.dateTime
        ? milestone.dateTime.startOf('day').toMillis()
        : DateTime.local().startOf('day').toMillis()
    );
    // Rename into own 'milestones' object to better merge with updates
    const renamedGroups: { [key: string]: { milestones: RelativeMilestone[] } } = {};
    for (const key in groups) {
      renamedGroups[key] = { milestones: groups[key] };
    }
    return renamedGroups;
  }, [annualizedMilestones]);

  // Merge ( and sort ) updates/milestones into array to map over
  // then group into past and future
  const mergedActivity = useMemo(() => {
    const merged = merge({}, groupedUpdates, groupedMilestones);
    const paired = toPairs(merged);
    const sorted = orderBy(paired, ([millis]) => parseInt(millis), 'desc');
    const now = new Date().valueOf();
    const grouped = groupBy(sorted, ([millis]) => (parseInt(millis) < now ? 'past' : 'future'));
    return grouped;
  }, [groupedUpdates, groupedMilestones]);

  // Determine if should show or hide update group
  // ( person only in one group and it's the same one as update )
  // ( same as tasks section )
  const shouldHideGroups = useCallback(
    (updateGroups: string[]) => {
      const userGroupIds = userGroups?.map(({ id }) => id) || [];
      const personGroupsInCommon = isLocationAdmin
        ? person.groups
        : intersection(person.groups, userGroupIds);
      const updateGroupsInCommon = isLocationAdmin
        ? updateGroups
        : intersection(updateGroups, userGroupIds);
      return (
        personGroupsInCommon.length === 1 &&
        updateGroupsInCommon.length === 1 &&
        personGroupsInCommon[0] === updateGroupsInCommon[0]
      );
    },
    [userGroups, isLocationAdmin, person.groups]
  );

  return (
    <>
      <SheetHeader
        sticky
        leadingIcon='library_books'
        mainTitle='Activity'
        trailing={
          <>
            {/* <Wiggler disabled={!!updates.length || person.isArchived || !isActive}> */}
            <PrimaryButton
              // TODO: don't autofocus on mobile
              autoFocus={true}
              medium
              onClick={() => setShowNewUpdateForm(true)}
              disabled={person.isArchived || !isActive}
              data-intercom-target='Add update button'
            >
              Add <strong>update</strong>
            </PrimaryButton>
            {/* Pointing get started arrow */}
            {!updates.length && !person.isArchived && isActive && (
              <GetStartedArrow icon='arrow_upward' iconSize='48px' />
            )}
            {/* </Wiggler> */}
          </>
        }
        // Header fades if has no updates
        active={!!updates.length}
        // Button to expand/hide upcoming milestones ( when has any )
        subtitle={
          mergedActivity.future ? (
            <SecondaryButton
              onClick={event => {
                event.preventDefault();
                setShowUpcomingMilestones(prev => !prev);
              }}
              dull={showUpcomingMilestones}
            >
              {showUpcomingMilestones ? 'Hide ' : 'Show '}
              {pluralize({ root: '{x} upcoming milestone', count: mergedActivity.future.length })}
            </SecondaryButton>
          ) : undefined
        }
        data-intercom-target='Person activity header'
      />

      {/* Map over future milestones */}
      <TransitionListWrapper>
        {!!mergedActivity.future && showUpcomingMilestones && (
          <TransitionList>
            {mergedActivity.future.map(([millis, { milestones = [] }]) => (
              <Fragment key={millis}>
                {/* Date header */}
                <StickyHeader top={64} heading={getFriendlyDate(parseInt(millis))} />
                {/* Each milestone on date */}
                {milestones.map(milestone => (
                  <MilestoneItem
                    key={milestone.id + milestone.yearsFromOriginal}
                    milestone={milestone}
                    dull
                  />
                ))}
              </Fragment>
            ))}
            {/* Double divider between future and past */}
            <Divider />
            <Spacer height='4px' />
            <Divider />
            <Spacer height='12px' />
          </TransitionList>
        )}
      </TransitionListWrapper>

      {/* Get started onboarding text + CTA ( no past activity ) */}
      {/* <GetStartedIcon icon='arrow_upward' iconSize='32px' /> */}
      {!updates.length && (
        <Padding padding='32px 24px' mobilePadding='24px 16px'>
          <FlexRow justify='center'>
            {/* Animation */}
            <LottieAnimation
              path='addUpdateIllustrationAnimationData'
              width='142px'
              height='232px'
            />
            <Spacer width='16px' />
            {/* If not archived */}
            <div>
              {!person.isArchived ? (
                <>
                  {/* Header */}
                  <GetStartedHeader>
                    <strong>Add an update</strong>
                    {/* <strong>Create an update</strong> for {person.firstName} */}
                  </GetStartedHeader>
                  {/* Supporting copy */}
                  <GetStartedCopy>
                    to start recording conversations
                    <br />
                    and life events
                  </GetStartedCopy>
                </>
              ) : (
                <GetStartedCopy>
                  Unarchive {person.profile.name.first} to add updates
                </GetStartedCopy>
              )}
            </div>
          </FlexRow>
        </Padding>
      )}

      {/* Map over merged (past) activity */}
      {mergedActivity.past?.map(([millis, { updates = [], milestones = [] }]) => (
        <Fragment key={millis}>
          {/* Date header */}
          <StickyHeader top={64} heading={getFriendlyDate(parseInt(millis))} />

          {/* Each update on date */}
          {updates.map(update => (
            <UpdateItem
              key={update.id}
              update={update}
              setEditUpdateDoc={setEditUpdateDoc}
              setDeleteUpdateDoc={setDeleteUpdateDoc}
              hidePerson
              hideUser={isSingleUser}
              canEdit={
                isActive &&
                !person.isArchived &&
                (isLocationAdmin || update.meta.createdBy.id === uid)
              }
              // Hide groups here if user only has one group
              // OR update's groups are exact same as person's
              hideGroups={shouldHideGroups(update.groups)}
            />
          ))}

          {/* Each milestone on date */}
          {milestones.map(milestone => (
            <MilestoneItem key={milestone.id + milestone.yearsFromOriginal} milestone={milestone} />
          ))}
        </Fragment>
      ))}
    </>
  );
};

// Export
export default ActivitySection;
