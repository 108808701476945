import { useCallback, useEffect, useRef, useState } from 'react';

import useIsPageVisible from './useIsPageVisible';

type Backoff = {
  type: 'exponential' | 'linear' | 'constant';
  factor: number;
};

type TriggerType = 'mount' | 'deps' | 'interval' | 'visibility' | 'manual';

interface UseEffectWithIntervalOptions {
  /**
   * Determines whether the effect should only run when the page is visible.
   * Default is true. If set to false, the effect will run even when the page is not in focus.
   */
  onlyOnVisible?: boolean;

  /**
   * The initial interval duration (in milliseconds) for running the effect.
   * Default is 1000 (1 second). Adjust this value to run the effect more or less frequently.
   */
  initialInterval?: number;

  /**
   * Specifies the backoff strategy. Default is 'exponential'.
   * If set to 'exponential', the interval duration doubles each time the effect is run.
   * If set to 'linear', the interval duration increases by the initial interval each time the effect is run.
   * If set to a number, that amount (in milliseconds) is added to the interval duration each time the effect is run.
   */
  backoff?: Backoff;

  /**
   * Specifies how quickly ( if at all ) to accelerate the rate of backoff.
   * If set to 2, the interval duration doubles each time the effect is run.
   * If set to 1, the interval duration increases by the initial interval each time the effect is run.
   * If set to 0, the interval duration does not change.
   */
  exponent?: number;

  /**
   * The maximum interval duration (in milliseconds). The interval duration will not exceed this value, regardless of the backoff strategy.
   * Default is 60000 (1 minute).
   */
  maxInterval?: number;
  /** Determines whether the interval should reset when when deps array changes */
  restartOnChange?: boolean;
  /** Determines whether the interval should reset when page/tab visibility changes. */
  restartOnVisibility?: boolean;
  /** We can just cut things out once the max is reached */
  quitAtMax?: boolean;
  /** A quick way to disable any extra retries */
  disableRetries?: boolean;

  /** Shows console log on each rerun */
  debug?: boolean;
}

/**
 * A React hook that runs a given effect at a specified interval. The interval duration increases over time according to a specified backoff strategy.
 * The effect and the interval can be manually triggered/ reset.
 *
 * @param effect The effect to run. Can optionally return a cleanup function that will be run before the next effect and when the component unmounts.
 * @param deps The dependencies of the effect. If any dependency changes, the effect will be run immediately, and the interval duration will be reset.
 * @param options An optional configuration object.
 *
 * @returns A function that when called, will immediately run the effect and reset the interval duration.
 */
function useEffectWithInterval(
  effect: (triggerType: TriggerType) => (() => void) | void,
  deps: unknown[],
  options: UseEffectWithIntervalOptions = {}
) {
  // Default options
  const {
    onlyOnVisible = true,
    initialInterval = 2000,
    backoff = { type: 'exponential', factor: 2 },
    maxInterval = 1000 * 60,
    restartOnChange = true,
    restartOnVisibility = true,
    quitAtMax = false,
    disableRetries = false,
    debug = false,
  } = options;
  // Current timer interval
  const [currentInterval, setCurrentInterval] = useState(initialInterval);

  // Use a ref to store the effect function
  const effectRef = useRef(effect);
  effectRef.current = effect;

  // Also use a ref to store the cleanup function
  const cleanupRef = useRef<(() => void) | void | null>(null);

  // Use ref to store the current interval timer
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Use ref to store the current interval iteration
  const intervalIteration = useRef(0);

  // This callback handles what each call of the function should do:
  const triggerEffect = useCallback(
    (type: TriggerType) => {
      debug &&
        console.log('triggerEffect', type, type === 'interval' ? intervalIteration.current : '');
      // Cleanup the previous effect
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = null;
      }
      // Run the effect ( and store the cleanup function )
      cleanupRef.current = effectRef.current(type);
    },
    [debug]
  );

  // Restart on visibility change ( if onlyOnVisible is true and not initial mount )
  const isPageVisible = useIsPageVisible();
  const visibilityRestart = onlyOnVisible && restartOnVisibility ? isPageVisible : false;
  const previousVisibility = useRef(isPageVisible);

  // Run function on mount and any time deps change
  // ( just like a normal useEffect ) + visibility change
  const initialMount = useRef(true);
  useEffect(() => {
    let type: TriggerType = 'deps';
    if (initialMount.current) type = 'mount';
    if (!disableRetries && previousVisibility.current !== visibilityRestart) {
      // Don't need to do anything if not visible
      type = 'visibility';
      previousVisibility.current = visibilityRestart;
      if (!visibilityRestart) return;
    }
    triggerEffect(type);
    initialMount.current = false;

    // Reset the interval if the deps change ( if resetOnChange is true )
    if (restartOnChange) {
      intervalIteration.current = 0;
      setCurrentInterval(initialInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, visibilityRestart, disableRetries]);

  const calculateBackoff = useCallback(
    (currentInterval: number) => {
      let nextInterval = currentInterval;
      if (backoff.type === 'constant') nextInterval = backoff.factor;
      if (backoff.type === 'linear') nextInterval = currentInterval + backoff.factor;
      if (backoff.type === 'exponential') nextInterval = currentInterval * backoff.factor;
      return Math.min(nextInterval, maxInterval);
    },
    [backoff.factor, backoff.type, maxInterval]
  );

  // Use a random number to force a re-render
  const [intervalSeed, setIntervalSeed] = useState(Math.random());
  // Run the function at the specified interval(s)
  const visibilityGate = onlyOnVisible ? isPageVisible : true;
  const quitGate = quitAtMax ? currentInterval < maxInterval : true;
  const shouldRunEffect = !disableRetries && visibilityGate && quitGate;
  useEffect(() => {
    // Clear the timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    if (!shouldRunEffect) return;

    // Create a new timer
    timerRef.current = setTimeout(() => {
      intervalIteration.current += 1;
      // Run the effect
      triggerEffect('interval');
      // Calculate the next interval
      const nextInterval = calculateBackoff(currentInterval);
      setIntervalSeed(Math.random());
      // Set the next interval
      setCurrentInterval(nextInterval);
    }, Math.round(currentInterval));
  }, [
    intervalSeed,
    calculateBackoff,
    currentInterval,
    initialInterval,
    shouldRunEffect,
    triggerEffect,
  ]);

  // Always do a final cleanup on unmount
  useEffect(() => {
    return () => {
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = null;
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  // Provide a way to manually trigger an update
  return () => {
    triggerEffect('manual');
    // Reset the interval if resetOnChange is true
    if (restartOnChange) {
      intervalIteration.current = 0;
      setCurrentInterval(initialInterval);
    }
  };
}

export default useEffectWithInterval;
