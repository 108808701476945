import { useEffect, useRef } from 'react';

import { fbAnalytics, fbPerf } from '@/lib/firebase';
import { setUserProperties } from 'firebase/analytics';
import { trace } from 'firebase/performance';

import { useIntercom } from 'react-use-intercom';

import useAppState from './appState';
import useAuth from './auth';
import useCounts, { CountsProvider } from './counts';
import useGroups, { GroupsProvider } from './groups';
import { InvitationsProvider } from './invitations';
import useLocation, { LocationProvider } from './location';
import useLocations, { LocationsProvider } from './locations';
import { MilestonesProvider } from './milestones';
import useOrganization, { OrganizationProvider } from './organization';
import useOrganizations, { OrganizationsProvider } from './organizations';
import { PeopleProvider } from './people';
import { TasksProvider } from './tasks';
import useUser, { UserProvider } from './user';
import { UsersProvider } from './users';
import { UsersListProvider } from './usersList';

export const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <UserProvider>
      <OrganizationsProvider>
        <OrganizationProvider>
          <LocationsProvider>
            <LocationProvider>
              <GroupsProvider>
                <CountsProvider>
                  <UsersProvider>
                    <InvitationsProvider>
                      <UsersListProvider>
                        <PeopleProvider>
                          <MilestonesProvider>
                            <TasksProvider>
                              {/*  */}
                              {children}
                              {/*  */}
                            </TasksProvider>
                          </MilestonesProvider>
                        </PeopleProvider>
                      </UsersListProvider>
                    </InvitationsProvider>
                  </UsersProvider>
                </CountsProvider>
              </GroupsProvider>
            </LocationProvider>
          </LocationsProvider>
        </OrganizationProvider>
      </OrganizationsProvider>
    </UserProvider>
  );
};

const useSessionIsInitializing = () => {
  // App state
  const { organizationId, locationId } = useAppState();
  // Context data
  const [auth, authIsFetching] = useAuth();
  const [, userIsFetching] = useUser();
  const [organizations, organizationsAreFetching] = useOrganizations();
  const [, organizationIsFetching] = useOrganization();
  const [locations, locationsAreFetching] = useLocations();
  const [, locationIsFetching] = useLocation();
  const [groups, groupsAreFetching] = useGroups();
  const [, countsAreFetching] = useCounts();

  const { update } = useIntercom();

  // Set user role for googleAnalytics and intercom
  let user_role = '';
  if (auth && organizations && locations) {
    user_role = organizations.find(({ owner }) => owner.id === auth.uid)
      ? 'Organization owner'
      : organizations.find(({ admins }) => admins.includes(auth.uid))
      ? 'Organization admin'
      : locations?.find(({ admins }) => admins.includes(auth.uid))
      ? 'Location admin'
      : 'Group member';
  }
  useEffect(() => {
    if (user_role) {
      update({ customAttributes: { user_role } });
      setUserProperties(fbAnalytics, { user_role });
    }
  }, [update, user_role]);

  // Performance monitoring
  const hasAuth = !!auth;
  const isInitialLoad = useRef(true);
  const sessionTrace = useRef(trace(fbPerf, 'start_session'));
  useEffect(() => {
    // Only run if authed and on initial load
    hasAuth && isInitialLoad.current && sessionTrace.current.start();
  }, [hasAuth]);

  // Tier 1.  Always fetches
  if (authIsFetching || userIsFetching || organizationsAreFetching || organizationIsFetching)
    return true;

  if (organizationId) {
    // Tier 2.  Always fetches within organization
    if (locationsAreFetching || locationIsFetching) return true;

    // Tier 3.  Always fetches within location
    if (locationId) {
      if (groupsAreFetching || countsAreFetching) return true;
    }
  }

  // Stop trace with doc count metrics ( if authed )
  if (hasAuth && isInitialLoad.current) {
    const organizationsCount = organizations?.length || 0;
    const locationsCount = locations?.length || 0;
    const groupsCount = groups?.length || 0;
    const totalCount = organizationsCount + locationsCount + groupsCount;
    sessionTrace.current.incrementMetric('organization_docs', organizationsCount);
    sessionTrace.current.incrementMetric('location_docs', locationsCount);
    sessionTrace.current.incrementMetric('group_docs', groupsCount);
    sessionTrace.current.putMetric('total_docs', totalCount);
    sessionTrace.current.stop();
    isInitialLoad.current = false;
  }

  return false;
};
export default useSessionIsInitializing;
