import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

import Organization from '@/classes/Organization';
import { getStripePrice, showCents } from '@/classes/Stripe';

import { fbAnalytics, fbFunctions } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { usePostHog } from 'posthog-js/react';

import useOrganization from '@/contexts/organization';

import { InputError, RadioList } from '@/components/formElements/FormElements';

import Icon from '@/components/common/Icon';
import { FormLabel } from '@/components/common/Label';
import { FormModal } from '@/components/common/Modals';
import Spacer from '@/components/common/Spacer';

// Styles
const ModalWrapper = styled.div<{ disabled: boolean }>`
  padding: 32px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
const StyledCardElement = styled(CardElement).attrs(({ theme }) => ({
  options: {
    style: { base: { fontFamily: 'Lato, sans-serif', fontSize: '18px', color: theme.textPrimary } },
  },
}))`
  padding: 12px;
  border: 2px solid ${({ theme }) => theme.lightAccent};
  &.StripeElement--invalid {
    border-color: ${({ theme }) => theme.danger600};
  }
  border-radius: 4px;
  transition: border-color 100ms ease-out;

  background-color: ${props => props.theme.sheetBackgroundColor};

  &:hover {
    border-color: ${({ theme }) => theme.textTertiary};
    &.StripeElement--invalid {
      border-color: ${({ theme }) => theme.danger400};
    }
  }
  &.StripeElement--focus {
    outline: none;
    border-color: ${({ theme }) => theme.primary500};
    &.StripeElement--invalid {
      border-color: ${({ theme }) => theme.danger400};
    }
    box-shadow: ${props => props.theme.shadow300};
  }
`;
const Copy = styled.div`
  line-height: 1.4;
  font-size: 22px;
  color: ${({ theme }) => theme.textTertiary};

  span {
    display: block;
    font-size: 18px;
  }
  strong {
    border-bottom: 2px solid ${({ theme }) => theme.linkColor};
    color: ${({ theme }) => theme.textPrimary};
  }
`;
const Calculation = styled.div`
  color: ${({ theme }) => theme.textFaded};
`;
const StripeLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  color: ${({ theme }) => theme.textFaded};
  i {
    margin-left: -6px;
    color: ${({ theme }) => theme.textFaded};
  }
`;

type Props = {
  show: boolean;
  handleCancel(): void;
};

const SubscribeModal = ({ show, handleCancel }: Props) => {
  const stripe = useStripe();
  const elements = useElements();

  const [organization] = useOrganization();
  const _stripe = organization?._stripe || { ...new Organization() }._stripe;

  const posthog = usePostHog();
  useEffect(() => {
    if (show && posthog) {
      posthog?.capture('stripe_subscription_modal_opened');
    }
  }, [posthog, show]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Listen for card completion/error
  const [cardFormComplete, setCardFormComplete] = useState(false);
  const [cardFormError, setCardFormError] = useState<StripeCardElementChangeEvent['error']>();
  const onCardChange = useCallback((event: StripeCardElementChangeEvent) => {
    setCardFormError(event.error);
    setCardFormComplete(event.complete);
  }, []);
  // Focus when loads
  const onCardReady = useCallback((element: StripeCardElement) => {
    element.focus();
  }, []);

  // Whether user wants to pay monthly or yearly
  const [intervalSelect, setIntervalSelect] = useState<'month' | 'year'>(
    _stripe.interval ?? 'month'
  );
  const interval = _stripe.forceInterval || intervalSelect;

  // Dissallow submissions when already submitting OR
  // stripe card input is incomplete
  const submitDisabled = isSubmitting || !cardFormComplete;

  // Calculate subscription costs ( including coupon/discount )
  const { couponName, couponAmountOff, couponPercentOff } = _stripe;
  const usersCount = Math.max(
    organization?.users.length || 1,
    organization?._stripe.minQuantity || 1
  );
  const isSingleUser = usersCount === 1;
  const monthlyPrice = getStripePrice({
    interval: 'month',
    usersCount,
    couponAmountOff,
    couponPercentOff,
  });
  const yearlyPrice = getStripePrice({
    interval: 'year',
    usersCount,
    couponAmountOff,
    couponPercentOff,
  });
  const selectedPrice = interval === 'month' ? monthlyPrice : yearlyPrice;
  const yearlyDiscountPercent = Math.round(
    (1 - yearlyPrice.amountMonthly / monthlyPrice.amount) * 100
  );
  const yearlyDiscountAmount = monthlyPrice.amountYearly - yearlyPrice.amount;

  ////////////
  // Submit //
  ////////////
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event && event.preventDefault();
      // Set processing state
      setIsSubmitting(true);

      // Use elements.getElement to get a reference to the mounted Element.
      const cardElement = elements?.getElement(CardElement);
      if (stripe && cardElement) {
        try {
          // Create payment token
          const { token } = await stripe.createToken(cardElement);
          // If valid token, send to server to attach to customer and begin subscription
          if (token && organization) {
            const {
              data: { subscription },
            } = await httpsCallable<unknown, { subscription: StripeSubscription }>(
              fbFunctions,
              'startStripeSubscription'
            )({
              organizationId: organization.id,
              interval,
              token,
            });
            // 3DSecure auth flow (if required)
            const intent = subscription?.latest_invoice?.payment_intent;
            if (intent?.status === 'requires_action' && intent?.client_secret) {
              await stripe.confirmCardPayment(intent.client_secret, {
                payment_method: { card: cardElement },
              });
            }
            posthog?.capture('stripe_subscription_created', {
              interval,
              users_count: usersCount,
              amount: selectedPrice.amount,
              per_user: selectedPrice.perUser,
            });
            logEvent(fbAnalytics, 'add_payment_info');
            logEvent(fbAnalytics, 'purchase', {
              // affiliation, coupon, currency, items, transaction_id, shipping, tax, value
              transaction_id: subscription?.latest_invoice?.id,
              value: selectedPrice.amount, // Total transaction value (incl. tax and shipping)
              items: [
                {
                  item_name: `${interval}ly subscription`,
                  quantity: usersCount,
                  price: selectedPrice.perUser,
                },
              ],
            });

            // Close modal on success
            handleCancel();
          }
        } catch (error) {
          alert('An error occurred. Please contact support. ' + error);
          console.error(error);
        }
      }

      setIsSubmitting(false);
    },
    [
      elements,
      handleCancel,
      interval,
      organization,
      posthog,
      selectedPrice.amount,
      selectedPrice.perUser,
      stripe,
      usersCount,
    ]
  );

  return (
    <FormModal
      formTitle='New subscription'
      show={show}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      handleCancel={() => {
        handleCancel();
      }}
      disabled={submitDisabled}
      submitBtn='Start subscription'
    >
      <ModalWrapper disabled={isSubmitting}>
        {/* Credit card input (stripe) */}
        <FormLabel>Credit or debit card</FormLabel>
        <Spacer height='8px' />
        <StyledCardElement onChange={onCardChange} onReady={onCardReady} />
        {!!cardFormError && <InputError>{cardFormError.message}</InputError>}
        <Spacer height='16px' />

        {/* Monthly/yearly selector ( if not forced ) */}
        {!_stripe.forceInterval && (
          <>
            <RadioList
              name='billingTerm'
              dense
              value={interval}
              onChange={event => {
                setIntervalSelect(event.target.value as 'month' | 'year');
              }}
              items={[
                { id: 'termMonthly', value: 'month', label: 'Pay Monthly' },
                {
                  id: 'termYearly',
                  value: 'year',
                  label: 'Pay Yearly',
                  trailing:
                    interval === 'month' ? (
                      <span>
                        <strong>{yearlyDiscountPercent}% OFF</strong> — Save $
                        {showCents(yearlyDiscountAmount)} every year!
                      </span>
                    ) : undefined,
                },
              ]}
            />
            <Spacer height='32px' />
          </>
        )}
        {/* Copy/text overview */}
        <Copy>
          <span>Your card will be charged</span>
          <strong>
            ${showCents(selectedPrice.amount)} once every {interval}
          </strong>{' '}
          starting today
        </Copy>
        <Spacer height='24px' />
        {/* Monthly calculation ( if not single user and no coupon ) */}
        {!isSingleUser && !couponName && (
          <Calculation>
            <strong>
              {usersCount} users @ ${showCents(selectedPrice.perUserMonthly)}/mo
            </strong>
          </Calculation>
        )}
        {/* Coupon ( if any ) */}
        {!!couponName && (
          <Calculation>
            Includes <strong>{couponName}</strong>
            {!couponName?.toLowerCase().includes('discount') && ' discount'}
          </Calculation>
        )}

        {/* Stripe label */}
        <Spacer height='32px' />
        <StripeLabel>
          <Icon icon='lock' iconSize='18px' />
          <Spacer width='4px' />
          <div>
            All <strong>payment details handled securely</strong> by{' '}
            <a href='https://stripe.com/' rel='noopener noreferrer' target='_blank'>
              Stripe
            </a>
          </div>
        </StripeLabel>
      </ModalWrapper>
    </FormModal>
  );
};
export default SubscribeModal;

// Stripe types
interface StripeSubscription {
  /**
   * The most recent invoice this subscription has generated.
   */
  latest_invoice: StripeInvoice | null;
}

interface StripeInvoice {
  /**
   * Unique identifier for the object. This property is always present unless the invoice is an upcoming invoice. See [Retrieve an upcoming invoice](https://stripe.com/docs/api/invoices/upcoming) for more details.
   */
  id: string;
  /**
   * The PaymentIntent associated with this invoice. The PaymentIntent is generated when the invoice is finalized, and can then be used to pay the invoice. Note that voiding an invoice will cancel the PaymentIntent.
   */
  payment_intent: StripePaymentIntent | null;
}
interface StripePaymentIntent {
  /**
   * Status of this PaymentIntent, one of `requires_payment_method`, `requires_confirmation`, `requires_action`, `processing`, `requires_capture`, `canceled`, or `succeeded`. Read more about each PaymentIntent [status](https://stripe.com/docs/payments/intents#intent-statuses).
   */
  status:
    | 'canceled'
    | 'processing'
    | 'requires_action'
    | 'requires_capture'
    | 'requires_confirmation'
    | 'requires_payment_method'
    | 'succeeded';
  /**
   * The client secret of this PaymentIntent. Used for client-side retrieval using a publishable key.
   *
   * The client secret can be used to complete a payment from your frontend. It should not be stored, logged, or exposed to anyone other than the customer. Make sure that you have TLS enabled on any page that includes the client secret.
   *
   * Refer to our docs to [accept a payment](https://stripe.com/docs/payments/accept-a-payment?ui=elements) and learn about how `client_secret` should be handled.
   */
  client_secret: string | null;
}
