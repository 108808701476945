import { SetStateAction, Dispatch, useMemo } from 'react';
import styled from 'styled-components';
// Libs
import firebase, { db } from '@/lib/firebase';
import getFriendlyTime from '@/lib/helpers/getFriendlyTime';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';
import { Link } from 'react-router-dom';
import { Interweave } from 'interweave';
import { Url, UrlMatcher, EmailMatcher } from 'interweave-autolink';
// Classes
import Doc from '@/classes/Doc';
import Task from '@/classes/Task';
// Context data
import useGroups from '@/contexts/groups';
import useUser from '@/contexts/user';
// Form components
import { Checkbox } from '../formElements/FormElements';
// Common components
import EditDeleteMenu from './EditDeleteMenu';
import GroupLabel from './GroupLabel';
import Spacer from './Spacer';
import Expanded from './Expanded';
import Avatar from './Avatar';
import FlexRow from './FlexRow';

// Styles
const TaskWrapper = styled.div`
  position: relative;
  padding: 0 24px 24px 16px;
  @media (max-width: 767px) {
    padding: 0 16px 24px 12px;
  }

  /* Edit button */
  &:hover {
    button > i {
      color: ${({ theme }) => theme.avatarText};
    }
  }
`;
const PersonName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-size: 18px;
  font-weight: bold;
`;
const Notes = styled.div`
  font-size: 18px;
  white-space: pre-line;
  line-height: 1.2;
`;
const NameTimeRow = styled.div`
  display: flex;
  align-items: baseline;
`;
const UserName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: bold;
  color: ${({ theme }) => theme.textSecondary};
`;
const Time = styled.div`
  white-space: nowrap;
  font-size: 14px;
  color: ${({ theme }) => theme.textTertiary};
`;

// Props
interface TaskItemProps {
  forwardedRef?: React.RefObject<HTMLDivElement>;
  task: Doc<Task>;
  setEditTaskDoc?: Dispatch<SetStateAction<Doc<Task> | undefined>>;
  setDeleteTaskDoc?: Dispatch<SetStateAction<Doc<Task> | undefined>>;
  canEdit?: boolean;
  hidePerson?: boolean;
  hideUser?: boolean;
  hideGroups?: boolean;
  showCreatedBy?: boolean;
}

// Component
const TaskItem = ({
  forwardedRef,
  task,
  setEditTaskDoc,
  setDeleteTaskDoc,
  canEdit = false,
  hidePerson = false,
  hideUser = false,
  hideGroups = false,
  showCreatedBy = false,
}: TaskItemProps) => {
  // Context data
  const [user] = useUser();
  const [groups] = useGroups();
  const groupNames = useMemo(
    () =>
      task.groups
        .map(groupId => groups?.find(({ id }) => id === groupId)?.name)
        .filter(groupName => !!groupName),
    [groups, task.groups]
  );

  // Only edit/delete if not complete, not archived, and has access
  // const showEditButton = true;
  const showEditButton = canEdit && !task.isCompleted && !task.isArchived;

  return (
    // Class name 'task-item' is for animating on the tasks page
    // see UncompletedTasksSheet and CompletedTasksSheet
    <TaskWrapper className='task-item' ref={forwardedRef}>
      {/* Person name header ( with included edit/delete button ) */}
      {!hidePerson ? (
        <>
          <FlexRow>
            <Avatar
              id={task.person.id}
              photo={task.person.profile.photo}
              name={task.person.profile.name.full}
              size={32}
              sizeOverride={28}
            />
            <Spacer width='8px' />
            <Link to={`/person/${task.person.id}`}>
              <PersonName>{task.person.profile.name.full}</PersonName>
            </Link>
          </FlexRow>
          <Spacer height='4px' />
        </>
      ) : (
        <Spacer height='8px' />
      )}
      {showEditButton && (
        <EditDeleteMenu
          handleEdit={() => setEditTaskDoc?.(task)}
          handleDelete={() => setDeleteTaskDoc?.(task)}
        />
      )}

      {/* Main content */}
      <FlexRow align='start'>
        {/* Extra left offset if showing person to match avatar width */}
        {!hidePerson && <Spacer width='36px' />}

        {/* Checkbox */}
        <Checkbox
          name='task-item'
          checked={task.isCompleted}
          onChange={event => {
            if (user) {
              const isCompleted = event.target.checked;
              db.doc(task.docPath).update({
                ...generateUpdatedByDottedMeta(user),
                isCompleted,
                completedBy: isCompleted
                  ? {
                      id: user.id,
                      profile: user.profile,
                    }
                  : null,
                // When uncompleting, keep completion date intact to
                // maintain proper position on animated unmount
                completedAt: isCompleted
                  ? firebase.firestore.FieldValue.serverTimestamp()
                  : task.completedAt,
              });
            }
          }}
        />
        <Spacer width='8px' />

        {/* Main task content */}
        <Expanded style={{ overflow: 'hidden' }}>
          {/* Notes */}
          <Notes>
            <Interweave
              content={task.notes}
              matchers={[
                new UrlMatcher('url', {}, props => <Url {...props} newWindow />),
                new EmailMatcher('email'),
              ]}
            />
          </Notes>

          {/* Groups */}
          {!hideGroups &&
            !!groupNames.length &&
            groupNames.map(groupName => <GroupLabel key={groupName}>{groupName}</GroupLabel>)}
          <Spacer height='4px' />

          {/* Assigned to user ( with task not completed ) */}
          {!hideUser && !task.isCompleted && (
            <NameTimeRow>
              {task.assignedTo ? (
                <>
                  <UserName>{task.assignedTo.profile.name.display}</UserName>
                  <Time>&nbsp;·&nbsp;Assigned</Time>
                </>
              ) : (
                <Time>Unassigned</Time>
              )}
            </NameTimeRow>
          )}
          {/* Completed user/time */}
          {!!task.isCompleted && !!task.completedBy && (
            <NameTimeRow>
              {!hideUser && (
                <>
                  <UserName>{task.completedBy.profile.name.display}</UserName>
                  <Time>&nbsp;·&nbsp;</Time>
                </>
              )}
              <Time>{getFriendlyTime(task.meta.createdAt?.toMillis())}</Time>
            </NameTimeRow>
          )}
          {/* Created by user/time */}
          {showCreatedBy && (
            <NameTimeRow>
              <Time>Created by</Time>&nbsp;
              <UserName>{task.meta.createdBy.profile.name.display}</UserName>
            </NameTimeRow>
          )}
        </Expanded>
      </FlexRow>
    </TaskWrapper>
  );
};
export default TaskItem;
