import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Invitation from '@/classes/Invitation';
import Organization from '@/classes/Organization';
import { showCents } from '@/classes/Stripe';
import { UserProfile } from '@/classes/User';

import firebase, { db, fbAnalytics, fbFunctions } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';

import { MAX_GROUP_ASSIGNMENTS } from '@/lib/config';
import generateCreatedByMeta, { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { usePostHog } from 'posthog-js/react';
import { object, string } from 'yup';

import useAppState from '@/contexts/appState';
import useGroups from '@/contexts/groups';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';
import { UsersListItem, useAllUsers } from '@/contexts/usersList';

import SheetHeader from '@/components/layout/SheetHeader';
import { SheetSubhead } from '@/components/layout/Sheets';

import { Input, InputError, Toggle } from '@/components/formElements/FormElements';
import FormScaffold from '@/components/formElements/FormScaffold';
import Select from '@/components/formElements/styled/FormSelect';

import DescriptionBlock from '@/components/common/DescriptionBlock';
import Divider from '@/components/common/Divider';
import Expanded from '@/components/common/Expanded';
import FlexRow from '@/components/common/FlexRow';
import Icon from '@/components/common/Icon';
import { FormModal } from '@/components/common/Modals';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Styles
const NameRow = styled.div`
  display: flex;
  padding: 0 32px;
  @media (max-width: 1023px) {
    padding: 0 12px;
  }

  ${Input} {
    flex: 1;
  }
`;
export const ConfirmHead = styled.div`
  margin-bottom: 2px;
  font-size: 22px;
  color: ${({ theme }) => theme.textSecondary};

  strong {
    border-bottom: 2px solid ${({ theme }) => theme.linkColor};
    color: ${({ theme }) => theme.textPrimary};
  }
`;
export const ConfirmSubhead = styled.div`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.textTertiary};
`;
export const ConfirmCopy = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.textSecondary};
`;
export const ConfirmSmall = styled.div`
  color: ${({ theme }) => theme.textFaded};
`;
const DiscountNotice = styled.div`
  padding: 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.textSecondary};
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  background-color: ${({ theme }) => theme.hoverFade};
  border: 2px solid ${({ theme }) => theme.dividerColor};
`;

// For Role Radio Select
const RadioOption = styled.div<{ expanded: boolean; disabled: boolean }>`
  border-radius: 8px;
  border: 2px solid ${({ expanded, theme }) => (expanded ? theme.primary600 : theme.dividerColor)};
  background-color: ${({ expanded, theme }) => (expanded ? theme.lightColor : theme.hoverFade)};
  input[type='radio'] {
    display: none;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  & > label {
    display: flex;

    cursor: ${({ expanded }) => (expanded ? 'auto' : 'pointer')};
    padding: 12px;
    font-weight: bold;
    font-size: 22px;
    color: ${({ expanded, theme }) => (expanded ? 'white' : theme.textSecondary)};
    background-color: ${({ expanded, theme }) => (expanded ? theme.primary600 : 'transparent')};
    small {
      padding-top: 2px;
      display: block;
      font-weight: normal;
      font-size: 16px;
      color: ${({ expanded, theme }) => (expanded ? theme.primary000 : theme.textTertiary)};
      strong {
        text-decoration: underline;
        color: ${({ expanded, theme }) => (expanded ? 'white' : theme.textSecondary)};
      }
    }

    ${({ expanded }) =>
      !expanded &&
      css`
        &:hover {
          color: ${({ theme }) => theme.textPrimary};
          ${RadioIconWrapper} {
            border-color: ${({ theme }) => theme.textPrimary};
            i {
              display: block;
              color: ${({ theme }) => theme.lightAccent};
            }
          }
        }
      `};
  }
`;
const RadioIconWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ expanded, theme }) => (expanded ? theme.primary000 : theme.lightColor)};
  border: 2px solid ${({ expanded, theme }) => (expanded ? 'transparent' : theme.textTertiary)};
  i {
    color: ${({ theme }) => theme.primary600};
    font-size: 24px;
    display: ${({ expanded }) => (expanded ? 'block' : 'none')};
  }
`;
const ExpandedOptions = styled.div<{ expanded: boolean }>`
  padding: 12px;
  display: ${props => (props.expanded ? 'block' : 'none')};
`;
const ExpandedFooter = styled.div<{ expanded: boolean }>`
  padding: 8px 12px;
  font-size: 14px;
  background-color: ${({ theme }) => theme.hoverFade};
  border-top: 2px solid ${({ theme }) => theme.dividerColor};
  display: ${props => (props.expanded ? 'block' : 'none')};
  font-weight: bold;
  color: ${({ theme }) => theme.textTertiary};
  border-radius: 0 0 8px 8px;
  strong {
    display: block;
    color: ${({ theme }) => theme.danger500};
    border-bottom: 2px solid ${({ theme }) => theme.dividerColor};
    padding-bottom: 4px;
    margin-bottom: 4px;
  }
`;
const OrDivider = styled(FlexRow)`
  color: ${({ theme }) => theme.textTertiary};
  font-weight: bold;
  gap: 12px;
  margin: 12px 0;
  div {
    flex: 1;
    height: 2px;
    background-color: ${({ theme }) => theme.dividerColor};
  }
`;
const ToggleLabel = styled.label`
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  strong {
    display: block;
    font-size: 18px;
    color: ${({ theme }) => theme.textPrimary};
  }
  color: ${({ theme }) => theme.textTertiary};
  cursor: pointer;
`;

// Types
interface IncrementQuote {
  userPriceAtTier: number;
  proration: number;
  amount: number;
  quantity: number;
}

// Default form values
export interface UserFields {
  firstName: string;
  lastName: string;
  email: string;
  role: 'admin' | 'groupMember' | '';
  groups: string[];
  hasRestrictedAccess: boolean;
  isOrganizationAdmin: boolean;
}

// Form Schema
const schema = object().shape({
  firstName: string().trim().required('First name is required').max(1000, '1000 characters max'),
  lastName: string().trim().max(1000, '1000 characters max'),
  email: string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email address is required')
    .max(250, '250 characters max'),
});

// Core form
const NewUserForm = ({ handleCancel }: { handleCancel: () => void }) => {
  const navigate = useNavigate();
  // App state
  const {
    organizationId,
    locationId,
    groupId,
    isTrialing,
    hasRestrictedAccess: currentUserHasRestrictedAccess,
  } = useAppState();
  // Context data
  const users = useAllUsers();
  const [user] = useUser();
  const [organization] = useOrganization();
  const stripe = organization?._stripe || { ...new Organization()._stripe };
  const [groups] = useGroups();
  const posthog = usePostHog();

  const initialValues: UserFields = {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
    groups: groupId ? [groupId] : [],
    hasRestrictedAccess: false,
    isOrganizationAdmin: false,
  };

  // If user already exists with this email, offer to go there.
  const [alreadyExistsUser, setAlreadyExistsUser] = useState<UsersListItem | null>(null);

  // Form helper (Formik)
  const { values, handleSubmit, isSubmitting, setFieldValue, getFieldProps, errors, touched } =
    useFormik({ initialValues, onSubmit, validationSchema: schema });

  // Users can only be in 10 groups
  const maximumGroupsSelected = values.groups.length >= MAX_GROUP_ASSIGNMENTS;

  // Values for confirm modal (if necessary)
  const [incrementUsersQuote, setIncrementUsersQuote] = useState<IncrementQuote>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // Dissallow submissions when already submitting OR
  // 1. First name is empty
  // or
  // 2. Email isn't valid
  // or
  // 3. No role
  // or
  // 4. Group member but doesn't have any groups
  const submitDisabled =
    isSubmitting ||
    !values.firstName.trim() || // 1
    !values.email.trim() || // 2
    !!errors.email ||
    !values.role || // 3
    (values.role === 'groupMember' && !values.groups.length); // 4
  ////////////
  // Submit //
  ////////////
  async function onSubmit() {
    if (user && organization && locationId) {
      setAlreadyExistsUser(null);
      // Check to see if user ( or invite ) already exists
      const existingUser = users?.find(
        ({ profile }) => profile.email.toLowerCase().trim() === values.email.toLowerCase().trim()
      );
      if (existingUser) {
        setAlreadyExistsUser(existingUser);
        return;
      }

      // Show modal to warn about pricing if not trialing and will incur costs
      // (and isn't already warning)
      if (
        !showConfirmModal &&
        !isTrialing &&
        (organization.users.length ?? 1) + 1 > stripe.quantity
      ) {
        const { data: quote } = await httpsCallable<unknown, IncrementQuote>(
          fbFunctions,
          'getStripeIncrementUsersQuote'
        )({ organizationId });
        if (quote) {
          setIncrementUsersQuote(quote);
          setShowConfirmModal(true);
          return; // Exit early
        }
      }

      // Add to invites collection
      const batch = db.batch();
      const organizationRef = db.doc(organization.docPath);
      const inviteRef = organizationRef.collection('invitations').doc();

      // Invitation
      const firstName = values.firstName.trim();
      const lastName = values.lastName.trim();
      const invitation: Invitation = {
        meta: generateCreatedByMeta(user),
        organizationName: organization.profile.name,
        profile: {
          ...new UserProfile(),
          name: {
            first: firstName,
            last: lastName,
            display: [firstName, lastName[0]].join(' ').trim(),
            full: [firstName, lastName].join(' ').trim(),
          },
          email: values.email.trim().toLowerCase(),
        },
        hasRestrictedAccess:
          currentUserHasRestrictedAccess &&
          values.role !== 'groupMember' &&
          values.hasRestrictedAccess,
        isOrganizationAdmin: values.role !== 'groupMember' && values.isOrganizationAdmin,
        locations:
          values.role !== 'groupMember' && values.isOrganizationAdmin
            ? {}
            : {
                [locationId]: {
                  isAdmin: values.role === 'admin',
                  groups: values.role === 'admin' ? [] : values.groups,
                },
              },
      };
      batch.set(inviteRef, invitation);

      // Add to appropriate users array in org (for proper stripe quantity count)
      batch.update(organizationRef, {
        ...generateUpdatedByDottedMeta(user),
        users: firebase.firestore.FieldValue.arrayUnion(inviteRef.id),
      });

      // Close modal ( if open )
      setShowConfirmModal(false);

      // Commit batch ( and await so we can open page directly after)
      await batch.commit();

      // Close form
      handleCancel();

      // Open user's invitation sheet
      navigate('/users/' + inviteRef.id);

      // Analytics
      posthog?.capture('user_invited', {
        role: values.isOrganizationAdmin
          ? 'Organization Admin'
          : values.role === 'admin'
            ? 'Location Admin'
            : 'Group Member',
        has_restricted_access: values.hasRestrictedAccess,
      });
      logEvent(fbAnalytics, 'invite_user', {
        role: values.isOrganizationAdmin
          ? 'organizationAdmin'
          : values.role === 'admin'
            ? 'locationAdmin'
            : 'groupMember',
        groups_count: values.groups.length,
      });
    }
  }

  return (
    <>
      {/* Modal to confirm payment and invite (when necessary) */}
      <FormModal
        position='absolute'
        formTitle='Confirm user'
        show={showConfirmModal}
        handleSubmit={handleSubmit}
        handleCancel={() => {
          setShowConfirmModal(false);
        }}
        submitBtn={
          <>
            Confirm payment and <strong>invite user</strong>
          </>
        }
        hideCancel
      >
        {!!incrementUsersQuote && (
          <Padding padding='48px 32px'>
            <ConfirmHead>
              Add this user for
              <strong>
                {' '}
                ${showCents(incrementUsersQuote.userPriceAtTier)}/
                {stripe.interval === 'year' ? 'yr' : 'mo'}
              </strong>
            </ConfirmHead>
            <Spacer height='32px' />
            <Divider padding={0} />
            <Spacer height='24px' />

            <ConfirmCopy>
              Your total subscription will be{' '}
              <strong>
                ${showCents(incrementUsersQuote.amount / 100)} a {stripe.interval}
              </strong>{' '}
              starting on{' '}
              {DateTime.fromMillis(stripe.currentPeriodEnd.toMillis()).toLocaleString(
                DateTime.DATE_SHORT
              )}
              .
            </ConfirmCopy>
            <Spacer height='12px' />
            {(stripe.couponAmountOff || stripe.couponPercentOff) && (
              <DiscountNotice>DISCOUNT APPLIED</DiscountNotice>
            )}
            <Spacer height='12px' />

            <Divider padding={0} />
            <Spacer height='24px' />
            <ConfirmSmall>
              Today, your card will be charged a prorated amount of $
              {showCents(incrementUsersQuote.proration / 100)} to add this user through the end of
              your current billing cycle.
            </ConfirmSmall>
          </Padding>
        )}
      </FormModal>
      {/* Form frame includes header with title/close and footer with cancel/save/delete */}
      <FormScaffold
        formTitle={'Invite New User'}
        isSubmitting={isSubmitting}
        disabled={submitDisabled}
        submitBtn={
          <>
            <strong>Invite</strong> user
          </>
        }
        handleSubmit={handleSubmit}
        handleCancel={() => {
          handleCancel();
        }}
      >
        <Spacer height='16px' />
        {/* Name */}
        <SheetSubhead title='Name' />
        <Spacer height='8px' />
        <NameRow>
          {/* First name */}
          <Expanded>
            <Input
              placeholder='First name'
              autoFocus={true}
              disabled={isSubmitting}
              error={!!touched.firstName && !!errors.firstName}
              {...getFieldProps({ name: 'firstName' })}
              maxLength={1200}
              autoComplete='off'
            />
            {!!touched.firstName && !!errors.firstName && (
              <InputError>{errors.firstName}</InputError>
            )}
          </Expanded>
          <Spacer width='16px' />
          {/* Last name */}
          <Expanded>
            <Input
              placeholder='Last name'
              disabled={isSubmitting}
              error={!!touched.lastName && !!errors.lastName}
              {...getFieldProps({ name: 'lastName' })}
              maxLength={1200}
              autoComplete='off'
            />
            {!!touched.lastName && !!errors.lastName && <InputError>{errors.lastName}</InputError>}
          </Expanded>
        </NameRow>
        <Spacer height='16px' />

        {/* Email */}
        <SheetSubhead title='Email' />
        <Padding padding='8px 32px 32px' mobilePadding='8px 12px 32px'>
          <Input
            placeholder='Email address'
            disabled={isSubmitting}
            error={!!touched.email && !!errors.email}
            {...getFieldProps({ name: 'email' })}
            maxLength={300}
            autoComplete='off'
          />
          {!!touched.email && !!errors.email && <InputError>{errors.email}</InputError>}
          {/* Warning if email already in use */}
          {alreadyExistsUser && (
            <InputError>
              This email address is already used by{' '}
              <Link to={`/users/${alreadyExistsUser.id}`} onClick={handleCancel}>
                {alreadyExistsUser.profile.name.full}
              </Link>
            </InputError>
          )}
        </Padding>

        {/* Role / Group(s) */}
        <SheetHeader leadingIcon='manage_accounts' mainTitle='User Role' />
        <DescriptionBlock>
          User roles determine what a user can see and do in Notebird.{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://guide.notebird.app/articles/3401591'
          >
            Learn more
          </a>
        </DescriptionBlock>
        <Spacer height='12px' />

        {/* Admin vs Group Member Radio Select */}
        <Padding padding='8px 32px 0' mobilePadding='8px 12px 0'>
          {/* Admin Option */}
          <RadioOption expanded={values.role === 'admin'} disabled={isSubmitting}>
            <label htmlFor='roleAdmin'>
              <input
                type='radio'
                id='roleAdmin'
                value={values.role}
                name='role'
                checked={values.role === 'admin'}
                onChange={() => {
                  setFieldValue('role', 'admin');
                }}
              />
              <RadioIconWrapper expanded={values.role === 'admin'}>
                <Icon icon='check' />
              </RadioIconWrapper>

              <div>
                Admin
                <small>
                  Access to updates <strong>in all groups</strong>
                </small>
              </div>
            </label>
            <ExpandedOptions expanded={values.role === 'admin'}>
              {/* If current user has restricted access, let them toggle on/off */}
              {currentUserHasRestrictedAccess && (
                <>
                  <ToggleLabel htmlFor='hasRestrictedAccess'>
                    <Toggle
                      disabled={isSubmitting}
                      name='hasRestrictedAccess'
                      id='hasRestrictedAccess'
                      checked={values.hasRestrictedAccess}
                      onChange={() => {
                        setFieldValue('hasRestrictedAccess', !values.hasRestrictedAccess);
                      }}
                    />
                    <div>
                      <strong>Access to Restricted Updates</strong>
                      Allow user to view and create restricted updates.
                    </div>
                  </ToggleLabel>
                  <Spacer height='12px' />
                </>
              )}

              {/* Show toggle to move user between org and location admin  */}
              <ToggleLabel htmlFor='isOrganizationAdmin'>
                <Toggle
                  disabled={isSubmitting}
                  name='isOrganizationAdmin'
                  id='isOrganizationAdmin'
                  checked={values.isOrganizationAdmin}
                  onChange={() => {
                    setFieldValue('isOrganizationAdmin', !values.isOrganizationAdmin);
                  }}
                />
                <div>
                  <strong>Access to Account Settings</strong>
                  Allow user to manage organization preferences, billing, users, and groups.
                </div>
              </ToggleLabel>
            </ExpandedOptions>
          </RadioOption>

          {/* Divider between options */}
          <OrDivider>
            <div />
            OR
            <div />
          </OrDivider>

          {/* Group Member Option */}
          <RadioOption expanded={values.role === 'groupMember'} disabled={isSubmitting}>
            <label htmlFor='roleGroupMember'>
              <input
                disabled={isSubmitting}
                type='radio'
                id='roleGroupMember'
                value={values.role}
                name='role'
                checked={values.role === 'groupMember'}
                onChange={() => {
                  setFieldValue('role', 'groupMember');
                }}
              />
              <RadioIconWrapper expanded={values.role === 'groupMember'}>
                <Icon icon='check' />
              </RadioIconWrapper>

              <div>
                Group Member
                <small>
                  Access to updates <strong>in selected groups only</strong>
                </small>
              </div>
            </label>
            <ExpandedOptions expanded={values.role === 'groupMember'}>
              {/* Groups(s) Selector */}
              <Select
                placeholder='Add to group(s)*'
                isMulti
                isDisabled={isSubmitting}
                isClearable={false}
                closeMenuOnSelect={values.groups.length >= MAX_GROUP_ASSIGNMENTS - 1}
                isSearchable={!maximumGroupsSelected && groups ? groups.length >= 8 : false}
                options={
                  maximumGroupsSelected
                    ? [
                        {
                          label: (
                            <>
                              You&apos;ve reached the <strong>10 group limit</strong>
                            </>
                          ),
                          value: '',
                          isDisabled: true,
                        },
                      ]
                    : (groups ?? []).map(group => ({
                        value: group.id,
                        label: group.name,
                        isDisabled: false,
                      }))
                }
                value={(groups ?? [])
                  .filter(({ id }) => values.groups.includes(id))
                  .map(({ id, name }) => ({
                    value: id,
                    label: name as React.ReactNode,
                    isDisabled: false,
                  }))}
                onChange={selections => {
                  setFieldValue(
                    'groups',
                    selections ? selections.map(selection => selection.value) : []
                  );
                }}
              />
            </ExpandedOptions>
            <ExpandedFooter expanded={values.role === 'groupMember'}>
              {maximumGroupsSelected && (
                <strong>{MAX_GROUP_ASSIGNMENTS} groups max per group member</strong>
              )}
              Group members can never access restricted updates or account settings
            </ExpandedFooter>
          </RadioOption>
        </Padding>
        <Spacer height='64px' />
      </FormScaffold>
    </>
  );
};
export default NewUserForm;
