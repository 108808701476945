import styled from 'styled-components';
import Expanded from '@/components/common/Expanded';

// Styles
const RadioItem = styled.label<{ active: boolean; faded: boolean; dense: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${({ dense }) => (dense ? '8px 16px' : '16px')};
  margin-bottom: ${({ dense }) => (dense ? '8px' : '12px')};

  font-size: 18px;
  line-height: 1.25;
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  color: ${props => (props.faded ? props.theme.textTertiary : props.theme.textPrimary)};
  border: 2px solid ${props => (props.active ? props.theme.primary500 : props.theme.hoverFade)};
  &:hover {
    outline: none;
    border-color: ${props => (props.active ? props.theme.primary500 : props.theme.lightAccent)};
    input {
      border-color: ${props => props.theme.primary500};
    }
  }
  border-radius: 6px;
  background-color: ${props => props.theme.hoverFade};

  span {
    display: block;
    font-size: 14px;
    color: ${({ theme }) => theme.textFaded};
    strong {
      color: ${({ theme }) => theme.textSecondary};
    }
  }

  input {
    flex-shrink: none;
    cursor: pointer;
    appearance: none;
    outline: none;
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 12px;

    border: 2px solid ${props => props.theme.grey300};
    background-color: ${props => props.theme.sheetBackgroundColor};
    border-radius: 100%;
    &:focus {
      border-color: ${props => props.theme.primary500};
      background-color: ${props => props.theme.grey100};
    }

    &:checked {
      outline: none;
      border-color: ${props => props.theme.primary500};
      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 14px;
        height: 14px;
        background-color: ${props => props.theme.primary500};
        border-radius: 100%;
      }
    }
  }
`;
interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  name: string;
  options: {
    id: string;
    value: string;
    label: React.ReactNode;
    subLabel?: React.ReactNode;
    trailing?: React.ReactNode;
  }[];
  dense?: boolean;
}
const RadioList = ({
  value,
  name,
  onChange,
  options,
  dense = false,
  autoFocus,
  ...rest
}: Props) => (
  <>
    {options.map((option, index) => (
      <RadioItem
        htmlFor={option.id}
        key={option.id}
        active={option.value === value}
        faded={!!value && option.value !== value}
        dense={dense}
      >
        <input
          checked={option.value === value}
          type='radio'
          name={name}
          id={option.id}
          value={option.value}
          onChange={onChange}
          autoFocus={autoFocus && index === 0}
          {...rest}
        />
        <Expanded>
          {option.label}
          {!!option.subLabel && <span>{option.subLabel}</span>}
        </Expanded>
        {!!option.trailing && <span>{option.trailing}</span>}
      </RadioItem>
    ))}
  </>
);
export default RadioList;
