import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

import { UserProfile } from '@/classes/User';

import { db, fbAuth } from '@/lib/firebase';
import { FirebaseError } from 'firebase/app';
import {
  AuthErrorCodes,
  EmailAuthProvider,
  type MultiFactorError,
  reauthenticateWithCredential,
} from 'firebase/auth';

import { FormikHelpers, useFormik } from 'formik';
import { object, string } from 'yup';

import useUser from '@/contexts/user';

import useMultiFactor from '@/hooks/useMultiFactor';

import { Input, InputError, PasswordInput } from '@/components/formElements/FormElements';

import Divider from '@/components/common/Divider';
import { FormLabel } from '@/components/common/Label';
import { DeleteCannotBeUndone, FormModal } from '@/components/common/Modals';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Form Schema
const EnableMultiFactorSmsModalSchema = object().shape({
  currentPassword: string().required('Current password is required'),
  verificationCode: string().length(6, 'Verification code must be 6 digits'),
});

// Types / defaults
const initialValues = {
  currentPassword: '',
  verificationCode: '',
};

// Component
interface Props {
  show: boolean;
  hide(): void;
}
export default function DisableMultiFactorSmsModal({ show, hide }: Props) {
  const [user] = useUser();
  const profile = user ? user.profile : { ...new UserProfile() };

  const [genericError, setGenericError] = useState('');

  const {
    RecaptchaRoot,
    hasSentVerification,
    resetVerification,
    sendSmsVerificationCode,
    unenrollMultiFactorSms,
  } = useMultiFactor();

  // Submit
  const onSubmit = useCallback(
    async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
      setGenericError('');

      try {
        const authUser = fbAuth.currentUser;
        if (!authUser) throw new Error('User not found');

        if (hasSentVerification) {
          // Unenroll then update db
          await unenrollMultiFactorSms(values.verificationCode);
          await db.collection('users').doc(authUser.uid).update({ multiFactorSms: null });

          // Close modal
          hide();
          setTimeout(() => {
            resetVerification();
            actions.resetForm({ values: initialValues });
          }, 1500);
        }

        // Re-authenticate (https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user)
        const credential = EmailAuthProvider.credential(profile.email, values.currentPassword);
        await reauthenticateWithCredential(authUser, credential);
      } catch (error) {
        // Deal with all the various error responses from firebase re-auth and firebase password reset
        if (error instanceof FirebaseError) {
          type ErrorCode = (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];
          const code = error.code as ErrorCode;
          // let resolver: MultiFactorResolver;
          switch (code) {
            case 'auth/wrong-password':
              actions.setFieldError('currentPassword', 'Incorrect password. Try again');
              break;
            case 'auth/too-many-requests':
              setGenericError('Too many requests. Please try again later.');
              break;
            case 'auth/invalid-verification-code':
              actions.setFieldError('verificationCode', 'Invalid verification code');
              return;
            case 'auth/multi-factor-auth-required': {
              await sendSmsVerificationCode(error as MultiFactorError);
              return;
            }
            default:
              setGenericError(
                `Error unlinking 2FA. Please contact support.\n\nError code: ${code}`
              );
          }
        } else {
          setGenericError(
            `Error unlinking 2FA. Please contact support.\n\nError: ${JSON.stringify(error)}`
          );
        }
        console.error(error);
      }
    },
    [
      hasSentVerification,
      hide,
      profile.email,
      resetVerification,
      sendSmsVerificationCode,
      unenrollMultiFactorSms,
    ]
  );

  // Form helper (Formik)
  const { values, errors, submitCount, handleSubmit, isSubmitting, getFieldProps, resetForm } =
    useFormik({
      initialValues,
      onSubmit,
      validationSchema: EnableMultiFactorSmsModalSchema,
    });

  const submitDisabled =
    isSubmitting ||
    !values.currentPassword ||
    (hasSentVerification && values.verificationCode.length !== 6);

  return createPortal(
    <>
      <FormModal
        show={show}
        formTitle='Disable 2-factor authentication'
        submitBtn={!hasSentVerification ? 'Send code' : 'Disable 2-Factor Auth'}
        danger={hasSentVerification}
        handleCancel={() => {
          hide();
          setTimeout(() => {
            resetVerification();
            resetForm({ values: initialValues });
          }, 1500);
        }}
        handleSubmit={handleSubmit}
        disabled={submitDisabled}
      >
        <RecaptchaRoot />
        <Padding padding='32px 32px 48px'>
          {/* Warning */}
          {!hasSentVerification && (
            <>
              <DeleteCannotBeUndone>
                Disabling 2-factor authentication will remove your phone number from this account.
                You will no longer need to enter a 6-digit code texted to you in order to log in.
                <br />
                <br />
                THIS WILL MAKE YOUR ACCOUNT LESS SECURE.
                <br />
                <br />
                Do you wish to continue?
              </DeleteCannotBeUndone>
              <Spacer height='16px' />
              <Divider />
              <Spacer height='32px' />
            </>
          )}

          {/* Misc error */}
          {!!genericError && (
            <>
              <InputError style={{ textAlign: 'center' }}>{genericError}</InputError>
              <Spacer height='16px' />
            </>
          )}

          {/* Current password */}
          {!hasSentVerification && (
            <>
              <FormLabel htmlFor='currentPassword'>Current password</FormLabel>
              <PasswordInput
                disabled={isSubmitting}
                {...getFieldProps({ name: 'currentPassword' })}
                autoComplete='current-password'
                error={!!submitCount && !!errors.currentPassword}
              />
              {!!submitCount && !!errors.currentPassword && (
                <InputError>{errors.currentPassword}</InputError>
              )}
            </>
          )}
          {!!hasSentVerification && (
            <>
              <div>
                {/* Check your text messages on device ending in {user?.multiFactorSms ?? 'your phone'} and{' '}
                <b>Enter the verification code below.</b> */}
                Check your phone ending in <b>{user?.multiFactorSms || '????'}</b> for a text code.
                Please enter it below.
              </div>
              <Spacer height='24px' />
              <FormLabel htmlFor='verificationCode'>Verification Code</FormLabel>
              <Input
                {...getFieldProps({ name: 'verificationCode' })}
                error={submitCount > 1 && !!errors.verificationCode}
              />
              {submitCount > 1 && !!errors.verificationCode && (
                <InputError>{errors.verificationCode}</InputError>
              )}
            </>
          )}
        </Padding>
      </FormModal>
    </>,
    document.getElementById('modal-portal')!
  );
}
