import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import User from '@/classes/User';

import { db, fbFunctions } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import { EARLY_BIRD_LOCAL_HOUR_OPTIONS } from '@/lib/config';

import { DateTime } from 'luxon';

import useAppState from '@/contexts/appState';
import useAuth from '@/contexts/auth';
import useUser from '@/contexts/user';

import useInterval from '@/hooks/useInterval';

import { Toggle } from '@/components/formElements/FormElements';
import ReactSelect from '@/components/formElements/styled/FormSelect';

import Accordion from '@/components/common/Accordion';
import PrimaryButton from '@/components/common/Buttons';
import Divider from '@/components/common/Divider';
import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Styles
const Notice = styled.div`
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 1.4;
`;
const FlexRow = styled.div<{ disabled?: boolean }>`
  display: flex;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
const ToggleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 16px; */
  label {
    margin-bottom: 8px;
  }

  color: ${({ theme }) => theme.textTertiary};
  font-weight: bold;
  font-size: 14px;
`;
const Title = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  font-size: 20px;
  font-weight: bold;
  color: ${({ active, theme }) => (active ? theme.textPrimary : theme.textTertiary)};
`;
const TitleIcon = styled(Icon)<{ active: boolean }>`
  margin-right: 8px;
  color: ${({ active, theme }) => (active ? theme.linkColor : theme.textFaded)};
`;
const Copy = styled.div`
  color: ${({ theme }) => theme.textFaded};
  line-height: 1.25;
`;

// New activity select options
const newActivityOptions: { label: string; value: User['preferences']['newActivityType'] }[] = [
  { label: 'Only people I follow', value: 'people' },
  { label: 'People and groups I follow', value: 'peopleAndGroups' },
  { label: 'All activity', value: 'all' },
];

// Early Bird Report time select options
const earlyBirdTimeOptions: { label: string; value: number }[] = EARLY_BIRD_LOCAL_HOUR_OPTIONS.map(
  hr => {
    return {
      label: DateTime.local().set({ hour: hr }).toLocaleString({ hour: 'numeric' }) as string,
      value: hr,
    };
  }
);

// Component
const NotificationsAccordion = ({ show }: { show: boolean }) => {
  // App state
  const { isSingleUser } = useAppState();
  // Context data
  const [auth, , refreshAuth] = useAuth();
  const [user] = useUser();
  const optionsDisabled = isSingleUser || !auth?.emailVerified;

  const [sentVerificationEmail, setSentVerificationEmail] = useState(false);
  // Make sure to poll verified status every 7 seconds if not verified
  // ( this is important for allowing changing of settings )
  useInterval(() => {
    refreshAuth();
  }, 7000);

  return (
    <Accordion show={show}>
      <Padding
        padding='24px 32px'
        mobilePadding='24px 16px'
        data-intercom-target='Notifications accordion'
      >
        {/* Early Bird Report */}
        <FlexRow disabled={!auth?.emailVerified}>
          {/* Email */}
          <ToggleBox>
            <Toggle
              checked={!!auth?.emailVerified && !!user?.preferences.earlyBirdReportEmails}
              onChange={e => {
                const isChecked = !!e.currentTarget.checked;
                user &&
                  db.doc(user.docPath).update({ 'preferences.earlyBirdReportEmails': isChecked });
              }}
            />
          </ToggleBox>
          <Spacer width='16px' />
          {/* Text + Selector */}
          <Expanded>
            <Title active={!!auth?.emailVerified && !!user?.preferences.earlyBirdReportEmails}>
              <TitleIcon
                active={!!auth?.emailVerified && !!user?.preferences.earlyBirdReportEmails}
                icon='markunread_mailbox'
                iconSize='22px'
              />{' '}
              Early Bird Report
            </Title>
            <Copy>Get a daily summary email of upcoming tasks and milestones</Copy>
            <Spacer height='16px' />
            <FlexRow>
              <Expanded>
                <ReactSelect
                  isDisabled={!auth?.emailVerified || !user?.preferences.earlyBirdReportEmails}
                  placeholder='Select time'
                  value={
                    user?.preferences.earlyBirdLocalHour !== undefined
                      ? {
                          label: DateTime.local()
                            .set({ hour: user.preferences.earlyBirdLocalHour })
                            .toLocaleString({ hour: 'numeric' }),
                          value: user.preferences.earlyBirdLocalHour,
                        }
                      : null
                  }
                  options={earlyBirdTimeOptions}
                  onChange={selection => {
                    if (selection) {
                      const localHour = selection.value;
                      const utcHour = DateTime.local().set({ hour: localHour }).toUTC().hour;
                      const timezone = DateTime.local().zoneName;
                      user &&
                        db.doc(user.docPath).update({
                          'preferences.earlyBirdLocalHour': localHour,
                          'preferences.earlyBirdUtcHour': utcHour,
                          'preferences.earlyBirdTimezone': timezone,
                        });
                    }
                  }}
                />
              </Expanded>
              <Expanded />
            </FlexRow>
          </Expanded>
        </FlexRow>
        {/* Separator */}
        <Spacer height='24px' />
        <Divider padding={0} />
        <Spacer height='24px' />

        {/* If not auth verified, disable notification selectors and give notice */}
        {!auth?.emailVerified &&
          (sentVerificationEmail ? (
            <Notice>
              Email sent! <strong>Check your inbox</strong> and click on the link from Notebird. If
              the email doesn&apos;t show up in a couple minutes, try looking in your spam or
              junk-mail folder.
            </Notice>
          ) : (
            <Notice>
              You need to <strong>verify your email address</strong> before receiving notifications.
              Check your inbox for a link from Notebird or{' '}
              <PrimaryButton
                outlined
                small
                onClick={event => {
                  event.preventDefault();
                  httpsCallable(fbFunctions, 'verificationEmail')();
                  setSentVerificationEmail(true);
                }}
              >
                resend verification email
              </PrimaryButton>
            </Notice>
          ))}
        {/* If no other users ( and user is verified ), disable notification selectors and give notice */}
        {/* This should never really show for anyone besides the account owner */}
        {isSingleUser && auth?.emailVerified && (
          <Notice>
            Receive notifications for <strong>activity in Notebird by others</strong>.{' '}
            <Link to='/users'>
              <strong>Invite a teammate</strong>
            </Link>{' '}
            to your account to start collaborating.
          </Notice>
        )}

        {/* New Activity */}
        <FlexRow disabled={optionsDisabled}>
          {/* Email */}
          <ToggleBox>
            <Toggle
              checked={!optionsDisabled && !!user?.preferences.newActivityEmails}
              onChange={e => {
                const isChecked = !!e.currentTarget.checked;
                user && db.doc(user.docPath).update({ 'preferences.newActivityEmails': isChecked });
              }}
            />
          </ToggleBox>
          {/* Mobile */}
          {/* <ToggleBox>
            <Toggle defaultChecked={true} />
            Mobile
          </ToggleBox> */}
          <Spacer width='16px' />
          {/* Text + Selector */}
          <Expanded>
            <Title active={!optionsDisabled && !!user?.preferences.newActivityEmails}>
              <TitleIcon
                active={!optionsDisabled && !!user?.preferences.newActivityEmails}
                icon='library_books'
                iconSize='22px'
              />{' '}
              New Activity
            </Title>
            <Copy>
              Get an email whenever another user creates an update, milestone, or task for:
            </Copy>
            {/* <Copy>Get notified whenever an update, milestone, or task is created for:</Copy> */}
            <Spacer height='16px' />
            <ReactSelect
              isDisabled={optionsDisabled || !user?.preferences.newActivityEmails}
              isSearchable={false}
              value={
                newActivityOptions.find(
                  ({ value }) => value === user?.preferences.newActivityType
                ) || newActivityOptions[1]
              }
              options={newActivityOptions}
              onChange={selection => {
                user &&
                  selection?.value &&
                  db.doc(user.docPath).update({ 'preferences.newActivityType': selection.value });
              }}
            />
          </Expanded>
        </FlexRow>
        {/* Separator */}
        <Spacer height='24px' />
        <Divider padding={0} />
        <Spacer height='24px' />

        {/* Task assignments */}
        <FlexRow disabled={optionsDisabled}>
          {/* Email */}
          <ToggleBox>
            <Toggle
              checked={!optionsDisabled && !!user?.preferences.taskAssignmentEmails}
              onChange={e => {
                const isChecked = !!e.currentTarget.checked;
                user &&
                  db.doc(user.docPath).update({ 'preferences.taskAssignmentEmails': isChecked });
              }}
            />
          </ToggleBox>
          {/* Mobile */}
          {/* <ToggleBox>
            <Toggle defaultChecked={true} />
            Mobile
          </ToggleBox> */}
          <Spacer width='16px' />
          {/* Text */}
          <Expanded>
            <Title active={!optionsDisabled && !!user?.preferences.taskAssignmentEmails}>
              <TitleIcon
                active={!optionsDisabled && !!user?.preferences.taskAssignmentEmails}
                icon='assignment_turned_in'
                iconSize='22px'
              />{' '}
              Task assignments
            </Title>
            <Copy>Get an email whenever another user assigns a task to you</Copy>
            {/* <Copy>Get notified whenever a new task is assigned to you</Copy> */}
          </Expanded>
        </FlexRow>
      </Padding>
    </Accordion>
  );
};
export default NotificationsAccordion;
