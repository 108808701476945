import {
  Fragment,
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Update from '@/classes/Update';

import { type FlattenedTimestamps, fbFunctions, restoreTimestamps } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import { DEFAULT_UPDATES_LIMIT, MAX_UPDATES_LIMIT } from '@/lib/config';
import { getFriendlyDate, pluralize } from '@/lib/helpers';
import getErrorMessage from '@/lib/helpers/getErrorMessage';

import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import { DateTime } from 'luxon';
import { SearchClient } from 'typesense';
import { type SearchParams, type SearchResponseHit } from 'typesense/lib/Typesense/Documents';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useCounts from '@/contexts/counts';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';
import { atom, useSetAtom } from 'jotai';

import useDebouncedState from '@/hooks/useDebouncedState';
import useEffectWithInterval from '@/hooks/useEffectWithInterval';

import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';

import { SearchInput } from '@/components/formElements/FormElements';

import PrimaryButton from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import FlexRow from '@/components/common/FlexRow';
import LinkButton from '@/components/common/LinkButton';
import Loader from '@/components/common/Loader';
import NoWrap from '@/components/common/NoWrap';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';
import StickyHeader from '@/components/common/StickyHeader';
import UpdateItem from '@/components/common/UpdateItem';

import NotebirdIcon from '@/components/svg/NotebirdIcon';

// Lazy load illustration
const NoRecentActivityIllustration = lazy(() => import('../../svg/NoRecentActivityIllustration'));

// Defaults
const MAX_SEARCH_RESULTS = 20;

// Styles
// TODO: share some of these styles with MilestonesSheet and beyond
const ScrollRef = styled.div`
  min-height: 100%;
`;
const EndOfList = styled.div`
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.textFaded};
`;

// Store facet counts for consumption in filter aside
export const updateTypeCountsAtom = atom<[string, number][]>([]);

// Component
const UpdatesSheet = () => {
  // When on mobile we need to know if updates sheet is active ( for scroll-y stuff below )
  const { sheet } = useParams();
  const showMilestones = sheet !== 'milestones';

  // App state
  const {
    locationId,
    groupId,
    groupName,
    hasSingleGroup,
    contentFilter,
    filterUpdatesBy,
    isSingleUser,
    typesenseUpdatesKey,
    updatesLimit,
    showArchivedPeople,
    showRestrictedUpdates,
    hasRestrictedAccess,
    followedPeopleIds,
  } = useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [organization] = useOrganization();
  const [counts] = useCounts();

  // Establish typesense search client
  const typesense = useMemo(() => {
    if (!typesenseUpdatesKey) return null;

    const nearestHost = import.meta.env.VITE_TYPESENSE_NEAREST_HOST;
    const hosts = import.meta.env.VITE_TYPESENSE_HOSTS.split(',');
    return new SearchClient({
      nearestNode: nearestHost
        ? {
            host: nearestHost,
            port: 443,
            protocol: 'https',
          }
        : undefined,
      nodes: hosts.map(host => ({
        host: host,
        port: 443,
        protocol: 'https',
      })),
      apiKey: typesenseUpdatesKey,
      connectionTimeoutSeconds: 5,
    });
  }, [typesenseUpdatesKey]);

  // Updates search stuffs
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue, debouncedSearch] = useDebouncedState({
    defaultValue: '',
    wait: 200,
  });
  const isSearching = !!debouncedSearch.trim().length;
  const [isSearchFetching, setIsSearchFetching] = useState(true);
  const setUpdateTypeCountsAtom = useSetAtom(updateTypeCountsAtom);

  // Where to save typesense results
  const [typesenseUpdates, setTypesenseUpdates] = useState<{
    total: number;
    hits: SearchResponseHit<FlattenedTimestamps<Update> & { id: string }>[];
    query: string;
  }>({ total: 0, hits: [], query: '' });

  // Typesense search ( with regularly scheduled refetching )
  useEffectWithInterval(
    triggerType => {
      (async () => {
        if (!typesense || !organization?.id) {
          setIsSearchFetching(true);
          setTypesenseUpdates({ total: 0, hits: [], query: '' });
          return;
        }

        // Main Search
        const trimmedSearch = debouncedSearch.trim();
        ['mount', 'deps'].includes(triggerType) && setIsSearchFetching(true);

        const sort_by = trimmedSearch
          ? // If searching, push archived to bottom
            ['_eval(isArchived:false):desc', 'meta.createdAt._seconds:desc']
          : // If not searching, just sort by date
            'meta.createdAt._seconds:desc';

        const per_page = trimmedSearch
          ? // If searching, just use max results config ( 20 )
            MAX_SEARCH_RESULTS
          : // If not searching, this paginates
            updatesLimit;

        // FILTERS depend on a number of factors
        // Show archived if that toggle is on
        const archivedFilter = !showArchivedPeople && 'isArchived:=false';
        // Restricted updates if that toggle is on
        const restrictedFilter = !showRestrictedUpdates && 'visibility:=groups';
        // Group filter if group is selected
        const groupFilter = groupId && `groups:=[${groupId}]`;
        // Following filter if following is selected
        const followingFilter =
          !groupId &&
          contentFilter === 'following' &&
          `person.id:=[${followedPeopleIds.join(',')}]`;
        // Type filter if type is selected in dropdown
        const typeFilter = filterUpdatesBy && `type:=${filterUpdatesBy}`;

        const searchParams: SearchParams = {
          q: trimmedSearch,
          query_by: [
            // Higher weights
            'notes',
            'person.profile.name.full',
            'meta.createdBy.profile.name.full',
            // Lower weights
            'type',
            'place.mainText',
            'place.secondaryText',
          ],
          sort_by,
          // Only filter if we're not searching
          filter_by: trimmedSearch
            ? ''
            : [archivedFilter, restrictedFilter, groupFilter, followingFilter, typeFilter]
                .filter(Boolean)
                .join(' && '),
          // filter_by: [...archivedFilter, ...restrictedFilter].join(' && '),
          per_page,
          highlight_start_tag: '<em>',
          highlight_end_tag: '</em>',
          snippet_threshold: 9,
        };
        const results = await typesense
          .collections<Update>(`${organization.id}_updates`)
          .documents()
          .search(searchParams, {});
        // console.log('results', results);
        const hits =
          (results.hits as SearchResponseHit<FlattenedTimestamps<Update> & { id: string }>[]) ?? [];
        setTypesenseUpdates({
          total: results.found,
          hits:
            hits.map(hit => {
              const highlightNotes = hit.highlight?.notes?.snippet ?? hit.document.notes;
              return { ...hit, document: { ...hit.document, notes: highlightNotes } };
            }) ?? [],
          query: trimmedSearch,
        });
        setIsSearchFetching(false);

        // Get and update facets to tally aside items ( but don't need to refresh on every search)
        if (trimmedSearch) return;

        // Update Type Facets - tallied AFTER other filter considerations like
        // archived, group, visibility ( but not including type filter )
        const typeFacetResults = await typesense
          .collections<Update>(`${organization.id}_updates`)
          .documents()
          .search(
            {
              q: '*',
              query_by: '',
              facet_by: 'type',
              filter_by: [archivedFilter, restrictedFilter, groupFilter, followingFilter]
                .filter(Boolean)
                .join(' && '),
              exclude_fields: '*',
              per_page: 0,
            },
            {}
          );
        const allTypes = typeFacetResults.found;
        const types = (typeFacetResults.facet_counts?.[0]?.counts ?? []).reduce(
          (acc, { value, count }) =>
            !value ? acc : [...acc, [value, count ?? 0] as [string, number]],
          [] as [string, number][]
        );
        const typeCounts: [string, number][] = [['', allTypes], ...types];
        setUpdateTypeCountsAtom(typeCounts);
      })();
    },
    [
      typesense,
      debouncedSearch,
      organization?.id,
      updatesLimit,
      showArchivedPeople,
      showRestrictedUpdates,
      hasRestrictedAccess,
      groupId,
      contentFilter,
      followedPeopleIds,
      filterUpdatesBy,
      setUpdateTypeCountsAtom,
    ],
    { disableRetries: !!debouncedSearch.trim() }
  );

  // Group updates by day
  const dayGroupedUpdates = useMemo(() => {
    const flattenedHits = typesenseUpdates.hits.map(hit => restoreTimestamps(hit.document));
    const groups = groupBy(flattenedHits, update =>
      update.meta.createdAt
        ? DateTime.fromMillis(update.meta.createdAt.toMillis()).startOf('day').toMillis()
        : DateTime.local().startOf('day').toMillis()
    );
    return orderBy(toPairs(groups), ([millis]) => millis, 'desc');
  }, [typesenseUpdates.hits]);

  // Reset search ( and limit ) every time locationgroup, filter, or sort changes
  useEffect(() => {
    setSearchValue('');
    setAppState({ updatesLimit: DEFAULT_UPDATES_LIMIT });
  }, [groupId, contentFilter, filterUpdatesBy, setAppState, setSearchValue]);

  // Set scroll to past mobile search on mount AND
  // any time locationId, groupId, contentFilter, filterUpdatesBy, showArchived changes
  const scrollableRef = useRef<HTMLDivElement>(null);
  const scrollToRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (scrollableRef?.current && scrollToRef?.current) {
      scrollableRef.current.scrollTop = scrollToRef.current.offsetTop;
    }
  }, [
    locationId,
    groupId,
    contentFilter,
    filterUpdatesBy,
    showMilestones, // This is also important so switching to this tab on mobile triggers reset
  ]);

  return (
    <>
      {/* Header w/ search ( desktop only ) */}
      <SheetHeader
        primary
        leadingIcon='library_books'
        mainTitle='Updates'
        // Header fades if has no past updates
        active={!!typesenseUpdates.total}
        className='hidden-mobile'
      >
        {/* Search updates input if 3 or more total ( desktop ) */}
        {counts && counts.updates >= 3 && (
          <SearchInput
            name='updatesSearch'
            placeholder='Search all updates'
            value={searchValue}
            forwardedRef={searchRef}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setSearchValue(event.target.value)
            }
            handleClear={() => setSearchValue('')}
          />
        )}
      </SheetHeader>

      {/* Loader */}
      <Loader show={isSearchFetching} />

      {/* Main scrollable area */}
      <Scrollable forwardedRef={scrollableRef} endBuffer='0'>
        {/* Search updates input if 3 or more total ( mobile ) */}
        {counts && counts.updates >= 3 && (
          <Padding padding='4px 16px' className='shown-mobile'>
            <SearchInput
              name='updatesSearch-mobile'
              placeholder='Search all updates'
              value={searchValue}
              forwardedRef={searchRef}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(event.target.value)
              }
              handleClear={() => setSearchValue('')}
            />
          </Padding>
        )}

        {/* Div wrapper to scroll to onMount ( and other list changes ) */}
        {/* also at a height to, at minimum, obscure mobile search */}
        <ScrollRef ref={scrollToRef}>
          {/* Search results list ( if searching ) */}
          {isSearching && (
            <>
              {/* None found */}
              {!typesenseUpdates.total && (
                <Padding padding='48px 32px' className='fade-in'>
                  <NoneFoundHeader>
                    No search results found for <strong>{searchValue}</strong>
                  </NoneFoundHeader>
                  <NoneFoundCopy>
                    <LinkButton onClick={() => setSearchValue('')}>
                      <strong>Clear search</strong>
                    </LinkButton>
                  </NoneFoundCopy>
                </Padding>
              )}
              {/* Loop over results ( if has any ) and print */}
              {!!typesenseUpdates.total && (
                <>
                  <Spacer height='16px' />
                  {typesenseUpdates.hits.map(typesenseUpdate => (
                    <UpdateItem
                      key={typesenseUpdate.document.id}
                      update={restoreTimestamps(typesenseUpdate.document)}
                      showDate
                    />
                  ))}
                  <Spacer height='64px' />
                  <NotebirdIcon width='100%' height='24px' dull />
                  <Spacer height='4px' />
                  <EndOfList>
                    <strong>{typesenseUpdates.hits.length}</strong>{' '}
                    {pluralize({ root: 'result', count: typesenseUpdates.hits.length })} for &apos;
                    <strong>{typesenseUpdates.query}</strong>&apos;
                  </EndOfList>
                </>
              )}
            </>
          )}

          {/* Not searching */}
          {!isSearching && (
            <>
              {/* Has updates */}
              {!!dayGroupedUpdates.length && (
                <div>
                  {/* Map over updates */}
                  {dayGroupedUpdates.map(([millis, updates]) => (
                    <Fragment key={millis}>
                      {/* Date header */}
                      <StickyHeader heading={getFriendlyDate(parseInt(millis))} />
                      {/* Each update on date */}
                      {!!updates &&
                        updates.map(update => (
                          <UpdateItem
                            key={update.id}
                            update={update}
                            hideGroups={hasSingleGroup || !!groupId}
                            hideUser={isSingleUser}
                          />
                        ))}
                    </Fragment>
                  ))}
                  {/* End of list */}
                  <Spacer height='48px' />
                  {/* Show more button ( increments by 20, up to 200 total ) */}
                  {typesenseUpdates.hits.length < typesenseUpdates.total &&
                    updatesLimit < MAX_UPDATES_LIMIT && (
                      <FlexRow justify='center'>
                        <PrimaryButton
                          outlined
                          small
                          onClick={() =>
                            setAppState({ updatesLimit: updatesLimit + DEFAULT_UPDATES_LIMIT })
                          }
                        >
                          <strong>Show more</strong>
                        </PrimaryButton>
                      </FlexRow>
                    )}
                  {/* If ever reaches 200, just point to reports page */}
                  {updatesLimit >= MAX_UPDATES_LIMIT && (
                    <EndOfList>
                      You&apos;ve reached the {MAX_UPDATES_LIMIT} limit.
                      <br />
                      Please run an <Link to='/reports/updates'>updates report</Link> to see more.
                    </EndOfList>
                  )}
                </div>
              )}

              {/* Empty notice for no activity for this group */}
              {!isSearchFetching &&
                (!!groupId || contentFilter === 'following') &&
                !typesenseUpdates.total && (
                  <Padding padding='48px 32px' className='fade-in' key={groupName || undefined}>
                    <ErrorSuspendPlaceholder width='100%' height='164px'>
                      <NoRecentActivityIllustration
                        width='100%'
                        height='164px'
                        className='fade-in'
                      />
                    </ErrorSuspendPlaceholder>
                    <Spacer height='16px' />
                    <NoneFoundHeader>
                      No updates{' '}
                      <NoWrap>
                        for{' '}
                        {groupName ||
                          (contentFilter === 'following' && 'followed people') ||
                          'this group'}
                      </NoWrap>
                    </NoneFoundHeader>
                    <NoneFoundCopy>
                      Back to{' '}
                      <LinkButton
                        onClick={() => setAppState({ contentFilter: 'all', groupId: null })}
                      >
                        <strong>all people</strong>
                      </LinkButton>
                    </NoneFoundCopy>
                  </Padding>
                )}

              {/* Empty notice for no activity at all */}
              {!isSearchFetching &&
                !groupId &&
                contentFilter === 'all' &&
                !typesenseUpdates.total && (
                  <Padding padding='48px 32px' className='fade-in'>
                    <Suspense fallback={<div style={{ width: '100%', height: '164px' }} />}>
                      <NoRecentActivityIllustration
                        width='100%'
                        height='164px'
                        className='fade-in'
                      />
                    </Suspense>
                    <Spacer height='16px' />
                    <NoneFoundHeader>
                      Nothing to <NoWrap>see here...</NoWrap>
                    </NoneFoundHeader>
                    <NoneFoundCopy>
                      Create an update within a person&apos;s profile and it will show here in this
                      Updates feed.
                    </NoneFoundCopy>
                  </Padding>
                )}
            </>
          )}

          {/* Special button to reset update timestamps (available only on staging) */}
          {import.meta.env.MODE !== 'production' && debouncedSearch === 'reset' && (
            <ResetUpdatesButton />
          )}

          {/* Scoll buffer needs to be INSIDE this div for all that scrollTo on mount stuff */}
          <Spacer height='32px' />
        </ScrollRef>
      </Scrollable>
    </>
  );
};
export default UpdatesSheet;

// Button shown on staging site when searching for 'reset'
// to reset update timestamps to look more recent
const ResetUpdatesButton = () => {
  const { organizationId } = useAppState();
  const [isLoading, setIsLoading] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);
  const [user] = useUser();

  return user?.profile.email.includes('@notebird.app') ||
    user?.profile.email.includes('@notebird.dev') ? (
    <Padding padding='24px 16px' style={{ textAlign: 'center' }}>
      {!resetComplete && (
        <>
          <PrimaryButton
            outlined
            disabled={isLoading}
            onClick={async () => {
              setIsLoading(true);
              try {
                await httpsCallable(fbFunctions, 'resetUpdateTimestamps')({ organizationId });
                setResetComplete(true);
              } catch (error) {
                alert('Error resetting timestamps:\n' + getErrorMessage(error));
                console.error(error);
                setIsLoading(false);
              }
            }}
          >
            Reset update timestamps
          </PrimaryButton>
          <Loader show={isLoading} />
        </>
      )}
      {resetComplete && (
        // eslint-disable-next-line jsx-a11y/accessible-emoji
        <h3>🙌 Updates reset! 🙌</h3>
      )}
    </Padding>
  ) : null;
};
