import { lazy, useCallback, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Organization from '@/classes/Organization';

import firebase, { db } from '@/lib/firebase';

import { MAX_MILESTONE_TYPES, MAX_UPDATE_TYPES } from '@/lib/config';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';
import isFuzzyTextMatch from '@/lib/helpers/isFuzzyTextMatch';

import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';

import useAppState from '@/contexts/appState';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import usePageView from '@/hooks/usePageView';

import MobilePageHeader from '@/components/layout/MobilePageHeader';
import SheetHeader from '@/components/layout/SheetHeader';
import Sheet, { SheetScrollable, SheetsWrapper } from '@/components/layout/Sheets';

import CustomTimeRangesAccordion from './CustomTimeRangesAccordion';

import DynamicInputRow from '@/components/formElements/DynamicInputRow';

import Accordion from '@/components/common/Accordion';
import { SecondaryButton } from '@/components/common/Buttons';
import DescriptionBlock from '@/components/common/DescriptionBlock';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Margin from '@/components/common/Margin';
import {
  DeleteCannotBeUndone,
  DeleteExtra,
  DeleteModal,
  DeleteWarning,
} from '@/components/common/Modals';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Lazy load illustration
const CustomizationsIllustration = lazy(() => import('../../svg/CustomizationsIllustration'));

// Styles
const FlexWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  div.header {
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.textSecondary};
    margin-bottom: 8px;
  }
  div.copy {
    color: ${props => props.theme.textFaded};
    line-height: 1.25;
  }
`;

// Component
const CustomizationsPage = () => {
  // Router navigator and params
  const navigate = useNavigate();
  const { accordion } = useParams();

  // Register page view
  usePageView({
    title: `Customizations${accordion ? ` - ${capitalize(accordion)}` : ''} | Notebird`,
  });

  // App state
  const { isActive, isOrganizationAdmin } = useAppState();
  // Context data
  const [user] = useUser();
  const [organization] = useOrganization();
  const {
    preferences: { updateTypes, milestoneTypes },
  } = organization || { ...new Organization() };

  // New updateType stuff
  const [showNewUpdateType, setShowNewUpdateType] = useState<string | boolean>(false);
  // Delete updateType stuff
  const [deleteUpdateType, setDeleteUpdateType] = useState<string | boolean>(false);

  // New milestoneType stuff
  const [showNewMilestoneType, setShowNewMilestoneType] = useState<string | boolean>(false);
  // Delete milestoneType stuff
  const [deleteMilestoneType, setDeleteMilestoneType] = useState<string | boolean>(false);

  const milestoneNameError = useCallback(
    (value: string, ignoreValue = '') => {
      // Can't name as any one of default milestones
      if (
        ['birthday', 'anniversary', 'joindate'].includes(value.split(' ').join('').toLowerCase())
      ) {
        return `${value.trim()} is a reserved milestone type`;
      }
      // Can't name a milestoneType the same as another
      return milestoneTypes.find(
        otherMilestoneType =>
          !isFuzzyTextMatch(otherMilestoneType, ignoreValue) &&
          isFuzzyTextMatch(otherMilestoneType, value)
      )
        ? 'This milestone type already exists'
        : false;
    },
    [milestoneTypes]
  );

  // If not organization admin, just get outta here!
  if (!isOrganizationAdmin) return <Navigate to='/people' replace />;

  return (
    <SheetsWrapper>
      {/* Header for mobile */}
      <MobilePageHeader hideGroupsAsideToggle>Customizations</MobilePageHeader>

      {/* Left - illustration/copy */}
      <Sheet position='left' className='hidden-mobile'>
        <FlexWrapperCentered>
          <Margin margin='-64px 0 0' />
          <ErrorSuspendPlaceholder>
            <CustomizationsIllustration width='232px' height='232px' className='fade-in' />
          </ErrorSuspendPlaceholder>
          <Spacer height='48px' />
          <div className='header'>Customize Notebird to fit your organization</div>
          <div className='copy'>Adjust these settings to fine-tune your account for all users</div>
        </FlexWrapperCentered>
      </Sheet>

      {/* Right - settings/accordions*/}
      <Sheet position='right'>
        <SheetScrollable>
          {/* Custom time ranges */}
          <SheetHeader
            expandable
            active={accordion === 'time-ranges'}
            sticky
            leadingIcon='watch_later'
            mainTitle='Custom time ranges'
            subtitle='Pulse and join date thresholds'
            onClick={() =>
              navigate(
                accordion === 'time-ranges' ? '/customizations' : '/customizations/time-ranges',
                { replace: true }
              )
            }
          />
          <CustomTimeRangesAccordion show={accordion === 'time-ranges'} />

          {/* Update types */}
          <SheetHeader
            expandable
            active={accordion === 'update-types'}
            sticky
            stickyTop='64px'
            leadingIcon='forum'
            mainTitle='Update types'
            subtitle='How your organization interacts with people'
            onClick={() =>
              navigate(
                accordion === 'update-types' ? '/customizations' : '/customizations/update-types',
                { replace: true }
              )
            }
          />
          <Accordion show={accordion === 'update-types'}>
            <DescriptionBlock>
              Think of update types as the topic or theme of an update.{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://guide.notebird.app/articles/3584779'
              >
                Learn more
              </a>
            </DescriptionBlock>

            <Padding padding='16px 32px 32px'>
              {sortBy(updateTypes).map(updateType => (
                <DynamicInputRow
                  key={'updateType-' + updateType}
                  name={'updateType-' + updateType}
                  required
                  maxLength={50}
                  error={value => {
                    // Can't name a update type the same as another
                    const matches = updateTypes.filter(
                      otherUpdateType =>
                        otherUpdateType !== updateType &&
                        otherUpdateType.trim().toLowerCase() === value.trim().toLowerCase()
                    );
                    return matches.length > 0 ? 'This milestoneType already exists' : false;
                  }}
                  value={updateType}
                  onSubmit={value => {
                    const oldType = updateType.trim();
                    const newType = value.trim();
                    if (newType && newType !== oldType) {
                      // Transform current updateTypes
                      db.runTransaction(async tx => {
                        if (user && organization) {
                          const orgDoc = await tx.get(db.doc(organization.docPath));
                          const oldTypes = orgDoc.get('preferences.updateTypes') || [];
                          const newTypes = oldTypes.map((type: string) =>
                            type.trim() === oldType ? newType : type
                          );
                          tx.update(orgDoc.ref, {
                            ...generateUpdatedByDottedMeta(user),
                            'preferences.updateTypes': newTypes,
                          });
                        }
                      });
                    }
                  }}
                  onDelete={
                    updateTypes.length > 1
                      ? () => {
                          setDeleteUpdateType(updateType);
                        }
                      : undefined
                  }
                />
              ))}
              <Spacer height='8px' />
              {/* Add update type - form */}
              {showNewUpdateType && (
                <DynamicInputRow
                  name='newUpdateType'
                  placeholder='New update type'
                  required
                  maxLength={50}
                  editOnMount
                  error={value => {
                    // Can't name a update type the same as another
                    return updateTypes.find(updateType => isFuzzyTextMatch(updateType, value))
                      ? 'This update type already exists'
                      : false;
                  }}
                  value=''
                  onCancel={() => setShowNewUpdateType(false)}
                  onSubmit={value => {
                    // Close form
                    setShowNewUpdateType(false);
                    // Add to DB
                    user &&
                      organization &&
                      db.doc(organization.docPath).update({
                        ...generateUpdatedByDottedMeta(user),
                        'preferences.updateTypes': firebase.firestore.FieldValue.arrayUnion(
                          value.trim()
                        ),
                      });
                  }}
                />
              )}
              {/* Add update type button */}
              {!showNewUpdateType && (
                <SecondaryButton
                  leadingIcon='add_circle'
                  disabled={!isActive || updateTypes.length >= MAX_UPDATE_TYPES}
                  onClick={() => setShowNewUpdateType(true)}
                >
                  Add <strong>update type</strong>
                </SecondaryButton>
              )}
            </Padding>
          </Accordion>

          {/* Milestone types */}
          <SheetHeader
            expandable
            active={accordion === 'milestone-types'}
            sticky
            stickyTop='128px'
            leadingIcon='flag'
            mainTitle='Milestone types'
            subtitle="Important events in peoples' lives"
            onClick={() =>
              navigate(
                accordion === 'milestone-types'
                  ? '/customizations'
                  : '/customizations/milestone-types',
                { replace: true }
              )
            }
          />
          <Accordion show={accordion === 'milestone-types'}>
            <DescriptionBlock>
              Customize the type of milestones that are most important to your organization.{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://guide.notebird.app/articles/3584780'
              >
                Learn more
              </a>
            </DescriptionBlock>

            <Padding padding='16px 32px 32px'>
              {sortBy(milestoneTypes).map(milestoneType => (
                <DynamicInputRow
                  key={'milestoneType-' + milestoneType}
                  name={'milestoneType-' + milestoneType}
                  required
                  maxLength={50}
                  error={value => milestoneNameError(value, milestoneType)}
                  value={milestoneType}
                  locked={milestoneType === 'Yahrzeit' || milestoneType === 'Bar/Bat Mitzvah'}
                  onSubmit={value => {
                    const oldMilestoneType = milestoneType.trim();
                    const newMilestoneType = value.trim();
                    if (newMilestoneType && newMilestoneType !== oldMilestoneType) {
                      // Transform current milestoneTypes
                      db.runTransaction(async tx => {
                        if (user && organization) {
                          const orgDoc = await tx.get(db.doc(organization.docPath));
                          const oldMilestoneTypes = orgDoc.get('preferences.milestoneTypes') || [];
                          const newMilestoneTypes = oldMilestoneTypes.map(
                            (milestoneType: string) =>
                              milestoneType.trim() === oldMilestoneType
                                ? newMilestoneType
                                : milestoneType
                          );
                          tx.update(orgDoc.ref, {
                            ...generateUpdatedByDottedMeta(user),
                            'preferences.milestoneTypes': newMilestoneTypes,
                          });
                        }
                      });
                    }
                  }}
                  onDelete={
                    milestoneTypes.length > 1
                      ? () => {
                          setDeleteMilestoneType(milestoneType);
                        }
                      : undefined
                  }
                />
              ))}
              <Spacer height='8px' />
              {/* Add milestoneType - form */}
              {showNewMilestoneType && (
                <DynamicInputRow
                  name='newMilestoneType'
                  placeholder='New milestone type'
                  required
                  maxLength={50}
                  editOnMount
                  error={milestoneNameError}
                  value=''
                  onCancel={() => setShowNewMilestoneType(false)}
                  onSubmit={value => {
                    // Close form
                    setShowNewMilestoneType(false);
                    // Add to DB
                    user &&
                      organization &&
                      db.doc(organization.docPath).update({
                        ...generateUpdatedByDottedMeta(user),
                        'preferences.milestoneTypes': firebase.firestore.FieldValue.arrayUnion(
                          value.trim()
                        ),
                      });
                  }}
                />
              )}
              {/* Add milestoneType button */}
              {!showNewMilestoneType && (
                <SecondaryButton
                  leadingIcon='add_circle'
                  disabled={!isActive || milestoneTypes.length >= MAX_MILESTONE_TYPES}
                  onClick={() => setShowNewMilestoneType(true)}
                >
                  Add <strong>milestone type</strong>
                </SecondaryButton>
              )}
            </Padding>
          </Accordion>

          <Spacer height='48px' />
        </SheetScrollable>
      </Sheet>

      {/* MODALS */}
      {/* Delete update type modal */}
      <DeleteModal
        name='update type'
        show={!!deleteUpdateType}
        handleCancel={() => setDeleteUpdateType(false)}
        handleDelete={() => {
          user &&
            organization &&
            db.doc(organization.docPath).update({
              ...generateUpdatedByDottedMeta(user),
              'preferences.updateTypes':
                firebase.firestore.FieldValue.arrayRemove(deleteUpdateType),
            });
        }}
      >
        <DeleteWarning>
          <strong>{deleteUpdateType}</strong> will be permanently removed from all updates and no
          longer show in reports!
        </DeleteWarning>
        <DeleteCannotBeUndone />
        <DeleteExtra>No updates will be deleted</DeleteExtra>
      </DeleteModal>

      {/* Delete milestoneType modal */}
      <DeleteModal
        name='milestone type'
        show={!!deleteMilestoneType}
        handleCancel={() => setDeleteMilestoneType(false)}
        handleDelete={() => {
          user &&
            organization &&
            db.doc(organization.docPath).update({
              ...generateUpdatedByDottedMeta(user),
              'preferences.milestoneTypes':
                firebase.firestore.FieldValue.arrayRemove(deleteMilestoneType),
            });
        }}
      >
        <DeleteWarning>
          All milestones with the type &quot;<strong>{deleteMilestoneType}</strong>&quot; will also
          be permanently deleted!
        </DeleteWarning>
        <DeleteCannotBeUndone />
      </DeleteModal>
    </SheetsWrapper>
  );
};
export default CustomizationsPage;
