import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Doc, { WithId } from '@/classes/Doc';
import Milestone from '@/classes/Milestone';
import Person from '@/classes/Person';
import Relationship from '@/classes/Relationship';
import Task from '@/classes/Task';
import Update from '@/classes/Update';

import firebase, { db } from '@/lib/firebase';

import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import orderBy from 'lodash/orderBy';
import { usePostHog } from 'posthog-js/react';

import useAppState from '@/contexts/appState';
import { usePeopleCache } from '@/contexts/people';
import useUser from '@/contexts/user';

import useBaseQuery from '@/hooks/useBaseQuery';
import useCollection from '@/hooks/useCollection';
import useDoc from '@/hooks/useDoc';
import usePageView from '@/hooks/usePageView';

import AnimatedFloatingSheet from '@/components/layout/AnimatedFloatingSheet';
import Scrollable from '@/components/layout/Scrollable';
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';

import ActivitySection from './ActivitySection';
import ChangeLocationPersonInfoSheet from './ChangeLocationPersonInfoSheet';
import DeletePersonSegment from './DeletePersonSegment';
import PersonInfoSheet from './PersonInfoSheet';
import PersonNotFoundSheets from './PersonNotFoundSheets';
import TasksSection from './TasksSection';

import MilestoneForm from '@/components/forms/MilestoneForm';
import PersonForm from '@/components/forms/PersonForm';
import RelationshipForm from '@/components/forms/RelationshipForm';
import TaskForm from '@/components/forms/TaskForm';
import UpdateForm from '@/components/forms/UpdateForm';

import Loader from '@/components/common/Loader';
import Modal from '@/components/common/Modal';
import { DeleteModal } from '@/components/common/Modals';
import Spacer from '@/components/common/Spacer';

// Component
const PersonPage = () => {
  const { id, sheet } = useParams();
  const posthog = usePostHog();

  // When on mobile we need to know which sheet is active
  const showActivity = sheet !== 'profile';
  // Go Back
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    history.length > 2 ? navigate(-1) : navigate('/people');
  }, [navigate]);

  // App state
  const { isActive, organizationId, locationId, hasRestrictedAccess } = useAppState();
  // Context data
  const [user] = useUser();
  const [peopleCache, setPeopleCache] = usePeopleCache();
  const personCache = id ? peopleCache[id] : undefined;

  // Fetch person doc
  const [personStream, isFetching] = useDoc<Person>(
    !!organizationId && id && `organizations/${organizationId}/people/${id}`
  );
  // Automatically update people cache with this person
  useEffect(() => {
    id && setPeopleCache(prev => ({ ...prev, [id]: personStream || undefined }));
  }, [id, personStream, setPeopleCache]);
  // We use the person stream if available
  // or the person doc in cache if present
  const person = personStream ?? personCache;

  // Register page view
  usePageView({
    title: person
      ? `${person.profile.name.full} | Notebird`
      : !isFetching
        ? 'Person Not Found | Notebird'
        : undefined,
    analyticsTitle: person
      ? 'Person Profile | Notebird'
      : !isFetching
        ? 'Person Not Found | Notebird'
        : undefined,
  });

  // TODO: go ahead and just change locations if this person is in another one?
  // Person is within the current location
  const personInCurrentLocation = useMemo(
    () => !!person && person.locationId === locationId,
    [locationId, person]
  );

  // Person edit, delete
  //////////////////////
  const [editPersonDoc, setEditPersonDoc] = useState<Doc<Person>>();
  const [showDeletePersonModal, setShowDeletePersonModal] = useState(false);

  // Milestone - create, edit, delete
  ///////////////////////////////////
  const [showNewMilestoneForm, setShowNewMilestoneForm] = useState(false);
  const [editMilestoneDoc, setEditMilestoneDoc] = useState<Doc<Milestone>>();
  const [deleteMilestoneDoc, setDeleteMilestoneDoc] = useState<Doc<Milestone>>();
  // Delete milestone handler
  const handleDeleteMilestone = useCallback(async () => {
    if (organizationId && deleteMilestoneDoc) {
      db.doc(deleteMilestoneDoc.docPath).delete();
      posthog?.capture('milestone_deleted', {
        type: deleteMilestoneDoc.type,
        show_annually: deleteMilestoneDoc.showAnnually,
        description_length: deleteMilestoneDoc.description.length,
      });
    }
    // Close delete modal
    setEditMilestoneDoc(undefined);
  }, [organizationId, deleteMilestoneDoc, posthog]);

  // Relationships - create, edit, delete
  ///////////////////////////////////////
  const [showNewRelationshipForm, setShowNewRelationshipForm] = useState(false);
  const [editRelationshipDoc, setEditRelationshipDoc] = useState<Relationship>();
  const [deleteRelationshipDoc, setDeleteRelationshipDoc] = useState<Relationship>();
  // Delete relationships handler
  const handleDeleteRelationship = useCallback(async () => {
    if (user && person && organizationId && deleteRelationshipDoc) {
      const batch = db.batch();
      const { arrayRemove } = firebase.firestore.FieldValue;
      batch.update(db.doc(person.docPath), {
        ...generateUpdatedByDottedMeta(user),
        relationships: arrayRemove(deleteRelationshipDoc),
      });
      // If relationship is linked, also remove there
      if (deleteRelationshipDoc.id) {
        const linkedPersonDoc = await db
          .doc(`organizations/${organizationId}/people/${deleteRelationshipDoc.id}`)
          .get();
        const linkedPersonRelationships = linkedPersonDoc.get('relationships') || [];
        const linkedPersonRelationship = linkedPersonRelationships.find(
          ({ id }: { id: string | null }) => id === person.id
        );
        linkedPersonRelationship &&
          batch.update(linkedPersonDoc.ref, {
            ...generateUpdatedByDottedMeta(user),
            relationships: arrayRemove(linkedPersonRelationship),
          });
      }
      batch.commit();
      posthog?.capture('relationship_deleted', {
        type: deleteRelationshipDoc.type,
        is_linked: !!deleteRelationshipDoc.id,
      });
    }
    // Close delete modal
    setDeleteRelationshipDoc(undefined);
  }, [user, person, organizationId, deleteRelationshipDoc, posthog]);

  // Task - create, edit, delete
  //////////////////////////////
  const [showNewTaskForm, setShowNewTaskForm] = useState(false);
  const [editTaskDoc, setEditTaskDoc] = useState<Doc<Task>>();
  const [deleteTaskDoc, setDeleteTaskDoc] = useState<Doc<Task>>();
  // Delete task handler
  const handleDeleteTask = useCallback(async () => {
    if (organizationId && deleteTaskDoc) {
      db.doc(`organizations/${organizationId}/tasks/${deleteTaskDoc.id}`).delete();
      posthog?.capture('task_deleted', {
        is_assigned: !!deleteTaskDoc.assignedTo,
        has_due_date: !!deleteTaskDoc.dueDate,
        notes_length: deleteTaskDoc.notes.length,
        groups_count: deleteTaskDoc.groups.length,
      });
    }
    // Close delete modal
    setDeleteTaskDoc(undefined);
  }, [organizationId, deleteTaskDoc, posthog]);

  // Update - create, edit, delete
  ////////////////////////////////
  const [showNewUpdateForm, setShowNewUpdateForm] = useState(false);
  const [editUpdateDoc, setEditUpdateDoc] = useState<WithId<Update>>();
  const [deleteUpdateDoc, setDeleteUpdateDoc] = useState<WithId<Update>>();
  // Delete update handler
  const handleDeleteUpdate = useCallback(async () => {
    if (organizationId && deleteUpdateDoc) {
      db.doc(`organizations/${organizationId}/updates/${deleteUpdateDoc.id}`).delete();
      posthog?.capture('update_deleted', {
        type: deleteUpdateDoc.type,
        notes_length: deleteUpdateDoc.notes.length,
        groups_count: deleteUpdateDoc.groups.length,
        has_place: !!deleteUpdateDoc.place,
        is_restricted: deleteUpdateDoc.visibility === 'restricted',
      });
    }
    // Close delete modal
    setDeleteUpdateDoc(undefined);
  }, [organizationId, deleteUpdateDoc, posthog]);

  // Clear all modals on person ID change
  // ( when navigating from one person to another — usually when using relationship link )
  useEffect(() => {
    setEditPersonDoc(undefined);
    setShowDeletePersonModal(false);

    setShowNewMilestoneForm(false);
    setEditMilestoneDoc(undefined);
    setDeleteMilestoneDoc(undefined);

    setShowNewRelationshipForm(false);
    setEditRelationshipDoc(undefined);
    setDeleteRelationshipDoc(undefined);

    setShowNewTaskForm(false);
    setEditTaskDoc(undefined);
    setDeleteTaskDoc(undefined);

    setShowNewUpdateForm(false);
    setEditUpdateDoc(undefined);
    setDeleteUpdateDoc(undefined);
  }, [id]);

  // Autofocus 'add update' button if no forms are showing
  // const focusAddUpdate = !!(
  //   showNewMilestoneForm ||
  //   !!editMilestoneDoc ||
  //   !!deleteMilestoneDoc ||
  //   showNewRelationshipForm ||
  //   !!editRelationshipDoc ||
  //   !!deleteRelationshipDoc ||
  //   showNewTaskForm ||
  //   !!editTaskDoc ||
  //   !!deleteTaskDoc ||
  //   showNewUpdateForm ||
  //   !!editUpdateDoc ||
  //   !!deleteUpdateDoc
  // );

  // Person's tasks
  const tasksQuery = useBaseQuery({
    collection: 'tasks',
    showArchived: true,
  })?.where('person.id', '==', id);
  const [tasks, tasksAreFetching] = useCollection<Task>(tasksQuery);
  const sortedTasks = useMemo(() => (!tasks ? null : orderBy(tasks, 'notes')), [tasks]);
  // Person's updates
  const updatesQuery = useBaseQuery({
    collection: 'updates',
    showArchived: true,
    hideRestricted: !hasRestrictedAccess,
  })?.where('person.id', '==', id);
  const [updates, updatesAreFetching] = useCollection<Update>(updatesQuery);
  // Person's milestones
  const milestonesQuery = useBaseQuery({
    collection: 'milestones',
    excludeMemberGroups: true,
    showArchived: true,
  })?.where('person.id', '==', id);
  const [milestones, milestonesAreFetching] = useCollection<Milestone>(milestonesQuery);
  const sortedMilestones = useMemo<Doc<Milestone>[] | null>(
    () => (!milestones ? null : orderBy(milestones, ['date', 'type', 'notes'])),
    [milestones]
  );

  return (
    <SheetsWrapper>
      {/* Person fetching/loading */}
      {!person && isFetching && (
        <>
          {/* Left Sheet - Person info placeholder */}
          <Sheet position='left'>
            <Loader show />
          </Sheet>

          {/* Blank Right Sheet */}
          <Sheet position='right' className='hidden-mobile' />
        </>
      )}

      {/* Person not found */}
      {!person && !isFetching && <PersonNotFoundSheets goBack={goBack} />}

      {/* Found person, but wrong location active */}
      {!!person && !isFetching && !personInCurrentLocation && (
        <>
          <ChangeLocationPersonInfoSheet person={person} goBack={goBack} />
          <Sheet position='right' className='hidden-mobile' />
        </>
      )}

      {/* Person found ( and in this location ) */}
      {!!person && personInCurrentLocation && (
        <>
          {/* Left Sheet - Person info */}
          <PersonInfoSheet
            person={person}
            milestones={sortedMilestones}
            goBack={goBack}
            setEditPersonDoc={setEditPersonDoc}
            setShowDeletePersonModal={setShowDeletePersonModal}
            setShowNewRelationshipForm={setShowNewRelationshipForm}
            setEditRelationshipDoc={setEditRelationshipDoc}
            setDeleteRelationshipDoc={setDeleteRelationshipDoc}
            setShowNewMilestoneForm={setShowNewMilestoneForm}
            setEditMilestoneDoc={setEditMilestoneDoc}
            setDeleteMilestoneDoc={setDeleteMilestoneDoc}
            disableButtons={!isActive}
            // disableButtons={
            //   !!editPersonDoc ||
            //   showNewRelationshipForm ||
            //   showNewMilestoneForm ||
            //   !!editMilestoneDoc
            // }
          />

          {/* Right Sheet - tasks and activity(milestones/updates) */}
          <Sheet position='right' className={showActivity ? '' : 'hidden-mobile'}>
            <Loader show={tasksAreFetching || updatesAreFetching} />
            {!tasksAreFetching && !updatesAreFetching && !milestonesAreFetching && (
              <Scrollable className='fade-in' endBuffer='0'>
                {/* Tasks */}
                <TasksSection
                  person={person}
                  tasks={sortedTasks || []}
                  setShowNewTaskForm={setShowNewTaskForm}
                  setEditTaskDoc={setEditTaskDoc}
                  setDeleteTaskDoc={setDeleteTaskDoc}
                />

                {/* Activity (Updates/milestones */}
                <div style={{ minHeight: 'calc(100% - 64px)' }}>
                  <ActivitySection
                    person={person}
                    updates={updates || []}
                    milestones={sortedMilestones || []}
                    setShowNewUpdateForm={setShowNewUpdateForm}
                    setEditUpdateDoc={setEditUpdateDoc}
                    setDeleteUpdateDoc={setDeleteUpdateDoc}
                  />
                  <Spacer height='32px' />
                </div>
              </Scrollable>
            )}
          </Sheet>

          {/* Floating sheet forms */}

          {/* New task form */}
          <AnimatedFloatingSheet forwardedKey={person.id + 'new-task'}>
            {!!showNewTaskForm && (
              <TaskForm person={person} handleCancel={() => setShowNewTaskForm(false)} />
            )}
          </AnimatedFloatingSheet>
          {/* Edit task form */}
          <AnimatedFloatingSheet forwardedKey={editTaskDoc ? editTaskDoc.id : undefined}>
            {!!editTaskDoc && (
              <TaskForm
                person={person}
                task={editTaskDoc}
                handleCancel={() => setEditTaskDoc(undefined)}
              />
            )}
          </AnimatedFloatingSheet>
          {/* Delete task modal */}
          <DeleteModal
            name='task'
            show={!!deleteTaskDoc}
            handleDelete={handleDeleteTask}
            handleCancel={() => setDeleteTaskDoc(undefined)}
          />

          {/* New update form */}
          <AnimatedFloatingSheet forwardedKey={person.id + 'new-update'}>
            {!!showNewUpdateForm && (
              <UpdateForm person={person} handleCancel={() => setShowNewUpdateForm(false)} />
            )}
          </AnimatedFloatingSheet>
          {/* Edit update form */}
          <AnimatedFloatingSheet forwardedKey={editUpdateDoc ? editUpdateDoc.id : undefined}>
            {!!editUpdateDoc && (
              <UpdateForm
                person={person}
                update={editUpdateDoc}
                handleCancel={() => setEditUpdateDoc(undefined)}
              />
            )}
          </AnimatedFloatingSheet>
          {/* Delete update modal */}
          <DeleteModal
            name='update'
            show={!!deleteUpdateDoc}
            handleDelete={handleDeleteUpdate}
            handleCancel={() => setDeleteUpdateDoc(undefined)}
          />

          {/* New milestone form */}
          <AnimatedFloatingSheet forwardedKey={person.id + 'new-milestone'}>
            {!!showNewMilestoneForm && (
              <MilestoneForm person={person} handleCancel={() => setShowNewMilestoneForm(false)} />
            )}
          </AnimatedFloatingSheet>
          {/* Edit milestone form */}
          <AnimatedFloatingSheet forwardedKey={editMilestoneDoc ? editMilestoneDoc.id : undefined}>
            {!!editMilestoneDoc && (
              <MilestoneForm
                person={person}
                milestone={editMilestoneDoc}
                handleCancel={() => setEditMilestoneDoc(undefined)}
              />
            )}
          </AnimatedFloatingSheet>
          {/* Delete milestone modal */}
          <DeleteModal
            name='milestone'
            show={!!deleteMilestoneDoc}
            handleDelete={handleDeleteMilestone}
            handleCancel={() => setDeleteMilestoneDoc(undefined)}
          />

          {/* Add relationship form */}
          <AnimatedFloatingSheet forwardedKey={person.id + 'new-relationship'}>
            {showNewRelationshipForm && (
              <RelationshipForm
                person={person}
                handleCancel={() => setShowNewRelationshipForm(false)}
              />
            )}
          </AnimatedFloatingSheet>
          {/* Edit relationship form */}
          <AnimatedFloatingSheet>
            {!!editRelationshipDoc && (
              <RelationshipForm
                person={person}
                relationship={editRelationshipDoc}
                handleCancel={() => setEditRelationshipDoc(undefined)}
              />
            )}
          </AnimatedFloatingSheet>
          {/* Delete relationship confirm modal*/}
          <DeleteModal
            name='relationship'
            show={!!deleteRelationshipDoc}
            handleCancel={() => setDeleteRelationshipDoc(undefined)}
            handleDelete={handleDeleteRelationship}
          />

          {/* Edit person form */}
          <AnimatedFloatingSheet forwardedKey={editPersonDoc ? editPersonDoc.id : undefined}>
            {!!editPersonDoc && (
              <PersonForm person={person} handleCancel={() => setEditPersonDoc(undefined)} />
            )}
          </AnimatedFloatingSheet>
          {/* Delete person  */}
          <Modal handleClose={() => setShowDeletePersonModal(false)}>
            {showDeletePersonModal && (
              <DeletePersonSegment
                person={person}
                handleCancel={() => setShowDeletePersonModal(false)}
                updatesCount={updates?.length || 0}
                milestonesCount={milestones?.length || 0}
                tasksCount={tasks?.length || 0}
              />
            )}
          </Modal>
        </>
      )}
    </SheetsWrapper>
  );
};
export default PersonPage;
