import styled, { css } from 'styled-components';
// Libs
import { Link } from 'react-router-dom';
// Classes
import Photo from '@/classes/Photo';
import Notebirb from '@/classes/Notebirb';
// Common components
import Avatar from '@/components/common/Avatar';
import NotebirbAvatar from '@/components/common/NotebirbAvatar';
import Spacer from '@/components/common/Spacer';
import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';

// Types
interface UserLinkItemProps {
  to: string;
  photo: Photo;
  notebirb: Notebirb;
  preferNotebirb?: boolean;

  userName: string;
  subtitle?: React.ReactNode;
  isArchived?: boolean;

  active?: boolean;
  faded?: boolean;
}

// Component
const UserLinkItemElement = ({
  to,
  photo,
  notebirb,
  preferNotebirb = false,
  userName,
  subtitle,
  isArchived = false,
  faded,
  ...rest
}: UserLinkItemProps) => {
  const showNotebirb = preferNotebirb || !photo;
  return (
    <Link to={to} {...rest}>
      {showNotebirb && <NotebirbAvatar notebirb={notebirb} />}

      {!showNotebirb && <Avatar photo={photo} faded={isArchived} isUser />}
      <Spacer width='12px' />
      <Expanded>
        <Name faded={isArchived}>{userName}</Name>
        {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Expanded>
      {/* Is Archived tag */}
      {isArchived && (
        <ArchivedTag>
          <Icon icon='archive' />
          Archived
        </ArchivedTag>
      )}
    </Link>
  );
};

// Styles
const Name = styled.div<{ faded: boolean }>`
  color: ${({ faded, theme }) => (faded ? theme.textTertiary : theme.textSecondary)};
  font-size: 18px;
  font-weight: bold;
`;
const UserLinkItem = styled(UserLinkItemElement)<UserLinkItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  padding: 8px 24px;

  text-align: left;
  line-height: 1.4;

  outline: none;

  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.25;
      user-select: none;
      pointer-events: none;
    `};

  ${({ faded }) =>
    !faded &&
    css`
      &:hover,
      &:focus,
      &.active {
        outline: none;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        ${Name} {
          color: ${props => props.theme.textPrimary};
        }
        background-color: ${props => props.theme.hoverFade};
      }
    `};
`;
const Subtitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.textFaded};
  strong {
    color: ${({ theme }) => theme.textTertiary};
  }
  .danger {
    font-weight: bold;
    color: ${({ theme }) => theme.danger500};
  }
`;
const ArchivedTag = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  color: ${({ theme }) => theme.avatarText};
  font-weight: bold;
  i {
    margin-right: 4px;
    color: ${({ theme }) => theme.lightAccent};
  }
`;
export default UserLinkItem;
