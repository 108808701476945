import { useMemo, useState, useEffect, useCallback } from 'react';
// Libs
import { DateTime } from 'luxon';
import { MAX_MILLIS } from '@/lib/config';
import { getFriendlyDate } from '@/lib/helpers';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import intersection from 'lodash/intersection';
// Classes
import Task from '@/classes/Task';
import Doc from '@/classes/Doc';
// Context data
import useAppState from '@/contexts/appState';
import { UsersListItem } from '@/contexts/usersList';
import useGroups from '@/contexts/groups';
// Layout components
import SheetHeader from '@/components/layout/SheetHeader';
// Common Components
import TaskItem from '@/components/common/TaskItem';
import { SecondaryButton } from '@/components/common/Buttons';
import TransitionList, { TransitionListWrapper } from '@/components/common/TransitionList';
import StickyHeader from '@/components/common/StickyHeader';

type Props = {
  user: UsersListItem;
  tasks: Doc<Task>[];
};
// Component
const TasksAccordion = ({ user, tasks }: Props) => {
  // App state
  const { isLocationAdmin } = useAppState();
  // Context data
  const [currentUserGroups] = useGroups();

  // Master accordion state
  const [expanded, setExpanded] = useState(false);

  // NOTE: This section taken, in large part, from a person's TasksSection
  ////////////////////////////////////////////////////////////////////////

  // Open/uncompleted tasks
  // grouped by day then flattened for proper accordion animating
  const uncompletedTasks = useMemo(() => tasks.filter(({ isCompleted }) => !isCompleted), [tasks]);
  const uncompletedFlatList: (string | Doc<Task>)[] = useMemo(() => {
    if (!expanded) return [];
    const today = DateTime.local().startOf('day');
    const grouped = groupBy(uncompletedTasks, ({ dueDate }) => {
      // Lump all 'unscheduled' together
      if (!dueDate) {
        return MAX_MILLIS;
      } else {
        const dueDateTime = DateTime.fromISO(dueDate).startOf('day');
        // Lump all 'overdue' together
        return dueDateTime < today ? 0 : dueDateTime.toMillis();
      }
    });
    return orderBy(toPairs(grouped), ([millis]) => millis).flat(2);
  }, [expanded, uncompletedTasks]);

  // Completed tasks ( and when to show them )
  // grouped by day then flattened for proper accordion animating
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);
  const completedTasks = useMemo(() => tasks.filter(({ isCompleted }) => isCompleted), [tasks]);
  const completedFlatList: (string | Doc<Task>)[] = useMemo(() => {
    if (!showCompletedTasks) return [];
    const grouped = groupBy(completedTasks, task =>
      task.completedAt
        ? DateTime.fromMillis(task.completedAt.toMillis()).startOf('day').toMillis()
        : DateTime.local().startOf('day').toMillis()
    );
    return orderBy(toPairs(grouped), ([millis]) => millis, 'desc').flat(2);
  }, [completedTasks, showCompletedTasks]);
  // Whenever there are no completed tasks, reset show status
  useEffect(() => {
    !completedTasks.length && setShowCompletedTasks(false);
  }, [completedTasks.length]);

  // Determine if should show or hide group
  // ( user only in one group and it's the same one as task )
  const shouldHideGroups = useCallback(
    (taskGroups: string[]) => {
      const userGroupIds = currentUserGroups?.map(({ id }) => id) || [];
      const groupsInCommon = isLocationAdmin
        ? user.groups
        : intersection(user.groups, userGroupIds);
      const taskGroupsInCommon = isLocationAdmin
        ? taskGroups
        : intersection(taskGroups, userGroupIds);
      return (
        groupsInCommon.length === 1 &&
        taskGroupsInCommon.length === 1 &&
        groupsInCommon[0] === taskGroupsInCommon[0]
      );
    },
    [currentUserGroups, isLocationAdmin, user.groups]
  );

  return (
    // This div is for proper sticky header scrolling
    <div>
      <SheetHeader
        sticky
        expandable={!!uncompletedTasks.length}
        leadingIcon='check'
        mainTitle='Tasks'
        active={expanded && (!!uncompletedTasks.length || showCompletedTasks)}
        subtitle={
          completedTasks.length ? (
            <SecondaryButton
              onClick={event => {
                event.stopPropagation();
                setShowCompletedTasks(prev => {
                  !prev && setExpanded(!prev);
                  return !prev;
                });
              }}
              dull={showCompletedTasks}
            >
              {showCompletedTasks ? 'Hide ' : 'Show '}
              {completedTasks.length} completed
            </SecondaryButton>
          ) : !uncompletedTasks.length ? (
            'No tasks assigned to ' + user.profile.name.first
          ) : undefined
        }
        onClick={() =>
          setExpanded(prev => {
            prev && setShowCompletedTasks(!prev);
            return !prev;
          })
        }
      />

      {/* Task lists */}
      {/* ( this whole thing pretty much copy/pasted from person's TasksSection too... ) */}
      <TransitionListWrapper>
        {/* Uncompleted tasks */}
        {uncompletedFlatList.map(value => {
          // Date/group header
          if (typeof value === 'string') {
            const millis = parseInt(value);
            const isOverdue = millis === 0;
            const isUnscheduled = millis === MAX_MILLIS;
            return (
              <TransitionList key={value} style={{ position: 'sticky', top: 64, zIndex: 8 }}>
                <StickyHeader
                  heading={
                    isOverdue
                      ? 'Overdue'
                      : isUnscheduled
                      ? 'Unscheduled'
                      : 'Due ' + getFriendlyDate(millis)
                  }
                  variant={isOverdue ? 'danger' : isUnscheduled ? 'dull' : 'normal'}
                />
              </TransitionList>
            );
          }
          // Task item
          const task = value;
          return (
            <TransitionList key={task.id}>
              <TaskItem
                key={task.id}
                task={task}
                hideUser
                // Hide groups here if user only has one group in common
                // and the task includes this group
                hideGroups={shouldHideGroups(task.groups)}
              />
            </TransitionList>
          );
        })}

        {/* Completed tasks */}
        {completedFlatList.map(value => {
          // Date/group header
          if (typeof value === 'string') {
            const millis = parseInt(value);
            return (
              <TransitionList key={value} style={{ position: 'sticky', top: 64, zIndex: 8 }}>
                <StickyHeader heading={`Completed ${getFriendlyDate(millis)}`} variant='dull' />
              </TransitionList>
            );
          }
          // Task item
          const task = value;
          return (
            <TransitionList key={task.id}>
              <TaskItem
                key={task.id}
                task={task}
                hideUser
                // Hide groups here if user only has one group in common
                // and the task includes this group
                hideGroups={shouldHideGroups(task.groups)}
              />
            </TransitionList>
          );
        })}
      </TransitionListWrapper>
    </div>
  );
};

// Export
export default TasksAccordion;
