/**
 * Used with react-spring to calculate accordion height
 * (inspired by demo: https://codesandbox.io/embed/q3ypxr5yp4)
 * (and also this repo: https://github.com/ZeeCoder/use-resize-observer/blob/master/src/index.js)
 */

import { useEffect, useState, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

interface Measurements {
  x: number;
  y: number;
  width: number;
  height: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
}

const defaultMeasurements: Measurements = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
};

export default function useMeasurements(
  enable = true
): [React.RefObject<HTMLDivElement>, Measurements] {
  const ref = useRef<HTMLDivElement>(null);
  const [measurements, setMeasurements] = useState(defaultMeasurements);

  useEffect(() => {
    if (enable) {
      const element = ref.current as HTMLDivElement;
      let resizeObserver: ResizeObserver;
      if (element) {
        resizeObserver = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
          setMeasurements(entry.contentRect);
        });
        resizeObserver.observe(element);
      }

      return () => !!resizeObserver && resizeObserver.unobserve(element);
    }
  }, [enable, ref]);

  return [ref, measurements];
}
