import { lazy } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import capitalize from 'lodash/capitalize';

import useUser from '@/contexts/user';

import usePageView from '@/hooks/usePageView';

import MobilePageHeader from '@/components/layout/MobilePageHeader';
import SheetHeader from '@/components/layout/SheetHeader';
import Sheet, { SheetScrollable, SheetsWrapper } from '@/components/layout/Sheets';

import NotificationsAccordion from './NotificationsAccordion';
import ProfileAccordion from './ProfileAccordion';
import ThemeAccordion from './ThemeAccordion';

import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Margin from '@/components/common/Margin';
import Spacer from '@/components/common/Spacer';

// Lazy load illustration
const ProfileIllustration = lazy(() => import('../../svg/ProfileIllustration'));

// Styles
const FlexWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  div.header {
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.textSecondary};
    margin-bottom: 8px;
  }
  div.copy {
    color: ${props => props.theme.textFaded};
    line-height: 1.25;
  }
`;

// Component
const ProfilePage = () => {
  // Current accordion comes from url path
  const { accordion } = useParams();
  const navigate = useNavigate();

  // Register page view
  usePageView({ title: `Profile${accordion ? ` - ${capitalize(accordion)}` : ''} | Notebird` });

  // Context data
  const [user] = useUser();

  return (
    <SheetsWrapper>
      {/* Header for mobile */}
      <MobilePageHeader hideGroupsAsideToggle>{user?.profile.name.full}</MobilePageHeader>

      {/* Left - illustration/copy */}
      <Sheet position='left' className='hidden-mobile'>
        <FlexWrapperCentered>
          <Margin margin='-64px 0 0' />
          <ErrorSuspendPlaceholder>
            <ProfileIllustration width='232px' height='232px' className='fade-in' />
          </ErrorSuspendPlaceholder>
          <Spacer height='48px' />
          <div className='header'>Your Notebird, your way</div>
          <div className='copy'>Adjust these settings to custom-tailor your experience</div>
        </FlexWrapperCentered>
      </Sheet>

      {/* Right - settings/accordions */}
      <Sheet position='right'>
        <SheetScrollable>
          {/* Profile */}
          <SheetHeader
            expandable
            active={accordion === 'user'}
            sticky
            leadingIcon='assignment_ind'
            mainTitle='Profile'
            subtitle='Your own account information'
            onClick={() =>
              navigate(accordion === 'user' ? '/profile' : '/profile/user', { replace: true })
            }
            data-intercom-target='Profile accordion header'
          />
          <ProfileAccordion show={accordion === 'user'} />
          {/* Theme */}
          <SheetHeader
            expandable
            active={accordion === 'theme'}
            sticky
            stickyTop='64px'
            leadingIcon='brightness_medium'
            mainTitle='Theme'
            subtitle="Change Notebird's look and feel"
            onClick={() =>
              navigate(accordion === 'theme' ? '/profile' : '/profile/theme', { replace: true })
            }
            data-intercom-target='Theme accordion header'
          />
          <ThemeAccordion show={accordion === 'theme'} />
          {/* Notifications */}
          <SheetHeader
            expandable
            active={accordion === 'notifications'}
            sticky
            stickyTop='128px'
            leadingIcon='notifications'
            mainTitle='Notifications'
            subtitle='Control how and when you receive messages'
            onClick={() =>
              navigate(accordion === 'notifications' ? '/profile' : '/profile/notifications', {
                replace: true,
              })
            }
            data-intercom-target='Notifications accordion header'
          />
          <NotificationsAccordion show={accordion === 'notifications'} />
        </SheetScrollable>
      </Sheet>
    </SheetsWrapper>
  );
};

// Export
export default ProfilePage;
