import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import Doc, { docFromSnapshot } from '@/classes/Doc';
import Person from '@/classes/Person';

import { db } from '@/lib/firebase';

import orderBy from 'lodash/orderBy';

import useBaseQuery from '@/hooks/useBaseQuery';
import useCollection from '@/hooks/useCollection';

import useAppState from './appState';
import useCounts from './counts';

// Defaults
const DEFAULT_FETCH_AMOUNT = 100;

// Active ( filtered / sorted ) people stream context ( with hook shortcut )
const fetchingPeople: {
  docs: Doc<Person>[];
  isFetching: boolean;
  count: number;
  loadMore(limit: number): void;
} = { docs: [], isFetching: true, count: 0, loadMore: () => undefined };
type PeopleStream = typeof fetchingPeople;
const peopleContext = createContext(fetchingPeople);
const usePeople = () => useContext(peopleContext);
export default usePeople;

// Full cache context ( for quick lookup on personPage lookup )
interface PeopleCache {
  [personId: string]: Doc<Person> | undefined;
}
const peopleCacheContext = createContext<
  [PeopleCache, React.Dispatch<React.SetStateAction<PeopleCache>>]
>([{}, () => undefined]);
export const usePeopleCache = () => useContext(peopleCacheContext);

// Context definition w/ provider
export const PeopleProvider = ({ children }: { children: React.ReactNode }) => {
  // App state
  const {
    organizationId,
    locationId,
    contentFilter,
    groupId,
    showArchivedPeople,
    followedPeopleIds,
    orderPeopleBy,
    orderPeopleDirection,
  } = useAppState();

  // Context data
  const [counts] = useCounts();

  // Complete/combined cache keyed by person's id
  // ( helpful for initial lookup on person page )
  const [peopleCache, setPeopleCache] = useState<PeopleCache>({});

  // Cache previously run collection streams here keyed by filter
  const [keyedCache, setKeyedCache] = useState<{
    [filterId: string]: PeopleStream;
  }>({});
  const [limit, setLimit] = useState(DEFAULT_FETCH_AMOUNT);

  // Reset limit every time groupId or content filter changes
  useEffect(() => {
    setLimit(DEFAULT_FETCH_AMOUNT);
  }, [groupId, contentFilter]);

  // Reference filter key to pass around internally here
  // ( this is to prevent a re-render and false cache when changing filters )
  const filterKey = useRef(groupId || contentFilter);
  useEffect(() => {
    filterKey.current = groupId || contentFilter;
  }, [groupId, contentFilter]);

  // Clear keyed cache and reset limit any time locationId or sort order changes
  useEffect(() => {
    setKeyedCache({});
    setLimit(DEFAULT_FETCH_AMOUNT);
  }, [locationId, orderPeopleBy, orderPeopleDirection]);

  // FOLLOWED PEOPLE
  //////////////////
  // are a special case and need to be fetched individually
  // all at once and sorted by themselves client side
  const [followedPeople, setFollowedPeople] = useState<{ [personId: string]: Doc<Person> }>({});
  useEffect(() => {
    if (!groupId && contentFilter === 'following') {
      // Create snapshot listener for each followed person document
      const unsubscribes = followedPeopleIds.map(id =>
        db
          .collection(`organizations/${organizationId}/people`)
          .doc(id)
          .onSnapshot(
            snapshot => {
              const person = docFromSnapshot<Person>(snapshot);
              setFollowedPeople(prev => ({ ...prev, [id]: person }));
              // And add to full cache too
              setPeopleCache(prev => ({ ...prev, [id]: person }));
            },
            error => {
              console.error(`Error streaming followed person '${id}'. ${error.message}`);
            }
          )
      );
      return () => {
        unsubscribes.map(unsub => unsub());
      };
    }
  }, [contentFilter, followedPeopleIds, groupId, organizationId]);
  // Need one extra step for followed people to determine whether fetching
  // and manually sort by filter
  const sortedFollowedPeople: PeopleStream = useMemo(() => {
    // Determine that we're stilling fetching some if followedPeople list
    // is less than total of all followed people we know about
    const followedPeopleDocs = Object.values(followedPeople);
    const isFetching = followedPeopleDocs.length < followedPeopleIds.length;
    // Refilter back through fresh followed id list
    // ( someone may have been removed from ids but wasn't remove in cache )
    let filteredFollowedPeople = followedPeopleDocs.filter(({ id }) =>
      followedPeopleIds.includes(id)
    );
    // Sort away!
    switch (orderPeopleBy) {
      case 'Last name':
        filteredFollowedPeople = orderBy(
          filteredFollowedPeople,
          'sortLastName',
          orderPeopleDirection === 'primary' ? 'asc' : 'desc'
        );
        break;
      case 'Joined':
        filteredFollowedPeople = orderBy(
          filteredFollowedPeople,
          ({ joinDate }) => joinDate || '0',
          orderPeopleDirection === 'primary' ? 'desc' : 'asc'
        );
        break;
      default:
        filteredFollowedPeople = orderBy(
          filteredFollowedPeople,
          'sortFirstName',
          orderPeopleDirection === 'primary' ? 'asc' : 'desc'
        );
        break;
    }
    return {
      docs: filteredFollowedPeople,
      isFetching,
      count: followedPeopleIds.length,
      loadMore: () => undefined,
    };
  }, [followedPeople, followedPeopleIds, orderPeopleBy, orderPeopleDirection]);

  // Construct query to execute base on current appState ( groups, filters, orders, n such )
  const baseQuery = useBaseQuery({
    collection: 'people',
    excludeMemberGroups: true,
    showArchived: showArchivedPeople,
    limit,
  });
  const peopleQuery = useMemo(() => {
    // Start with base query and extend
    let query = baseQuery;
    // ( query is entirely irrelevant when viewing 'following' people )
    if (query && (groupId || contentFilter !== 'following')) {
      // Group is specified, so limit the query to a single group
      if (groupId) {
        query = query.where('groups', 'array-contains', groupId);
      }
      // Requested to get all 'ungrouped' people instead
      else if (contentFilter === 'ungrouped') {
        query = query.where('groups', 'array-contains', 'ungrouped');
      }

      // Order people based on appState
      switch (orderPeopleBy) {
        // Sort by last name
        case 'Last name':
          query = query.orderBy(
            'sortLastName',
            orderPeopleDirection === 'primary' ? 'asc' : 'desc'
          );
          break;
        // Sort by join date
        case 'Joined':
          query = query.orderBy('joinDate', orderPeopleDirection === 'primary' ? 'desc' : 'asc');
          break;
        // Default to sort by first name
        default:
          query = query.orderBy(
            'sortFirstName',
            orderPeopleDirection === 'primary' ? 'asc' : 'desc'
          );
          break;
      }
    }
    return query;
  }, [baseQuery, contentFilter, groupId, orderPeopleBy, orderPeopleDirection]);
  const [peopleDocs, peopleAreFetching] = useCollection<Person>(peopleQuery, {
    ignoreCache: true,
    trace: 'fetch_people',
  });

  // Take current stream and cache it
  useEffect(() => {
    // Tabulate appropriate count ( slightly different for all/default vs groupId or ungrouped )
    let total = 0;
    let archived = 0;
    if (['all', 'default'].includes(filterKey.current)) {
      total = counts?.people ?? 0;
      archived = counts?.peopleArchived ?? 0;
    } else {
      total = counts?.groups[filterKey.current]?.people ?? 0;
      archived = counts?.groups[filterKey.current]?.peopleArchived ?? 0;
    }
    // Update keyed cache
    setKeyedCache(prev => {
      // While people are fetching, return previously cached docs ( or empty )
      // otherwise push queried docs into cache
      const docs = (peopleAreFetching ? prev[filterKey.current]?.docs : peopleDocs) || [];
      // Get true count from _counts collection for this location
      // ( count should never be less than current number of docs in current list! )
      const count = Math.max(showArchivedPeople ? total : total - archived, docs.length);
      // Mark as fetching only if clearly fetching AND
      // there were no documents residing inside the cache prior to this call
      // ( oh, and count inicates there are docs to fetch in the first place )
      const isFetching = peopleAreFetching && !prev[filterKey.current]?.docs?.length && !!count;
      // Formulate new stream for primary and cache
      const stream = {
        docs,
        isFetching,
        count,
        loadMore: (limit: number) => {
          setLimit(limit);
        },
      };
      // Add to keyed cache on current filter
      return {
        ...prev,
        [filterKey.current]: stream,
      };
    });
    // And add to full cache too keyed by ID
    setPeopleCache(prev => ({
      ...prev,
      ...Object.assign({}, ...(peopleDocs ?? []).map(person => ({ [person.id]: person }))),
    }));
  }, [counts, showArchivedPeople, peopleDocs, peopleAreFetching]);

  // Get primary people stream any time cached list changes
  // ( have to choose between following list or current key )
  const people = useMemo(() => {
    return filterKey.current === 'following'
      ? sortedFollowedPeople
      : keyedCache[filterKey.current] || fetchingPeople;
  }, [sortedFollowedPeople, keyedCache]);

  return (
    <peopleContext.Provider value={people}>
      <peopleCacheContext.Provider value={[peopleCache, setPeopleCache]}>
        {children}
      </peopleCacheContext.Provider>
    </peopleContext.Provider>
  );
};
