import { useState, Fragment, useMemo, useCallback } from 'react';
// Libs
import { DateTime } from 'luxon';
import { getFriendlyDate } from '@/lib/helpers';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import orderBy from 'lodash/orderBy';
import intersection from 'lodash/intersection';
// Classes
import Doc from '@/classes/Doc';
import Update from '@/classes/Update';
// Context data
import useAppState from '@/contexts/appState';
import { UsersListItem } from '@/contexts/usersList';
import useGroups from '@/contexts/groups';
// Layout components
import SheetHeader from '@/components/layout/SheetHeader';
// Common components
import UpdateItem from '@/components/common/UpdateItem';
import Divider from '@/components/common/Divider';
import Spacer from '@/components/common/Spacer';
import Accordion from '@/components/common/Accordion';
import StickyHeader from '@/components/common/StickyHeader';

// Props
type Props = {
  user: UsersListItem;
  updates: Doc<Update>[];
};

// Component
const UpdatesAccordion = ({ user, updates }: Props) => {
  const { isLocationAdmin } = useAppState();
  const [currentUserGroups] = useGroups();

  // Master accordion state
  const [expanded, setExpanded] = useState(false);

  // Group updates by day
  const groupedUpdates = useMemo(() => {
    const groups = groupBy(updates, update =>
      update.meta.createdAt
        ? DateTime.fromJSDate(update.meta.createdAt.toDate()).startOf('day').toMillis()
        : DateTime.local().startOf('day').toMillis()
    );
    return orderBy(toPairs(groups), ([millis]) => millis, 'desc');
  }, [updates]);

  // Determine if should show or hide update group
  // ( user only in one group and it's the same one as update )
  const shouldHideGroups = useCallback(
    (updateGroups: string[]) => {
      const userGroupIds = currentUserGroups?.map(({ id }) => id) || [];
      const groupsInCommon = isLocationAdmin
        ? user.groups
        : intersection(user.groups, userGroupIds);
      const updateGroupsInCommon = isLocationAdmin
        ? updateGroups
        : intersection(updateGroups, userGroupIds);
      return (
        groupsInCommon.length === 1 &&
        updateGroupsInCommon.length === 1 &&
        groupsInCommon[0] === updateGroupsInCommon[0]
      );
    },
    [currentUserGroups, isLocationAdmin, user.groups]
  );

  return (
    // This div is for proper sticky header scrolling
    <div>
      <SheetHeader
        sticky
        expandable={!!groupedUpdates.length}
        leadingIcon='library_books'
        mainTitle='Recent updates'
        // Header fades if has no recent updates
        active={expanded && !!groupedUpdates.length}
        subtitle={
          !groupedUpdates.length ? 'No recent updates by ' + user.profile.name.first : undefined
        }
        onClick={() => setExpanded(prev => !prev)}
      />

      <Accordion show={expanded && !!groupedUpdates.length}>
        {/* Map over recent updates groups */}
        {groupedUpdates.map(([millis, updates], index) => (
          <Fragment key={millis}>
            {/* Date header */}
            <StickyHeader heading={getFriendlyDate(parseInt(millis))} />

            {/* Each update on date */}
            {updates.map(update => (
              <UpdateItem
                key={update.id}
                update={update}
                hideUser
                // Hide groups here if user only has one group
                // OR update's groups are exact same as user's
                hideGroups={shouldHideGroups(update.groups)}
              />
            ))}
            {/* Divider only if not last */}
            {index + 1 !== groupedUpdates.length && <Divider />}
          </Fragment>
        ))}
        <Spacer height='24px' />
      </Accordion>
    </div>
  );
};

// Export
export default UpdatesAccordion;
