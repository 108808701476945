import { useState } from 'react';
import styled from 'styled-components';
// Libs
import { db } from '@/lib/firebase';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';
// Hooks/data
import useUser from '@/contexts/user';
import useOrganization from '@/contexts/organization';
// Form elements
import DynamicInputRow, { ValueWrapper } from '@/components/formElements/DynamicInputRow';
import { Formik } from 'formik';
import FormikAutocompletePlace from '@/components/formElements/FormikAutocompletePlace';
// Common components
import Padding from '@/components/common/Padding';
import Accordion from '@/components/common/Accordion';
import Spacer from '@/components/common/Spacer';
import { FormLabel } from '@/components/common/Label';
import Icon from '@/components/common/Icon';
import { SecondaryButton } from '@/components/common/Buttons';
import Expanded from '@/components/common/Expanded';

// Styles
const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

// Component
const OrganizationAccordion = ({ show }: { show: boolean }) => {
  const [user] = useUser();
  const [organization] = useOrganization();

  const [editAddress, setEditAddress] = useState(false);

  return (
    <Accordion show={show}>
      <Padding padding='24px 32px 0'>
        {/* Name */}
        <DynamicInputRow
          label='Organization name'
          name='orgName'
          value={organization?.profile.name ?? ''}
          required
          maxLength={75}
          documentPath={'organizations/' + organization?.id}
          fieldName='profile.name'
          includeUpdatedByMeta
        />
        <Spacer height='12px' />
        {/* Address */}
        <FormLabel>Address</FormLabel>
        {!editAddress && (
          <ValueWrapper onClick={() => setEditAddress(true)}>
            {!!organization?.profile.address && (
              <div>
                {organization.profile.address.mainText}
                {!!organization.profile.address.secondaryText && (
                  <>
                    <br />
                    <span className='placeholder'>
                      {organization.profile.address.secondaryText}
                    </span>
                  </>
                )}
              </div>
            )}
            <span className='placeholder'>
              {!organization?.profile.address && 'Organization address'}
            </span>
            <Icon icon='edit' iconSize='18px' />
          </ValueWrapper>
        )}
        {editAddress && (
          <FlexRow>
            <Expanded>
              <Formik
                initialValues={{ organizationAddress: organization?.profile.address ?? null }}
                onSubmit={() => undefined}
              >
                {() => (
                  <FormikAutocompletePlace
                    autoFocus
                    name='organizationAddress'
                    placeholder='Enter address'
                    onPlaceSelect={place => {
                      user &&
                        organization &&
                        db.doc(organization.docPath).update({
                          ...generateUpdatedByDottedMeta(user),
                          'profile.address': place,
                        });
                      setEditAddress(false);
                    }}
                  />
                )}
              </Formik>
            </Expanded>
            <SecondaryButton dull onClick={() => setEditAddress(false)}>
              Cancel
            </SecondaryButton>
          </FlexRow>
        )}
        <Spacer height='12px' />
        {/* Website */}
        <DynamicInputRow
          label='Website'
          name='orgWebsite'
          placeholder='http://'
          maxLength={200}
          value={organization?.profile.website || ''}
          documentPath={'organizations/' + organization?.id}
          fieldName='profile.website'
          includeUpdatedByMeta
        />
        <Spacer height='12px' />
        {/* Phone */}
        <DynamicInputRow
          label='Phone'
          name='orgPhone'
          maxLength={30}
          placeholder='(xxx) xxx-xxxx'
          value={organization?.profile.phone || ''}
          documentPath={'organizations/' + organization?.id}
          fieldName='profile.phone'
          includeUpdatedByMeta
        />
      </Padding>
      <Spacer height='48px' />
    </Accordion>
  );
};
export default OrganizationAccordion;
