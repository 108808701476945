import { memo, HTMLProps } from 'react';
import styled, { css } from 'styled-components';
// Libs
import { DateTime } from 'luxon';
import { getFriendlyDate } from '@/lib/helpers';
import { Link } from 'react-router-dom';
import { Markup } from 'interweave';
// Classes
import { WithId } from '@/classes/Doc';
import Person from '@/classes/Person';
// Common components
import Avatar from '@/components/common/Avatar';
import Spacer from '@/components/common/Spacer';
import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';
import Divider from '@/components/common/Divider';

// Styles
const Name = styled.span<{ faded: boolean; bold: boolean }>`
  color: ${({ faded, theme }) => (faded ? theme.textTertiary : theme.textSecondary)};
  font-size: 18px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  em {
    padding: 1px 0;
    font-style: normal;
    font-weight: bold;
    border-bottom: 2px solid ${({ theme }) => theme.avatarText};
  }
`;
const StyledPersonLinkItem = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 24px 8px 16px;
  @media (max-width: 424px) {
    padding: 6px 24px 6px 4px;
  }

  line-height: 1.4;

  &:hover,
  &:focus,
  &.active {
    outline: none;
    text-decoration: none;
    ${Name} {
      color: ${props => props.theme.textPrimary};
    }
    background-color: ${props => props.theme.hoverFade};
  }
`;
const Subtitle = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.textFaded};
  strong {
    color: ${({ theme }) => theme.textTertiary};
  }
  em {
    border-bottom: 2px solid ${({ theme }) => theme.lightAccent};
    font-style: normal;
    font-weight: bold;
    color: ${({ theme }) => theme.textSecondary};
    /* background-color: ${({ theme }) => theme.offLight}; */
  }
  span {
    color: ${({ theme }) => theme.lightAccent};
  }
`;
const PulseIndicator = styled.div<{ status: Props['pulseStatus'] }>`
  position: absolute;
  width: 6px;
  height: 100%;
  top: 0;
  left: 6px;
  border-radius: 0 2px 2px 0;
  /* Pulse color */
  ${({ status }) =>
    status === 'danger' &&
    css`
      background-color: ${({ theme }) => theme.danger500};
    `}
  ${({ status }) =>
    status === 'warn' &&
    css`
      background-color: ${({ theme }) => theme.danger400};
      opacity: 0.4;
    `}
  ${({ status }) =>
    status === 'none' &&
    css`
      background-color: ${({ theme }) => theme.lightAccent};
      opacity: 0.5;
    `}
`;
const JoinedIcon = styled(Icon)<{ faded: boolean }>`
  color: ${({ faded, theme }) => (faded ? theme.avatarText : theme.textPrimary)};
`;
const ArchivedTag = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  color: ${({ theme }) => theme.avatarText};
  font-weight: bold;
  i {
    margin-right: 4px;
    color: ${({ theme }) => theme.lightAccent};
  }
`;

// Types
interface Props {
  person: WithId<Person>;
  active?: boolean;
  nameMarkup?: string;
  subtitleMarkup?: string;
  showJoinDate?: boolean;
  boldFirstName?: boolean;
  boldLastName?: boolean;
  pulseStatus?: 'danger' | 'warn' | 'fresh' | 'none';
  joinStatus?: 'new' | 'fading';
}

// Component
const PersonLinkItem = memo(function PersonLinkItem({
  person,
  active = false,
  nameMarkup,
  subtitleMarkup,
  showJoinDate = false,
  boldFirstName = false,
  boldLastName = false,
  pulseStatus = 'fresh',
  joinStatus,
  ...rest
}: Props & HTMLProps<HTMLDivElement>) {
  return (
    <div {...rest}>
      <StyledPersonLinkItem to={`/person/${person.id}`} className={active ? 'active' : ''}>
        <PulseIndicator status={pulseStatus} />
        <Spacer width='12px' />
        <Avatar
          id={person.id}
          photo={person.profile.photo}
          name={person.profile.name.full.charAt(0)}
          faded={person.isArchived}
        />
        <Spacer width='12px' />
        {/* Used for highlighting search results */}
        {!!nameMarkup && (
          <Expanded style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <Name faded={person.isArchived} bold={false}>
              <Markup content={nameMarkup} />
            </Name>
            <Subtitle>
              <Markup content={subtitleMarkup} />
            </Subtitle>
          </Expanded>
        )}
        {/* When not search result */}
        {!nameMarkup && (
          <Expanded style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            <Name faded={person.isArchived} bold={boldFirstName}>
              {person.profile.name.first}
            </Name>{' '}
            <Name faded={person.isArchived} bold={boldLastName}>
              {person.profile.name.last}
            </Name>
            {!!showJoinDate &&
              (person.joinDate ? (
                <Subtitle>
                  Joined <strong>{getFriendlyDate(person.joinDate)}</strong>
                </Subtitle>
              ) : (
                <Subtitle>
                  <span>No join date listed</span>
                </Subtitle>
              ))}
          </Expanded>
        )}
        {/* Join status star (when not archived and is active for org) */}
        {!person.isArchived && person.joinDate && !!joinStatus && (
          <JoinedIcon
            icon='fiber_new'
            faded={joinStatus === 'fading'}
            iconSize='28px'
            title={`Joined ${
              DateTime.fromISO(person.joinDate).diff(DateTime.local().startOf('day')).as('days') *
              -1
            } days ago`}
          />
        )}
        {/* Is Archived tag */}
        {person.isArchived && (
          <ArchivedTag>
            <Icon icon='archive' />
            Archived
          </ArchivedTag>
        )}
      </StyledPersonLinkItem>
      <Divider />
    </div>
  );
});
export default PersonLinkItem;
