import { useCallback, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import Doc from '@/classes/Doc';
import Group from '@/classes/Group';
import { INVITATION_EXPIRATION_DAYS } from '@/classes/Invitation';
import Location from '@/classes/Location';
import Organization from '@/classes/Organization';
import { showCents } from '@/classes/Stripe';
import Task from '@/classes/Task';
import Update from '@/classes/Update';

import firebase, { db, fbFunctions } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import { MAX_GROUP_ASSIGNMENTS } from '@/lib/config';
import { getFriendlyDate } from '@/lib/helpers';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useGroups from '@/contexts/groups';
import useLocation from '@/contexts/location';
import useLocations from '@/contexts/locations';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';
import { useAllUsers } from '@/contexts/usersList';

import useBaseQuery from '@/hooks/useBaseQuery';
import useCollection from '@/hooks/useCollection';

import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';

// import UserNotFoundSheet from './UserNotFoundSheet';
import PermissionsAccordion from './PermissionsAccordion';
import TasksAccordion from './TasksAccordion';
import UpdatesAccordion from './UpdatesAccordion';

import {
  ConfirmCopy,
  ConfirmHead,
  ConfirmSmall,
  ConfirmSubhead,
} from '@/components/forms/NewUserForm';

import { Input } from '@/components/formElements/FormElements';

import Avatar from '@/components/common/Avatar';
import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import Divider from '@/components/common/Divider';
import Expanded from '@/components/common/Expanded';
import GroupLabel from '@/components/common/GroupLabel';
import Icon from '@/components/common/Icon';
import { FormLabel } from '@/components/common/Label';
import Loader from '@/components/common/Loader';
import {
  DeleteCannotBeUndone,
  DeleteExtra,
  DeleteWarning,
  FormModal,
} from '@/components/common/Modals';
import NotebirbAvatar from '@/components/common/NotebirbAvatar';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Styles
const BackLink = styled(SecondaryButton).attrs(() => ({ as: Link }))<{ to: string }>`
  float: right;
  margin-right: -8px;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;
const UserDetails = styled.div`
  flex: 1;
`;
const UserName = styled.div`
  margin-bottom: 2px;
  font-size: 24px;
  font-weight: bold;
`;
const UserEmail = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
`;
const UserRole = styled.div`
  font-size: 14px;
  color: ${props => props.theme.textTertiary};
  .danger {
    color: ${({ theme }) => theme.danger500};
  }
`;
const Heading = styled.div`
  font-size: 22px;
  line-height: 1.4;
  color: ${({ theme }) => theme.textTertiary};
  strong {
    color: ${({ theme }) => theme.textSecondary};
    border-bottom: 2px solid ${({ theme }) => theme.linkColor};
  }
`;
const Copy = styled.div`
  font-size: 18px;
  line-height: 1.4;
  color: ${({ theme }) => theme.textSecondary};
  strong {
    color: ${({ theme }) => theme.textPrimary};
  }
`;
const GroupRow = styled.div<{ danger?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 32px 0 52px;
  font-size: 18px;
  font-weight: bold;
  color: ${({ danger, theme }) => (danger ? theme.danger500 : theme.textTertiary)};

  button {
    opacity: 0;
    transition: opacity 150ms ease;
  }

  &:hover {
    color: ${({ danger, theme }) => (danger ? theme.danger500 : theme.textPrimary)};
    button {
      opacity: 1;
    }
  }
`;

// Types
type IncrementQuote = {
  userPriceAtTier: number;
  proration: number;
  amount: number;
  quantity: number;
};

// Component
const UserDetailSheet = ({ userId }: { userId: string }) => {
  // App state
  const {
    isOrganizationAdmin,
    locationId,
    isLocationAdmin,
    isTrialing,
    isActive,
    willEnd,
    hasRestrictedAccess,
  } = useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [currentUser] = useUser();
  const [organization] = useOrganization();
  const stripe = organization?._stripe || { ...new Organization()._stripe };
  const [locations] = useLocations();
  const [currentLocation] = useLocation();
  const locationUsers = useAllUsers();
  const [groups] = useGroups();

  // Find user and profile
  // const user = useMemo(() => users.find(user => user.id === userId), [userId, users]);
  const user = locationUsers?.find(({ id }) => id === userId);
  const userProfile = (user && user.profile) || null;
  const showNotebirb = userProfile && (userProfile.preferNotebirb || !userProfile.photo);

  // Tell if this user is or isn't a part of this location
  const belongsAtLocation =
    !!user && (user.isOrganizationAdmin || user.isLocationAdmin || user.isLocationUser);
  // Find other locations this user belongs to
  const otherLocations =
    locations?.filter(({ id, users }) => id !== locationId && !!user && users.includes(user.id)) ||
    [];
  // Determine whether user is group member or not
  const userIsGroupMember =
    !!user && user.isLocationUser && !user.isLocationAdmin && !user.isOrganizationAdmin;

  // Invite stuff
  const invitationDateTime =
    !!user && !!user.invitationCreatedAt
      ? DateTime.fromMillis(user.invitationCreatedAt.toMillis())
      : DateTime.local();
  const showInvitationResend = invitationDateTime < DateTime.local().minus({ minutes: 5 });
  const [invitationResent, setInvitationResent] = useState(false);
  const invitationIsExpired =
    !!user && !!user.invitationExpiresAt && user.invitationExpiresAt < DateTime.local();
  const [showInviteCancelModal, setShowInviteCancelModal] = useState(false);

  // Users's tasks
  const tasksQuery = useBaseQuery({
    collection: 'tasks',
    showArchived: true,
  })?.where('assignedTo.id', '==', userId);
  const [tasks, tasksAreFetching] = useCollection<Task>(
    belongsAtLocation && !user?.isInvitation && tasksQuery
  );
  // Users's recent updates ( limited to 20 )
  const updatesQuery = useBaseQuery({
    collection: 'updates',
    showArchived: true,
    limit: 20,
    hideRestricted: !hasRestrictedAccess,
  })
    ?.where('meta.createdBy.id', '==', userId)
    .orderBy('meta.createdAt', 'desc');
  const [updates, updatesAreFetching] = useCollection<Update>(
    belongsAtLocation && !user?.isInvitation && updatesQuery
  );

  // Add or remove user/member from the current location
  // ( or add/remove as admin )
  const assignUserToLocation = useCallback(
    async ({ remove = false, admin = false } = {}) => {
      if (user && currentUser && currentLocation) {
        const batch = db.batch();

        const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;
        const updateValue = remove ? arrayRemove(user.id) : arrayUnion(user.id);
        const meta = generateUpdatedByDottedMeta(currentUser);
        batch.update(
          db.doc(currentLocation.docPath),
          admin ? { ...meta, admins: updateValue } : { ...meta, users: updateValue }
        );
        // If removing as admin, need to unassign all tasks at this location
        if (remove && organization) {
          const tasksToUnassign = await db
            .doc(organization.docPath)
            .collection('tasks')
            .where('locationId', '==', currentLocation.id)
            .where('assignedTo.id', '==', user.id)
            .get();
          for (const taskDoc of tasksToUnassign.docs) {
            batch.update(taskDoc.ref, {
              ...generateUpdatedByDottedMeta(currentUser),
              assignedTo: null,
            });
          }
        }
        await batch.commit();
      }
    },
    [currentUser, currentLocation, organization, user]
  );

  // Add or remove user/member from group
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [groupToRemove, setGroupToRemove] = useState<Doc<Group>>();
  const assignMemberToGroup = useCallback(
    async ({ groupId, remove = false }: { groupId?: string; remove?: boolean }) => {
      const group = groups?.find(({ id }) => id === groupId || id === selectedGroupId);
      if (user && currentUser && group && currentLocation) {
        const batch = db.batch();
        // Update location's 'groupMembers' map
        const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;
        batch.update(db.doc(currentLocation.docPath), {
          ...generateUpdatedByDottedMeta(currentUser),
          ['groupMembers.' + user.id]: remove ? arrayRemove(groupId) : arrayUnion(groupId),
        });
        // If removing from group, remove all assigned tasks in that group too
        if (remove && organization) {
          const tasksToUnassign = await db
            .doc(organization.docPath)
            .collection('tasks')
            .where('groups', 'array-contains', groupId)
            .where('assignedTo.id', '==', user.id)
            .get();
          for (const taskDoc of tasksToUnassign.docs) {
            batch.update(taskDoc.ref, {
              ...generateUpdatedByDottedMeta(currentUser),
              assignedTo: null,
            });
          }
        }
        await batch.commit();
      }
    },
    [groups, user, currentUser, currentLocation, selectedGroupId, organization]
  );

  // Archive/unarchive user
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false);
  const [incrementUsersQuote, setIncrementUsersQuote] = useState<IncrementQuote>();
  const [isFetchingQuote, setIsFetchingQuote] = useState(false);
  const archiveUser = useCallback(
    async ({ unarchive = false } = {}) => {
      setIsFetchingQuote(true);
      // Show modal to warn about pricing if not trialing and will incur costs
      // (and isn't already warning)
      // (this is similar to what's on the new user form)
      if (
        unarchive &&
        !showUnarchiveModal &&
        !isTrialing &&
        organization &&
        organization.users.length + 1 > organization._stripe.quantity
      ) {
        const { data: quote } = await httpsCallable<unknown, IncrementQuote>(
          fbFunctions,
          'getStripeIncrementUsersQuote'
        )({
          organizationId: organization.id,
        });
        if (quote) {
          setIncrementUsersQuote(quote);
          setShowUnarchiveModal(true);
          setIsFetchingQuote(false);
          return; // Exit early
        }
      }

      // Add/remove from users and archivedUsers array in organization
      if (currentUser && organization) {
        const batch = db.batch();
        const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;
        const addUser = arrayUnion(userId);
        const removeUser = arrayRemove(userId);
        batch.update(db.doc(organization.docPath), {
          ...generateUpdatedByDottedMeta(currentUser),
          users: unarchive ? addUser : removeUser,
          archivedUsers: unarchive ? removeUser : addUser,
        });
        // If archiving, remove all assigned tasks from that user
        if (!unarchive && organization) {
          const tasksToUnassign = await db
            .doc(organization.docPath)
            .collection('tasks')
            .where('assignedTo.id', '==', userId)
            .get();
          for (const taskDoc of tasksToUnassign.docs) {
            batch.update(taskDoc.ref, {
              ...generateUpdatedByDottedMeta(currentUser),
              assignedTo: null,
            });
          }
        }
        await batch.commit();
      }

      // Finish by resetting states
      setShowUnarchiveModal(false);
      setIsFetchingQuote(false);
    },
    [currentUser, isTrialing, organization, showUnarchiveModal, userId]
  );
  // We can only unarchive if
  const usersCount = organization?.users.length ?? 1;
  const paidForQuantity = stripe.quantity;
  const seatsRemaining = paidForQuantity - usersCount;
  const unarchiveDisabled = !seatsRemaining && (!isActive || willEnd);

  // Delete user
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [deleteUserConfirm, setDeleteUserConfirm] = useState('');
  const deleteUser = useCallback(() => {
    const fieldValue = firebase.firestore.FieldValue;
    const removeUser = fieldValue.arrayRemove(userId);
    currentUser &&
      organization &&
      db.doc(organization.docPath).update({
        ...generateUpdatedByDottedMeta(currentUser),
        users: removeUser,
        archivedUsers: removeUser,
      });
  }, [currentUser, organization, userId]);

  // Convert admin to group member (coming from permissions accordion)
  const [isConvertingToGroupMember, setIsConvertingToGroupMember] = useState(false);
  const convertToGroupMember = useCallback(async () => {
    if (!currentUser || !currentLocation || !organization) return;
    setIsConvertingToGroupMember(true);

    // Batch all the things
    const batch = db.batch();
    const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;
    const addUser = arrayUnion(userId);
    const removeUser = arrayRemove(userId);
    const docUpdateMeta = generateUpdatedByDottedMeta(currentUser);

    // Helper to handle each location
    const demoteToLocationGroupMember = async ({
      location,
      incompleteTaskGroups,
    }: {
      location: Doc<Location>;
      /** By default, we want to move into groups that the user has completed tasks in */
      incompleteTaskGroups: string[];
    }) => {
      // Groups that user might have been a part of if was previous group member
      const previousGroups = location.groupMembers[userId] ?? [];
      // Merged groups
      const groupsToAssign = uniq([...incompleteTaskGroups, ...previousGroups]);

      // Cap to max groups though (currently 10)
      const firstTenGroups = groupsToAssign.slice(0, MAX_GROUP_ASSIGNMENTS);
      const groupsBeyondTen = groupsToAssign.slice(MAX_GROUP_ASSIGNMENTS);

      // Update location with user as group member (and make sure doesn't exist as location admin)
      batch.update(db.doc(location.docPath), {
        ...docUpdateMeta,
        admins: removeUser,
        users: addUser,
        [`groupMembers.${userId}`]: firstTenGroups,
      });
      // Remove user from organization admins
      organization.admins.includes(userId) &&
        batch.update(db.doc(organization.docPath), {
          ...docUpdateMeta,
          admins: removeUser,
        });

      // If there are any groups beyond the max (10), we need to unassign all tasks there
      // Unassign all tasks that are in groups beyond the max
      for (const group of groupsBeyondTen) {
        const tasksToUnassign = await db
          .doc(organization.docPath)
          .collection('tasks')
          .where('groups', 'array-contains', group)
          .where('assignedTo.id', '==', userId)
          .where('isCompleted', '==', false)
          .where('locationId', '==', location.id)
          .orderBy('meta.createdAt', 'desc')
          .get();
        for (const taskDoc of tasksToUnassign.docs) {
          batch.update(taskDoc.ref, {
            ...docUpdateMeta,
            assignedTo: null,
          });
        }
      }
    };

    // Process current location (we already have fetched tasks for this location, so can use those)
    const incompleteTaskGroups =
      tasks
        ?.filter(t => !t.isCompleted)
        .map(t => t.groups)
        .flat() ?? [];
    await demoteToLocationGroupMember({ location: currentLocation, incompleteTaskGroups });

    // Loop through all other locations and process
    const otherLocations = locations?.filter(l => l.id !== currentLocation.id) ?? [];
    for (const otherLocation of otherLocations) {
      // Skip if user is already only group member of this other location
      if (
        !organization.admins.includes(userId) &&
        !otherLocation.admins.includes(userId) &&
        otherLocation.users.includes(userId)
      )
        continue;

      // Get tasks for this user at this other location
      const otherIncompleteTasks = await db
        .doc(organization.docPath)
        .collection('tasks')
        .where('assignedTo.id', '==', userId)
        .where('isCompleted', '==', false)
        .where('locationId', '==', otherLocation.id)
        .orderBy('meta.createdAt', 'desc')
        .get();
      const otherIncompleteTaskGroups = otherIncompleteTasks.docs.map(t => t.data().groups).flat();
      await demoteToLocationGroupMember({
        location: otherLocation,
        incompleteTaskGroups: otherIncompleteTaskGroups,
      });
    }

    // Commit
    await batch.commit();
    setIsConvertingToGroupMember(false);
  }, [currentLocation, currentUser, locations, organization, tasks, userId]);

  // Convert group member to admin (coming from permissions accordion
  const convertToAdmin = useCallback(async () => {
    if (!user || !currentUser || !currentLocation) return;
    // Simply add to loction admins
    await db.doc(currentLocation.docPath).update({
      admins: firebase.firestore.FieldValue.arrayUnion(userId),
      ...generateUpdatedByDottedMeta(currentUser),
    });
  }, [currentUser, currentLocation, user, userId]);

  // Just close sheet if user can't be found
  if (!user || !userProfile) return <Navigate to='/users' replace />;
  // <UserNotFoundSheet />

  return (
    <>
      {/* Header info ( and close button ) */}
      <SheetHeader primary>
        <Spacer height='12px' mobileHeight='8px' />
        <BackLink to='/users'>
          <Icon icon='close' />
        </BackLink>
        <Spacer height='16px' mobileHeight='8px' />

        <FlexRow>
          {/* Avatar */}
          {showNotebirb && <NotebirbAvatar notebirb={userProfile.notebirb} size={72} />}
          {!showNotebirb && <Avatar photo={userProfile.photo} size={96} isUser />}
          <Spacer width='16px' />
          {/* Details */}
          <UserDetails>
            {/* Name */}
            <UserName>{userProfile.name.full}</UserName>
            {/* Email */}
            <UserEmail>
              <a href={'mailto:' + userProfile.email}>{userProfile.email}</a>
            </UserEmail>
            {/* Role/groups */}
            <UserRole>
              {
                // User is archived
                user.isArchived ? (
                  <span className='danger'>Archived</span>
                ) : // Not at this location (and not organization admin)
                !belongsAtLocation ? (
                  <span className='danger'>Unassigned</span>
                ) : // Organization owner
                user.isOrganizationOwner ? (
                  'Organization Owner'
                ) : // Admin (Organization or Location)
                user.isOrganizationAdmin || user.isLocationAdmin ? (
                  'Admin'
                ) : // Group member label w/ groups list (or no groups warning)
                user.groups.length ? (
                  user.groups.map(groupId => {
                    const group = groups?.find(({ id }) => id === groupId);
                    return !!group && <GroupLabel key={groupId}>{group.name}</GroupLabel>;
                  })
                ) : (
                  <span className='danger'>No Groups</span>
                )
              }
            </UserRole>
          </UserDetails>
        </FlexRow>
        <Spacer height='24px' mobileHeight='12px' />
      </SheetHeader>

      {/* If is invitation */}
      {user.isInvitation && (
        <Scrollable endBuffer='0'>
          <Padding padding='48px 48px 0'>
            {/* Expired */}
            {invitationIsExpired && (
              <>
                <Heading>
                  {user.profile.name.first}&apos;s invite link <strong>has expired!</strong>
                </Heading>
                <Spacer height='24px' />
                <Copy>
                  {user.profile.name.first} didn&apos;t accept the invite link within{' '}
                  {INVITATION_EXPIRATION_DAYS} days, so it has been disabled for security purposes.
                  You can <strong>resend an invitation link to {user.profile.name.first}</strong> by
                  clicking the link below.
                </Copy>
              </>
            )}
            {/* Not Expired */}
            {!invitationIsExpired && (
              <>
                <Heading>
                  <strong>Waiting for {user.profile.name.first}</strong> to accept their invite
                </Heading>
                <Spacer height='24px' />
                <Copy>
                  Ask {user.profile.name.first} to{' '}
                  <strong>search for an email from Notebird</strong> sent{' '}
                  {invitationDateTime.toRelativeCalendar()}. If they still can&apos;t find it,
                  suggest looking in the <em>spam</em> folder
                  {showInvitationResend && ' or you can..'}.
                </Copy>
              </>
            )}
            <Spacer height='48px' />

            {/* Resend button */}
            {/* (show only if resent in this component's mount session or more than 5 minutes ago) */}
            {(showInvitationResend || invitationResent) && (
              <PrimaryButton
                outlined
                onClick={() => {
                  setInvitationResent(true);
                  db.doc(user.docPath).update({
                    'meta.createdAt': firebase.firestore.FieldValue.serverTimestamp(),
                  });
                }}
                disabled={invitationResent}
              >
                <strong>
                  {invitationResent ? 'Invitation link sent!' : 'Resend invitation link'}
                </strong>
              </PrimaryButton>
            )}
            <Spacer height='16px' />
            {/* Cancel/delete button */}
            <SecondaryButton dull onClick={() => setShowInviteCancelModal(true)}>
              Cancel invitation
            </SecondaryButton>
          </Padding>
          <Spacer height='48px' />
        </Scrollable>
      )}

      {/* If is archived */}
      {user.isArchived && (
        <>
          {/* Show loader if fetching quote */}
          <Loader show={isFetchingQuote} />
          <Scrollable endBuffer='0'>
            <Padding padding='48px 48px 0'>
              <Heading>
                This user <strong>has been archived</strong>!
              </Heading>
              <Spacer height='24px' />
              <Copy>
                {user.profile.name.first} <strong>no longer has access</strong> to this
                organization&apos;s Notebird account. To grant access and re-enable their account,
                click the button below.
              </Copy>
              <Spacer height='48px' />
              {/* Unarchive button */}
              <PrimaryButton
                disabled={isFetchingQuote || unarchiveDisabled}
                outlined
                onClick={() => archiveUser({ unarchive: true })}
              >
                <strong>Unarchive user</strong>
              </PrimaryButton>

              {/* Can't unarchive user warning */}
              {unarchiveDisabled && (
                <>
                  <Spacer height='12px' />
                  <Link to='/account/subscription'>
                    <strong>Update your payment method</strong>
                  </Link>{' '}
                  to unarchive this user.
                </>
              )}
              <Spacer height='16px' />

              {/* Delete user button */}
              <SecondaryButton
                disabled={isFetchingQuote}
                dull
                onClick={() => setShowDeleteUserModal(true)}
              >
                Delete user
              </SecondaryButton>
            </Padding>
            <Spacer height='48px' />
          </Scrollable>
        </>
      )}

      {/* Real user ( non-invite and not archived ) data */}
      {!user.isInvitation && !user.isArchived && (
        <Scrollable endBuffer='0'>
          {/* Not at this location */}
          {!belongsAtLocation && (
            <>
              <Padding padding='48px 48px 0'>
                {/* Not a part of any locations */}
                {!otherLocations.length && (
                  <Heading>
                    {user.profile.name.first} <strong>is not a part of any locations</strong>!
                  </Heading>
                )}
                {/* Has other locations */}
                {!!otherLocations.length && (
                  <Heading>
                    {user.profile.name.first}{' '}
                    <strong>is not a part of the {currentLocation?.name} location</strong>. Instead,
                    you can find them at:
                  </Heading>
                )}
              </Padding>
              <Spacer height='24px' />
              {/* Other locations list/links */}
              {otherLocations.map(({ id, name }) => (
                <GroupRow key={id}>
                  <Expanded>{name}</Expanded>
                  <SecondaryButton
                    onClick={() =>
                      setAppState({
                        locationId: id,
                        isMobileAsideOpen: false,
                        contentFilter: 'all',
                        groupId: null,
                      })
                    }
                  >
                    Go to location
                  </SecondaryButton>
                </GroupRow>
              ))}
              <Spacer height='32px' />
              <Divider />
              {/* Give access to location */}
              <Padding padding='32px 48px 0'>
                <div>
                  {otherLocations.length ? 'Or you can ' : 'You can '}
                  <strong>give {user.profile.name.first} access to this location</strong> by
                  clicking the button below...
                </div>
                <Spacer height='24px' />
                <PrimaryButton small outlined onClick={() => assignUserToLocation()}>
                  <strong>Add to {currentLocation?.name}</strong>
                </PrimaryButton>
              </Padding>

              <Spacer height='48px' />
            </>
          )}

          {/* At this location */}
          <Loader show={belongsAtLocation && tasksAreFetching && updatesAreFetching} />
          {belongsAtLocation && !tasksAreFetching && !updatesAreFetching && (
            <div className='fade-in'>
              {/* Role/Permissions/Actions accordion if user is not current user/owner AND...
                  1. Current user is organizatation admin
                    OR
                  2. Current user is location admin + user is group member */}
              {user.id !== currentUser?.id &&
                !user.isOrganizationOwner &&
                (isOrganizationAdmin || (isLocationAdmin && userIsGroupMember)) && (
                  <PermissionsAccordion
                    user={user}
                    selectedGroupId={selectedGroupId}
                    setSelectedGroupId={setSelectedGroupId}
                    setGroupToRemove={setGroupToRemove}
                    assignMemberToGroup={assignMemberToGroup}
                    setShowArchiveModal={setShowArchiveModal}
                    convertToGroupMember={convertToGroupMember}
                    convertToAdmin={convertToAdmin}
                    isConvertingToGroupMember={isConvertingToGroupMember}
                  />
                )}

              {/* All tasks for this user */}
              <TasksAccordion user={user} tasks={tasks || []} />

              {/* Recent updates by this user ( 10 max ) accordion */}
              <UpdatesAccordion user={user} updates={updates || []} />
            </div>
          )}
          <Spacer height='48px' mobileHeight='24px' />
        </Scrollable>
      )}

      {/* Modals */}

      {/* Cancel/delete invitation confirmation modal */}
      <FormModal
        position='absolute'
        formTitle='Cancel invitation'
        show={!!showInviteCancelModal}
        danger
        submitBtn={
          <span>
            <strong>Cancel</strong> invitation
          </span>
        }
        handleCancel={() => {
          setShowInviteCancelModal(false);
        }}
        handleSubmit={event => {
          event && event.preventDefault();
          if (currentUser && user.isInvitation && organization) {
            const batch = db.batch();
            // Remove invitation doc
            batch.delete(db.doc(user.docPath));
            // And remove user from organization's users array
            batch.update(db.doc(organization.docPath), {
              ...generateUpdatedByDottedMeta(currentUser),
              users: firebase.firestore.FieldValue.arrayRemove(userId),
            });
            batch.commit();
          }
          setShowInviteCancelModal(false);
        }}
      >
        <Padding padding='32px 32px 48px'>
          <DeleteWarning>
            Are you sure you want to
            <strong> cancel the invitation for {user.profile.name.full}</strong>?
          </DeleteWarning>
          {!isTrialing && !stripe.minQuantity ? (
            <>
              <DeleteCannotBeUndone>
                This will create an opening so you can add another user as a replacement at no
                additional cost.
              </DeleteCannotBeUndone>
              <DeleteExtra>
                If their spot isn&apos;t filled by the end of this billing cycle on{' '}
                {getFriendlyDate(stripe.currentPeriodEnd.toMillis())}, it will not be renewed on
                your subscription.
              </DeleteExtra>
            </>
          ) : (
            <DeleteExtra>You can reinvite this user at any time.</DeleteExtra>
          )}
        </Padding>
      </FormModal>

      {/* Unarchive user confirmation */}
      {/* Modal to confirm payment upon unarchive (when necessary) */}
      {/* (this is similar to what's on the new user form) */}
      <FormModal
        position='absolute'
        formTitle='Unarchive user'
        show={!!showUnarchiveModal}
        handleSubmit={event => {
          event && event.preventDefault();
          archiveUser({ unarchive: true });
        }}
        handleCancel={() => {
          setShowUnarchiveModal(false);
        }}
        submitBtn={
          <>
            Confirm payment and <strong>unarchive user</strong>
          </>
        }
        hideCancel
      >
        {!!incrementUsersQuote && (
          <Padding padding='32px 32px 48px'>
            <ConfirmSmall>You do not have any seats remaining</ConfirmSmall>
            <Spacer height='4px' />
            <ConfirmHead>
              Unarchive this user for <strong>${incrementUsersQuote.userPriceAtTier}/mo</strong>
            </ConfirmHead>
            {stripe.interval === 'year' && <ConfirmSubhead>Paid annually</ConfirmSubhead>}
            <Spacer height='32px' />
            <Divider padding={0} />
            <Spacer height='24px' />
            <ConfirmCopy>
              Your total subscription will be{' '}
              <strong>
                ${showCents(incrementUsersQuote.amount / 100)} a {stripe.interval}
              </strong>{' '}
              starting on{' '}
              {DateTime.fromMillis(stripe.currentPeriodEnd.toMillis()).toLocaleString(
                DateTime.DATE_SHORT
              )}
              .
            </ConfirmCopy>
            <Spacer height='24px' />
            <Divider padding={0} />
            <Spacer height='24px' />
            <ConfirmSmall>
              Today, your card will be charged a prorated amount of $
              {showCents(incrementUsersQuote.proration / 100)} to re-add this user through the end
              of your current billing cycle.
            </ConfirmSmall>
          </Padding>
        )}
      </FormModal>

      {/* Delete user confirmation */}
      <FormModal
        position='absolute'
        formTitle='Delete user'
        show={!!showDeleteUserModal}
        danger
        submitBtn={
          <span>
            <strong>Delete</strong> user
          </span>
        }
        handleCancel={() => {
          setShowDeleteUserModal(false);
        }}
        handleSubmit={event => {
          event && event.preventDefault();
          deleteUser();
          setShowDeleteUserModal(false);
        }}
        disabled={
          user.profile.name.full.trim().toLowerCase() !== deleteUserConfirm.trim().toLowerCase()
        }
      >
        <Padding padding='32px 32px 48px'>
          <DeleteWarning>
            Are you sure you want to <strong>permanently delete</strong> the user account for{' '}
            {user.profile.name.first}?
          </DeleteWarning>
          <DeleteCannotBeUndone>
            All updates and tasks created by {user.profile.name.first} will also be permanently
            deleted. This action <strong>can not</strong> be undone!
          </DeleteCannotBeUndone>
          <FormLabel htmlFor='delete-user-confirm'>
            Type <em>&quot;{user.profile.name.full}&quot;</em>&nbsp; to confirm deletion
          </FormLabel>
          <Input
            name='delete-user-confirm'
            placeholder="Type user's full name"
            value={deleteUserConfirm}
            onChange={event => {
              setDeleteUserConfirm(event.target.value);
            }}
          />
          <Spacer />
        </Padding>
      </FormModal>

      {/* For permissions accordion */}

      {/* Remove user from group confirmation */}
      <FormModal
        position='absolute'
        formTitle='Remove user from group'
        show={!!groupToRemove}
        danger
        submitBtn={
          <span>
            <strong>Remove</strong> user from group
          </span>
        }
        handleCancel={() => {
          setGroupToRemove(undefined);
        }}
        handleSubmit={event => {
          event?.preventDefault();
          !!groupToRemove && assignMemberToGroup({ groupId: groupToRemove.id, remove: true });
          setGroupToRemove(undefined);
        }}
      >
        <Padding padding='32px 32px 48px'>
          <DeleteWarning>
            Are you sure you want to{' '}
            <strong>
              remove {user.profile.name.first} from the {groupToRemove?.name} group
            </strong>
            ?
          </DeleteWarning>
          <DeleteCannotBeUndone>
            They <em>will lose access</em> to updates and assigned tasks in this group.
          </DeleteCannotBeUndone>
          <DeleteExtra>
            <strong>This action is reversible</strong> and can be undone at any time, although the
            user&apos;s current tasks will not be reassigned.
          </DeleteExtra>
        </Padding>
      </FormModal>

      {/* Archive user confirmation */}
      <FormModal
        position='absolute'
        formTitle='Archive user'
        show={!!showArchiveModal}
        danger
        submitBtn={
          <span>
            <strong>Archive</strong> user
          </span>
        }
        handleCancel={() => setShowArchiveModal(false)}
        handleSubmit={event => {
          event && event.preventDefault();
          archiveUser();
          setShowArchiveModal(false);
        }}
      >
        <Padding padding='32px 32px 48px'>
          <DeleteWarning>
            Are you sure you want to
            <strong> archive {user.profile.name.first}</strong>?
          </DeleteWarning>
          <DeleteCannotBeUndone>
            They <em>will lose all access</em> to this organization&apos;s Notebird account and all
            their tasks will be unassigned. However, their activity history will remain visible.
          </DeleteCannotBeUndone>
          <DeleteExtra>
            <strong>This action is reversible</strong> and can be undone at any time, although the
            user&apos;s current tasks will not be reassigned.
          </DeleteExtra>
          {organization && !isTrialing && !willEnd && !stripe.minQuantity && (
            <>
              <Spacer />
              <DeleteExtra>
                Archiving a user creates an opening so you can add another user as a replacement at
                no additional cost. If this spot isn&apos;t filled by the end of the billing cycle
                on {getFriendlyDate(organization._stripe.currentPeriodEnd.toMillis())}, it will not
                be renewed on your subscription.{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://guide.notebird.app/articles/3604878'
                >
                  Learn more.
                </a>
              </DeleteExtra>
            </>
          )}
        </Padding>
      </FormModal>

      {/* Modal Portal ( For 'Grant Admin Access' confirmation modal in PermissionsAccordion ) */}
      <div id='user-detail-sheet-modal-root' />
    </>
  );
};
export default UserDetailSheet;
