import { lazy, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { fbFunctions } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import { DateTime } from 'luxon';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useAuth from '@/contexts/auth';
import useOrganization from '@/contexts/organization';
import { useFilteredUsers } from '@/contexts/usersList';

import useInterval from '@/hooks/useInterval';
import usePageView from '@/hooks/usePageView';

import AnimatedFloatingSheet from '@/components/layout/AnimatedFloatingSheet';
import MobilePageHeader from '@/components/layout/MobilePageHeader';
import SheetHeader from '@/components/layout/SheetHeader';
import Sheet, { SheetScrollable, SheetsWrapper } from '@/components/layout/Sheets';

import UserDetailSheet from './UserDetailSheet';
import UserLinkItem from './UserLinkItem';

import NewUserForm from '@/components/forms/NewUserForm';

import PrimaryButton from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Icon from '@/components/common/Icon';
import LinkButton from '@/components/common/LinkButton';
import Margin from '@/components/common/Margin';
import NoWrap from '@/components/common/NoWrap';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';
import Wiggler from '@/components/common/Wiggler';

// Lazy load illustrations
const AddUserIllustrationGettingStarted = lazy(
  () => import('../../svg/AddUserIllustrationGettingStarted')
);
const AddUserIllustration = lazy(() => import('../../svg/AddUserIllustration'));

// Empty users default/samples
// const gettingStartedUsers = [
//   'Audra Albatross',
//   'Bill Buzzard',
//   'Cara Crane',
//   'Daniel Dodo',
//   'Fabien Finch',
//   'Gregg Gull',
//   'Hal Heron',
//   'Ibis Ives',
//   'Jay Johnson'
//   // 'Katy Kingfisher',
//   // 'Lucy Lark',
//   // 'Mandy Mallard',
//   // 'Nina Nightjar',
//   // 'Oren Osprey',
//   // 'Peyton Pheasant',
//   // 'Quail Quibbs',
//   // 'Robin Riggs',
//   // 'Sparrow Smith',
//   // 'Thomas Toucan',
//   // 'Wren Washington'
// ];

// Styles
const FlexWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 24px;
  text-align: center;
`;
const Header = styled.div`
  font-size: 20px;
  color: ${props => props.theme.textSecondary};
  strong {
    font-size: 28px;
    color: ${props => props.theme.textPrimary};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.primary500};
  }
`;
const Copy = styled.div`
  line-height: 1.25;
  color: ${props => props.theme.textFaded};
`;
const MobileAddButton = styled(PrimaryButton)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: ${({ theme }) => theme.shadow300};
  /* To make it perfectly round for some reason */
  i {
    width: 39px;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;

// Component
const UsersPage = () => {
  // Register page view
  usePageView({ title: 'My team | Notebird' });

  // userId from url
  const { id = '' } = useParams();

  // App state
  const { isOrganizationAdmin, locationId, groupName, isTrialing, isActive, willEnd } =
    useAppState();
  const setAppState = useSetAppState();

  // Context data
  const [auth, , authRefresh] = useAuth();
  const [organization] = useOrganization();
  const usersList = useFilteredUsers();

  // State
  const [showNewUserForm, setShowNewUserForm] = useState(false);

  // Helper statuses
  const stripe = organization?._stripe;
  const usersCount = organization?.users.length ?? 1;
  const paidForQuantity = stripe?.quantity ?? 1;
  const seatsRemaining = Math.max(paidForQuantity - usersCount, 0);
  const addUserDisabled = !seatsRemaining && (!isActive || willEnd);
  const isGettingStarted = usersCount <= 1 && !seatsRemaining && !addUserDisabled;
  const [sentVerificationEmail, setSentVerificationEmail] = useState(false);
  // Make sure to poll verified status of organization admin every 7 seconds
  // ( this is important for allowing adding users or not )
  useInterval(() => {
    isOrganizationAdmin && authRefresh();
  }, 7000);

  return (
    <SheetsWrapper>
      {/* Header for mobile */}
      <MobilePageHeader>My team</MobilePageHeader>

      {/* Users list (if admin of some kind for this location) */}
      <Sheet position='left'>
        <SheetHeader
          primary
          mainTitle='My Team'
          leadingIcon='supervised_user_circle'
          className='hidden-mobile'
        >
          {/* Todo: decide if we need to search users or not */}
          {/* {!isGettingStarted && (
              <SearchInput
                // Only autofocus search is has 10 or more users
                // autoFocus={peopleStream.docs.length > 9}
                name="userSearch"
                placeholder="Search all users"
                // value={searchValue}
                // forwardedRef={searchRef}
                onChange={() => {
                  // setSearchValue(event.target.value);
                }}
                // handleClear={() => {
                //   setSearchValue('');
                // }}
              />
            )} */}
        </SheetHeader>

        <SheetScrollable>
          <Spacer height='8px' />

          {/* Warning if no users in this group/view */}
          {!usersList.length && (
            <Padding padding='48px 32px' className='fade-in' key={groupName || undefined}>
              <NoneFoundHeader>
                No users in <NoWrap>{groupName || 'this group'}</NoWrap>
              </NoneFoundHeader>
              <NoneFoundCopy>
                Back to{' '}
                <LinkButton onClick={() => setAppState({ contentFilter: 'all', groupId: null })}>
                  <strong>all users</strong>
                </LinkButton>
              </NoneFoundCopy>
            </Padding>
          )}

          {/* Users list */}
          {usersList.map(user => {
            const userProfile = user.profile;

            // Show if admin role
            let subtitle: React.ReactNode = '';
            if (user.isOrganizationAdmin || user.isLocationAdmin) {
              subtitle = 'Admin';
            }
            if (user.isOrganizationOwner) {
              subtitle = (
                <span>
                  <strong>Organization owner</strong>
                </span>
              );
            }
            // Show invitation expiration status as subtitle (if invitation)
            if (user.isInvitation && user.invitationCreatedAt && user.invitationExpiresAt) {
              subtitle =
                user.invitationExpiresAt > DateTime.local() ? (
                  <span>
                    Invitation expires <strong>{user.invitationExpiresAt.toRelative()}</strong>
                  </span>
                ) : (
                  <span className='danger'>Invitation expired</span>
                );
            }
            return (
              <UserLinkItem
                to={'/users/' + user.id}
                key={user.id}
                photo={userProfile.photo}
                notebirb={userProfile.notebirb}
                preferNotebirb={userProfile.preferNotebirb}
                userName={userProfile.name.full}
                subtitle={subtitle}
                isArchived={user.isArchived}
              />
            );
          })}
          <Spacer height='48px' />

          {/* Fake users if only single user */}
          {/* Removed because no one else liked these... :'( */}
          {/* {usersCount === 1 &&
              gettingStartedUsers.map((name, i) => (
                <UserLinkItem
                  to={'/users'}
                  key={name}
                  photo={null}
                  notebirb={{
                    index: (i * 101) % notebirbsTotal,
                    colorwayIndex: (i * 173) % notebirbColorwaysTotal
                  }}
                  userName={name}
                  faded
                />
              ))} */}
        </SheetScrollable>
        {/* How we add users on mobile ( for organization admins ) */}
        {isOrganizationAdmin && (
          <MobileAddButton
            disabled={addUserDisabled || !auth?.emailVerified}
            onClick={() => setShowNewUserForm(true)}
            data-intercom-target='Add user button - mobile'
          >
            <Icon icon='add' iconSize='36px' />
          </MobileAddButton>
        )}
      </Sheet>

      {/* New user prompt (if organization admin) */}
      <Sheet position='right' className='hidden-mobile'>
        <SheetScrollable>
          {/* Add user illustration and CTA */}
          <FlexWrapperCentered>
            <Margin margin='-96px 0 0 ' />
            {/* Users illustration ( colored if getting started ) */}
            {isGettingStarted ? (
              <ErrorSuspendPlaceholder>
                <AddUserIllustrationGettingStarted
                  width='232px'
                  height='232px'
                  className='fade-in'
                  tint
                />
              </ErrorSuspendPlaceholder>
            ) : (
              <ErrorSuspendPlaceholder>
                <AddUserIllustration width='232px' height='232px' className='fade-in' />
              </ErrorSuspendPlaceholder>
            )}
            {/* Organization admin user addition button and supporting content */}
            {isOrganizationAdmin && (
              <>
                {/* Add user button ( wiggles if getting started ) */}
                <Wiggler disabled={!isGettingStarted || !auth?.emailVerified}>
                  <PrimaryButton
                    disabled={addUserDisabled || !auth?.emailVerified}
                    leadingIcon='group_add'
                    onClick={() => setShowNewUserForm(true)}
                    data-intercom-target='Add user button'
                  >
                    Add new user
                  </PrimaryButton>
                </Wiggler>
                <Spacer height='16px' />
                {/* Getting started copy */}
                {isGettingStarted && auth?.emailVerified && (
                  <>
                    <Spacer height='8px' />
                    <Header>
                      <strong>Add a user</strong> to collaborate together
                    </Header>
                    <Spacer height='16px' />
                    <Copy>
                      Other users in your organization can
                      <br />
                      share updates, tasks, and milestones
                    </Copy>
                  </>
                )}
                {/* Seats remaning count ( if any and not trialing ) */}
                {seatsRemaining > 0 && !isTrialing && (
                  <Copy>
                    <strong>
                      {seatsRemaining} of {paidForQuantity}
                    </strong>{' '}
                    seats available
                  </Copy>
                )}
                {/* Can't add user warning ( if not verified ) */}
                {!auth?.emailVerified &&
                  (sentVerificationEmail ? (
                    <Copy>
                      Email sent! <strong>Check your inbox</strong> and click on the link from
                      Notebird. If the email doesn&apos;t show up in a couple minutes, try looking
                      in your spam or junk-mail folder.
                    </Copy>
                  ) : (
                    <Copy>
                      You need to <strong>verify your email address</strong> before adding users.
                      Check your inbox for a link from Notebird or{' '}
                      <LinkButton
                        onClick={event => {
                          event.preventDefault();
                          httpsCallable(fbFunctions, 'verificationEmail')();
                          setSentVerificationEmail(true);
                        }}
                      >
                        <strong>resend verification email</strong>
                      </LinkButton>
                    </Copy>
                  ))}
                {/* Can't add user warning ( if disabled and auth warning not already showing ) */}
                {addUserDisabled && auth?.emailVerified && (
                  <Copy>
                    <Link to='/account/subscription/update-card'>
                      <strong>Update your payment method</strong>
                    </Link>{' '}
                    to invite additional users.
                  </Copy>
                )}
              </>
            )}
          </FlexWrapperCentered>
        </SheetScrollable>
      </Sheet>

      {/* Floating new user Form */}
      <AnimatedFloatingSheet forwardedKey={locationId ?? undefined}>
        {showNewUserForm && auth?.emailVerified && !addUserDisabled && (
          <NewUserForm handleCancel={() => setShowNewUserForm(false)} />
        )}
      </AnimatedFloatingSheet>

      {/* Floating user details */}
      <AnimatedFloatingSheet forwardedKey={id}>
        {id && <UserDetailSheet userId={id} />}
      </AnimatedFloatingSheet>
    </SheetsWrapper>
  );
};
export default UsersPage;
