import Organization, { OrganizationType } from '@/classes/Organization';

/** Total number of groups an organiztion can add in total */
export const MAX_GROUPS = 50;
/** Total number of groups a user, person, update, or task can be placed in */
export const MAX_GROUP_ASSIGNMENTS = 10;
/** Total number of update types an organization can have  */
export const MAX_UPDATE_TYPES = 40;
/** Total number of milestone types an organization can have  */
export const MAX_MILESTONE_TYPES = 30;

/** Default number of updates that show on the activity page
 *  ( and how many to increment by) */
export const DEFAULT_UPDATES_LIMIT = 20;
/** Maximum number of updates you can view on the activity page */
export const MAX_UPDATES_LIMIT = 200;

/** Maximum number of milliseconds since UNIX epoch.
 * Helpful, in some situations, for sorting dates */
export const MAX_MILLIS = 9999999999999;

/** We don't want to show years for birthdays/anniversaries
 * past a certain point. Mostly means year is unknown */
export const HIDE_BIRTHDAY_YEAR_PAST = 115;
export const HIDE_ANNIVERSARY_YEAR_PAST = 80;

/** Hours options (24-based) for local Early Bird Report to be sent  */
// export const EARLY_BIRD_LOCAL_HOUR_OPTIONS = ;
export const EARLY_BIRD_LOCAL_HOUR_OPTIONS = import.meta.env.DEV
  ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
  : [4, 5, 6, 7, 8, 9, 10, 11];

/** Group defaults based on organization type
 * ( originally from this document: https://docs.google.com/spreadsheets/d/1w7ZDff0ATXc6eOqOabv5s1ADimg4zxUZYXTs6HMwBqA )
 */
export const DEFAULT_ORGANIZATION_GROUPS: { [key in OrganizationType]: string[] } = {
  Ministry: [
    'General Care',
    'Homebound',
    'Hospital Visits',
    'Long-term Care',
    'Elderly Care',
    'Grief Care',
    'Prayer List',
  ],
  Synagogue: [
    'Bereaved',
    'Hospital Visitation',
    'Elderly Care',
    'Long-term Issue',
    'Acute Issue',
    'Homebound',
    'General Care',
  ],
  School: ['Teachers', 'Support Staff', 'Parents & Guardians', 'Community Partners'],
  Nonprofit: ['Clients', 'Strategic Partners', 'Volunteers', 'Contractors', 'Donors'],
  Company: ['Current Customers', 'Prospective Customers', 'Vendors'],
  Personal: ['Friends', 'Co-workers', 'Family', 'Neighbors', 'Acquaintances'],
  Other: ['Friends', 'Co-workers', 'Family', 'Neighbors', 'Acquaintances'],
};

/** Onboarding vbalues */
export const ORGANIZATION_TYPE_OPTIONS: { id: string; label: string; value: OrganizationType }[] = [
  { id: 'typeMinistry', label: 'Church or Ministry', value: 'Ministry' },
  { id: 'typeSynagogue', label: 'Synagogue', value: 'Synagogue' },
  { id: 'typeSchool', label: 'School', value: 'School' },
  { id: 'typeNonprofit', label: 'General Non-profit', value: 'Nonprofit' },
  { id: 'typeCompany', label: 'Company or Business', value: 'Company' },
  { id: 'typePersonal', label: 'Personal Use', value: 'Personal' },
  { id: 'typeOther', label: 'Other', value: 'Other' },
];
export const REFERRED_BY_OPTIONS = [
  'Friend or Colleague',
  'Facebook',
  'Google Search',
  'Blog or Article',
  // 'Mail',
];
// Cms softare options
// ( 'Excel and Spreadsheets' + 'Other' options added to all in the component below )
export const CMS_OPTIONS: { [key in OrganizationType]: string[] } = {
  Ministry: [
    'ACS',
    'Breeze',
    'Church Community Builder',
    'Church Windows',
    'FellowshipOne',
    'Planning Center',
  ],
  Synagogue: ['ShulCloud', 'Chaverware', 'Rakefet', 'MM2000'],
  School: ['Fedena', 'Script', 'Stars Campus Solutions', 'Frontline Education'],
  Nonprofit: ['Blackbaud', 'DonorPerfect', 'Bloomerang', 'Keela'],
  Company: ['Gusto', 'BambooHR', 'Zenefits', 'Salesforce'],
  Personal: ['Google Contacts', 'iPhone Contacts', 'Airtable', 'Monday.com'],
  Other: ['Google Contacts', 'iPhone Contacts', 'Airtable', 'Monday.com'],
};

/** Preference defaults based on organization type
 * ( originally from this document: https://docs.google.com/spreadsheets/d/1w7ZDff0ATXc6eOqOabv5s1ADimg4zxUZYXTs6HMwBqA )
 * ( First 3 in a specific order for demo updates as declared in `generateDemoData` )
 */
const DEFAULT_ORGANIZATION_UPDATE_TYPES: { [key in OrganizationType]: string[] } = {
  Ministry: [
    'General Check-In', // Cara
    'Surgery', // Greg
    'Illness', // Robin
    'Counseling Session',
    'Care Follow-Up',
    'Prayer Request',
    'Communion Visit',
    'Home Visit',
    'Benevolence Request',
    'Funeral',
  ],
  Synagogue: [
    'General Update', // Cara
    'Surgery', // Greg
    'Illness', // Robin
    'Job/Employment',
    'Engagement',
    'Pregnancy',
    'Social/Emotional',
    'Long Term Health',
    'Marital',
    'Funeral',
    'Mental Health',
  ],
  School: [
    'Recognition', // Cara
    'Teacher Performance', // Greg
    'Follow-up', // Robin
    'Intentional Check-In',
    'Life Event',
    'Life Update',
    'Coaching For Success',
    'Crucial Conversation',
    'Mentor Moment',
    'Classroom Management',
    'Behavior Intervention',
    'Student Update',
    'Home Visit',
  ],
  Nonprofit: [
    'Life Update', // Cara
    'Donor Engagement', // Greg
    'Check-in', // Robin
    'Follow-up',
    'Life Event',
    'Recognition',
    'Employee Review',
    'Coaching For Success',
    'Crucial Conversation',
    'Mentor Moment',
  ],
  Company: [
    'Check-in', // Cara
    'Life Update', // Greg
    'Follow-up', // Robin
    'Life Event',
    'Employee Recognition',
    'Employee Review',
    'Coaching for Success',
    'Crucial Conversation',
    'Customer Feedback',
  ],
  Personal: [
    'Life Event', // Cara
    'Life Update', // Greg
    'Check-in', // Robin
    'Follow-up',
  ],
  Other: [
    'Life Event', // Cara
    'Life Update', // Greg
    'Check-in', // Robin
    'Follow-up',
  ],
};
const DEFAULT_ORGANIZATION_MILESTONE_TYPES: { [key in OrganizationType]: string[] } = {
  Ministry: [
    'Birth',
    'Passing of a Loved One',
    'Divorce',
    'Graduation',
    'New Job',
    'Job Loss',
    'Sobriety Date',
    'Completed New Member Class',
    'Baptism',
    'Answered Prayer',
    'Care Package Sent',
    'Handwritten Letter Sent',
    'Deceased',
  ],
  Synagogue: [
    'Birth',
    'Passing of a Loved One',
    'Yahrzeit',
    'Divorce',
    'Bar/Bat Mitzvah',
    'Resignation',
    'Deceased',
  ],
  School: [
    'Birth',
    'Passing of a Loved One',
    'Divorce',
    'Graduation',
    'Teacher of the Month',
    'Student of the Month',
    'Completed Certification',
  ],
  Nonprofit: [
    'Birth',
    'Passing of a Loved One',
    'Divorce',
    'Graduation',
    'Job Promotion',
    'Completed Volunteer Training',
    'First Financial Donation',
    'Completed Background Check',
  ],
  Company: [
    'Birth',
    'Passing of a Loved One',
    'Job Promotion',
    'First Commission',
    '90 Day Review',
    '180 Day Review',
    'Leadership Course Completed',
    'Continued Education Completed',
  ],
  Personal: ['Birth', 'Passing of a Loved One', 'Divorce', 'Graduation', 'New Job', 'Job Loss'],
  Other: ['Birth', 'Passing of a Loved One', 'Divorce', 'Graduation', 'New Job', 'Job Loss'],
};
// Little helper to assemble for most organization types
const generateDefaults = (type: OrganizationType): Organization['preferences'] => ({
  updateTypes: DEFAULT_ORGANIZATION_UPDATE_TYPES[type],
  milestoneTypes: DEFAULT_ORGANIZATION_MILESTONE_TYPES[type],
  pulseIndicator: new Organization().preferences.pulseIndicator,
  joinDateIndicator: new Organization().preferences.joinDateIndicator,
});
export const DEFAULT_ORGANIZATION_PREFERENCES: {
  [key in OrganizationType]: Organization['preferences'];
} = {
  Ministry: generateDefaults('Ministry'),
  Synagogue: generateDefaults('Synagogue'),
  School: {
    ...generateDefaults('School'),
    joinDateIndicator: { ...new Organization().preferences.joinDateIndicator, enabled: true },
  },
  Nonprofit: generateDefaults('Nonprofit'),
  Company: {
    ...generateDefaults('Company'),
    pulseIndicator: { enabled: true, first: 14, second: 21 },
  },
  Personal: generateDefaults('Personal'),
  Other: generateDefaults('Other'),
};
