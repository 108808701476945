import { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
// Libs
import { db } from '@/lib/firebase';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';
// Classes
import Organization from '@/classes/Organization';
// Hooks
import useDebouncedState from '@/hooks/useDebouncedState';
// Context data
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';
// Common components
import Accordion from '@/components/common/Accordion';
import Padding from '@/components/common/Padding';
import { Toggle, Input } from '@/components/formElements/FormElements';
import Spacer from '@/components/common/Spacer';
import Icon from '@/components/common/Icon';
import DescriptionBlock from '@/components/common/DescriptionBlock';

// Styles
const TimeRangeTitle = styled.div`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.textPrimary};
  label {
    margin-right: 4px;
    cursor: pointer;
  }
`;
const TimeRangeFlexRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 0;

  font-size: 18px;
  font-weight: bold;

  color: ${({ theme }) => theme.textTertiary};
  .warning {
    position: absolute;
    width: 6px;
    height: calc(100% - 8px);
    top: 4px;

    border-radius: 2px;
    &.warningOne {
      background-color: ${({ theme }) => theme.danger400};
      opacity: 0.4;
    }
    &.warningTwo {
      background-color: ${({ theme }) => theme.danger500};
    }
  }
  input {
    margin: 0 8px;
    padding: 9px;
    width: 54px;
  }
  i {
    margin-right: 8px;
    color: ${({ theme }) => theme.textPrimary};
    &.faded {
      color: ${({ theme }) => theme.avatarText};
    }
  }
`;

// Component
const CustomTimeRangesAccordion = ({ show }: { show: boolean }) => {
  // Organization prefs from database
  const [user] = useUser();
  const [organization] = useOrganization();
  const {
    preferences: { pulseIndicator, joinDateIndicator },
  } = organization || { ...new Organization() };

  // Debounced pulse updaters
  // Pulse - enabled
  const dbSetPulseEnabled = useCallback(
    (debouncedPulseEnabled: boolean) => {
      user &&
        organization &&
        db.doc(organization.docPath).update({
          ...generateUpdatedByDottedMeta(user),
          'preferences.pulseIndicator.enabled': debouncedPulseEnabled,
        });
    },
    [user, organization]
  );
  const [pulseEnabled, setPulseEnabled] = useDebouncedState({
    defaultValue: pulseIndicator.enabled,
    debouncedCallback: dbSetPulseEnabled,
  });
  // Pulse - first
  const dbSetPulseFirst = useCallback(
    (debouncedPulseFirst: string) => {
      const max = 499;
      const min = 1;
      let days = parseInt(debouncedPulseFirst);
      // Reset to current if not a number
      days = isNaN(days) ? pulseIndicator.first : days;
      // Ensure minimum
      days = Math.max(min, days);
      // Ensure maximum
      days = Math.min(max, days);
      // Set db pref
      user &&
        organization &&
        db.doc(organization.docPath).update({
          ...generateUpdatedByDottedMeta(user),
          'preferences.pulseIndicator.first': days,
        });
      // Update second val if lesser or equal
      if (pulseIndicator.second <= days) {
        setPulseSecond((days + 1).toString());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pulseIndicator.first, pulseIndicator.second, organization]
  );
  const [pulseFirst, setPulseFirst] = useDebouncedState({
    defaultValue: pulseIndicator.first.toString(),
    shouldSyncDefault: true,
    wait: 1500,
    debouncedCallback: dbSetPulseFirst,
  });
  // Pulse - second
  const dbSetPulseSecond = useCallback(
    (debouncedPulseSecond: string) => {
      const max = 500;
      const min = 2;
      let days = parseInt(debouncedPulseSecond);
      // Reset to current if not a number
      days = isNaN(days) ? pulseIndicator.second : days;
      // Ensure minimum
      days = Math.max(min, days);
      // Ensure maximum
      days = Math.min(max, days);
      // Set db pref
      user &&
        organization &&
        db.doc(organization.docPath).update({
          ...generateUpdatedByDottedMeta(user),
          'preferences.pulseIndicator.second': days,
        });
      // Update first val if greater or equal
      if (pulseIndicator.first >= days) {
        setPulseFirst((days - 1).toString());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pulseIndicator.second, pulseIndicator.first, user, organization]
  );
  const [pulseSecond, setPulseSecond] = useDebouncedState({
    defaultValue: pulseIndicator.second.toString(),
    shouldSyncDefault: true,
    wait: 1500,
    debouncedCallback: dbSetPulseSecond,
  });

  // Debounced join date updaters
  // Join date - enabled
  const dbSetJoinDateEnabled = useCallback(
    (debouncedJoinDateEnabled: boolean) => {
      user &&
        organization &&
        db.doc(organization.docPath).update({
          ...generateUpdatedByDottedMeta(user),
          'preferences.joinDateIndicator.enabled': debouncedJoinDateEnabled,
        });
    },
    [user, organization]
  );
  const [joinDateEnabled, setJoinDateEnabled] = useDebouncedState({
    defaultValue: joinDateIndicator.enabled,
    debouncedCallback: dbSetJoinDateEnabled,
  });
  // Join date - first
  const dbSetJoinDateFirst = useCallback(
    (debouncedJoinDateFirst: string) => {
      const max = 500;
      const min = 1;
      let days = parseInt(debouncedJoinDateFirst);
      // Reset to current if not a number
      days = isNaN(days) ? joinDateIndicator.first : days;
      // Ensure minimum
      days = Math.max(min, days);
      // Ensure maximum
      days = Math.min(max, days);
      // Set db pref
      user &&
        organization &&
        db.doc(organization.docPath).update({
          ...generateUpdatedByDottedMeta(user),
          'preferences.joinDateIndicator.first': days,
        });
      // Update second val if lesser or equal
      if (joinDateIndicator.second <= days) {
        setJoinDateSecond((days + 1).toString());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [joinDateIndicator.first, joinDateIndicator.second, user, organization]
  );
  const [joinDateFirst, setJoinDateFirst] = useDebouncedState({
    defaultValue: joinDateIndicator.first.toString(),
    shouldSyncDefault: true,
    wait: 1500,
    debouncedCallback: dbSetJoinDateFirst,
  });
  // Join date - second
  const dbSetJoinDateSecond = useCallback(
    (debouncedJoinDateSecond: string) => {
      const max = 999;
      const min = 2;
      let days = parseInt(debouncedJoinDateSecond);
      // Reset to current if not a number
      days = isNaN(days) ? joinDateIndicator.second : days;
      // Ensure minimum
      days = Math.max(min, days);
      // Ensure maximum
      days = Math.min(max, days);
      // Set db pref
      user &&
        organization &&
        db.doc(organization.docPath).update({
          ...generateUpdatedByDottedMeta(user),
          'preferences.joinDateIndicator.second': days,
        });
      // Update first val if greater or equal
      if (joinDateIndicator.first >= days) {
        setJoinDateFirst((days - 1).toString());
      }
    },
    [joinDateIndicator.first, joinDateIndicator.second, user, organization, setJoinDateFirst]
  );
  const [joinDateSecond, setJoinDateSecond] = useDebouncedState({
    defaultValue: joinDateIndicator.second.toString(),
    shouldSyncDefault: true,
    wait: 1500,
    debouncedCallback: dbSetJoinDateSecond,
  });

  return (
    <Accordion show={show}>
      <DescriptionBlock>
        The Pulse &amp; Join date indicators help you better connect with people.{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://guide.notebird.app/articles/8059693'
        >
          Learn more
        </a>
      </DescriptionBlock>

      <Padding padding='24px 32px 32px'>
        {/* Pulse */}
        <TimeRangeTitle>
          <Toggle
            id='pulseToggle'
            checked={pulseEnabled}
            onChange={e => {
              setPulseEnabled(e.currentTarget.checked);
            }}
          />{' '}
          <label htmlFor='pulseToggle'>Pulse indicators</label>
        </TimeRangeTitle>
        <Accordion show={pulseEnabled} hideShadow>
          {/* Pulse one */}
          <TimeRangeFlexRow>
            <div className='warning warningOne' />
            <Spacer width='18px' />
            First warning after
            <Input
              name='pulseOne'
              value={pulseFirst}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPulseFirst(e.currentTarget.value);
              }}
            />{' '}
            days
          </TimeRangeFlexRow>
          {/* Pulse two */}
          <TimeRangeFlexRow>
            <div className='warning warningTwo' />
            <Spacer width='18px' />
            Second warning after
            <Input
              name='pulseTwo'
              value={pulseSecond}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPulseSecond(e.currentTarget.value);
              }}
            />{' '}
            days
          </TimeRangeFlexRow>
          <Spacer height='16px' />
        </Accordion>
        <Spacer height='16px' />

        {/* Join date */}
        <TimeRangeTitle>
          <Toggle
            id='joinDateToggle'
            checked={joinDateEnabled}
            onChange={e => {
              setJoinDateEnabled(e.currentTarget.checked);
            }}
          />{' '}
          <label htmlFor='joinDateToggle'>Join date indicators</label>
        </TimeRangeTitle>
        <Accordion show={joinDateEnabled} hideShadow>
          {/* Join one */}
          <TimeRangeFlexRow>
            <Icon icon='fiber_new' iconSize='30px' />
            First status until
            <Input
              name='joinOne'
              value={joinDateFirst}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setJoinDateFirst(e.currentTarget.value);
              }}
            />{' '}
            days
          </TimeRangeFlexRow>
          {/* Join two */}
          <TimeRangeFlexRow>
            <Icon icon='fiber_new' iconSize='30px' className='faded' />
            Second status until
            <Input
              name='joinTwo'
              value={joinDateSecond}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setJoinDateSecond(e.currentTarget.value);
              }}
            />{' '}
            days
          </TimeRangeFlexRow>
        </Accordion>
      </Padding>
    </Accordion>
  );
};

// Export
export default CustomTimeRangesAccordion;
