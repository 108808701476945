import { lazy, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { LocationCounts } from '@/classes/Counts';
import Organization from '@/classes/Organization';

import { db, fbAnalytics, fbFunctions } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';

import { pluralize } from '@/lib/helpers';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';
import { usePostHog } from 'posthog-js/react';

import useAppState from '@/contexts/appState';
import useLocations from '@/contexts/locations';
import useOrganization from '@/contexts/organization';

import useDoc from '@/hooks/useDoc';
import usePageView from '@/hooks/usePageView';

import AnimatedFloatingSheet from '@/components/layout/AnimatedFloatingSheet';
import MobilePageHeader from '@/components/layout/MobilePageHeader';
import SheetHeader from '@/components/layout/SheetHeader';
import Sheet, { SheetScrollable, SheetsWrapper } from '@/components/layout/Sheets';

import LocationsAccordion from './LocationsAccordion';
import OrganizationAccordion from './OrganizationAccordion';
import PlanInfoSheet from './PlanInfoSheet';
import SubscribeModal from './SubscribeModal';
import UpdateCardModal from './UpdateCardModal';

import FormInputGroup from '@/components/formElements/styled/FormInputGroup';

import Accordion from '@/components/common/Accordion';
import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import Divider from '@/components/common/DividerTwo';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Loader from '@/components/common/Loader';
import Margin from '@/components/common/Margin';
import Modal, {
  DeleteCannotBeUndone,
  DeleteModal,
  DeleteWarning,
} from '@/components/common/Modals';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';
import Wiggler from '@/components/common/Wiggler';

// Lazy load integration stuff and illustration
const AccountIllustration = lazy(() => import('../../svg/AccountIllustration'));
const MinistryIntegrationsAccordion = lazy(() => import('./MinistryIntegrationsAccordion'));
const SynagogueIntegrationsAccordion = lazy(() => import('./SynagogueIntegrationsAccordion'));
const BreezeModal = lazy(() => import('./BreezeModal'));

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '');

// Styles
const FlexWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;

  div.header {
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.textSecondary};
    margin-bottom: 8px;
  }
  div.copy {
    color: ${props => props.theme.textFaded};
    line-height: 1.25;
  }
`;
const UpgradeSection = styled.div`
  text-align: center;
  padding: 32px 32px 24px;
`;
const UpgradeHeader = styled.div<{ danger?: boolean }>`
  margin-bottom: 8px;
  font-size: 18px;
  color: ${props => props.theme.textSecondary};
  strong {
    font-size: 26px;
    color: ${props => props.theme.textPrimary};
    text-decoration: underline;
    text-decoration-color: ${({ danger, theme }) => (danger ? theme.danger400 : theme.primary500)};
  }
`;
const UpgradeCopy = styled.div`
  margin-bottom: 24px;
  line-height: 1.25;
  font-size: 18px;
  color: ${props => props.theme.textFaded};
`;
const SubscriptionRow = styled.div`
  display: flex;
  padding: 16px 0;
  align-items: center;
  font-weight: bold;

  .label {
    flex: 1;
    margin-right: 12px;
    color: ${({ theme }) => theme.textFaded};
    .value {
      margin-top: 4px;
      font-size: 18px;
      color: ${({ theme }) => theme.textPrimary};
      &.danger {
        color: ${({ theme }) => theme.danger500};
      }
      .extra {
        margin-top: 2px;
        font-size: 14px;
        color: ${({ theme }) => theme.textTertiary};
        font-weight: normal;
      }
    }
  }
  .trailing {
    flex-shrink: 0;
    text-align: right;
  }
`;
// const UpgradeDisclaimer = styled.div`
//   margin-bottom: 24px;
//   line-height: 1.25;
//   font-size: 14px;
//   color: ${props => props.theme.textFaded};
// `;

// Component
const AccountPage = () => {
  const { accordion, modal } = useParams<'accordion' | 'modal'>();
  const navigate = useNavigate();

  // Register page view
  usePageView({
    title: `Account${accordion ? ` - ${capitalize(accordion)}` : ''}${
      modal ? ` - ${capitalize(modal)}` : ''
    } | Notebird`,
  });

  // App state
  const {
    organizationId,
    isOrganizationAdmin,
    isActive,
    isTrialing,
    daysRemaining,
    isCanceled,
    hasCardError,
    willEnd,
  } = useAppState();
  const posthog = usePostHog();

  // Context data
  const [organization] = useOrganization();
  const stripe = organization?._stripe ?? { ...new Organization()._stripe };
  const [locations] = useLocations();

  // Delete location stuff
  const [deleteLocationId, setDeleteLocationId] = useState<string | boolean>(false);
  const deleteLocation = locations?.find(location => location.id === deleteLocationId);
  const deleteLocationName = deleteLocation ? deleteLocation.name : '';
  const [deleteLocationConfirm, setDeleteLocationConfirm] = useState('');
  const [deleteLocationCounts] = useDoc<LocationCounts>(
    !!organizationId &&
      deleteLocationId &&
      `organizations/${organizationId}/_counts/${deleteLocationId}`
  );

  // Subscribe stuff
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showPlanInfo, setShowPlanInfo] = useState(false);
  const [showUpdateCardModal, setShowUpdateCardModal] = useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const noStripeCard = !stripe.cardLast4 || !stripe.cardExpMonth || !stripe.cardExpYear;
  const dateTimeCurrentPeriodEnd = DateTime.fromMillis(stripe.currentPeriodEnd.toMillis());
  const roundedDaysRemaining = Math.round(daysRemaining);
  // Determine what subscription 'status' to show
  let statusLabel: 'Will end' | 'Active' | 'Card error' | 'Canceled' = 'Canceled';
  let statusLabelDanger = true;
  if (isActive) {
    statusLabel = willEnd ? 'Will end' : 'Active';
    statusLabelDanger = false;
  } else if (hasCardError) {
    statusLabel = 'Card error';
  }

  // Breeze integration popup
  const [showBreezeModal, setShowBreezeModal] = useState(false);

  // When modal param is set, open up specified modal then remove from history
  useEffect(() => {
    if (modal) {
      switch (modal) {
        case 'subscribe':
          setShowSubscribeModal(true);
          break;
        case 'payment-history':
          setShowPlanInfo(true);
          break;
        case 'update-card':
          setShowUpdateCardModal(true);
          break;
        case 'breeze':
          setShowBreezeModal(true);
          break;
        case 'close-breeze':
          setShowBreezeModal(false);
          break;
      }
      // Redirect to same path, just without the modal param
      navigate('/account/' + (accordion || ''), { replace: true });
    }
  }, [modal, accordion, navigate]);

  // On load, if not trialing and accordion isn't set,
  // open Organization profile ( if organization admin)
  useEffect(() => {
    !isTrialing &&
      !accordion &&
      isOrganizationAdmin &&
      navigate('/account/organization', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load stripe
  const [stripeLoaded, setStripeLoaded] = useState(false);
  useEffect(() => {
    // Only load if not already loaded
    if (!window['Stripe']) {
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      script.onload = () => {
        setStripeLoaded(true);
      };
      document.body.appendChild(script);
    } else {
      setStripeLoaded(true);
    }
  }, []);

  // If not organization admin, just get outta here!
  if (!isOrganizationAdmin) return <Navigate to='/people' replace />;

  return (
    <SheetsWrapper>
      {/* Header for mobile */}
      <MobilePageHeader hideGroupsAsideToggle>{organization?.profile.name}</MobilePageHeader>

      {/* Left - illustration/copy */}
      <Sheet position='left' className='hidden-mobile'>
        <FlexWrapperCentered>
          <Margin margin='-64px 0 0' />
          <ErrorSuspendPlaceholder>
            <AccountIllustration width='232px' height='232px' className='fade-in' />
          </ErrorSuspendPlaceholder>
          <Spacer height='48px' />
          <div className='header'>Keep your account up to date </div>
          <div className='copy'>
            Manage your organization&apos;s profile and subscription settings
          </div>
        </FlexWrapperCentered>
      </Sheet>

      {/* Right - settings/accordions*/}
      <Sheet position='right'>
        <SheetScrollable>
          {/* Organization */}
          <SheetHeader
            expandable
            active={accordion === 'organization'}
            sticky
            leadingIcon='store'
            mainTitle='Organization profile'
            subtitle="Your organization's profile information"
            onClick={() =>
              navigate(accordion === 'organization' ? '/account' : '/account/organization', {
                replace: true,
              })
            }
          />
          <OrganizationAccordion show={accordion === 'organization'} />

          {/* Locations... but only if has multiple already  */}
          {/* ( we're trying to get away from locations, but these could still be available for
                existing accounts or multi-campus PCO syncs ) */}
          {!!locations && locations.length > 1 && (
            <>
              <SheetHeader
                expandable
                active={accordion === 'locations'}
                sticky
                leadingIcon='scatter_plot'
                mainTitle='Locations'
                subtitle='Different locations or parts of your organization'
                onClick={() =>
                  navigate(accordion === 'locations' ? '/account' : '/account/locations', {
                    replace: true,
                  })
                }
              />
              <LocationsAccordion
                show={accordion === 'locations'}
                setDeleteLocationId={setDeleteLocationId}
              />
            </>
          )}

          {/* Ministry Integrations ( only for Planning Center right now ) */}
          {organization?.profile.type === 'Ministry' && (
            <>
              <SheetHeader
                expandable
                active={accordion === 'integrations'}
                sticky
                leadingIcon='sync_alt'
                mainTitle='Integrations'
                subtitle='Sync your account with other platforms'
                onClick={() =>
                  navigate(accordion === 'integrations' ? '/account' : '/account/integrations', {
                    replace: true,
                  })
                }
              />
              <ErrorSuspendPlaceholder
                fallback={
                  <>
                    <Loader show={true} />
                    <Spacer height='48px' />
                  </>
                }
              >
                <MinistryIntegrationsAccordion show={accordion === 'integrations'} />
              </ErrorSuspendPlaceholder>
            </>
          )}
          {/* Synagogue Integrations ( only for linked ShulCloud accounts right now ) */}
          {organization?.profile.type === 'Synagogue' && (
            // {organization?._integration?.service === 'shulCloud' && (
            <>
              <SheetHeader
                expandable
                active={accordion === 'integrations'}
                sticky
                leadingIcon='sync_alt'
                mainTitle='Integrations'
                subtitle='Sync your account with other platforms'
                onClick={() =>
                  navigate(accordion === 'integrations' ? '/account' : '/account/integrations', {
                    replace: true,
                  })
                }
              />
              <ErrorSuspendPlaceholder
                fallback={
                  <>
                    <Loader show={true} />
                    <Spacer height='48px' />
                  </>
                }
              >
                <SynagogueIntegrationsAccordion show={accordion === 'integrations'} />
              </ErrorSuspendPlaceholder>
            </>
          )}

          {/* Subscription */}
          {/* Redirect to open if trialing and no accordion is active */}
          {isTrialing && !accordion && <Navigate to='/account/subscription' replace />}
          <SheetHeader
            expandable={isTrialing ? accordion !== 'subscription' : true}
            active={accordion === 'subscription'}
            sticky
            leadingIcon='settings_backup_restore'
            mainTitle='Subscription'
            subtitle='Payment method and billing info'
            onClick={() =>
              navigate(accordion === 'subscription' ? '/account' : '/account/subscription', {
                replace: true,
              })
            }
          />
          <Accordion show={accordion === 'subscription'}>
            {/* Trialing */}
            {isTrialing && (
              <UpgradeSection>
                {isActive ? (
                  // Still trialing
                  <>
                    <UpgradeHeader>
                      <strong>
                        {roundedDaysRemaining === 0 && 'Less than 1 day'}
                        {pluralize({
                          root: '{x} day',
                          count: roundedDaysRemaining,
                        })}{' '}
                        left
                      </strong>{' '}
                      on your trial!
                    </UpgradeHeader>
                    <UpgradeCopy>Upgrade now to avoid service interruption</UpgradeCopy>
                  </>
                ) : (
                  // Trial ended
                  <>
                    <UpgradeHeader danger>
                      <strong>Your trial has ended!</strong>
                    </UpgradeHeader>
                    <UpgradeCopy>Upgrade now to continue using Notebird</UpgradeCopy>
                  </>
                )}
                <Wiggler>
                  <PrimaryButton
                    onClick={e => {
                      e.preventDefault();
                      setShowSubscribeModal(true);
                    }}
                  >
                    <strong>Upgrade</strong> subscription
                  </PrimaryButton>
                </Wiggler>
                <Spacer height='12px' />
              </UpgradeSection>
            )}

            {/* When not trialing */}
            {!isTrialing && (
              <Padding padding='16px 64px 24px'>
                {/* Status */}
                <SubscriptionRow>
                  <div className='label'>
                    Status
                    <div className={statusLabelDanger ? 'value danger' : 'value'}>
                      {statusLabel}
                    </div>
                  </div>
                  {/* CTA when canceled */}
                  {isCanceled && (
                    <div className='trailing'>
                      <PrimaryButton
                        small
                        onClick={e => {
                          e.preventDefault();
                          setShowSubscribeModal(true);
                        }}
                      >
                        <strong>Restart</strong> subscription
                      </PrimaryButton>
                    </div>
                  )}
                </SubscriptionRow>
                <Divider />

                {/* Plan/price */}
                <SubscriptionRow>
                  <div className='label'>
                    Plan
                    <div className='value'>
                      {capitalize(stripe.interval)}ly
                      <div className='extra'>
                        {willEnd || isCanceled ? (isActive ? 'Ends ' : 'Ended ') : 'Next payment '}
                        {dateTimeCurrentPeriodEnd.toLocaleString(DateTime.DATE_SHORT)}
                      </div>
                    </div>
                  </div>
                  {/* View plan/price info button */}
                  <div className='trailing'>
                    <SecondaryButton
                      onClick={event => {
                        event.preventDefault();
                        setShowPlanInfo(true);
                      }}
                    >
                      <strong>View plan info</strong>
                    </SecondaryButton>
                  </div>
                </SubscriptionRow>

                {/* Card (when not canceled) */}
                {!isCanceled && (
                  <>
                    <Divider />
                    <SubscriptionRow>
                      <div className='label'>
                        Card
                        {noStripeCard ? (
                          <div className='value danger'>No card on file</div>
                        ) : (
                          <div className='value'>
                            ···· ···· ···· {stripe.cardLast4}
                            {stripe.cardExpYear && stripe.cardExpMonth && (
                              <div className='extra'>
                                exp{' '}
                                {DateTime.local(
                                  stripe.cardExpYear,
                                  stripe.cardExpMonth
                                ).toLocaleString({
                                  year: '2-digit',
                                  month: 'numeric',
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {/* Add/update card button */}
                      <div className='trailing'>
                        <SecondaryButton
                          onClick={event => {
                            event.preventDefault();
                            setShowUpdateCardModal(true);
                          }}
                        >
                          <strong>{noStripeCard ? 'Add card' : 'Update card'}</strong>
                        </SecondaryButton>
                      </div>
                    </SubscriptionRow>
                  </>
                )}
              </Padding>
            )}
          </Accordion>

          <Spacer height='48px' />
        </SheetScrollable>
      </Sheet>

      {/* MODALS */}
      {/* Delete location modal */}
      <DeleteModal
        name='location'
        show={!!deleteLocationId}
        handleCancel={() => {
          setDeleteLocationId(false);
          setDeleteLocationConfirm('');
        }}
        handleDelete={() => {
          deleteLocation && deleteLocation.docPath && db.doc(deleteLocation.docPath).delete();
        }}
        disabled={
          deleteLocationName.trim().toLowerCase() !== deleteLocationConfirm.trim().toLowerCase()
        }
      >
        <DeleteWarning>
          <strong>Deleting this location is irreversible</strong> and can not be undone. Please be
          sure this is the action you want to take before proceeding.
        </DeleteWarning>

        <DeleteCannotBeUndone>
          {pluralize({
            root: '{x} ',
            singular: 'person and ',
            plural: 'people and ',
            count: deleteLocationCounts?.people || 0,
            showZero: true,
          })}
          {pluralize({
            root: '{x} update',
            count: deleteLocationCounts?.updates || 0,
            showZero: true,
          })}{' '}
          along with all milestones and tasks will also be permanently deleted for{' '}
          {deleteLocationName}.
        </DeleteCannotBeUndone>

        <Spacer height='32px' />
        <FormInputGroup
          label={
            <>
              Type <em>&quot;{deleteLocation?.name}&quot;</em>&nbsp; to confirm deletion
            </>
          }
          name='delete-location-confirm'
          placeholder='Type location name to confirm'
          value={deleteLocationConfirm}
          onChange={event => {
            setDeleteLocationConfirm(event.target.value);
          }}
        />
      </DeleteModal>

      {/* Stripe elements modals (subscribe and edit/add card) */}
      {stripeLoaded && (
        <Elements
          stripe={stripePromise}
          options={{
            fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Lato:400' }],
          }}
        >
          <>
            {/* Subscribe modal */}
            <SubscribeModal
              show={showSubscribeModal && (!isActive || isTrialing)}
              handleCancel={() => setShowSubscribeModal(false)}
            />
            {/* Edit/add card modal */}
            <UpdateCardModal
              show={showUpdateCardModal}
              handleCancel={() => setShowUpdateCardModal(false)}
              handleDelete={noStripeCard ? undefined : () => setShowDeleteCardModal(true)}
            />
          </>
        </Elements>
      )}

      {/* Delete stripe card confirm modal */}
      <DeleteModal
        name='Card'
        show={showDeleteCardModal}
        handleCancel={() => {
          setShowDeleteCardModal(false);
        }}
        handleDelete={async () => {
          if (organization) {
            try {
              await httpsCallable(
                fbFunctions,
                'deleteStripeCard'
              )({
                organizationId: organization.id,
              });
              setShowDeleteCardModal(false);
              setShowUpdateCardModal(false);
              posthog?.capture('stripe_card_removed');
              logEvent(fbAnalytics, 'remove_payment_info');
            } catch (error) {
              alert('An error occurred. Please contact support. ' + error);
              console.error(error);
            }
          }
        }}
      >
        <DeleteWarning>
          Removing your card <strong>ending in {stripe.cardLast4}</strong> card will set your
          subscription to{' '}
          <strong>cancel on {dateTimeCurrentPeriodEnd.toLocaleString(DateTime.DATE_SHORT)}</strong>
        </DeleteWarning>
        <DeleteCannotBeUndone>Are you sure you want to continue?</DeleteCannotBeUndone>
      </DeleteModal>

      {/* Breeze integration modal */}
      <Modal
        show={showBreezeModal && organization?._integration?.service !== 'breeze'}
        handleClose={() => setShowBreezeModal(false)}
      >
        <ErrorSuspendPlaceholder
          fallback={
            <>
              <Loader show={true} />
              <Spacer height='48px' />
            </>
          }
        >
          <BreezeModal key='breezeModal' />
        </ErrorSuspendPlaceholder>
      </Modal>

      {/* More subscription info floating sheet (payment history) */}
      <AnimatedFloatingSheet position='right'>
        {showPlanInfo && (
          <PlanInfoSheet
            handleClose={() => {
              setShowPlanInfo(false);
            }}
          />
        )}
      </AnimatedFloatingSheet>
    </SheetsWrapper>
  );
};
export default AccountPage;
