import { useField, useFormikContext } from 'formik';
import RadioList from './styled/RadioList';

// Props
export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    id: string;
    value: string;
    label: React.ReactNode;
    subLabel?: React.ReactNode;
    trailing?: React.ReactNode;
  }[];
  dense?: boolean;
}

// Component
export default function FormikRadioList({ name, disabled, ...rest }: Props) {
  const { isSubmitting } = useFormikContext();
  const [field] = useField(name);
  return <RadioList disabled={isSubmitting || disabled} {...rest} {...field} />;
}
