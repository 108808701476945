import { useState } from 'react';
import styled from 'styled-components';

import Group from '@/classes/Group';
import Organization, { ORGANIZATION_TYPES, OrganizationType } from '@/classes/Organization';

import { db, fbAuth, fbFunctions } from '@/lib/firebase';
import { signOut } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

import {
  CMS_OPTIONS,
  DEFAULT_ORGANIZATION_GROUPS,
  DEFAULT_ORGANIZATION_PREFERENCES,
  ORGANIZATION_TYPE_OPTIONS,
  REFERRED_BY_OPTIONS,
} from '@/lib/config';
import generateCreatedByMeta, { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import { Form, Formik, useFormikContext } from 'formik';
import camelCase from 'lodash/camelCase';
import { usePostHog } from 'posthog-js/react';

import useAppState from '@/contexts/appState';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import usePageView from '@/hooks/usePageView';
import useUrlQuery from '@/hooks/useUrlQuery';

import Scrollable from '@/components/layout/Scrollable';
import SheetHeader from '@/components/layout/SheetHeader';

import FormikInput from '@/components/formElements/FormikInput';
import FormikRadioList from '@/components/formElements/FormikRadioList';
import FormikSelect from '@/components/formElements/FormikSelect';

import PrimaryButton, { SecondaryButton } from '@/components/common/Buttons';
import LottieAnimation from '@/components/common/LazyLottieAnimation';
import Loader from '@/components/common/Loader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

const TOTAL_STAGES = 4;

// Layout styles
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-height: 100%;
  max-height: calc(100% - env(safe-area-inset-bottom));
  width: 384px;
  max-width: 100%;
  max-width: 100vw;
  overflow: visible;
  margin: 0 auto;
`;
const FormPanel = styled(Form)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 16px;
  overflow: hidden;

  border-radius: 6px;
  box-shadow: ${props => props.theme.shadow400};
  background-color: ${props => props.theme.sheetBackgroundColor};

  @media (max-width: 383px), (max-height: 639px) {
    flex: 1;
    margin-top: 0;
    border-radius: 0 0 6px 6px;
  }
`;
const Subfooter = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 10px;
  @media (max-width: 383px), (max-height: 639px) {
    padding: 4px 10px;
  }
`;
const ProgressBar = styled.div<{ progress: number }>`
  z-index: 1;
  position: absolute;
  top: 12px;
  left: 12px;
  height: 16px;
  width: calc(100% - 24px);
  overflow: hidden;

  border: 4px solid ${props => props.theme.hoverFade};
  border-radius: 8px;
  background-color: ${props => props.theme.hoverFade};

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: ${props => props.theme.primary500};
    transform: translateX(${props => -105 + props.progress}%);
    transition: transform 300ms ${props => props.theme.easeStandard};
  }
`;
// Stage styles ( used in stages below )
const Head = styled.div`
  margin-bottom: 12px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: ${props => props.theme.linkColor};
`;
const Subhead = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.textFaded};
`;
const Question = styled.div`
  margin-bottom: 12px;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.textTertiary};
`;

// Form defaults
const initialValues = {
  type: 'Ministry' as OrganizationType,
  name: '',
  size: '',
  website: '',
  phone: '',
  cms: '',
  cmsOther: '',
  referredBy: '',
  referredByOther: '',
};
// Type options

// Page/form component
export default function OnboardingPage() {
  // Register page view
  usePageView({ title: 'Welcome to Notebird!', analyticsTitle: 'Onboarding' });

  const { locationId } = useAppState();

  // Potential org type brought over from landing page
  const { orgType = '' } = useUrlQuery<{ orgType?: OrganizationType }>();

  // Context data
  const [user] = useUser();
  const [organization] = useOrganization();

  // Establish Onboarding Stages and progress
  const [stage, setStage] = useState(0);
  const progress = (stage / TOTAL_STAGES) * 100;

  const posthog = usePostHog();

  // Render
  return (
    <PageWrapper>
      {/* Main panel */}
      <Formik
        initialValues={
          {
            ...initialValues,
            type: ORGANIZATION_TYPES.includes(orgType as OrganizationType)
              ? orgType
              : initialValues.type,
          } as typeof initialValues
        }
        onSubmit={async values => {
          if (user && organization && locationId) {
            const orgRef = db.doc(organization.docPath);
            const batch = db.batch();

            // Update organization profile and preferences
            const profile: Organization['profile'] = {
              name: values.name.trim() || user.profile.name.full,
              type: values.type || 'Other',
              size: (values.type === 'Ministry' && values.size) || null,
              locationCount: null,
              website: values.website.trim(),
              phone: values.phone.trim(),
              address: null,
              isOnboarding: false,
              cms: (values.cms === 'Other' && values.cmsOther.trim()) || values.cms,
              setupMeetingStatus: 'prompt',
              referredBy:
                (values.referredBy === 'Other' && values.referredByOther.trim()) ||
                values.referredBy,
              birdcallPlaceId: organization.profile.birdcallPlaceId ?? null,
            };
            let { preferences } = organization;
            // Only establish organization preferences if we know for sure
            // the organization doesn't already have update and milestone types
            // ( this shouldn't ever happen, but just want to be over-cautious! )
            if (!preferences.updateTypes.length && !preferences.milestoneTypes.length) {
              preferences = DEFAULT_ORGANIZATION_PREFERENCES[profile.type];
            }
            batch.update(orgRef, {
              ...generateUpdatedByDottedMeta(user),
              profile,
              preferences,
            });

            // Location was created during signup, just need to update name
            batch.update(orgRef.collection('locations').doc(locationId), {
              ...generateUpdatedByDottedMeta(user),
              name: profile.name,
            });

            // Generate groups for each default group for this organization type
            const groups: { id: string; name: string }[] = [];
            for (const name of DEFAULT_ORGANIZATION_GROUPS[profile.type]) {
              const groupData: Group = { meta: generateCreatedByMeta(user), name };
              const groupRef = orgRef.collection('groups').doc();
              groups.push({ id: groupRef.id, name });
              batch.set(groupRef, groupData);
            }

            // Generate default/demo/dummy people, updates, and tasks for this organization type
            const { default: generateDemoData } = await import('@/lib/generateDemoData');
            const demoData = generateDemoData(user, profile.type, locationId, groups);
            for (const person of demoData.people) {
              const personRef = orgRef
                .collection('people')
                .doc(camelCase(person.profile.name.full));
              batch.set(personRef, person);
            }
            for (const update of demoData.updates) {
              batch.set(orgRef.collection('updates').doc(), update);
            }
            for (const task of demoData.tasks) {
              batch.set(orgRef.collection('tasks').doc(), task);
            }

            try {
              // Commit batch
              await batch.commit();
              // Send internal notification
              httpsCallable(fbFunctions, 'sendNewSignupSlack')({ organizationId: organization.id });
              // Wait just a sec, then mark as onboarding complete
              setTimeout(() => {
                posthog?.capture('organization_onboarding_complete', {
                  type: profile.type,
                  size: profile.size,
                  cms: profile.cms,
                  referred_by: profile.referredBy,
                });
              }, 5000);
            } catch (error) {
              window.alert('Could not update this organization');
              console.error(error);
            }
          }
        }}
      >
        <FormPanel>
          {/* Progress bar */}
          <ProgressBar progress={progress} />

          {/* Main form content 'n stuff */}
          <Scrollable>
            <Padding padding='48px 24px 0'>
              {/* Show stage based on state */}
              {stage === 0 && <WelcomeStage />}
              {stage === 1 && <TypeStage />}
              {stage === 2 && <InfoStage />}
              {stage === 3 && <CmsStage />}
              {stage === 4 && <ReferredByStage />}
            </Padding>
          </Scrollable>

          {/* Footer for back/continue/finish */}
          <FormFooter stage={stage} setStage={setStage} />
        </FormPanel>
      </Formik>

      {/* Subfooter ( logout and chat ) */}
      <Subfooter>
        <SecondaryButton dull onClick={() => signOut(fbAuth)}>
          Log out
        </SecondaryButton>
        <SecondaryButton className='intercom-launcher' leadingIcon='chat_bubble'>
          <strong>Chat with us</strong>
        </SecondaryButton>
      </Subfooter>
    </PageWrapper>
  );
}

// Submit footer ( back/continue/finish )
const FormFooter = ({
  stage,
  setStage,
}: {
  stage: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [user] = useUser();
  const { values, isSubmitting, setFieldValue } = useFormikContext<typeof initialValues>();
  return (
    <>
      <Loader show={isSubmitting} />
      <Spacer height='4px' />
      <SheetHeader
        className='fade-in'
        // Back button ( if on stage 2 or greater )
        leading={
          stage > 1 ? (
            <SecondaryButton
              dull
              leadingIcon='arrow_back'
              onClick={() => setStage(prev => prev - 1)}
              disabled={isSubmitting}
            >
              Back
            </SecondaryButton>
          ) : undefined
        }
        // Submit/next button
        trailing={
          <PrimaryButton
            type='submit'
            autoFocus
            onClick={event => {
              // If on final stage, go right to submitting the form
              if (stage === TOTAL_STAGES) return;
              // Otherwise, prevent form from being submitted
              event.preventDefault();

              // If leaving first stage and `personal` org type selected
              // prefill org name with person's name
              if (stage === 1 && values.type === 'Personal') {
                setFieldValue('name', user?.profile.name.full);
              }
              // Go to next stage
              setStage(prev => prev + 1);
            }}
            // Continuing is disabled unless certain conditions are met per stage
            disabled={
              isSubmitting ||
              (stage === 1 && !values.type) ||
              (stage === 2 && !values.name.trim()) ||
              (stage === 3 && !values.cms.trim())
            }
          >
            {stage === TOTAL_STAGES ? 'Finish' : 'Continue'}
          </PrimaryButton>
        }
      />
    </>
  );
};

// Stage 00. Welcome
const WelcomeStage = () => (
  <div className='fade-in'>
    <LottieAnimation
      path='onboardingIllustrationAnimationData'
      height='300px'
      maxHeight='50vh'
      width='100%'
      loop={false}
    />
    <Spacer height='32px' />
    <Head>Welcome to Notebird!</Head>
    <Subhead>Just a couple quick questions to set up your account...</Subhead>
  </div>
);

// Stage 01. Select organization type
const TypeStage = () => (
  <div className='fade-in'>
    <Question>I&apos;ll be using Notebird for my...</Question>
    <Spacer height='24px' />
    <FormikRadioList name='type' options={ORGANIZATION_TYPE_OPTIONS} autoFocus />
  </div>
);

// Stage 02. Collect info
const InfoStage = () => {
  const { values } = useFormikContext<typeof initialValues>();
  return (
    <div className='fade-in'>
      <Question>Tell us a little about your organization.</Question>
      <Spacer height='24px' />
      {/* Name */}
      <FormikInput
        label='Organization name'
        name='name'
        autoFocus={!values.name.trim()}
        placeholder='required'
      />
      <Spacer height='24px' />
      {/* Congregation Size ( if Ministry ) */}
      {values.type === 'Ministry' && (
        <>
          <FormikSelect
            label='Average weekly attendance'
            name='size'
            options={['0-50', '50-100', '100-200', '200-500', '500-1000', '1000+'].map(value => ({
              label: value,
              value,
            }))}
          />
          <Spacer height='24px' />
        </>
      )}
      {/* Website */}
      <FormikInput label='Website' name='website' />
      <Spacer height='24px' />
      {/* Phone */}
      <FormikInput label='Phone' name='phone' />
    </div>
  );
};

// Stage 03. Find out current CMS
const CmsStage = () => {
  const { values } = useFormikContext<typeof initialValues>();
  return (
    <div className='fade-in'>
      <Question>What software do you currently use?</Question>
      <Spacer height='24px' />
      <FormikRadioList
        name='cms'
        options={[...CMS_OPTIONS[values.type], 'Excel or Spreadsheet', 'No Software', 'Other'].map(
          (value: string) => ({
            id: camelCase(`cms ${value}`),
            label: value,
            value,
          })
        )}
        autoFocus
        dense
      />
      {values.cms === 'Other' && (
        <FormikInput name='cmsOther' autoFocus placeholder='Type the name of your software' />
      )}
    </div>
  );
};

// Stage 04. Find out how user heard about Notebird
const ReferredByStage = () => {
  const { values } = useFormikContext<typeof initialValues>();
  return (
    <div className='fade-in'>
      <Question>How did you find Notebird?</Question>
      <Spacer height='24px' />
      <FormikRadioList
        name='referredBy'
        options={[...REFERRED_BY_OPTIONS, 'Other'].map((value: string) => ({
          id: camelCase(`referredBy ${value}`),
          label: value,
          value,
        }))}
        autoFocus
        dense
      />
      {values.referredBy === 'Other' && (
        <FormikInput
          name='referredByOther'
          autoFocus
          placeholder='Tell us how you learned about Notebird'
        />
      )}
    </div>
  );
};
