import React, { ChangeEvent, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { WithId } from '@/classes/Doc';
import Person from '@/classes/Person';

import { FlattenedTimestamps, restoreTimestamps } from '@/lib/firebase';

import { pluralize, stringToProfileName } from '@/lib/helpers';
import isFuzzyTextMatch from '@/lib/helpers/isFuzzyTextMatch';

import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';
import { DateTime } from 'luxon';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { SearchClient } from 'typesense';
import { type SearchParams, SearchResponseHit } from 'typesense/lib/Typesense/Documents';

import useAppState, { useSetAppState } from '@/contexts/appState';
import useCounts from '@/contexts/counts';
import useGroups from '@/contexts/groups';
import useOrganization from '@/contexts/organization';
import usePeople from '@/contexts/people';

import useDebouncedCallback from '@/hooks/useDebouncedCallback';
import useDebouncedValue from '@/hooks/useDebouncedValue';
import usePageView from '@/hooks/usePageView';

import AnimatedFloatingSheet from '@/components/layout/AnimatedFloatingSheet';
import MobilePageHeader from '@/components/layout/MobilePageHeader';
import SheetHeader from '@/components/layout/SheetHeader';
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';

import PersonLinkItem from './PersonLinkItem';
import PersonLinkItemLoader from './PersonLinkItemLoader';
import ScheduleACallNotice from './ScheduleACallNotice';

import PersonForm from '@/components/forms/PersonForm';

import { SearchInput } from '@/components/formElements/FormElements';

import PrimaryButton from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Expanded from '@/components/common/Expanded';
import Icon from '@/components/common/Icon';
import LottieAnimation from '@/components/common/LazyLottieAnimation';
import LinkButton from '@/components/common/LinkButton';
import Loader from '@/components/common/Loader';
import Margin from '@/components/common/Margin';
import NoWrap from '@/components/common/NoWrap';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

import NotebirdIcon from '@/components/svg/NotebirdIcon';

// Lazy load illustration
const AddPersonIllustration = lazy(() => import('../../svg/AddPersonIllustration'));

// Styles
const FlexWrapperCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
`;
const GetStartedHeader = styled.div`
  font-size: 20px;
  color: ${props => props.theme.textSecondary};
  strong {
    font-size: 28px;
    white-space: nowrap;
    color: ${props => props.theme.textPrimary};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.primary500};
  }
`;
const GetStartedCopy = styled.div`
  line-height: 1.25;
  color: ${props => props.theme.textFaded};
`;
const ResultsCountWrapper = styled.div`
  text-align: center;
  padding: 16px;
  color: ${({ theme }) => theme.avatarText};
`;
// Mobile specific styles
const MobileAddButton = styled(PrimaryButton)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  padding: 10px;
  box-shadow: ${({ theme }) => theme.shadow300};
  /* To make it perfectly round for some reason */
  i {
    width: 39px;
  }
  @media (min-width: 1024px) {
    display: none;
  }
`;
const arrowWaveRight = keyframes`
    0% { transform: translateX(16px); opacity: 0.5; }
  100% { transform: translateX(0); opacity: 0.2; }
`;
const GetStartedArrowMobile = styled(Icon)`
  position: absolute;
  bottom: 24px;
  right: 96px;
  color: ${({ theme }) => theme.linkColor};
  animation: ${arrowWaveRight} 800ms ${({ theme }) => theme.easeStandard} infinite alternate;
`;
const arrowWaveUp = keyframes`
    0% { transform: translateY(16px); opacity: 0.2; }
  100% { transform: translateY(0); opacity: 0.5; }
`;
const GetStartedArrow = styled(Icon)`
  color: ${({ theme }) => theme.linkColor};
  animation: ${arrowWaveUp} 800ms ${({ theme }) => theme.easeStandard} infinite alternate;
`;

// Defaults
const MAX_SEARCH_RESULTS = 99;

// Component
const PeoplePage = () => {
  const navigate = useNavigate();

  // Register page view
  usePageView({ title: 'People | Notebird' });

  // App state
  const {
    isActive,
    isTrialing,
    isOrganizationAdmin,
    isLocationAdmin,
    groupId,
    groupName,
    contentFilter,
    showArchivedPeople,
    orderPeopleBy,
    orderPeopleDirection,
    peopleScrollCacheId,
    peopleScrollCacheIndex,
    peopleSearchCache,
    kidcardMode,
    typesensePeopleKey,
  } = useAppState();
  const setAppState = useSetAppState();
  // Context data
  const [organization] = useOrganization();
  const [groups] = useGroups();
  const groupIds = useMemo(() => groups?.map(({ id }) => id) || [], [groups]);
  const people = usePeople();
  const [counts] = useCounts();

  // Determine pulse / join indicators
  const thresholds = useMemo(() => {
    if (organization) {
      const {
        preferences: { pulseIndicator, joinDateIndicator },
      } = organization;
      const today = DateTime.local().startOf('day');
      return {
        pulseFirst: pulseIndicator.enabled && today.minus({ days: pulseIndicator.first }),
        pulseSecond: pulseIndicator.enabled && today.minus({ days: pulseIndicator.second }),
        joinedFirst: joinDateIndicator.enabled && today.minus({ days: joinDateIndicator.first }),
        joinedSecond: joinDateIndicator.enabled && today.minus({ days: joinDateIndicator.second }),
      };
    }
  }, [organization]);

  // State
  const [showNewPersonForm, setShowNewPersonForm] = useState(false);
  // Reset show new form on page load and if current location ID changes
  // (test if form session values aren't empty)
  // useEffect(() => {
  //   const formFields = getSessionValue<PersonFields>(
  //     "newPersonIntialValues-" + auth.uid + currentLocationId
  //   );
  //   if (!!formFields) {
  //     const { firstName, lastName, notes, customId } = formFields;
  //     setShowNewPersonForm(some([firstName, lastName, notes, customId]));
  //   } else {
  //     setShowNewPersonForm(false);
  //   }
  // }, [auth.uid, currentLocationId]);
  // Variable for empty or "no people" state
  const totalPeople =
    counts && (showArchivedPeople ? counts.people : counts.people - counts.peopleArchived);
  const hasNoPeople = !people.count && !totalPeople;

  // Search stuffs
  const [searchValue, setSearchValue] = useState(peopleSearchCache);
  const debouncedSearch = useDebouncedValue(searchValue, 175);
  const isSearching = searchValue.trim().length > 0 && debouncedSearch.trim().length > 0;
  const [isSearchFetching, setIsSearchFetching] = useState(false);
  const [searchResults, setSearchResults] = useState<{
    nbHits: number;
    hits: SearchResponseHit<FlattenedTimestamps<WithId<Person>>>[];
    query: string;
  }>({
    nbHits: 0,
    hits: [],
    query: '',
  });
  // Establish typesense search client
  const typesense = useMemo(() => {
    if (!typesensePeopleKey) return null;

    const nearestHost = import.meta.env.VITE_TYPESENSE_NEAREST_HOST;
    const hosts = import.meta.env.VITE_TYPESENSE_HOSTS.split(',');
    return new SearchClient({
      nearestNode: nearestHost
        ? {
            host: nearestHost,
            port: 443,
            protocol: 'https',
          }
        : undefined,
      nodes: hosts.map(host => ({
        host: host,
        port: 443,
        protocol: 'https',
      })),
      apiKey: typesensePeopleKey,
      connectionTimeoutSeconds: 5,
    });
  }, [typesensePeopleKey]);
  // Typesense search
  useEffect(() => {
    async function search() {
      const trimmedSearch = debouncedSearch.trim();
      if (!typesense || !trimmedSearch || !organization?.id) return;
      setIsSearchFetching(true);
      const searchParams: SearchParams = {
        q: trimmedSearch,
        query_by: [
          // Higher weights
          'profile.name.full',
          'notes',
          'customId',
          'relationships.profile.name.full',
          // Lower weights
          'emails.address',
          'phones.number',
          'addresses.place.mainText',
          'addresses.place.secondaryText',
        ].join(','),
        per_page: MAX_SEARCH_RESULTS,
        highlight_start_tag: '<em>',
        highlight_end_tag: '</em>',
        snippet_threshold: 9,
        // Push archived to bottom
        sort_by: '_eval(isArchived:false):desc',
      };
      const results = await typesense
        .collections<Person>(`${organization.id}_people`)
        .documents()
        .search(searchParams, {});
      const hits =
        (results.hits as SearchResponseHit<FlattenedTimestamps<WithId<Person>>>[]) ?? null;
      setSearchResults({
        nbHits: results.found,
        hits: hits ?? [],
        query: trimmedSearch,
      });
      setIsSearchFetching(false);
    }
    search();
  }, [typesense, debouncedSearch, organization?.id]);

  // Used to create new person from empty search
  const [newPersonFromSearch, setNewPersonFromSearch] = useState({
    defaultFirstName: '',
    defaultLastName: '',
  });
  // Navigate and confirm search results with keyboard
  const searchRef = useRef<HTMLInputElement>(null);
  const selectedSearchIndex = useRef(-1);
  const [statefulSelectedSearchIndex, setStatefulSelectedSearchIndex] = useState(-1);
  const handleSearchNavigation = useCallback(
    (event: KeyboardEvent) => {
      if (isSearching) {
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          searchResults.hits.length > selectedSearchIndex.current + 1 &&
            selectedSearchIndex.current++;
          setStatefulSelectedSearchIndex(selectedSearchIndex.current);
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          selectedSearchIndex.current !== 0 && selectedSearchIndex.current--;
          setStatefulSelectedSearchIndex(selectedSearchIndex.current);
        } else if (event.key === 'Enter') {
          event.preventDefault();
          const selectedTypesensePerson = searchResults.hits[selectedSearchIndex.current];
          if (selectedTypesensePerson) {
            // Go to page if person found
            navigate('/person/' + selectedTypesensePerson.document.id);
            // And preserve search state
            setAppState({
              peopleScrollCacheId: selectedTypesensePerson.document.id,
              peopleScrollCacheIndex: selectedSearchIndex.current,
              peopleSearchCache: searchValue,
            });
          } else if (!showNewPersonForm) {
            // If not, create new person (if not already creating one)
            // This populates new person form
            const name = stringToProfileName(searchValue);
            setNewPersonFromSearch({
              defaultFirstName: name.first,
              defaultLastName: name.last,
            });
            // Then opens the form
            setShowNewPersonForm(true);
            // Clear search
            setSearchValue('');
          }
        } else {
          // Otherwise, reset selected on keypress
          selectedSearchIndex.current = 0;
          setStatefulSelectedSearchIndex(selectedSearchIndex.current);
        }
      }
      if (event.key === 'Escape') {
        // Clear search and defocus with escape
        event.preventDefault();
        setSearchValue('');
        searchRef.current && searchRef.current.blur();
      }
    },
    [navigate, isSearching, searchResults.hits, searchValue, setAppState, showNewPersonForm]
  );
  useEffect(() => {
    const searchInput = searchRef.current;
    if (searchInput) {
      searchInput?.addEventListener('keydown', handleSearchNavigation);
      return () => searchInput?.removeEventListener('keydown', handleSearchNavigation);
    }
  }, [handleSearchNavigation]);
  // Search row (very similar to regular row)
  const searchRowRender = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const totalResults = searchResults.hits.length;
      const showResultsCount = index === totalResults;
      const showItem = !showResultsCount;

      const TypesenseDoc = showItem && searchResults.hits[index];
      const person = TypesenseDoc && restoreTimestamps(TypesenseDoc.document);
      if (!TypesenseDoc || !person) return null;

      let pulseStatus: 'danger' | 'warn' | 'fresh' | 'none' = 'fresh';
      let joinStatus: 'new' | 'fading' | undefined = undefined;
      if (person && thresholds) {
        const { pulseFirst, pulseSecond, joinedFirst, joinedSecond } = thresholds;
        // Determine pulse status (if enabled)
        // TODO: Extract as function to use for regular row render too
        if (pulseFirst && pulseSecond) {
          // Latest timestamp is always latest for admins
          // but need to find most recent of available groups for members
          const lastUpdatedTimestamp = isLocationAdmin
            ? person.lastUpdated.latest
            : orderBy(
                toPairs(person.lastUpdated).filter(([groupId]) => groupIds.includes(groupId)),
                ([, timestamp]) => timestamp?.toMillis(),
                'desc'
              )[0]?.[1];
          if (lastUpdatedTimestamp) {
            const pulseDateTime = DateTime.fromMillis(lastUpdatedTimestamp.toMillis());
            const isWarn = pulseDateTime < pulseFirst;
            const isDanger = pulseDateTime < pulseSecond;
            pulseStatus = isDanger ? 'danger' : isWarn ? 'warn' : 'fresh';
          } else {
            pulseStatus = 'none';
          }
        }
        // Determine join date status (if enabled)
        if (person.joinDate && joinedFirst && joinedSecond) {
          const joinDateTime = DateTime.fromISO(person.joinDate);
          const isNew = joinDateTime >= joinedFirst;
          const isFading = joinDateTime >= joinedSecond;
          joinStatus = isNew ? 'new' : isFading ? 'fading' : undefined;
        }
      }

      // Highlight stuff
      const { profile, customId, emails, notes, phones, addresses, relationships } =
        TypesenseDoc.highlight;
      const nameMarkup = profile?.name?.full?.snippet || person.profile.name.full;
      const subtitleMarkup = (() => {
        // Custom ID
        if (customId?.snippet) return customId.snippet;
        // Emails
        const email = emails?.find(email => email?.address?.matched_tokens?.length)?.address
          ?.snippet;
        if (email) return email;
        // Notes
        if (notes?.snippet) return notes.snippet;
        // Phones
        const phone = phones?.find(phone => phone?.number?.matched_tokens?.length)?.number?.snippet;
        if (phone) return phone;
        // Addresses
        const addressMain = addresses?.find(
          address => address?.place?.mainText?.matched_tokens?.length
        )?.place?.mainText?.snippet;
        if (addressMain) return addressMain;
        const addressSecondary = addresses?.find(
          address => address?.place?.secondaryText?.matched_tokens?.length
        )?.place?.secondaryText?.snippet;
        if (addressSecondary) return addressSecondary;
        // Relationships
        const relationship = relationships?.find(
          relationship => relationship?.profile?.name?.full?.matched_tokens?.length
        )?.profile?.name?.full?.snippet;
        if (relationship) {
          const relationshipNameToMatch = relationship?.replace('<em>', '').replace('</em>', '');
          const relationshipType = person.relationships?.find(rel =>
            isFuzzyTextMatch(rel.profile.name.full, relationshipNameToMatch)
          )?.type;
          if (relationshipType) return `${relationship} · ${relationshipType}`;
        }

        return null;
      })();

      return (
        <>
          {/* Each person item */}
          {showItem &&
            (!!TypesenseDoc && !!person ? (
              // Loaded
              <PersonLinkItem
                person={person}
                style={style}
                key={person.id}
                nameMarkup={nameMarkup || ''}
                subtitleMarkup={subtitleMarkup || ''}
                pulseStatus={pulseStatus}
                joinStatus={joinStatus}
                active={statefulSelectedSearchIndex === index}
                onClick={() =>
                  setAppState({
                    peopleScrollCacheId: person.id,
                    peopleScrollCacheIndex: index,
                    peopleSearchCache: debouncedSearch,
                  })
                }
              />
            ) : (
              // Not loaded yet
              <PersonLinkItemLoader style={style} />
            ))}
          {/* Results count at end */}
          {showResultsCount && (
            <ResultsCountWrapper style={style}>
              <strong>{totalResults}</strong> {pluralize({ root: 'result', count: totalResults })}{' '}
              for &apos;<strong>{searchResults.query}</strong>&apos;
            </ResultsCountWrapper>
          )}
        </>
      );
    },
    [
      debouncedSearch,
      groupIds,
      isLocationAdmin,
      searchResults.hits,
      searchResults.query,
      setAppState,
      statefulSelectedSearchIndex,
      thresholds,
    ]
  );
  // Scroll to selected index anytime it changes ( make sure it's in view )
  const searchListRef = useRef<FixedSizeList>(null);
  useEffect(() => {
    if (searchListRef && searchListRef.current) {
      searchListRef.current.scrollToItem(statefulSelectedSearchIndex);
    }
  }, [statefulSelectedSearchIndex]);

  // Virtual scroller
  const isItemLoaded = useCallback(
    // `index === people.count` for last birdie in scroll row
    (index: number) => index === people.count || !!people.docs[index],
    [people.count, people.docs]
  );
  const [loadMoreItems] = useDebouncedCallback(
    (_startIndex: number, stopIndex: number) => {
      people.loadMore(stopIndex + 1);
      return Promise.resolve();
    },
    150,
    { maxWait: 250 }
  );
  const rowRenderer = useCallback(
    ({ index, style }: { index: number; style: React.CSSProperties }) => {
      const showEndSpacer = index === people.count;
      const showItem = !showEndSpacer;
      const person = showItem && people.docs[index];
      let pulseStatus: 'danger' | 'warn' | 'fresh' | 'none' = 'fresh';
      let joinStatus: 'new' | 'fading' | undefined = undefined;
      if (person && thresholds) {
        const { pulseFirst, pulseSecond, joinedFirst, joinedSecond } = thresholds;
        // Determine pulse status (if enabled)
        // TODO: Extract as function to use for search row render too
        if (pulseFirst && pulseSecond) {
          // Latest timestamp is always latest for admins
          // but need to find most recent of available groups for members
          const lastUpdatedTimestamp = isLocationAdmin
            ? person.lastUpdated.latest
            : orderBy(
                toPairs(person.lastUpdated).filter(([groupId]) => groupIds.includes(groupId)),
                ([, timestamp]) => timestamp?.toMillis(),
                'desc'
              )[0]?.[1];
          if (lastUpdatedTimestamp) {
            const pulseDateTime = DateTime.fromMillis(lastUpdatedTimestamp.toMillis());
            const isWarn = pulseDateTime < pulseFirst;
            const isDanger = pulseDateTime < pulseSecond;
            pulseStatus = isDanger ? 'danger' : isWarn ? 'warn' : 'fresh';
          } else {
            pulseStatus = 'none';
          }
        }
        // Determine join date status (if enabled)
        if (person.joinDate && joinedFirst && joinedSecond) {
          const joinDateTime = DateTime.fromISO(person.joinDate);
          const isNew = joinDateTime >= joinedFirst;
          const isFading = joinDateTime >= joinedSecond;
          joinStatus = isNew ? 'new' : isFading ? 'fading' : undefined;
        }
      }
      return (
        <>
          {/* Each person item */}
          {showItem &&
            (person ? (
              // Loaded
              <PersonLinkItem
                person={person}
                style={style}
                key={person.id}
                showJoinDate={orderPeopleBy === 'Joined'}
                boldFirstName={['First name', 'Joined'].includes(orderPeopleBy)}
                boldLastName={['Last name', 'Joined'].includes(orderPeopleBy)}
                pulseStatus={pulseStatus}
                joinStatus={joinStatus}
                onClick={() =>
                  setAppState({ peopleScrollCacheId: person.id, peopleScrollCacheIndex: index })
                }
              />
            ) : (
              // Not loaded yet
              <PersonLinkItemLoader style={style} />
            ))}
          {/* Space at end */}
          {showEndSpacer && (
            <div style={style}>
              <Spacer height='16px' />
              <NotebirdIcon dull height='24px' width='100%' />
              <Spacer height='24px' />
            </div>
          )}
        </>
      );
    },
    [groupIds, isLocationAdmin, orderPeopleBy, people.count, people.docs, setAppState, thresholds]
  );

  // Clear search and reset scroll every time group, filter, or sort changes ( only AFTER initial render tho )
  const listRef: { current: FixedSizeList | null } = useRef<FixedSizeList>(null);
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (!isInitialRender.current) {
      setSearchValue('');
      listRef?.current?.scrollToItem(0, 'start');
    }
    isInitialRender.current = false;
  }, [groupId, contentFilter, orderPeopleBy, orderPeopleDirection, showArchivedPeople]);

  // Take action if scrollId or scrollIndex caches are set ( but not search )
  useEffect(() => {
    if (
      !peopleSearchCache &&
      (peopleScrollCacheId || peopleScrollCacheIndex) &&
      people.docs.length
    ) {
      // ( deferred to make sure listRef has been established )
      setTimeout(() => {
        const foundIndex = peopleScrollCacheId
          ? people.docs.findIndex(({ id }) => id === peopleScrollCacheId)
          : -1;
        const scrollToIndex = foundIndex !== -1 ? foundIndex : peopleScrollCacheIndex;
        listRef.current?.scrollToItem(scrollToIndex, 'start');
        setAppState({ peopleScrollCacheId: '', peopleScrollCacheIndex: 0 });
      });
    }
  }, [people.docs, peopleScrollCacheId, peopleScrollCacheIndex, peopleSearchCache, setAppState]);
  // Take action if search cache is set
  useEffect(() => {
    if (
      peopleSearchCache &&
      (peopleScrollCacheId || peopleScrollCacheIndex) &&
      searchResults.hits.length
    ) {
      const foundIndex = peopleScrollCacheId
        ? searchResults.hits.findIndex(({ document }) => document.id === peopleScrollCacheId)
        : -1;
      const selectedIndex = foundIndex !== -1 ? foundIndex : peopleScrollCacheIndex;
      selectedSearchIndex.current = selectedIndex;
      setStatefulSelectedSearchIndex(selectedSearchIndex.current);
      setAppState({ peopleScrollCacheId: '', peopleScrollCacheIndex: 0, peopleSearchCache: '' });
      searchRef.current?.focus();
    }
  }, [
    peopleScrollCacheId,
    peopleScrollCacheIndex,
    peopleSearchCache,
    searchResults.hits,
    setAppState,
  ]);

  return (
    <SheetsWrapper>
      {/* Header for mobile */}
      <MobilePageHeader>
        {counts && counts.people >= 3 ? (
          <SearchInput
            // Only autofocus search is has 10 or more people
            // ( had to prevent this because of mobile/touch devices virtual keyboard)
            // autoFocus={peopleList.docs.length > 9}
            className='fade-in'
            name='peopleSearch'
            placeholder={kidcardMode ? 'Search all students' : 'Search all people'}
            value={searchValue}
            forwardedRef={searchRef}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(event.target.value);
            }}
            handleClear={() => setSearchValue('')}
            maxLength={150}
          />
        ) : (
          'People'
        )}
      </MobilePageHeader>

      {/* People List */}
      <Sheet position='left'>
        {/* People list (if has people) */}
        {!hasNoPeople && (
          <>
            {/* 'People' header with search bar ( desktop ) */}
            <SheetHeader
              primary
              leadingIcon='people'
              mainTitle={kidcardMode ? 'Students' : 'People'}
              className='hidden-mobile'
            >
              {/* Search bar in header, if has 3 or more people total */}
              {counts && counts.people >= 3 && (
                <SearchInput
                  // Only autofocus search is has 10 or more people
                  // ( had to prevent this because of mobile/touch devices virtual keyboard)
                  // autoFocus={peopleList.docs.length > 9}
                  className='fade-in'
                  name='peopleSearch'
                  placeholder={kidcardMode ? 'Search all students' : 'Search all people'}
                  value={searchValue}
                  forwardedRef={searchRef}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(event.target.value);
                  }}
                  handleClear={() => setSearchValue('')}
                  maxLength={150}
                />
              )}
            </SheetHeader>
            <Loader show={people.isFetching} />

            {/* Warning if no people in this view/group ( if NOT searching ) */}
            {!people.isFetching && !people.count && !isSearching && (
              <Padding padding='48px 32px' className='fade-in' key={groupName || undefined}>
                <NoneFoundHeader>
                  No people in <NoWrap>{groupName || 'this group'}</NoWrap>
                </NoneFoundHeader>
                <NoneFoundCopy>
                  Back to{' '}
                  <LinkButton
                    onClick={() => setAppState({ contentFilter: 'all', groupId: null })}
                    autoFocus
                  >
                    <strong>all people</strong>
                  </LinkButton>
                </NoneFoundCopy>
              </Padding>
            )}

            {/* Filtered people List ( if NOT searching & has some ) */}
            {/* ( also contains ScheduleACallNotice under certain conditions ) */}
            {!!people.count && !isSearching && (
              <>
                <Expanded>
                  <AutoSizer>
                    {({ height, width }) => (
                      <InfiniteLoader
                        // Plus one for little birdie at end
                        itemCount={people.count + 1}
                        isItemLoaded={isItemLoaded}
                        loadMoreItems={loadMoreItems}
                        minimumBatchSize={100}
                      >
                        {({ onItemsRendered, ref }) => (
                          <FixedSizeList
                            itemCount={people.count + 1}
                            onItemsRendered={onItemsRendered}
                            ref={el => {
                              // Funny callback ref to handle "scrollToItem" properly
                              // ( because of InfiniteLoader )
                              listRef.current = el;
                              if (typeof ref === 'function') {
                                ref(el);
                              }
                            }}
                            itemSize={65}
                            width={width}
                            height={height}
                          >
                            {rowRenderer}
                          </FixedSizeList>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer>
                </Expanded>
                {people.count < 8 &&
                  !organization?._integration &&
                  isOrganizationAdmin &&
                  organization?.profile.setupMeetingStatus === 'prompt' &&
                  isTrialing && <ScheduleACallNotice />}
              </>
            )}

            {/* Search list */}
            {isSearching && (
              <Expanded>
                <Loader show={isSearchFetching} />
                {/* Results (when has some) */}
                {!!searchResults.nbHits && (
                  <AutoSizer>
                    {({ height, width }) => (
                      <FixedSizeList
                        itemCount={searchResults.hits.length + 1}
                        ref={searchListRef}
                        itemSize={65}
                        width={width}
                        height={height}
                      >
                        {searchRowRender}
                      </FixedSizeList>
                    )}
                  </AutoSizer>
                )}
                {/* No search results notice */}
                {!searchResults.nbHits && (
                  <Padding padding='48px 32px' className='fade-in' key={groupName || undefined}>
                    <NoneFoundHeader>
                      {isSearchFetching ? 'Searching...' : 'No search results found'}
                    </NoneFoundHeader>
                    {!isSearchFetching && (
                      <NoneFoundCopy>
                        {/* Only show create new button if not already creating */}
                        {!showNewPersonForm && (
                          <LinkButton
                            onClick={() => {
                              // This populates new person form
                              const name = stringToProfileName(searchValue);
                              setNewPersonFromSearch({
                                defaultFirstName: name.first,
                                defaultLastName: name.last,
                              });
                              // Then opens the form
                              setShowNewPersonForm(true);
                              // Clear search
                              setSearchValue('');
                            }}
                          >
                            Create person &apos;<strong>{debouncedSearch}</strong>&apos;
                          </LinkButton>
                        )}
                      </NoneFoundCopy>
                    )}
                  </Padding>
                )}
              </Expanded>
            )}
          </>
        )}

        {/* Get started onboarding animation + text ( no people ) */}
        {hasNoPeople && (
          <>
            <FlexWrapperCentered className='fade-in'>
              <Margin margin='-16% 0 0' />
              <LottieAnimation path='addPersonIllustrationAnimationData' maxHeight='33%' />
              <Spacer height='24px' />
              <GetStartedHeader>
                Get started by <strong>adding a person</strong>
              </GetStartedHeader>
              <Spacer height='16px' />
              <GetStartedCopy>
                You&apos;ll create updates for this person over time
                <br />
                to record conversations and life events
              </GetStartedCopy>
            </FlexWrapperCentered>
            {/* Pointing arrow ( only for mobile ) */}
            <GetStartedArrowMobile icon='arrow_forward' iconSize='48px' className='shown-mobile' />
          </>
        )}

        {/* How we add people on mobile */}
        <MobileAddButton
          onClick={() => {
            // Clear any value that was already prefilled from search results
            setNewPersonFromSearch({ defaultFirstName: '', defaultLastName: '' });
            // And clear search
            setSearchValue('');
            // Then open form
            setShowNewPersonForm(true);
          }}
          disabled={!isActive}
          data-intercom-target='Add person button - mobile'
        >
          <Icon icon='add' iconSize='36px' />
        </MobileAddButton>
      </Sheet>

      {/* Add New Person CTA */}
      <Sheet position='right' className='hidden-mobile'>
        <FlexWrapperCentered>
          {/* Regular/faded illustration (if has people) */}
          {!hasNoPeople && (
            <>
              <Margin margin='-128px 0 0 ' />
              <ErrorSuspendPlaceholder>
                <AddPersonIllustration width='232px' height='232px' className='fade-in' />
              </ErrorSuspendPlaceholder>
              <Spacer height='48px' />
            </>
          )}
          {/* Add person CTA */}
          {/* <Wiggler disabled={!isGettingStarted || !isActive}> */}
          <PrimaryButton
            disabled={!isActive}
            leadingIcon='person_add'
            onClick={() => {
              // Clear any value that was already prefilled from search results
              setNewPersonFromSearch({ defaultFirstName: '', defaultLastName: '' });
              // And clear search
              setSearchValue('');
              // Then open form
              setShowNewPersonForm(true);
            }}
            data-intercom-target='Add person button'
          >
            Add new {kidcardMode ? 'student' : 'person'}
          </PrimaryButton>
          {hasNoPeople && isActive && <GetStartedArrow icon='arrow_upward' iconSize='48px' />}
          {/* </Wiggler> */}
          {/* Import button ( only for admins ) */}
          {/* This feature on standby.  Notebird support only for now. */}
          {/* {isAdmin && <ImportLink to="/import">or batch import</ImportLink>} */}
        </FlexWrapperCentered>
      </Sheet>

      <AnimatedFloatingSheet>
        {!!showNewPersonForm && (
          <PersonForm
            handleCancel={() => setShowNewPersonForm(false)}
            defaultFirstName={newPersonFromSearch.defaultFirstName}
            defaultLastName={newPersonFromSearch.defaultLastName}
          />
        )}
      </AnimatedFloatingSheet>
    </SheetsWrapper>
  );
};

// Export
export default PeoplePage;
