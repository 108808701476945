import { DateTime } from 'luxon';
import Meta from './Meta';
import { PersonProfile } from './Person';

export default class Milestone {
  meta = { ...new Meta() };
  isArchived = false;
  // Permissions and references
  locationId = '';
  groups = ['ungrouped'];
  person = {
    id: '',
    profile: { ...new PersonProfile() },
  };
  // Core info
  type = '';
  date = new Date().toISOString().slice(0, 10);
  dateMmddyyyy = DateTime.fromISO(this.date).toFormat('MMddy');
  showAnnually = false;
  description = '';
}

export function getMilestoneIcon(type: string) {
  let typeIcon = 'flag';
  switch (type) {
    case 'birthday':
    case 'Birthday':
      typeIcon = 'cake';
      break;

    case 'anniversary':
    case 'Marriage':
    case 'Anniversary':
      typeIcon = 'loyalty';
      break;

    case 'joinDate':
    case 'Will join':
    case 'Joined':
      typeIcon = 'mood';
      break;

    case 'Birth':
    case 'Will be born':
    case 'Was born':
      typeIcon = 'child_friendly';
      break;

    case 'Passing':
      typeIcon = 'local_florist';
      break;

    case 'Graduation':
      typeIcon = 'school';
      break;
  }
  return typeIcon;
}
