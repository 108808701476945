import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

// Classes
import { UserProfile } from '@/classes/User';

// Libs
import { fbAuth } from '@/lib/firebase';
import { FirebaseError } from 'firebase/app';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { type AuthErrorCodes, type MultiFactorError } from 'firebase/auth';

// Hooks
import { type FormikHelpers, useFormik } from 'formik';
import { object, string } from 'yup';

// Context data
import useUser from '@/contexts/user';

import useMultiFactor from '@/hooks/useMultiFactor';

// Form elements
import { Input, InputError, PasswordInput } from '@/components/formElements/FormElements';

import { FormLabel } from '@/components/common/Label';
import { FormModal } from '@/components/common/Modals';
import Padding from '@/components/common/Padding';
// Common components
import Spacer from '@/components/common/Spacer';

// Styles
const CenterText = styled.div`
  text-align: center;
`;
const Note = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 32px;
  color: ${props => props.theme.textSecondary};
`;

// Form Schema
const EditEmailSchema = object().shape({
  currentPassword: string().required('Current password is required'),
  newPassword: string()
    .required('New password is required')
    .min(8, 'Not long enough. 8 characters minimum'),
  verificationCode: string().length(6, 'Verification code must be 6 digits'),
});

// Types / defaults
const initialValues = {
  currentPassword: '',
  newPassword: '',
  verificationCode: '',
};

// Component
interface Props {
  show: boolean;
  hide(): void;
}
const EditPasswordModal = ({ show, hide }: Props) => {
  const [user] = useUser();
  const profile = user ? user.profile : { ...new UserProfile() };

  // Form State
  const [newPasswordSuccessful, setNewPasswordSuccessful] = useState(false);
  const [genericError, setGenericError] = useState('');
  // Refs to target focus
  const currentPasswordRef = useRef<HTMLInputElement>(null);
  const newPasswordRef = useRef<HTMLInputElement>(null);

  // Multi-factor SMS stuff
  const {
    RecaptchaRoot,
    hasSentVerification,
    resetVerification,
    sendSmsVerificationCode,
    finalizeSignIn,
    resolver,
  } = useMultiFactor();

  // Submit
  const onSubmit = useCallback(
    async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
      setGenericError('');

      try {
        // If we've sent code, need to try verifying that
        if (hasSentVerification) {
          await finalizeSignIn(values.verificationCode);
        }

        const authUser = fbAuth.currentUser;
        if (authUser) {
          // Re-authenticate (https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user)
          const credential = EmailAuthProvider.credential(profile.email, values.currentPassword);
          !hasSentVerification && (await reauthenticateWithCredential(authUser, credential));
          // Re-authed successfully, now try to reset email
          await updatePassword(authUser, values.newPassword);
          // Reset email successful—set appropriate success state
          setNewPasswordSuccessful(true);
        } else {
          throw new Error('User not found');
        }
      } catch (error) {
        // Deal with all the various error responses from firebase re-auth and firebase password reset
        if (error instanceof FirebaseError) {
          type ErrorCode = (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];
          const code = error.code as ErrorCode;
          switch (code) {
            case 'auth/wrong-password':
              actions.setFieldError('currentPassword', 'Incorrect password. Try again');
              break;
            case 'auth/weak-password':
              actions.setFieldError('newPassword', "New password isn't strong enough.");
              break;
            case 'auth/multi-factor-auth-required': {
              await sendSmsVerificationCode(error as MultiFactorError);
              return;
            }
            case 'auth/invalid-verification-code': {
              actions.setFieldError('verificationCode', 'Invalid verification code');
              return;
            }
            default:
              setGenericError(
                `Password reset error. Please contact support.\n\nError code: ${code}`
              );
          }
        } else {
          setGenericError(
            `Password reset error. Please contact support.\n\nError: ${JSON.stringify(error)}`
          );
        }
        console.error(error);
      }
    },
    [finalizeSignIn, hasSentVerification, profile.email, sendSmsVerificationCode]
  );

  // Form helper (Formik)
  const {
    values,
    errors,
    // setFieldError,
    submitCount,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: EditEmailSchema,
  });

  // Dissallow submissions when already submitting OR
  // 1. No current password or new password is less than 8 characters
  const submitDisabled =
    isSubmitting ||
    !values.currentPassword ||
    values.newPassword.length < 8 ||
    (hasSentVerification && values.verificationCode.length !== 6); // 1.

  // Listen for when user submits focus any error fields
  // once state has flushed and triggered re-render (defer)
  useEffect(() => {
    if (submitCount && !isSubmitting) {
      if (errors.currentPassword) {
        currentPasswordRef.current && currentPasswordRef.current.focus();
      } else if (errors.newPassword) {
        newPasswordRef.current && newPasswordRef.current.focus();
      }
    }
  }, [errors.currentPassword, errors.newPassword, isSubmitting, submitCount]);

  return createPortal(
    <FormModal
      formTitle='Change password'
      show={show}
      handleSubmit={
        newPasswordSuccessful
          ? e => {
              e && e.preventDefault();
              hide();
              resetVerification();
              setNewPasswordSuccessful(false);
              resetForm({ values: initialValues });
            }
          : handleSubmit
      }
      handleCancel={() => {
        hide();
        resetVerification();
        setNewPasswordSuccessful(false);
        resetForm({ values: initialValues });
      }}
      hideCancel={newPasswordSuccessful}
      submitBtn={newPasswordSuccessful ? 'Finish' : 'Set new password'}
      isSubmitting={isSubmitting}
      disabled={submitDisabled}
    >
      <RecaptchaRoot />
      <Padding padding='32px 32px 64px'>
        {/* Success message */}
        {newPasswordSuccessful ? (
          <CenterText>
            <strong>Your password has been reset!</strong>
          </CenterText>
        ) : (
          // Form
          <>
            {/* Misc error */}
            {!!genericError && (
              <>
                <CenterText>
                  <InputError>{genericError}</InputError>
                </CenterText>
                <Spacer height='16px' />
              </>
            )}

            {/* Not verifing ( just asking for old and new passwords ) */}
            {!hasSentVerification && (
              <>
                {/* Current password */}
                <FormLabel htmlFor='currentPassword'>Current password</FormLabel>
                <PasswordInput
                  forwardedRef={currentPasswordRef}
                  disabled={isSubmitting}
                  {...getFieldProps({ name: 'currentPassword' })}
                  autoComplete='current-password'
                  error={!!submitCount && !!errors.currentPassword}
                />
                {!!submitCount && !!errors.currentPassword && (
                  <InputError>{errors.currentPassword}</InputError>
                )}
                <Spacer height='32px' />
                {/* New password */}
                <FormLabel htmlFor='newPassword'>New password</FormLabel>
                <PasswordInput
                  forwardedRef={newPasswordRef}
                  placeholder='8 characters minimum'
                  disabled={isSubmitting}
                  {...getFieldProps({ name: 'newPassword' })}
                  autoComplete='new-password'
                  error={!!submitCount && !!errors.newPassword}
                />
                {!!submitCount && !!errors.newPassword && (
                  <InputError>{errors.newPassword}</InputError>
                )}
              </>
            )}

            {/* Verification code */}
            {hasSentVerification && (
              <>
                <Note>
                  <div>
                    Check your phone ending in <b>{resolver?.hints[0].displayName || '????'}</b> for
                    a text code. Please enter it below.
                  </div>
                </Note>
                <FormLabel htmlFor='email'>Verification Code</FormLabel>
                <Input
                  disabled={isSubmitting}
                  {...getFieldProps({ name: 'verificationCode' })}
                  autoComplete='false'
                  autoFocus
                  error={submitCount > 1 && !!errors.verificationCode}
                />
                {submitCount > 1 && !!errors.verificationCode && (
                  <InputError>{errors.verificationCode}</InputError>
                )}
              </>
            )}
          </>
        )}
      </Padding>
    </FormModal>,
    document.getElementById('modal-portal')!
  );
};

// Export
export default EditPasswordModal;
