import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Organization from '@/classes/Organization';
import { getStripePrice, showCents } from '@/classes/Stripe';

import { fbFunctions } from '@/lib/firebase';
import { httpsCallable } from 'firebase/functions';

import { getFriendlyDate } from '@/lib/helpers';

import useAppState from '@/contexts/appState';
import useOrganization from '@/contexts/organization';

import SheetHeader from '@/components/layout/SheetHeader';
import { SheetScrollable } from '@/components/layout/Sheets';

import { SecondaryButton } from '@/components/common/Buttons';
import Divider from '@/components/common/Divider';
import Loader from '@/components/common/Loader';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

import NotebirdIcon from '@/components/svg/NotebirdIcon';

// Styles
const Label = styled.div`
  margin-bottom: 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.textTertiary};
  strong {
    color: ${({ theme }) => theme.textSecondary};
  }
`;
const Content = styled.div`
  font-size: 28px;
  em {
    font-size: 16px;
    color: ${({ theme }) => theme.textFaded};
  }
  s {
    color: ${({ theme }) => theme.textTertiary};
    font-weight: normal;
    margin-right: 8px;
  }
`;
// const Extra = styled.div`
//   margin-top: 8px;
//   color: ${({ theme }) => theme.textFaded};
// `;
const BillingDate = styled.div`
  font-size: 22px;
  font-weight: bold;
`;
const ChargeRow = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 8px 12px 16px;
  border-bottom: 2px solid ${({ theme }) => theme.dividerColor};

  .body {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    .danger {
      color: ${({ theme }) => theme.danger500};
      font-size: 14px;
    }
    .extra {
      margin-top: 4px;
      font-size: 16px;
      font-weight: normal;
      color: ${({ theme }) => theme.textFaded};
    }
  }

  a {
    font-size: 16px;
  }
`;
const Notice = styled.div`
  margin: 24px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: ${({ theme }) => theme.textFaded};
`;
const Coupon = styled.div`
  background: ${({ theme }) => theme.hoverFade};
  border: 2px solid ${({ theme }) => theme.offLight};
  border-radius: 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.textTertiary};
  padding: 8px;
  margin-top: 16px;
`;

// Component
const PlanInfoSheet = ({ handleClose }: { handleClose(): void }) => {
  // App state
  const { isActive } = useAppState();

  // Context data
  const [organization] = useOrganization();
  const organizationId = organization?.id ?? '';
  const stripe = organization?._stripe ?? { ...new Organization()._stripe };
  const interval = stripe.interval;
  const usersCount = organization?.users.length ?? 1;
  const quantity = Math.max(usersCount, stripe.quantity, stripe.minQuantity ?? 1);
  const fullPrice = getStripePrice({
    interval,
    usersCount: quantity,
  });
  const stripePrice = getStripePrice({
    interval,
    usersCount: quantity,
    couponAmountOff: stripe.couponAmountOff,
    couponPercentOff: stripe.couponPercentOff,
  });

  // Get list of all this organization's charges from Stripe via cloud func callable
  const [isFetchingCharges, setIsFetchingCharges] = useState(true);
  const [charges, setCharges] = useState<StripeCharge[]>([]);
  useEffect(() => {
    const fetchCharges = async () => {
      if (organizationId) {
        const { data: fetchedCharges } = await httpsCallable<unknown, { data: StripeCharge[] }>(
          fbFunctions,
          'getStripeCharges'
        )({
          organizationId,
        });
        setIsFetchingCharges(false);
        setCharges(fetchedCharges.data);
      }
    };
    fetchCharges();
  }, [organizationId]);

  return (
    <>
      <SheetHeader
        primary
        mainTitle='Subscription details'
        trailing={
          <SecondaryButton
            leadingIcon='close'
            onClick={event => {
              event.preventDefault();
              handleClose();
            }}
          />
        }
      />
      <SheetScrollable>
        <Padding padding='48px 48px 0'>
          {/* Subscription/price details like quantity, amount, interval, etc ( if active ) */}
          {organization && isActive && (
            <>
              {/* User count */}
              <Label>
                <strong>
                  {usersCount}
                  {usersCount < quantity && ` of ${quantity}`}
                </strong>{' '}
                user{quantity > 1 && 's'} @
              </Label>
              {/* Price and interval */}
              <Content>
                <strong>
                  {stripePrice.amount !== fullPrice.amount && <s>${showCents(fullPrice.amount)}</s>}
                  ${showCents(stripePrice.amount)}/{stripe.interval === 'month' ? 'mo' : 'yr'}
                </strong>
              </Content>
              {/* Coupon */}
              {!!stripe.couponName && (
                <Coupon>
                  {stripe.couponName}{' '}
                  {!stripe.couponName.toLowerCase().includes('discount') && 'Discount '} Applied
                </Coupon>
              )}
              {/* <Extra></Extra> */}
              <Spacer height='24px' />
              <Divider padding={0} />
              <Spacer height='24px' />

              {/* Next billing cycle */}
              <Label>Billing cycle renews</Label>
              <BillingDate>{getFriendlyDate(stripe.currentPeriodEnd.toMillis())}</BillingDate>
              <Spacer height='64px' />
            </>
          )}

          {/* Payment history (stripe charge list) */}
          <Label>Payment history</Label>
          {/* Loader and loading text */}
          <Loader show={isFetchingCharges} />
          {isFetchingCharges && <Notice>Loading...</Notice>}
          {/* No charges found */}
          {!isFetchingCharges && !charges.length && <Notice>No payment history</Notice>}
          {/* Charges list when loaded and has some */}
          {!isFetchingCharges && !!charges.length && (
            <>
              {charges.map(charge => {
                const date = new Date(charge.created * 1000);
                return (
                  <ChargeRow key={charge.id}>
                    <div className='body'>
                      ${showCents(charge.amount / 100)}
                      {!charge.paid && <span className='danger'> Unpaid</span>}
                      {charge.refunded && <span className='danger'> Refunded</span>}
                      <div className='extra'>{getFriendlyDate(date)}</div>
                    </div>
                    {!!charge.receipt_url && (
                      <a href={charge.receipt_url} rel='noopener noreferrer' target='_blank'>
                        View receipt
                      </a>
                    )}
                  </ChargeRow>
                );
              })}
              {/* Notebird icon to signify end of list */}
              <Spacer height='16px' />
              <NotebirdIcon dull height='24px' width='100%' />
            </>
          )}
        </Padding>
        <Spacer height='64px' />
      </SheetScrollable>
    </>
  );
};
export default PlanInfoSheet;

interface StripeCharge {
  /**
   * Unique identifier for the object.
   */
  id: string;
  /**
   * Time at which the object was created. Measured in seconds since the Unix epoch.
   */
  created: number;
  /**
   * Amount intended to be collected by this payment. A positive integer representing how much to charge in the [smallest currency unit](https://stripe.com/docs/currencies#zero-decimal) (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency). The minimum amount is $0.50 US or [equivalent in charge currency](https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts). The amount value supports up to eight digits (e.g., a value of 99999999 for a USD charge of $999,999.99).
   */
  amount: number;
  /**
   * `true` if the charge succeeded, or was successfully authorized for later capture.
   */
  paid: boolean;
  /**
   * Whether the charge has been fully refunded. If the charge is only partially refunded, this attribute will still be false.
   */
  refunded: boolean;
  /**
   * This is the URL to view the receipt for this charge. The receipt is kept up-to-date to the latest state of the charge, including any refunds. If the charge is for an Invoice, the receipt will be stylized as an Invoice receipt.
   */
  receipt_url: string | null;
}
