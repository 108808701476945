import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

// Classes
import { UserProfile } from '@/classes/User';

// Libs
import { db, fbAuth } from '@/lib/firebase';
import { FirebaseError } from 'firebase/app';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  updateEmail,
} from 'firebase/auth';
import { type AuthErrorCodes, type MultiFactorError } from 'firebase/auth';

// Hooks
import { type FormikHelpers, useFormik } from 'formik';
import { object, string } from 'yup';

// Context data
import useUser from '@/contexts/user';

import useMultiFactor from '@/hooks/useMultiFactor';

// Form elements
import { Input } from '@/components/formElements/FormElements';
import { InputError, PasswordInput } from '@/components/formElements/FormElements';

import Label, { FormLabel } from '@/components/common/Label';
import { FormModal } from '@/components/common/Modals';
import Padding from '@/components/common/Padding';
// Common components
import Spacer from '@/components/common/Spacer';

// Styles
const CenterText = styled.div`
  text-align: center;
`;
const FadedText = styled.div`
  color: ${({ theme }) => theme.textFaded};
  font-size: 14px;
`;
const Note = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 32px;
  color: ${props => props.theme.textSecondary};
`;

// Form Schema
const EditEmailSchema = object().shape({
  newEmail: string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  confirmNewEmail: string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email address is required'),
  newEmailPassword: string().required('Password is required'),
  verificationCode: string().length(6, 'Verification code must be 6 digits'),
});

// Types / defaults
const initialValues = {
  newEmail: '',
  confirmNewEmail: '',
  newEmailPassword: '',
  verificationCode: '',
};

// Component
interface Props {
  show: boolean;
  hide(): void;
}
const EditEmailModal = ({ show, hide }: Props) => {
  const [user] = useUser();
  const profile = user ? user.profile : { ...new UserProfile() };

  // Form State
  const [newEmailSuccessful, setNewEmailSuccessful] = useState(false);
  const [genericError, setGenericError] = useState('');
  // Refs to target focus
  const newEmailRef = useRef<HTMLInputElement>(null);
  const newEmailPasswordRef = useRef<HTMLInputElement>(null);

  // Multi-factor SMS stuff
  const {
    RecaptchaRoot,
    hasSentVerification,
    resetVerification,
    sendSmsVerificationCode,
    finalizeSignIn,
    resolver,
  } = useMultiFactor();

  // Submit
  const onSubmit = useCallback(
    async (values: typeof initialValues, actions: FormikHelpers<typeof initialValues>) => {
      // Confirm email doesn't match
      setGenericError('');
      if (values.newEmail.trim().toLowerCase() !== values.confirmNewEmail.trim().toLowerCase()) {
        actions.setFieldError('newEmail', 'Emails do not match');
        return;
      }

      try {
        // If we've sent code, need to try verifying that
        if (hasSentVerification) {
          await finalizeSignIn(values.verificationCode);
        }

        const authUser = fbAuth.currentUser;
        if (authUser && user) {
          // Re-authenticate (https://firebase.google.com/docs/auth/web/manage-users#re-authenticate_a_user)
          const credential = EmailAuthProvider.credential(profile.email, values.newEmailPassword);
          !hasSentVerification && (await reauthenticateWithCredential(authUser, credential));
          // Re-authed successfully, now try to reset email
          await updateEmail(authUser, values.newEmail.trim());
          // Reset email successful
          // Update user doc, send verification email, and set appropriate success state
          db.doc(user.docPath).update({ 'profile.email': values.newEmail.trim() });
          sendEmailVerification(authUser);
          setNewEmailSuccessful(true);
        } else {
          throw new Error('User not found');
        }
      } catch (error) {
        if (error instanceof FirebaseError) {
          type ErrorCode = (typeof AuthErrorCodes)[keyof typeof AuthErrorCodes];
          const code = error.code as ErrorCode;
          // Deal with all the various error responses from firebase re-auth and firebase email reset
          switch (code) {
            case 'auth/wrong-password':
              actions.setFieldError('newEmailPassword', 'Incorrect password. Try again');
              break;
            case 'auth/email-already-in-use':
              actions.setFieldError('newEmail', 'This email address already in use');
              break;
            case 'auth/multi-factor-auth-required': {
              await sendSmsVerificationCode(error as MultiFactorError);
              return;
            }
            case 'auth/invalid-verification-code': {
              actions.setFieldError('verificationCode', 'Invalid verification code');
              return;
            }
            default:
              setGenericError(
                `Unknown email reset error. Please contact support.\n\nError code: ${code}`
              );
          }
        } else {
          setGenericError(
            `Unknown email reset error. Please contact support.\n\nError: ${JSON.stringify(error)}}`
          );
        }
        console.error(error);
      }
    },
    [hasSentVerification, user, finalizeSignIn, profile.email, sendSmsVerificationCode]
  );

  // Form helper (Formik)
  const {
    values,
    errors,
    // setFieldError,
    submitCount,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit,
    validationSchema: EditEmailSchema,
  });

  // Dissallow submissions when already submitting OR
  // no new email, confirm email, or password
  const submitDisabled =
    isSubmitting ||
    !values.newEmail.trim() ||
    !values.confirmNewEmail.trim() ||
    !values.newEmailPassword.trim() ||
    (hasSentVerification && values.verificationCode.length !== 6);

  // Listen for when user submits focus any error fields
  // once state has flushed and triggered re-render (defer)
  useEffect(() => {
    if (submitCount && !isSubmitting) {
      if (errors.newEmail) {
        newEmailRef.current && newEmailRef.current.focus();
      } else if (errors.newEmailPassword) {
        newEmailPasswordRef.current && newEmailPasswordRef.current.focus();
      }
    }
  }, [errors.confirmNewEmail, errors.newEmail, errors.newEmailPassword, isSubmitting, submitCount]);

  return createPortal(
    <FormModal
      formTitle='Edit email address'
      show={show}
      handleSubmit={
        newEmailSuccessful
          ? e => {
              e && e.preventDefault();
              hide();
              resetVerification();
              setNewEmailSuccessful(false);
              resetForm({ values: initialValues });
            }
          : handleSubmit
      }
      handleCancel={() => {
        hide();
        resetVerification();
        setNewEmailSuccessful(false);
        resetForm({ values: initialValues });
      }}
      hideCancel={newEmailSuccessful}
      submitBtn={newEmailSuccessful ? 'Finish' : 'Set new email'}
      isSubmitting={isSubmitting}
      disabled={submitDisabled}
    >
      <RecaptchaRoot />
      <Padding padding='32px 32px 64px'>
        {/* Success message */}
        {newEmailSuccessful ? (
          <CenterText>
            <FadedText>
              Your email has been set to <strong>{values.newEmail}</strong>
            </FadedText>
            <Spacer height='32px' />
            <strong>An email has been sent to verify your new address</strong>
          </CenterText>
        ) : (
          // Form
          <>
            {/* Misc error */}
            {!!genericError && (
              <>
                <CenterText>
                  <InputError>{genericError}</InputError>
                </CenterText>
                <Spacer height='16px' />
              </>
            )}

            {/* Not verifing ( just asking for new email address and password ) */}
            {!hasSentVerification && (
              <>
                {/* Current email address */}
                <CenterText>
                  <Label>Current email address</Label>
                  <strong>{profile.email}</strong>
                </CenterText>
                <Spacer height='32px' />

                {/* New email */}
                <FormLabel htmlFor='newEmail'>New email address</FormLabel>
                <Input
                  ref={newEmailRef}
                  autoFocus
                  disabled={isSubmitting}
                  {...getFieldProps({ name: 'newEmail' })}
                  type='email'
                  autoComplete='email'
                  error={!!submitCount && !!errors.newEmail}
                />
                {!!submitCount && !!errors.newEmail && <InputError>{errors.newEmail}</InputError>}
                <Spacer height='16px' />
                {/* Confirm email */}
                <FormLabel htmlFor='confirmEmail'>Confirm new email address</FormLabel>
                <Input
                  disabled={isSubmitting}
                  {...getFieldProps({ name: 'confirmNewEmail' })}
                  type='email'
                  autoComplete='email'
                  error={!!submitCount && !!errors.confirmNewEmail}
                />
                {!!submitCount && !!errors.confirmNewEmail && (
                  <InputError>{errors.confirmNewEmail}</InputError>
                )}
                <Spacer height='32px' />
                {/* Password */}
                <FormLabel htmlFor='newEmailPassword'>Current password</FormLabel>
                <PasswordInput
                  forwardedRef={newEmailPasswordRef}
                  placeholder='Enter your current password'
                  disabled={isSubmitting}
                  {...getFieldProps({ name: 'newEmailPassword' })}
                  autoComplete='current-password'
                  error={!!submitCount && !!errors.newEmailPassword}
                />
                {!!submitCount && !!errors.newEmailPassword && (
                  <InputError>{errors.newEmailPassword}</InputError>
                )}
              </>
            )}

            {/* Verification code */}
            {hasSentVerification && (
              <>
                <Note>
                  <div>
                    Check your phone ending in <b>{resolver?.hints[0].displayName || '????'}</b> for
                    a text code. Please enter it below.
                  </div>
                </Note>
                <FormLabel htmlFor='email'>Verification Code</FormLabel>
                <Input
                  disabled={isSubmitting}
                  {...getFieldProps({ name: 'verificationCode' })}
                  autoComplete='false'
                  autoFocus
                  error={submitCount > 1 && !!errors.verificationCode}
                />
                {submitCount > 1 && !!errors.verificationCode && (
                  <InputError>{errors.verificationCode}</InputError>
                )}
              </>
            )}
          </>
        )}
      </Padding>
    </FormModal>,
    document.getElementById('modal-portal')!
  );
};

// Export
export default EditEmailModal;
