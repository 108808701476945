import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Doc, { WithId } from '@/classes/Doc';
import Person, { PersonProfile } from '@/classes/Person';
import Relationship, {
  RELATIONSHIP_DICT,
  RELATIONSHIP_TYPES,
  RelationshipType,
} from '@/classes/Relationship';

import firebase, { FlattenedTimestamps, db, fbAnalytics } from '@/lib/firebase';
import { logEvent } from 'firebase/analytics';

import { stringToProfileName } from '@/lib/helpers';
import { generateUpdatedByDottedMeta } from '@/lib/helpers/generateMeta';

import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import { usePostHog } from 'posthog-js/react';
import { SearchClient } from 'typesense';
import { SearchParams } from 'typesense/lib/Typesense/Documents';

import useAppState from '@/contexts/appState';
import useOrganization from '@/contexts/organization';
import useUser from '@/contexts/user';

import useDebouncedState from '@/hooks/useDebouncedState';

import { Input } from '@/components/formElements/FormElements';
import FormScaffold from '@/components/formElements/FormScaffold';
import ReactSelect, { ReactCreatableSelect } from '@/components/formElements/ReactSelect';

import Avatar from '@/components/common/Avatar';
import DescriptionBlock from '@/components/common/DescriptionBlock';
import Expanded from '@/components/common/Expanded';
import { FormLabel } from '@/components/common/Label';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

// Styles
const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;
const RelationshipNotice = styled.div`
  margin-top: 12px;
  font-size: 15px;
  color: ${({ theme }) => theme.textFaded};
  strong {
    color: ${({ theme }) => theme.textTertiary};
    text-transform: lowercase;
  }
  a {
    font-weight: bold;
  }
`;

// Default form values
export interface RelationshipFields {
  id: string;
  profile: PersonProfile;
  type: RelationshipType | '';
  customType: string;
}
const emptyValues: RelationshipFields = {
  id: '',
  profile: {
    name: {
      first: '',
      last: '',
      full: '',
    },
    photo: null,
  },
  type: '',
  customType: '',
};

// Props
interface FormProps {
  person: Doc<Person>;
  relationship?: Relationship;
  handleCancel: () => void;
  handleDelete?: () => void;
}
// Component
const RelationshipForm = ({ person, relationship, handleCancel }: FormProps) => {
  const [user] = useUser();
  const [organization] = useOrganization();
  const { organizationId, typesensePeopleKey } = useAppState();
  const posthog = usePostHog();

  // Default values used if editing
  let editingInitialValues = emptyValues;
  if (relationship) {
    const { id, profile, type } = relationship;
    const isCustom = !RELATIONSHIP_TYPES.includes(type as RelationshipType);
    editingInitialValues = {
      id: id || '',
      profile,
      type: isCustom ? 'Other' : (type as RelationshipType),
      customType: isCustom ? type : '',
    };
  }
  // Form helper (Formik)
  const { values, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = useFormik({
    initialValues: relationship ? editingInitialValues : emptyValues,
    onSubmit,
  });

  // Dissallow submissions when already submitting OR
  // 1. Name is empty
  // or
  // 2. Editing relationship and nothing in form values has changed
  const submitDisabled =
    isSubmitting ||
    !values.profile.name.full.trim() || // 1
    (!!relationship && isEqual(editingInitialValues, values)); // 2
  ////////////
  // Submit //
  ////////////
  async function onSubmit() {
    if (user) {
      try {
        const { arrayRemove, arrayUnion } = firebase.firestore.FieldValue;
        const relationshipFromForm: Relationship = {
          id: values.id || null,
          profile: values.profile,
          type: values.type === 'Other' ? values.customType.trim() : values.type,
        };

        // Update person with new or updated relationship
        const batch = db.batch();
        const personRef = db.doc(person.docPath);
        // If editing, first remove the old one
        !!relationship &&
          batch.update(personRef, {
            ...generateUpdatedByDottedMeta(user),
            relationships: arrayRemove(relationship),
          });
        // Then add new
        batch.update(personRef, {
          ...generateUpdatedByDottedMeta(user),
          relationships: arrayUnion(relationshipFromForm),
        });

        // If should be linked with another person, also update there
        if (values.id && organization) {
          const linkedPersonRef = db.doc(organization.docPath).collection('people').doc(values.id);
          const linkedPersonDoc = await linkedPersonRef.get();
          if (linkedPersonDoc.exists) {
            // If editing, first remove the old one
            const linkedPersonRelationships = linkedPersonDoc.get('relationships') || [];
            const oldRelationship = linkedPersonRelationships.find(
              ({ id }: { id: string }) => id === person.id
            );
            // This is to preserve old update type if was previously 'Other'
            const oldOtherRelationshipType =
              !!oldRelationship && !RELATIONSHIP_TYPES.includes(oldRelationship.type)
                ? oldRelationship.type
                : 'Other';
            !!relationship &&
              !!oldRelationship &&
              batch.update(linkedPersonRef, {
                ...generateUpdatedByDottedMeta(user),
                relationships: arrayRemove(oldRelationship),
              });
            // Then add new
            batch.update(linkedPersonRef, {
              ...generateUpdatedByDottedMeta(user),
              relationships: arrayUnion({
                id: person.id,
                profile: person.profile,
                type:
                  !!values.type && values.type !== 'Other'
                    ? RELATIONSHIP_DICT[values.type]
                    : oldOtherRelationshipType,
              }),
            });
          }
        }

        // Commit the batch
        batch.commit();

        // Analytics
        const analyticsProps = {
          is_linked: !!values.id,
          type: relationshipFromForm.type,
        };
        posthog?.capture(
          !relationship ? 'relationship_created' : 'relationship_edited',
          analyticsProps
        );
        logEvent(
          fbAnalytics,
          !relationship ? 'relationship_create' : 'relationship_edit',
          analyticsProps
        );
      } catch (error) {
        // Otherwise report error in console and to user
        console.error(error);
        window.alert(error);
      }
      // Close form when finished
      handleCancel();
    }
  }

  // Typesense client initializer
  const typesense = useMemo(() => {
    if (!typesensePeopleKey) return null;

    const nearestHost = import.meta.env.VITE_TYPESENSE_NEAREST_HOST;
    const hosts = import.meta.env.VITE_TYPESENSE_HOSTS.split(',');
    return new SearchClient({
      nearestNode: nearestHost
        ? {
            host: nearestHost,
            port: 443,
            protocol: 'https',
          }
        : undefined,
      nodes: hosts.map(host => ({
        host: host,
        port: 443,
        protocol: 'https',
      })),
      apiKey: typesensePeopleKey,
      connectionTimeoutSeconds: 5,
    });
  }, [typesensePeopleKey]);

  // Run search and get results ( with Typesense )
  const [searchValue, setSearchValue, debouncedSearch] = useDebouncedState({
    defaultValue: '',
    wait: 200,
  });
  const [searchResults, setSearchResults] = useState<FlattenedTimestamps<WithId<Person>>[]>([]);
  useEffect(() => {
    if (!organizationId || !typesense) return;

    const search = async () => {
      const searchParams: SearchParams = {
        q: debouncedSearch,
        query_by: 'profile.name.full',
        include_fields: ['id', 'profile.name.full', 'profile.photo'],
        sort_by: [
          '_eval(isArchived:false):desc',
          'profile.name.last:asc',
          'profile.name.first:asc',
        ],
        hidden_hits: person.id,
      };
      const results = await typesense
        .collections<Person>(organizationId + '_people')
        .documents()
        .search(searchParams, {});
      const hits =
        (results.hits?.map(hit => hit.document) as FlattenedTimestamps<WithId<Person>>[]) ?? [];
      setSearchResults(hits);
    };
    search();
  }, [debouncedSearch, organizationId, person.id, typesense]);

  // Track focus/blur
  const isSelectingPerson = useRef(false);
  const hasFocus = useRef(false);
  // If input is ever cleared all the way out ( while focused ),
  // set selected value to empty too
  useEffect(() => {
    if (hasFocus.current && !searchValue.trim()) {
      setFieldValue('id', null);
      setFieldValue('profile', emptyValues.profile);
    }
  }, [searchValue, setFieldValue]);
  return (
    <FormScaffold
      formTitle={relationship ? 'Edit relationship' : 'New relationship'}
      isSubmitting={isSubmitting}
      disabled={submitDisabled}
      submitBtn={
        <>
          <strong>Save</strong> relationship
        </>
      }
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
    >
      {/* Manage relationships in Breeze notice ( if linked ) */}
      {organization?._integration?.service === 'breeze' && !!person.integration?.id && (
        <DescriptionBlock>
          Manage relationships within the{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://${organization._integration.subdomain}.breezechms.com/people/view/${person.integration.id}`}
          >
            Breeze family
          </a>{' '}
          for {person.profile.name.first} to prevent data inconsistency.{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://guide.notebird.app/articles/4600703'
          >
            Learn more
          </a>
        </DescriptionBlock>
      )}
      {/* Manage relationships in Planning Center notice ( if linked ) */}
      {organization?._integration?.service === 'planningCenter' && !!person.integration?.id && (
        <DescriptionBlock>
          Manage relationships within the{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://people.planningcenteronline.com/people/${person.integration.id}`}
          >
            Planning Center household
          </a>{' '}
          for {person.profile.name.first} to prevent data inconsistency.{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://guide.notebird.app/articles/3856885#i-see-that-there-are-addition-relationship-types-in-notebird-that-are-not-in-planning-center-whats-up-with-that'
          >
            Learn more
          </a>
        </DescriptionBlock>
      )}
      {/* Manage relationships in Church Windows notice ( if linked ) */}
      {organization?._integration?.service === 'churchWindows' && !!person.integration?.id && (
        <DescriptionBlock>
          Manage relationships within Church Windows for {person.profile.name.first} to prevent data
          inconsistency.{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://guide.notebird.app/articles/8632934#h_267de16717'
          >
            Learn more
          </a>
        </DescriptionBlock>
      )}
      {/* Manage relationships in ShulCloud notice ( if linked ) */}
      {organization?._integration?.service === 'shulCloud' && !!person.integration?.id && (
        <DescriptionBlock>
          Manage relationships within the{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`${organization._integration.url}/admin/members.php?action=view&id=${person.integration.households[0]}`}
          >
            ShulCloud Account
          </a>{' '}
          for {person.profile.name.first} to prevent data inconsistency.{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://guide.notebird.app/articles/3657333'
          >
            Learn more
          </a>
        </DescriptionBlock>
      )}
      <Padding padding='32px 48px 0' mobilePadding='32px 12px 0'>
        <FormLabel>Name</FormLabel>
        <ReactCreatableSelect
          autoFocus={!relationship}
          placeholder='Search for person'
          // Should be able to scroll through
          // since this is at very bottom of form
          captureMenuScroll={false}
          // Bypass react select's default search
          filterOption={() => true}
          // Blur so search value can be reset on focus
          blurInputOnSelect
          // Allow creation of new person as long as there's something to create
          isValidNewOption={(name: string) => !!name.trim()}
          // Hide label if nothing matches
          formatCreateLabel={(name: string) =>
            searchResults.find(
              ({ profile }) => name.trim().toLowerCase() === profile.name.full.trim().toLowerCase()
            ) ? (
              <div>
                Don&apos;t link &quot;<strong>{name}</strong>&quot;
              </div>
            ) : (
              <div>
                Use &quot;<strong>{name}</strong>&quot;
              </div>
            )
          }
          noOptionsMessage={() => 'No other people available'}
          // These are the options taken from typesense search and formatted nicely
          options={searchResults.map(({ profile, id }) => ({
            label: (
              <FlexRow key={id}>
                <Avatar id={id} name={profile.name.full} photo={profile.photo} size={32} />
                <Spacer width='12px' />
                {profile.name.full}
              </FlexRow>
            ),
            value: { id, profile },
          }))}
          // This is the value when searching
          inputValue={searchValue}
          // Every time on type, set search value
          onInputChange={(val: string) => setSearchValue(val)}
          // This is the currently selected value
          value={
            values.profile.name.full.trim()
              ? {
                  value: { id: values.id, profile: values.profile },
                  label: values.profile.name.full as React.ReactNode,
                }
              : { value: '' }
          }
          // Whenever a person is selected, set them as the current value
          onChange={selection => {
            // This to track whether selecting or not to
            // prevent onBlur from being too aggressive
            isSelectingPerson.current = true;

            // Creating new
            if (typeof selection?.value === 'string' && selection.value.trim()) {
              setFieldValue('id', null);
              setFieldValue('profile', {
                name: stringToProfileName(selection.value),
                photo: null,
              });
            }
            // Selecting `Person` from search options
            else if (selection && typeof selection?.value !== 'string') {
              setFieldValue('id', selection.value.id);
              setFieldValue('profile', selection.value.profile);
            }
          }}
          // When focus, we want to return the current value to the search field
          onFocus={() => {
            hasFocus.current = true;
            // This is also when we should reset the isSelecting value
            isSelectingPerson.current = false;
            setSearchValue(values.profile.name.full);
          }}
          // When blurring, change value to match search IF
          // this blur isn't just triggered by 'onChange' and the name
          // is in fact different than the current person value
          onBlur={event => {
            hasFocus.current = false;
            const name = event.target.value.trim();
            if (!isSelectingPerson.current && name !== values.profile.name.full) {
              setFieldValue('id', null);
              setFieldValue('profile', {
                name: stringToProfileName(name),
                photo: null,
              });
            }
          }}
        />
        {/* This notice shows for all linked people */}
        {!!values.id &&
          // Editing/updating same linked person
          (!!relationship && relationship.id === values.id ? (
            <RelationshipNotice>
              This relationship will also be updated for{' '}
              <Link to={'/person/' + values.id}>{values.profile.name.full}</Link>
            </RelationshipNotice>
          ) : (
            // New linked relationship
            <RelationshipNotice>
              {person.profile.name.first} will also be added to{' '}
              <Link to={'/person/' + values.id}>{values.profile.name.full}</Link>
              {!!values.type && values.type !== 'Other' && (
                <>
                  {' '}
                  as a <strong>{RELATIONSHIP_DICT[values.type]}</strong>
                </>
              )}
            </RelationshipNotice>
          ))}
        <Spacer height='48px' />

        {/* Determine relationship ( could be custom/declared by user ) */}
        <FormLabel>Relationship to {person.profile.name.first}</FormLabel>
        <FlexRow>
          <Expanded>
            <ReactSelect
              id='relationshipType'
              placeholder='---'
              isDisabled={isSubmitting}
              value={
                !!values.type && {
                  value: values.type,
                  label: values.type,
                }
              }
              options={RELATIONSHIP_TYPES.map(type => ({ label: type, value: type }))}
              onChange={selection => {
                setFieldValue('type', selection ? selection.value : '');
              }}
            />
          </Expanded>
          {values.type === 'Other' && (
            <>
              <Spacer width='16px' />
              <Expanded>
                <Input
                  autoFocus={!values.customType.trim()}
                  {...getFieldProps({ name: 'customType' })}
                />
              </Expanded>
            </>
          )}
        </FlexRow>
      </Padding>
      <Spacer height='128px' />
    </FormScaffold>
  );
};
export default RelationshipForm;
