import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import useAppState from '@/contexts/appState';
import useLocations from '@/contexts/locations';
import useOrganization from '@/contexts/organization';
import useSessionIsInitializing from '@/contexts/session';

import useUrlQuery from '@/hooks/useUrlQuery';

import AccountPage from '@/components/pages/AccountPage';
import ActivityPage from '@/components/pages/ActivityPage';
import CustomizationsPage from '@/components/pages/CustomizationsPage';
import LoadingPage from '@/components/pages/LoadingPage';
import NoAccessPage from '@/components/pages/NoAccessPage';
import OnboardingPage from '@/components/pages/OnboardingPage';
import PeoplePage from '@/components/pages/PeoplePage';
import PersonPage from '@/components/pages/PersonPage';
import ProfilePage from '@/components/pages/ProfilePage';
import ReportsPage from '@/components/pages/ReportsPage';
import TasksPage from '@/components/pages/TasksPage';
import UsersPage from '@/components/pages/UsersPage';

import AppLayout from '@/components/layout/AppLayout';
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';

import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Loader from '@/components/common/Loader';

// Lazy load import and admin stuff
const ImportPage = lazy(() => import('@/components/pages/ImportPage'));
const AdminPage = lazy(() => import('@/components/pages/AdminPage'));

// Component
const UserRoutes = () => {
  // Potential org type brought over from landing page
  const { orgType } = useUrlQuery();
  // App state
  const { isOnboarding } = useAppState();
  // Context data
  const sessionIsInitializing = useSessionIsInitializing();
  const [organization] = useOrganization();
  const [locations] = useLocations();

  // Onboarding page ( if onboarding )
  if (isOnboarding) {
    return (
      <Routes>
        <Route path='/onboarding' element={<OnboardingPage />} />
        <Route
          path='*'
          element={
            <Navigate to={orgType ? `/onboarding/?orgType=${orgType}` : '/onboarding'} replace />
          }
        />
      </Routes>
    );
  }

  // Missing organization or location(s)
  if (!sessionIsInitializing && (!organization || !locations?.length)) return <NoAccessPage />;

  return (
    <>
      {/* Loading page (if fetching auth or hydrating) */}
      <LoadingPage show={sessionIsInitializing} />

      {/* Only render routes when auth isn't fetching and is completely hydrated */}
      {!sessionIsInitializing && (
        <AppLayout>
          <ErrorSuspendPlaceholder
            fallback={
              <SheetsWrapper>
                <Sheet position='left'>
                  <Loader show={true} />
                </Sheet>
                <Sheet position='right' className='hidden-mobile'>
                  <Loader show={true} />
                </Sheet>
              </SheetsWrapper>
            }
          >
            <Routes>
              {/* Main menu pages */}
              <Route path='/people' element={<PeoplePage />} />
              <Route path='/activity/:sheet?' element={<ActivityPage />} />
              <Route path='/tasks/:sheet?' element={<TasksPage />} />
              <Route path='/users/:id?' element={<UsersPage />} />

              {/* Detail pages */}
              <Route path='/person/:id/:sheet?' element={<PersonPage />} />

              {/* Org/user-wide pages ( in drop down menu ) */}
              <Route path='/profile/:accordion?' element={<ProfilePage />} />
              <Route path='/account/:accordion?/:modal?' element={<AccountPage />} />
              <Route path='/customizations/:accordion?' element={<CustomizationsPage />} />
              <Route path='/import/' element={<ImportPage />} />
              <Route path='/reports/:type?/:locationId?' element={<ReportsPage />} />

              {/* Super admin page */}
              <Route path='/admin/:organizationId?' element={<AdminPage />} />

              {/* Not found?  Redirect to people */}
              <Route path='*' element={<Navigate to='/people' replace />} />
            </Routes>
          </ErrorSuspendPlaceholder>
        </AppLayout>
      )}
    </>
  );
};
export default UserRoutes;
