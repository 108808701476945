import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
// Classes
import Photo from '@/classes/Photo';
// Hooks
import { useDropzone } from 'react-dropzone';
import useDebouncedCallback from '@/hooks/useDebouncedCallback';
// Form elements
import PhotoCropper from './PhotoCropper';
// Common components
import { SecondaryButton } from '../common/Buttons';
import Spacer from '../common/Spacer';
import Avatar from '../common/Avatar';
import Icon from '../common/Icon';
import type { Area } from 'react-easy-crop';

// Styles
const DeletePhotoFlexWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;

  border-radius: 6px;
  background-color: ${props => props.theme.hoverFade};
  border: 2px solid ${props => props.theme.hoverFade};

  /* Disabled */
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;
const PhotoCropperFlexWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  border-radius: 6px;
  background-color: ${props => props.theme.hoverFade};

  /* Disabled */
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;
const PhotoDropzone = styled.div<{ dragActive: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;

  background-color: ${props => props.theme.hoverFade};
  border: 2px dashed
    ${props => (props.dragActive ? props.theme.primary500 : props.theme.lightAccent)};
  border-radius: 6px;

  color: ${props => props.theme.textSecondary};
  font-weight: bold;
  .browse-files-btn:focus {
    text-decoration: underline;
  }

  /* Disabled */
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;
const PhotoPlaceholder = styled.div<{ dragActive: boolean; isUser: boolean }>`
  flex-shrink: 0;
  width: 64px;
  height: 64px;
  margin-right: 24px;
  border-radius: ${({ isUser }) => (isUser ? '6px' : '50%')};

  background-color: ${props => (props.dragActive ? props.theme.primary100 : props.theme.avatarBG)};
  background-size: auto 100%;
  background-position: calc(50% + 0px) calc(50% + 0px);
  background-repeat: no-repeat;
`;
const PhotoError = styled.div`
  margin: 8px 0;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.danger500};
`;
const DragText = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  ${Icon} {
    margin-right: 4px;
    color: ${props => props.theme.textFaded};
  }
`;
const DropText = styled.div`
  align-items: center;
`;

// Types and defaults
interface PhotoFile extends File {
  preview: string;
}
interface Props {
  currentPhoto?: Photo;
  photoFile: PhotoFile | undefined;
  setPhotoFile: Dispatch<SetStateAction<PhotoFile | undefined>>;
  setPhotoCrop: Dispatch<
    SetStateAction<{
      cropX: string;
      cropY: string;
      cropWidth: string;
      cropHeight: string;
    }>
  >;
  setDeletePhoto: () => void;
  showDropzone: boolean;
  disabled?: boolean;
  isUser?: boolean;
}
const ACCEPTED_FILETYPES = { 'image/*': ['.jpg', '.jpeg', '.png'] };
const MAX_FILESIZE = 8000000; // 8mb

// Component
const PhotoUploader = ({
  currentPhoto,
  photoFile,
  setPhotoFile,
  setPhotoCrop,
  setDeletePhoto,
  showDropzone,
  disabled = false,
  isUser = false,
}: Props) => {
  // Errors if improperly uploading photo
  const [photoError, setPhotoError] = useState('');

  // Props received back from PhotoCropper
  const [handlePhotoCropComplete] = useDebouncedCallback(
    (crop: Area) => {
      setPhotoCrop({
        cropX: crop.x.toString(),
        cropY: crop.y.toString(),
        cropWidth: crop.width.toString(),
        cropHeight: crop.height.toString(),
      });
    },
    100,
    { leading: true }
  );

  // https://react-dropzone.netlify.com/
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: () => {
      setPhotoError(''); // Reset error
    },
    onDropAccepted: files => {
      const file: PhotoFile = Object.assign(files[0], {
        preview: URL.createObjectURL(files[0]),
      });
      setPhotoFile(file);
    },
    onDropRejected: rejections => {
      const errorCode = rejections[0]?.errors[0]?.code ?? 'unknown';
      switch (errorCode) {
        case 'file-invalid-type':
          setPhotoError('Only .jpg and .png file types supported');
          break;
        case 'file-too-large':
          setPhotoError('Too big! Files should be less than 8mb');
          break;
        case 'too-many-files':
          setPhotoError('Only 1 file allowed at a time');
          break;
        default:
          setPhotoError('An error occurred. Please try again or contact support.');
      }
    },
    multiple: false,
    disabled,
    accept: ACCEPTED_FILETYPES,
    maxSize: MAX_FILESIZE,
    noClick: true, // Don't click anywhere in the box to open file dialog
    noKeyboard: true, // Don't allow :focus on entire box
  });
  // This revokes the data uri on unmount (if present) to "avoid memory leaks"
  useEffect(
    () => () => {
      photoFile && URL.revokeObjectURL(photoFile.preview);
    },
    [photoFile]
  );
  // End picture upload-drag and drop stuff

  return (
    <>
      {/* Photo to remove if editing */}
      {!photoFile && !showDropzone && currentPhoto instanceof Object && (
        <DeletePhotoFlexWrapper disabled={disabled}>
          <Avatar photo={currentPhoto} size={64} isUser={isUser} />
          <Spacer width='16px' />
          <SecondaryButton
            leadingIcon='close'
            dull
            onClick={event => {
              event.preventDefault();
              setDeletePhoto();
            }}
          >
            Remove
          </SecondaryButton>
        </DeletePhotoFlexWrapper>
      )}
      {/* Dropzone (if new person or editing without photo or deleted photo) */}
      {showDropzone && (
        <>
          <PhotoDropzone {...getRootProps()} dragActive={isDragActive} disabled={disabled}>
            <PhotoPlaceholder dragActive={isDragActive} isUser={isUser} />
            {!isDragActive && (
              <div>
                <DragText>
                  <Icon icon='add_photo_alternate' />
                  Drag and drop profile picture here
                </DragText>
                <SecondaryButton onClick={open} className='browse-files-btn'>
                  or browse files
                </SecondaryButton>
              </div>
            )}
            {isDragActive && <DropText>Drop to upload...</DropText>}
            <input {...getInputProps()} />
          </PhotoDropzone>
          {photoError && <PhotoError>{photoError}</PhotoError>}
        </>
      )}
      {/* Cropper */}
      {!!photoFile && (
        <PhotoCropperFlexWrapper disabled={disabled}>
          <PhotoCropper
            previewUrl={photoFile.preview}
            width={128}
            height={128}
            handleOnComplete={handlePhotoCropComplete}
            isUser={isUser}
          />
          <Spacer width='16px' />
          <SecondaryButton
            leadingIcon='close'
            dull
            onClick={event => {
              event.preventDefault();
              setPhotoFile(undefined);
            }}
          >
            Remove
          </SecondaryButton>
        </PhotoCropperFlexWrapper>
      )}
    </>
  );
};
export default PhotoUploader;
