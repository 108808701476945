// Libs
import { fbAuth } from '@/lib/firebase';
import { signOut } from 'firebase/auth';
// Classes,
// import Organization from '@/classes/Organization';
// import Meta from '@/classes/Meta';
// Context data
import useOrganization from '@/contexts/organization';
import useOrganizations from '@/contexts/organizations';
import { useSetAppState } from '@/contexts/appState';
// import useUser from '@/contexts/user';
// Layout components
import Sheet, { SheetsWrapper } from '@/components/layout/Sheets';
import { StyledAppLayout, PageWrapper } from '@/components/layout/AppLayout';
// Common components
import NotebirdIcon from '@/components/svg/NotebirdIcon';
// import PrimaryButton from '@/components/common/Buttons';
import NoneFoundHeader from '@/components/common/NoneFoundHeader';
import NoneFoundCopy from '@/components/common/NoneFoundCopy';
import Spacer from '@/components/common/Spacer';
import Expanded from '@/components/common/Expanded';
import Padding from '@/components/common/Padding';
import Scrollable from '@/components/layout/Scrollable';
import FlexColumn from '@/components/common/FlexColumn';
import LinkButton from '@/components/common/LinkButton';
// import Loader from '@/components/common/Loader';

// Component
export default function NoAccessPage() {
  // App state
  const setAppState = useSetAppState();
  // Context data
  // const [user] = useUser();
  const [organizations] = useOrganizations();
  const [organization] = useOrganization();

  const otherOrganizations = organizations?.filter(({ id }) => id !== organization?.id) || [];
  // const [creatingOrganization, setCreatingOrganization] = useState(false);

  // When user has no organizations, we need a way for them to make one
  // const createNewOrganization = useCallback(async () => {
  //   if (user) {
  //     setCreatingOrganization(true);
  //     const { id, profile } = user;
  //     // Note: similar to SignUpPage
  //     const organization: Organization = {
  //       ...new Organization(),
  //       meta: {
  //         ...new Meta(),
  //         createdBy: { id, profile },
  //         createdAt: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
  //       },
  //       owner: { id, profile },
  //       admins: [id],
  //       users: [id],
  //       // Start organization name as user's name
  //       profile: {
  //         ...new Organization().profile,
  //         name: profile.name.full,
  //       },
  //     };
  //     const organizationDoc = await db.collection('organizations').add(organization);
  //     setAppState({ organizationId: organizationDoc.id });
  //   }
  // }, [setAppState, user]);

  return (
    <StyledAppLayout>
      <PageWrapper>
        <SheetsWrapper>
          <Sheet position='left'>
            <Scrollable>
              {/* <Loader show={creatingOrganization} /> */}
              <FlexColumn>
                <Expanded />
                <Padding padding='32px 48px'>
                  {/* Notebird icon */}
                  <NotebirdIcon height='32px' width='100%' />
                  <Spacer height='24px' />

                  {/* Can't acces this organization */}
                  {organization && (
                    <>
                      <NoneFoundHeader>
                        You no longer have access to {organization.profile.name}
                      </NoneFoundHeader>
                      <NoneFoundCopy>
                        Please speak with{' '}
                        <a href={`mailto:${organization.owner.profile.email}`}>
                          {organization.owner.profile.name.full}
                        </a>{' '}
                        or another account admin about restoring access.
                      </NoneFoundCopy>
                      <Spacer height='32px' />
                      {/* Other organization's to switch to ( if any ) */}
                      {otherOrganizations.map(organization => (
                        <NoneFoundCopy key={organization.id}>
                          Switch to{' '}
                          <LinkButton
                            // disabled={creatingOrganization}
                            onClick={() => setAppState({ organizationId: organization.id })}
                          >
                            {organization.profile.name}
                          </LinkButton>
                        </NoneFoundCopy>
                      ))}
                    </>
                  )}

                  {/* Has no organizations at all ( create new ) */}
                  {!organization && (
                    <>
                      <NoneFoundHeader>
                        You no longer have access to any organizations
                      </NoneFoundHeader>
                      <Spacer height='16px' />
                      <NoneFoundCopy>
                        Please speak with the user who originally invited you to Notebird or{' '}
                        <a className='intercom-launcher' href='mailto:support@notebird.app'>
                          contact Notebird support
                        </a>
                        .
                        {/* <PrimaryButton small outlined onClick={createNewOrganization}>
                          Create one now
                        </PrimaryButton> */}
                      </NoneFoundCopy>
                    </>
                  )}
                </Padding>
                <Expanded />

                {/* Logout */}
                <NoneFoundCopy>
                  <LinkButton onClick={() => signOut(fbAuth)}>Log out</LinkButton>
                </NoneFoundCopy>

                <Expanded />
              </FlexColumn>
            </Scrollable>
          </Sheet>

          {/* Blank right sheet */}
          <Sheet position='right' className='hidden-mobile' />
        </SheetsWrapper>
      </PageWrapper>
    </StyledAppLayout>
  );
}
