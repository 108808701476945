import { Fragment, useState } from 'react';
// Libs
import { db } from '@/lib/firebase';
import fuzzyTextMatch from '@/lib/helpers/isFuzzyTextMatch';
import generateCreatedByMeta from '@/lib/helpers/generateMeta';
// Classes
import Location from '@/classes/Location';
// Context data
import useAppState, { useSetAppState } from '@/contexts/appState';
import useUser from '@/contexts/user';
import useOrganization from '@/contexts/organization';
import useLocations from '@/contexts/locations';
// Common components
import Accordion from '@/components/common/Accordion';
import DynamicInputRow from '@/components/formElements/DynamicInputRow';
import { SecondaryButton } from '@/components/common/Buttons';
import Spacer from '@/components/common/Spacer';
import Padding from '@/components/common/Padding';
import DescriptionBlock from '@/components/common/DescriptionBlock';

// Props
interface Props {
  show: boolean;
  setDeleteLocationId(id: string): void;
}

// Component
const LocationsAccordion = ({ show, setDeleteLocationId }: Props) => {
  // App state
  const setAppState = useSetAppState();
  const { isActive } = useAppState();
  // Context data
  const [user] = useUser();
  const [organization] = useOrganization();
  const { _integration } = organization || {};
  const [locations] = useLocations();
  // State when creating new location
  const [isAddingLocation, setIsAddingLocation] = useState(false);

  // Whether or not to show Breeze notice
  const integratedWithBreeze = _integration?.service === 'breeze';
  // Whether or not to show Planning Center notice
  const integratedWithPlanningCenter =
    _integration?.service === 'planningCenter' && _integration.status === 'active';
  // Whether or not to show ShulCloud notice
  const integratedWithShulcloud = _integration?.service === 'shulCloud';

  const hasIntegration =
    integratedWithBreeze || integratedWithPlanningCenter || integratedWithShulcloud;

  return (
    <Accordion show={show}>
      {/* Learn more about locations notice */}
      {!hasIntegration && (
        <DescriptionBlock>
          Locations help organize people and keeps information separate.{' '}
          {/* <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://guide.notebird.app/articles/3584775'
          >
            Learn more
          </a> */}
        </DescriptionBlock>
      )}

      {/* Breeze locations notice */}
      {integratedWithBreeze && (
        <DescriptionBlock danger>
          When linked with Breeze, we manage locations for you.
        </DescriptionBlock>
      )}
      {/* Planning Center locations notice */}
      {integratedWithPlanningCenter && (
        <DescriptionBlock danger>
          When linked with Planning Center, we manage locations for you.{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://guide.notebird.app/articles/3642174'
          >
            Learn more
          </a>
        </DescriptionBlock>
      )}
      {/* Shulcloud locations notice */}
      {integratedWithShulcloud && (
        <DescriptionBlock danger>
          When linked with ShulCloud, we manage locations for you.
        </DescriptionBlock>
      )}

      <Padding padding='16px 32px 32px'>
        {/* Each location */}
        {locations?.map(location => (
          <Fragment key={'location-' + location.id}>
            <DynamicInputRow
              name={'location-' + location.id}
              required
              locked={!isActive || hasIntegration}
              maxLength={75}
              error={value =>
                !!locations?.find(
                  ({ id, name }) =>
                    location.id !== id &&
                    fuzzyTextMatch(value, name, { ignoreSpecialCharacters: false })
                ) && 'This location already exists'
              }
              value={location.name}
              documentPath={location.docPath}
              fieldName='name'
              includeUpdatedByMeta
              onDelete={
                locations.length > 1
                  ? () => {
                      setDeleteLocationId(location.id);
                    }
                  : undefined
              }
            />
            <Spacer height='4px' />
          </Fragment>
        ))}
        <Spacer height='8px' />

        {/* Add location - form */}
        {isAddingLocation && (
          <DynamicInputRow
            name='newLocation'
            placeholder='New location'
            required
            maxLength={75}
            editOnMount
            error={value =>
              !!locations?.find(({ name }) =>
                fuzzyTextMatch(value, name, { ignoreSpecialCharacters: true })
              ) && 'This location already exists'
            }
            value=''
            onCancel={() => setIsAddingLocation(false)}
            onSubmit={async value => {
              // Close form
              setIsAddingLocation(false);

              // Add to DB
              if (user && organization) {
                const newLocation: Location = {
                  ...new Location(),
                  meta: generateCreatedByMeta(user),
                  name: value.trim(),
                };
                const { id } = await db
                  .doc(organization.docPath)
                  .collection('locations')
                  .add(newLocation);
                // Change to location once committed
                setAppState({ locationId: id });
              }
            }}
          />
        )}

        {/* Add location button ( if not already adding location AND isn't integrated with another service ) */}
        {!isAddingLocation && !hasIntegration && (
          <SecondaryButton
            leadingIcon='add_circle'
            disabled={!isActive || (!!locations && locations.length >= 10)}
            onClick={() => setIsAddingLocation(true)}
          >
            Add <strong>location</strong>
          </SecondaryButton>
        )}
      </Padding>
    </Accordion>
  );
};

export default LocationsAccordion;
