import { memo, useMemo, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
// Libs
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Interweave } from 'interweave';
import { Url, UrlMatcher, EmailMatcher } from 'interweave-autolink';
import { getFriendlyDate } from '@/lib/helpers';
// Classes
import { WithId } from '@/classes/Doc';
import Update from '@/classes/Update';
// Context data
import useGroups from '@/contexts/groups';
// Common components
import EditDeleteMenu from './EditDeleteMenu';
import Avatar from './Avatar';
import NotebirbAvatar from './NotebirbAvatar';
import Spacer from './Spacer';
import GroupLabel from './GroupLabel';
import FlexRow from './FlexRow';
import Expanded from './Expanded';
import Margin from './Margin';
import Icon from './Icon';

// Styles
const StyledUpdateItem = styled.div`
  display: flex;
  position: relative;

  padding: 0 24px 24px;
  @media (max-width: 767px) {
    padding: 0 16px 24px;
  }

  /* Edit button */
  &:hover {
    button > i {
      color: ${({ theme }) => theme.avatarText};
    }
  }
`;
const PersonNameLink = styled(Link)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  margin-bottom: 4px;
  font-size: 18px;
  font-weight: bold;
`;
const Notes = styled.div`
  font-size: 18px;
  white-space: pre-line;
  margin-bottom: 4px;

  /* For search results */
  em {
    font-style: normal;
    font-weight: bold;
    border-bottom: 2px solid ${({ theme }) => theme.linkColor};
    padding: 1px 2px;
    margin: 0 1px;
    background-color: ${({ theme }) => theme.primary000};
    color: ${({ theme }) => theme.grey900};
  }
`;
const Signature = styled.div`
  overflow: hidden;
  flex: 1;
`;
const NameTimeRow = styled.div`
  display: flex;
  align-items: baseline;
`;
const UserName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-weight: bold;
  color: ${({ theme }) => theme.textSecondary};
`;
const Time = styled.div`
  white-space: nowrap;
  font-size: 14px;
  color: ${({ theme }) => theme.textTertiary};
`;
const Type = styled.div`
  display: inline-block;
  margin-right: 6px;
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.textTertiary};
`;
const Place = styled.div`
  display: inline-block;
  font-size: 14px;
  color: ${({ theme }) => theme.textFaded};
`;
const RestrictedTag = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 12px 4px 6px;
  background-color: ${({ theme }) => theme.danger000};
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.danger600};
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.danger100};

  i {
    margin-right: 4px;
    color: ${({ theme }) => theme.danger500};
  }
`;

interface UpdateItemProps {
  update: WithId<Update>;
  setEditUpdateDoc?: Dispatch<SetStateAction<WithId<Update> | undefined>>;
  setDeleteUpdateDoc?: Dispatch<SetStateAction<WithId<Update> | undefined>>;
  canEdit?: boolean;
  hidePerson?: boolean;
  hideUser?: boolean;
  hideGroups?: boolean;
  showDate?: boolean;
}

// Component
const UpdateItem = memo(function UpdateItem({
  update,
  setEditUpdateDoc,
  setDeleteUpdateDoc,
  canEdit = false,
  hidePerson = false,
  hideUser = false,
  hideGroups = false,
  showDate = false,
}: UpdateItemProps) {
  const [groups] = useGroups();
  const groupNames = useMemo(
    () =>
      update.groups
        .map(groupId => groups?.find(({ id }) => id === groupId)?.name || '')
        .filter(groupName => !!groupName),
    [groups, update.groups]
  );

  const userProfile = update.meta.createdBy.profile;
  const showNotebirb = userProfile.preferNotebirb || !userProfile.photo;

  return (
    <StyledUpdateItem>
      {/* Person photo */}
      {!hidePerson && (
        <>
          <Margin margin='0 0 0 -8px' mobileMargin='-4' />
          <Avatar
            id={update.person.id}
            photo={update.person.profile.photo}
            name={update.person.profile.name.full}
            size={48}
            sizeOverride={42}
          />
          <Spacer width='8px' />
        </>
      )}

      {/* Update content */}
      <Expanded style={{ overflow: 'hidden' }}>
        {/* Person name */}
        {!hidePerson ? (
          <PersonNameLink to={'/person/' + update.person.id}>
            {update.person.profile.name.full}
          </PersonNameLink>
        ) : (
          <Spacer height='8px' />
        )}

        {/* Notes ( or type if no notes ) */}
        <Notes>
          <Interweave
            content={update.notes || `<strong>${update.type}</strong>`}
            matchers={[
              new UrlMatcher('url', {}, props => <Url {...props} newWindow />),
              new EmailMatcher('email'),
            ]}
          />
        </Notes>

        {/* Groups */}
        {!hideGroups &&
          groupNames.map(groupName => <GroupLabel key={groupName}>{groupName}</GroupLabel>)}
        <Spacer height='4px' />

        {/* User avatar, name, and time */}
        <FlexRow>
          {/* User notebirb/photo */}
          {!hideUser && (
            <>
              {showNotebirb ? (
                <NotebirbAvatar notebirb={userProfile.notebirb} size={22} />
              ) : (
                <Avatar
                  photo={userProfile.photo}
                  name={userProfile.name.display}
                  size={24}
                  sizeOverride={22}
                  isUser
                />
              )}
              <Spacer width='8px' />
            </>
          )}

          <Signature>
            {/* User name and time */}
            {!hideUser && (
              <NameTimeRow>
                <UserName>{userProfile.name.display}</UserName>
                {!!update.meta.createdAt && (
                  <Time>
                    &nbsp;·{' '}
                    {showDate
                      ? getFriendlyDate(update.meta.createdAt.toMillis())
                      : DateTime.fromMillis(update.meta.createdAt.toMillis()).toLocaleString(
                          DateTime.TIME_SIMPLE
                        )}
                  </Time>
                )}
              </NameTimeRow>
            )}
            {/* Type / Place */}
            <div>
              {!!update.notes && update.type && <Type>{!!update.notes && update.type}</Type>}
              {!!update.place && update.place.mainText && (
                <Place
                  title={
                    update.place.mainText +
                    (!!update.place.secondaryText && ' ' + update.place.secondaryText)
                  }
                >
                  {!!update.place && update.place.mainText}
                </Place>
              )}
            </div>
            {/* Put time after if not showing user */}
            {hideUser && !!update.meta.createdAt && (
              <Time>
                {showDate
                  ? getFriendlyDate(update.meta.createdAt.toMillis())
                  : DateTime.fromMillis(update.meta.createdAt.toMillis()).toLocaleString(
                      DateTime.TIME_SIMPLE
                    )}
              </Time>
            )}
          </Signature>

          {/* Restricted Tag */}
          {update.visibility === 'restricted' && (
            <RestrictedTag>
              <Icon icon='lock' iconSize='16px' /> RESTRICTED
            </RestrictedTag>
          )}
        </FlexRow>
      </Expanded>

      {/* Edit/delete dropdown btn ( if has access ) */}
      {canEdit && !update.isArchived && (
        <EditDeleteMenu
          handleEdit={() => setEditUpdateDoc?.(update)}
          handleDelete={() => setDeleteUpdateDoc?.(update)}
        />
      )}
    </StyledUpdateItem>
  );
});
export default UpdateItem;
