import { lazy, useCallback, useState } from 'react';
import styled from 'styled-components';

import { UserProfile } from '@/classes/User';

import { db } from '@/lib/firebase';

import { type Country, getCountryCallingCode } from 'react-phone-number-input';

import useUser from '@/contexts/user';

import DisableMultiFactorSmsModal from './DisableMultiFactorSmsModal';
import EditAvatarModal from './EditAvatarModal';
import EditEmailModal from './EditEmailModal';
import EditPasswordModal from './EditPasswordModal';

import DynamicInputRow from '@/components/formElements/DynamicInputRow';
import { Input, Toggle } from '@/components/formElements/FormElements';

import Accordion from '@/components/common/Accordion';
import Avatar from '@/components/common/Avatar';
import { SecondaryButton } from '@/components/common/Buttons';
import ErrorSuspendPlaceholder from '@/components/common/ErrorSuspendPlaceholder';
import Expanded from '@/components/common/Expanded';
import Label from '@/components/common/Label';
import NotebirbAvatar from '@/components/common/NotebirbAvatar';
import Padding from '@/components/common/Padding';
import Spacer from '@/components/common/Spacer';

const EnableMultiFactorSmsModal = lazy(() => import('./EnableMultiFactorSmsModal'));

// Styles
const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;
const EditAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 0 12px;
`;
const EditAvatarButton = styled(SecondaryButton)`
  font-size: 16px;
`;

// Component
const ProfileAccordion = ({ show }: { show: boolean }) => {
  const [user] = useUser();
  const profile = user ? user.profile : { ...new UserProfile() };
  const showNotebirb = profile.preferNotebirb || !profile.photo;

  const [modal, setModal] = useState<
    | 'editAvatar'
    | 'editEmail'
    | 'editPassword'
    | 'enableMultiFactorSms'
    | 'disableMultiFactorSms'
    | null
  >(null);
  const hide = useCallback(() => {
    setModal(null);
  }, []);

  const isNonUS = navigator.languages[0] !== 'en-US';
  const showWhatsAppPref =
    !!user?.preferences.whatsAppCode || isNonUS || user?.profile.email.includes('@notebird');

  return (
    <>
      <Accordion show={show}>
        <Padding
          padding='24px 32px 32px'
          mobilePadding='24px 16px 32px'
          data-intercom-target='Profile accordion'
        >
          <FlexRow>
            {/* Name section */}
            <Expanded>
              {/* First name */}
              <DynamicInputRow
                label='First name'
                name='firstName'
                value={profile.name.first}
                required
                onSubmit={firstName => {
                  if (user && firstName) {
                    const lastName = user.profile.name.last;
                    const fullName = [firstName, lastName].join(' ').trim();
                    db.doc(user.docPath).update({
                      'profile.name.first': firstName,
                      'profile.name.full': fullName,
                    });

                    // Was there a reason I orgianlly put this in a transaction?
                    // Transform first and full name
                    // db.runTransaction(async tx => {
                    //   const userDoc = await tx.get(db.doc(currentUser.docPath));
                    //   const lastName = userDoc.get('profile.name.last') || '';
                    //   const fullName = [firstName, lastName].join(' ').trim();
                    //   tx.update(userDoc.ref, {
                    //     'profile.name.first': firstName,
                    //     'profile.name.full': fullName,
                    //     'profile.name.display': fullName
                    //   });
                    // });
                  }
                }}
              />
              <Spacer height='16px' />

              {/* Last name */}
              <DynamicInputRow
                label='Last name'
                name='lastName'
                value={profile.name.last}
                required
                placeholder='Last name'
                onSubmit={lastName => {
                  if (user && lastName) {
                    const firstName = user.profile.name.first;
                    const fullName = [firstName, lastName].join(' ').trim();
                    db.doc(user.docPath).update({
                      'profile.name.last': lastName,
                      'profile.name.full': fullName,
                    });

                    // Again, was there a good reason I orgianlly put this in a transaction?
                    // Transform last and full name
                    // db.runTransaction(async tx => {
                    //   const userDoc = await tx.get(db.doc(currentUser.docPath));
                    //   const firstName = userDoc.get('profile.name.first') || '';
                    //   const fullName = [firstName, lastName].join(' ').trim();
                    //   tx.update(userDoc.ref, {
                    //     'profile.name.last': lastName,
                    //     'profile.name.full': fullName,
                    //     'profile.name.display': fullName
                    //   });
                    // });
                  }
                }}
              />
              <Spacer height='16px' />

              {/* Display name */}
              <DynamicInputRow
                label='Display name'
                name='displayName'
                value={profile.name.display}
                required
                documentPath={user?.docPath}
                fieldName='profile.name.display'
                maxLength={100}
              />
            </Expanded>
            {/* Avatar (or notebirb) */}
            <EditAvatar>
              {showNotebirb ? (
                <NotebirbAvatar notebirb={profile.notebirb} size={64} />
              ) : (
                <Avatar photo={profile.photo} size={64} isUser />
              )}
              {/* Edit button */}
              <EditAvatarButton
                onClick={() => setModal('editAvatar')}
                small
                leadingIcon='edit'
                // I guess this needs to stay enabled in case the
                // crop fails and remains in 'pending' status
                // disabled={profile.photo === 'pending'}
              >
                <strong>Edit</strong>
              </EditAvatarButton>
            </EditAvatar>
          </FlexRow>
          <Spacer height='24px' />

          {/* Email */}
          <Label>Email</Label>
          <Padding padding='4px 0 4px 12px'>
            <FlexRow>
              <Expanded>
                <strong>{profile.email}</strong>
              </Expanded>
              <SecondaryButton onClick={() => setModal('editEmail')}>Change email</SecondaryButton>
            </FlexRow>
          </Padding>
          <Spacer height='16px' />

          {/* Password */}
          <Label>Password</Label>
          <Padding padding='2px 0 2px 12px'>
            <FlexRow>
              <Expanded>
                <strong>••••••••••••</strong>
              </Expanded>
              <SecondaryButton onClick={() => setModal('editPassword')}>
                Change password
              </SecondaryButton>
            </FlexRow>
          </Padding>

          {/* 2-Factor Auth ( but only if verified user ) */}
          {user?.isEmailVerified && (
            <>
              <Spacer height='16px' />
              <Label>2-Factor Text Message Authentication</Label>
              <Padding padding='2px 0 2px 12px'>
                <FlexRow>
                  <Expanded>
                    {!user?.multiFactorSms && <strong>Disabled</strong>}
                    {!!user?.multiFactorSms && (
                      <>
                        <strong>ENABLED</strong> for phone ending in {user.multiFactorSms}
                      </>
                    )}
                  </Expanded>
                  {!user?.multiFactorSms && (
                    <SecondaryButton onClick={() => setModal('enableMultiFactorSms')}>
                      Enable 2FA
                    </SecondaryButton>
                  )}
                  {!!user?.multiFactorSms && (
                    <SecondaryButton
                      danger
                      onClick={() => {
                        setModal('disableMultiFactorSms');
                      }}
                    >
                      Disable 2FA
                    </SecondaryButton>
                  )}
                </FlexRow>
              </Padding>
            </>
          )}

          {/* Enable WhatsApp */}
          {showWhatsAppPref && (
            <>
              <Spacer height='16px' />
              <Label>WhatsApp</Label>
              <Padding padding='2px 0 2px 12px'>
                <FlexRow>
                  <Toggle
                    onChange={e => {
                      if (!user) return;

                      const { checked } = e.target;
                      if (checked) {
                        let callingCode = '';
                        try {
                          const estimatedCountry = navigator.languages[0].split('-')[1] as Country;
                          callingCode = getCountryCallingCode(estimatedCountry as Country) || '';
                        } catch (error) {
                          console.error(error);
                        }
                        db.doc(user.docPath).update({ 'preferences.whatsAppCode': callingCode });
                        return;
                      }

                      if (!checked)
                        db.doc(user.docPath).update({ 'preferences.whatsAppCode': null });
                    }}
                    checked={user?.preferences.whatsAppCode != null}
                  />
                  &nbsp;&nbsp;
                  <strong>Use WhatsApp for Texting</strong>
                  <Expanded />
                  {!!user && user.preferences.whatsAppCode != null && (
                    <>
                      <Label>
                        Country Code
                        <Input
                          style={{ width: '128px' }}
                          value={'+' + user.preferences.whatsAppCode}
                          maxLength={4}
                          onChange={e => {
                            const whatsAppCode = e.target.value.replace(/\D/g, '');
                            if (whatsAppCode !== user.preferences.whatsAppCode) {
                              db.doc(user.docPath).update({
                                'preferences.whatsAppCode': whatsAppCode,
                              });
                            }
                          }}
                        />
                      </Label>
                    </>
                  )}
                </FlexRow>
              </Padding>
            </>
          )}
        </Padding>
      </Accordion>

      {/* Modals */}
      <EditAvatarModal show={modal === 'editAvatar'} hide={hide} />
      <EditEmailModal show={modal === 'editEmail'} hide={hide} />
      <EditPasswordModal show={modal === 'editPassword'} hide={hide} />
      <ErrorSuspendPlaceholder>
        <EnableMultiFactorSmsModal show={modal === 'enableMultiFactorSms'} hide={hide} />
      </ErrorSuspendPlaceholder>
      <DisableMultiFactorSmsModal show={modal === 'disableMultiFactorSms'} hide={hide} />
    </>
  );
};
export default ProfileAccordion;
