import { createContext, useContext } from 'react';
// Classes
import Doc from '@/classes/Doc';
import { LocationCounts as Counts } from '@/classes/Counts';
// Hooks
import useDoc from '@/hooks/useDoc';
// Context data
import useAppState from './appState';

// Context ( with hook shortcut )
const fetchingCounts: [Doc<Counts> | null, boolean] = [null, true];
const countsContext = createContext(fetchingCounts);
const useCounts = () => useContext(countsContext);
export default useCounts;

// Context definition w/ provider
export const CountsProvider = ({ children }: { children: React.ReactNode }) => {
  const { organizationId, locationId } = useAppState();

  const counts = useDoc<Counts>(
    !!organizationId && !!locationId && `organizations/${organizationId}/_counts/${locationId}`
  );
  return <countsContext.Provider value={counts}>{children}</countsContext.Provider>;
};
